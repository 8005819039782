import React from 'react';

import { Link, useLocation } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import * as Api from "api";
import ProgressIndicator from 'tsui/ProgressIndicator';
import PageContents from 'components/PageContents';
import ImgElement from 'tsui/DomElements/ImgElement';
import { Box } from '@mui/material';
import Env from 'env';
import session_ from 'api/session';
import { useTranslation } from 'react-i18next';



export default function SuccessPayment() {
    const [t] = useTranslation();

    const mounted = React.useRef(false);
    const [dataRequested, setDataRequested] = React.useState(false);
    let [error, setError] = React.useState<Error | null>(null);
    let [reqAnswerMsg, setReqAnswerMsg] = React.useState<string | null>(null);
    let [reqAnswerCodeColor, setReqAnswerMsgColor] = React.useState<string | null>(null);

    const search = useLocation().search;
    const urlParams = new URLSearchParams(search);
    // const arcaOrderId = urlParams.get('orderId');
    const ameriaPaymentId = urlParams.get('paymentID');
    const paymentId = urlParams.get('orderID');
    let resMsg = urlParams.get('description');

    console.log('arcaOrderId', ameriaPaymentId)

    React.useEffect(() => {
        mounted.current = true;
        if (!dataRequested) {
            Api.requestSessionJson<any>('payments', 'get_status', { ameriaPaymentId: ameriaPaymentId!, paymentId: paymentId!, language: session_.userLang ?? 'en' }).then(d => {
                if (mounted.current) {
                    console.log('d.code', d.code)
                    if (d.code === 0) {
                        setReqAnswerMsgColor('green')
                        // setReqAnswerMsg('Payment processed sucessfully')
                    } else {
                        setReqAnswerMsgColor(theme_.erorTextColor)
                        // setReqAnswerMsg(d.requestAnswer)
                    }
                    setReqAnswerMsg(d.requestAnswer)
                }
            }).catch(setError);
            setDataRequested(true);
            return;
        }
        return () => { mounted.current = false }
    }, [dataRequested]);



    if (resMsg) {
        if (resMsg === "Գործքրքը կատարված է" || resMsg === "գործքրքը կատարված է") {
            resMsg = "Գործարքը կատարված է"
        }
        return <PageContents title='Payment Answer'>
            <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' />  {/* svg/png */}
            <Box sx={{ width: '100%', textAlign: 'center', justifyContent: 'center', mt: 10 }}>

                <Box sx={{
                    display: 'inline-block',
                    width: Env.isMobile ? '65%' : '50%',
                    border: `1px solid ${theme_.themeColor}`,
                    backgroundColor: theme_.evocardFeatureBackgroundColor,
                    p: 5,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderStyle: 'solid',
                }}>
                    <Typography sx={{
                        fontSize: Env.isMobile ? 'xx-large' : 'xxx-large',
                        color: reqAnswerCodeColor
                    }}>{resMsg}</Typography>
                    <Link style={{ fontSize: Env.isMobile ? 'x-large' : 'xx-large', color: theme_.themeColor, }} to='/orders'>
                        {t('My Orders')}
                    </Link>
                </Box>
            </Box>
        </PageContents>

    }


    if (!reqAnswerMsg) {
        return <PageContents title='Loading'>
            <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' />  {/* svg/png */}
            <ProgressIndicator />

        </PageContents >
    }

    if (reqAnswerMsg === "Գործքրքը կատարված է" || reqAnswerMsg === "գործքրքը կատարված է") {
        reqAnswerMsg = "Գործարքը կատարված է"
    }

    return <PageContents title='Payment Answer'>
        <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' />  {/* svg/png */}
        <Box sx={{ width: '100%', textAlign: 'center', justifyContent: 'center', mt: 10 }}>

            <Box sx={{
                display: 'inline-block',
                width: Env.isMobile ? '65%' : '50%',
                border: `1px solid ${theme_.themeColor}`,
                backgroundColor: theme_.evocardFeatureBackgroundColor,
                p: 5,
                borderRadius: 4,
                borderWidth: 1,
                borderStyle: 'solid',
            }}>
                <Typography sx={{
                    fontSize: Env.isMobile ? 'xx-large' : 'xxx-large',
                    color: reqAnswerCodeColor ? reqAnswerCodeColor : 'white'
                }}>{reqAnswerMsg}</Typography>
                <Link style={{ fontSize: Env.isMobile ? 'x-large' : 'xx-large', color: theme_.themeColor, }} to='/orders'>
                    {t('My Orders')}
                </Link>
            </Box>
        </Box>

    </PageContents>
}


//     return <PageContents title='Payment Answer'>
//         <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' />  {/* svg/png */}
//         <Box sx={{ width: '100%', textAlign: 'center', justifyContent: 'center', mt: 10 }}>

//             <Box sx={{
//                 display: 'inline-block',
//                 width: Env.isMobile ? '65%' : '50%',
//                 border: `1px solid ${theme_.themeColor}`,
//                 backgroundColor: theme_.evocardFeatureBackgroundColor,
//                 p: 5,
//                 borderRadius: 4,
//                 borderWidth: 1,
//                 borderStyle: 'solid',
//             }}>
//                 <Typography
//                     sx={{

//                         fontSize: Env.isMobile ? 'xx-large' : 'xxx-large',
//                         color: 'green',
//                     }}
//                 >
//                     Payment has successfully done.
//                 </Typography>
//                 <Link style={{ fontSize: Env.isMobile ? 'x-large' : 'xx-large', color: 'black', }} to='/orders'>
//                     My Orders
//                 </Link>
//             </Box>
//         </Box>
//     </PageContents>
// }
