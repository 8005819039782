import React from 'react';

import { Box, Grid, Stack } from '@mui/material';

import { CardCategoryInit, cardDataArray_ } from 'data/card';
import CardBox from './Card';
import Env from 'env';


interface CardCategoryProps {
    category: CardCategoryInit;
}

export default function CardCategory(props: CardCategoryProps) {


    if (Env.isMobile) {
        let portrait = window.matchMedia("(orientation: landscape)");

        // window.addEventListener("orientationchange", (event) => {
        //     window.location.reload();
        // });

        // let portrait = window.matchMedia("(orientation: landscape)");
        // console.log(portrait.matches)

        return (
            <Stack direction='column' width='100%' justifyContent='center' alignItems='center' sx={{ mt: 5 }}>
                {/* <Category>
                </Category> */}
                {/* <Grid container spacing={6} justifyContent='center'> */}
                {/* <Stack direction='column' spacing={5} width='95%'> */}
                <Box sx={{
                    width: '95%',
                    '@media screen and (orientation:landscape)': {
                        display: 'flex',
                    },
                }}>
                    {/* <Stack direction={portrait.matches ? 'row' : 'column'} spacing={5} width='95%'> */}

                    {cardDataArray_.map((card, index) => (
                        // <Grid
                        //     key={index}
                        //     item
                        //     xs={10}
                        //     sm={6}
                        //     md={6}
                        //     sx={{
                        //         mt: -5,
                        //         display: 'flex',
                        //         justifyContent: 'center',
                        //     }}
                        // >
                        <React.Fragment key={index}>
                            <CardBox card={card} />
                        </React.Fragment>
                        // </Grid>
                    ))}
                    {/* </Stack> */}
                </Box>
                {/* </Grid> */}
            </Stack>
        );
    }

    return (
        <Stack direction='column' width='100%' justifyContent='center' alignItems='center' sx={{ mt: 5 }}>
            {/* <Category>
            </Category> */}
            {/* <Grid container spacing={6} justifyContent='center'> */}
            <Stack direction='row' spacing={40} >
                {cardDataArray_.map((card, index) => (
                    // <Grid
                    //     key={index}
                    //     item
                    //     xs={10}
                    //     sm={6}
                    //     md={6}
                    //     sx={{
                    //         mt: -5,
                    //         display: 'flex',
                    //         justifyContent: 'center',
                    //     }}
                    // >
                    <React.Fragment key={index}>
                        <CardBox card={card} />
                    </React.Fragment>
                    // </Grid>
                ))}
            </Stack>
            {/* </Grid> */}
        </Stack>
    );
}
