
import { styled } from "@mui/material";
import ImgElement from "../../../tsui/DomElements/ImgElement";
import { makeSxProps } from "../../../tsui/Mui/SxPropsUtil";

// let mobileWidth = 140
// let mobileHeight = mobileWidth * 2;
let mobileWidth = 100
let mobileHeight = mobileWidth * 2.2;
// let desktopWidth = 270
// let desktopHeight = 550

// const phoneLeft = -3;
// const phoneTop = -40;
const phoneLeft = -1.2;
const phoneTop = -12;

const rightIconRadioLeft = 70;
const rightIconRadioTop = -8;

const radioSize = 65;

const leftIconRadioLeft = -65;
const leftIconRadioTop = 25;

const animDuration = '5s';


const phoneSx = makeSxProps({
    position: 'absolute',
    left: phoneLeft * 22,
    top: phoneTop,
    width: mobileWidth * 1.8,
    height: mobileHeight,
})

const phoneTransform = makeSxProps({
    // transform: 'perspective(700px) translateZ(-200px) rotateY(20deg) rotateZ(-17deg) rotateX(30deg)',
    transform: 'translateZ(-200px)'
})

const rightIconRadioTransform = makeSxProps({
    transform: 'perspective(700px) translateZ(-200px) rotateY(20deg) rotateZ(-17deg) rotateX(30deg)',
})

const leftIconRadioTransform = makeSxProps({
    transform: 'rotate(180deg) perspective(700px) translateZ(-200px) rotateY(20deg) rotateZ(-17deg) rotateX(30deg)',
})


const rightIconRadioSx = makeSxProps({
    position: 'absolute',
    left: rightIconRadioLeft,
    top: rightIconRadioTop,
    width: radioSize,
    height: radioSize,
})

const leftIconRadioSx = makeSxProps({
    position: 'absolute',
    left: leftIconRadioLeft,
    top: leftIconRadioTop,
    width: radioSize,
    height: radioSize,
})





// import { styled } from "@mui/material";


// let widthMobPic = 120;
// let heightMobPic = 250;
// let marginLeftMobPic = '40vw';

// export const KeyframeMobileImgMobVersion = styled("img")({

//     marginTop: -25,
//     marginLeft: marginLeftMobPic,
//     width: widthMobPic,
//     height: heightMobPic,
//     transform: 'perspective(800px) translateZ(-200px) rotateY(20deg) scale(0.9) rotateZ(-17deg) rotateX(30deg)',
//     transformStyle: 'flat',
//     WebkitTransformStyle: 'flat',

//     "@keyframes move": {
//         '0%': {
//             content: 'url("/images/phone_1.png")',
//         },
//         '10%': {
//             content: 'url("/images/phone_2.png")',
//         },
//         '25%': {
//             content: 'url("/images/phone_3.png")',
//         },
//         '80%': {
//             content: 'url("/images/phone_4.png")',
//         },
//         '100%': {
//             content: 'url("/images/phone_4.png")',
//         },

//     },
//     // animation: "move 7s infinite ease",
//     animation: "move 5s infinite alternate",
//     // position: "relative"
// });


// import { styled } from "@mui/material";
// import ImgElement from "../../../tsui/DomElements/ImgElement";
// import { makeSxProps } from "../../../tsui/Mui/SxPropsUtil";

// let desktopWidth = 240
// let desktopHeight = desktopWidth * 2;
// // let desktopWidth = 270
// // let desktopHeight = 550

// const phoneRight = '15%';
// const phoneTop = '3%';

// const radioRight = '15%';
// const radioTop = '10%';
// const radioSize = 120;

// const animDuration = '5s';


// const phoneSx = makeSxProps({
//     position: 'absolute',
//     right: phoneRight,
//     top: phoneTop,
//     width: desktopWidth,
//     height: desktopHeight,
// })

// const phoneTransform = makeSxProps({
//     transform: 'perspective(700px) translateZ(-200px) rotateY(20deg) rotateZ(-17deg) rotateX(30deg)',
// })

// const radioTransform = makeSxProps({
//     transform: 'perspective(700px) translateZ(-200px) rotateY(20deg) rotateZ(-17deg) rotateX(30deg)',
// })




// const radioSx = makeSxProps({
//     position: 'absolute',
//     right: radioRight,
//     top: radioTop,
//     width: radioSize,
//     height: radioSize,
// })



















// export function KeyframeMobileImgMobVersion() {
//     return <>
//         <ImgElement src='/images/home_animation/home-phone.png' alt='phone' sx={[...phoneSx, ...phoneTransform, {
//             filter: 'drop-shadow(-10px 10px 10px #555)'
//         }]} />

//         <ImgElement src='/images/home_animation/home-radio-right-1.svg' alt='phone' sx={[...rightIconRadioSx, ...rightIconRadioTransform, {
//             opacity: 0,
//             "@keyframes radio1": {
//                 '0%': { opacity: 0 },
//                 '15%': { opacity: 1 },
//                 '25%': { opacity: 0 },
//                 '40%': { opacity: 0 },
//                 '75%': { opacity: 0 },
//                 '100%': { opacity: 0 },
//             },
//             animation: `radio1 ${animDuration} infinite alternate`,
//         }]} />
//         <ImgElement src='/images/home_animation/home-radio-right-2.svg' alt='phone' sx={[...rightIconRadioSx, ...rightIconRadioTransform, {
//             opacity: 0,
//             "@keyframes radio2": {
//                 '0%': { opacity: 0 },
//                 '15%': { opacity: 0 },
//                 '25%': { opacity: 1 },
//                 '75%': { opacity: 0 },
//                 '100%': { opacity: 0 },
//             },
//             animation: `radio2 ${animDuration} infinite alternate`,

//         }]} />
//         <ImgElement src='/images/home_animation/home-radio-right-3.svg' alt='phone' sx={[...rightIconRadioSx, ...rightIconRadioTransform, {
//             opacity: 0,
//             "@keyframes radio3": {
//                 '0%': { opacity: 0 },
//                 '15%': { opacity: 0 },
//                 '25%': { opacity: 0 },
//                 '40%': { opacity: 1 },
//                 '75%': { opacity: 0 },
//                 '100%': { opacity: 0 },
//             },
//             animation: `radio3 ${animDuration} infinite alternate`,
//         }]}
//         />

//         <ImgElement src='/images/home_animation/home-radio-right-1.svg' alt='phone' sx={[...leftIconRadioSx, ...leftIconRadioTransform, {
//             opacity: 0,
//             "@keyframes radio1": {
//                 '0%': { opacity: 0 },
//                 '15%': { opacity: 1 },
//                 '25%': { opacity: 0 },
//                 '40%': { opacity: 0 },
//                 '75%': { opacity: 0 },
//                 '100%': { opacity: 0 },
//             },
//             animation: `radio1 ${animDuration} infinite alternate`,
//         }]} />
//         <ImgElement src='/images/home_animation/home-radio-right-2.svg' alt='phone' sx={[...leftIconRadioSx, ...leftIconRadioTransform, {
//             opacity: 0,
//             "@keyframes radio2": {
//                 '0%': { opacity: 0 },
//                 '15%': { opacity: 0 },
//                 '25%': { opacity: 1 },
//                 '75%': { opacity: 0 },
//                 '100%': { opacity: 0 },
//             },
//             animation: `radio2 ${animDuration} infinite alternate`,

//         }]} />
//         <ImgElement src='/images/home_animation/home-radio-right-3.svg' alt='phone' sx={[...leftIconRadioSx, ...leftIconRadioTransform, {
//             opacity: 0,
//             "@keyframes radio3": {
//                 '0%': { opacity: 0 },
//                 '15%': { opacity: 0 },
//                 '25%': { opacity: 0 },
//                 '40%': { opacity: 1 },
//                 '75%': { opacity: 0 },
//                 '100%': { opacity: 0 },
//             },
//             animation: `radio3 ${animDuration} infinite alternate`,
//         }]}
//         />

//         <ImgElement src='/images/home_animation/home-phone-html.svg' alt='phone' sx={[...phoneSx, ...phoneTransform, {
//             opacity: 0,
//             "@keyframes phoneHtml": {
//                 '0%': { opacity: 0 },
//                 '50%': { opacity: 0 },
//                 '75%': { opacity: 1 },
//                 '100%': { opacity: 1 },
//             },
//             animation: `phoneHtml ${animDuration} infinite alternate`,
//         }]} />

//     </>;
// }

export function KeyframeMobileImgMobVersion() {
    return <>
        <ImgElement src='/images/home_animation/home-phone.png' alt='phone' sx={[...phoneSx, ...phoneTransform, {
            filter: 'drop-shadow(-10px 10px 10px #555)'
        }]} />

        <ImgElement src='/images/home_animation/home-radio-right-1.svg' alt='phone' sx={[...rightIconRadioSx, ...rightIconRadioTransform, {
            opacity: 0,
            "@keyframes radio1": {
                '0%': { opacity: 0 },
                '15%': { opacity: 1 },
                '25%': { opacity: 0 },
                '40%': { opacity: 0 },
                '75%': { opacity: 0 },
                '100%': { opacity: 0 },
            },
            animation: `radio1 ${animDuration} infinite alternate`,
        }]} />
        <ImgElement src='/images/home_animation/home-radio-right-2.svg' alt='phone' sx={[...rightIconRadioSx, ...rightIconRadioTransform, {
            opacity: 0,
            "@keyframes radio2": {
                '0%': { opacity: 0 },
                '15%': { opacity: 0 },
                '25%': { opacity: 1 },
                '75%': { opacity: 0 },
                '100%': { opacity: 0 },
            },
            animation: `radio2 ${animDuration} infinite alternate`,

        }]} />
        <ImgElement src='/images/home_animation/home-radio-right-3.svg' alt='phone' sx={[...rightIconRadioSx, ...rightIconRadioTransform, {
            opacity: 0,
            "@keyframes radio3": {
                '0%': { opacity: 0 },
                '15%': { opacity: 0 },
                '25%': { opacity: 0 },
                '40%': { opacity: 1 },
                '75%': { opacity: 0 },
                '100%': { opacity: 0 },
            },
            animation: `radio3 ${animDuration} infinite alternate`,
        }]}
        />

        <ImgElement src='/images/home_animation/home-radio-right-1.svg' alt='phone' sx={[...leftIconRadioSx, ...leftIconRadioTransform, {
            opacity: 0,
            "@keyframes radio1": {
                '0%': { opacity: 0 },
                '15%': { opacity: 1 },
                '25%': { opacity: 0 },
                '40%': { opacity: 0 },
                '75%': { opacity: 0 },
                '100%': { opacity: 0 },
            },
            animation: `radio1 ${animDuration} infinite alternate`,
        }]} />
        <ImgElement src='/images/home_animation/home-radio-right-2.svg' alt='phone' sx={[...leftIconRadioSx, ...leftIconRadioTransform, {
            opacity: 0,
            "@keyframes radio2": {
                '0%': { opacity: 0 },
                '15%': { opacity: 0 },
                '25%': { opacity: 1 },
                '75%': { opacity: 0 },
                '100%': { opacity: 0 },
            },
            animation: `radio2 ${animDuration} infinite alternate`,

        }]} />
        <ImgElement src='/images/home_animation/home-radio-right-3.svg' alt='phone' sx={[...leftIconRadioSx, ...leftIconRadioTransform, {
            opacity: 0,
            "@keyframes radio3": {
                '0%': { opacity: 0 },
                '15%': { opacity: 0 },
                '25%': { opacity: 0 },
                '40%': { opacity: 1 },
                '75%': { opacity: 0 },
                '100%': { opacity: 0 },
            },
            animation: `radio3 ${animDuration} infinite alternate`,
        }]}
        />

        <ImgElement src='/images/home_animation/home-phone-html.png' alt='phone' sx={[...phoneSx, ...phoneTransform, {
            opacity: 0,
            "@keyframes phoneHtml": {
                '0%': { opacity: 0 },
                '50%': { opacity: 0 },
                '75%': { opacity: 1 },
                '100%': { opacity: 1 },
            },
            animation: `phoneHtml ${animDuration} infinite alternate`,
        }]} />

    </>;
}











/*
export const KeyframesHomePhoneImage = styled("img")({
    WebkitTransformStyle: 'flat',
    transformStyle: 'flat',

    marginLeft: '18vw',
    width: desktopWidth,
    height: desktopHeight,
    transform: 'perspective(800px) translateZ(-200px) rotateY(20deg) scale(0.9) rotateZ(-17deg) rotateX(30deg)',

    "@keyframes move": {
        '0%': {
            content: `url('/images/phone_1.png')`,
        },
        '10%': {
            content: `url('/images/phone_2.png')`,
        },
        '25%': {
            content: `url('/images/phone_3.png')`,
        },
        '80%': {
            content: `url('/images/phone_4.png')`,
        },
        '100%': {
            content: `url('/images/phone_4.png')`,
        },

    },
    // animation: "move 7s infinite ease",
    animation: "move 5s infinite alternate",
    // position: "relative"
});
*/

