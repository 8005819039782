import React from 'react';

import {AlertColor} from '@mui/material';

import {makeError} from 'tslib/error';
import {PubSub} from 'tsfront/pubsub';

import * as FT from './FormTypes';
import {InputFormField} from './FormElements/FormFieldContext';

export interface FormContextProps {
    type: FT.FormTypeT;

    // formElement?: FT.FormElementType
    // formContainer?: FT.FormContainerType
    // layoutElement?: FT.FormLayoutType

    title?: string;
    ttitle?: string;

    data?: any;
}

export class FormContextHook<S> {
    val!: S;
    set!: (s: S) => void;

    //constructor(hook: [S, React.Dispatch<React.SetStateAction<S>>]) {

    setup(hook: [S, React.Dispatch<React.SetStateAction<S>>]) {
        this.val = hook[0];
        this.set = hook[1];
    }
}

interface FormContextParams {
    forceUpdate: React.DispatchWithoutAction;
}

export interface FormMessage {
    text: string;
    severity: AlertColor;
}

export class InputFormContext {
    // Form setup
    formIndex: number;
    formId: string;
    formType: FT.FormTypeT;

    formMargin = 0;
    // formPadding = 1; // Env.isMobile ? 0 : 1
    formPadding = theme_.formPadding; //3; // Env.isMobile ? 0 : 1
    gridMargin = 0; //1;

    // Loading state
    dataPending = false;
    dataRequested = false;

    // Error handling
    error?: Error;

    inputFieldId?: Array<string>;

    // General user message
    message?: FormMessage;
    // messageText?: string
    // messageSeverity: AlertColor = 'info'

    //messageHook = React.useState(this.messageText)

    // Changes and updates
    forceUpdate: React.DispatchWithoutAction;

    formElement?: FT.FormElementType;
    formContainer?: FT.FormContainerType;
    layoutElement?: FT.FormLayoutType;

    // Initialization
    //constructor(formId: number, props: FormContextProps) {
    constructor(props: FormContextProps, params: FormContextParams) {
        this.forceUpdate = params.forceUpdate;

        // this.formContainer = props.formContainer ?? 'frame'
        // this.formElement = props.formElement ?? (props.type === 'update-fields' ? 'none' : 'form')
        // this.layoutElement = props.layoutElement ?? 'grid'

        this.formIndex = currentFormIndex++;
        this.formType = props.type;
        this.rerenderListenerId = `formContextRenderListenerId_${this.formIndex}`;
        this.formId = `form_${this.formIndex}`;

        ///if (props.data)

        if (this.formType === 'update' && !props.data) {
            this.isLoading = true;
            this.isBusy = true;
        }
    }

    release() {
        this.reset();
        this.pubsub.release();
    }

    clearError() {
        this.error = undefined;
        this.forceUpdate();
        //this.errorH.set(this.error)
        //this.errorHookSet(this.error)
    }

    // setError(err: Error | string) {
    setError(err: Error | string, inputFieldId?: Array<string>) {
        console.log('form.setError: ', err, inputFieldId);
        this.isLoading = false;
        this.isBusy = false;
        this.inputFieldId = inputFieldId;
        this.error = makeError(err);
        this.forceUpdate();

        //this.errorH.set(this.error)

        //this.errorHookSet(this.error)

        // this.errorHook[1](this.error)
        //console.debug(this.errorHookGet())

        //this.triggerRerender()
    }

    //
    // Message
    //
    clearMessage() {
        this.message = undefined;
        this.forceUpdate();
        //this.triggerRerender()
    }

    setMessageUpdateSuccess() {
        this.message = {
            text: 'Updated.',
            severity: 'success',
        };
        this.isBusy = false;
        this.forceUpdate();

        // this.messageText = 'Updated.'
        // this.messageSeverity = 'success'
        // this.clearBusy()
        //this.triggerRerender();
    }

    //
    // Loading/Busy state
    //
    isLoading = false;
    isBusy = false;
    isDisable = false;

    setBusy() {
        this.isBusy = true;
        this.error = undefined;
        this.message = undefined;
        // console.log('this.isBusy', this.isBusy)

        this.forceUpdate();
    }

    setDisable(){
        this.isDisable = true;
        this.forceUpdate();
    }

    clearDisable(){
        this.isDisable = false;
        this.forceUpdate();
    } 

    clearBusy() {
        this.isBusy = false;
        this.forceUpdate();
    }

    setFieldBusy(field: InputFormField) {
        field.isBusy = true;
        this.forceUpdate();
    }

    clearFieldBusy(field: InputFormField) {
        field.isBusy = false;
        this.forceUpdate();
    }

    reloadData() {
        this.dataPending = true;
        this.dataRequested = false;
    }

    setLoading() {
        this.isLoading = true;
        this.setBusy();
    }
    clearLoading() {
        this.isLoading = false;
        this.isBusy = false;
        this.error = undefined;

        this.triggerRerender();
    }

    //
    // Registerd input fields
    //
    registeredFields = new Map<string, InputFormField>();

    registerField(props: FT.FieldProps, params: FT.FieldParams): InputFormField {
        // console.log(props);
        if (props.id) {
            let field = this.registeredFields.get(props.id);
            if (field) {
                field.updateParams(params);
                return field;
            }
        }

        let field = new InputFormField(props, params);

        if (props.id) {
            this.registeredFields.set(props.id, field);
        }

        return field;
    }

    getField(id: string) {
        return this.registeredFields.get(id);
    }

    processValueEdit(field: InputFormField, value: string) {
        field.value = value;
        this.onFieldUpdate && this.onFieldUpdate(field);
        return true;
    }

    onFieldUpdate?: (field: InputFormField) => void;

    //setFieldValue(id: string, val: string | boolean) {
    // setFieldValue(field: FT.InputFormField, val: string | boolean) {
    //     field.setValue(val)
    // }

    reset() {
        this.registeredFields.clear();
    }

    //
    // Dealing with React hooks
    //
    rerenderListenerId: string;
    pubsub = new PubSub();

    private triggerRerender() {
        //this.pubsub.dispatch(this.rerenderListenerId)
    }
}

let currentFormIndex = 1;
