import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { InputFormContext } from 'tsui/Form/FormContext';
import * as F from 'tsui/Form';
import i18next from 'i18next';
import { supportedLangs_ } from '../../personal/DesktopCardDetailsPersonal';


interface BusinessManProps {
    form: InputFormContext
    chosenLang: Array<string>
    getFieldValue: (name: string, lang?: string) => string
    tab: string;
    getFieldAtt: (lang: string, fieldName: string) => boolean

    // onSubmit: (evt: F.InputFormEvent) => void
    // error: Error | string | null
}

export default function BusinessMan(props: BusinessManProps) {

    const [lt] = React.useState({
        en: i18next.getFixedT('en', 'profile'),
        am: i18next.getFixedT('am', 'profile'),
        ru: i18next.getFixedT('ru', 'profile'),
    })

    const getFieldValue = React.useCallback((name: string, lang?: string) => { 
        return props.getFieldValue(name, lang) 
    }, []);
    const form = props.form

    return (
        <F.FormGrid form={form} hidden={props.tab !== 'businessMan'}>
            <F.InputGroup label='Name' form={form} />
            {supportedLangs_.map((lang, index) =>
                <React.Fragment key={index}>
                    <F.InputText maxLength={30} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.firstName')} id={`firstName${`_${lang}`}`} attention={props.getFieldAtt(lang, "firstName")} autocomplete='given-name' value={getFieldValue('firstName', lang)} form={form} xs={4} />
                    <F.InputText maxLength={30} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.middleName')} id={`middleName${`_${lang}`}`} autocomplete='additional-name' value={getFieldValue('middleName', lang)} form={form} xs={4} />
                    <F.InputText maxLength={30} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.lastName')} id={`lastName${`_${lang}`}`} attention={props.getFieldAtt(lang, "lastName")} autocomplete='family-name' value={getFieldValue('lastName', lang)} form={form} xs={4} />
                </React.Fragment>
            )}

            {/* <F.InputGroup label='card.position' form={form} /> */}
            <F.InputGroup label='card.profession' form={form} />
            {supportedLangs_.map((lang, index) =>
                <F.InputText key={index} maxLength={70} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.profession')} id={`profession${`_${lang}`}`} value={getFieldValue('profession', lang)} form={form} xs={3} />
            )}


            <F.InputGroup label='Contact' form={form} />
            <F.InputText maxLength={20} label='Phone' id='phone' validate="tel" autocomplete='tel' value={getFieldValue('phone')} form={form} xs={3} />
            <F.Spacer form={form} xs={8} />

            <F.InputText maxLength={50} label='Email' id='email' validate='email' autocomplete='email' value={getFieldValue('email')} form={form} xs={3} />
            <F.Spacer form={form} xs={8} />

            <F.InputText maxLength={20} label='Telegram' id='telegram' validate='tel' autocomplete='tel' value={getFieldValue('telegram')} form={form} xs={3} />
            <F.InputText maxLength={20} label='Viber' id='viber' validate='tel' autocomplete='tel' value={getFieldValue('viber')} form={form} xs={3} />
            <F.InputText maxLength={20} label='WhatsApp' id='whatsapp' validate='tel' autocomplete='tel' value={getFieldValue('whatsapp')} form={form} xs={3} />


            <F.InputGroup label='Social Networks' form={form} />
            <F.InputText maxLength={200} label='Facebook' id='facebook' value={getFieldValue('facebook')} form={form} xs={3} />
            <F.InputText maxLength={200} label='Instagram' id='instagram' value={getFieldValue('instagram')} form={form} xs={3} />
            <F.InputText maxLength={200} label='LinkedIn' id='linkedin' value={getFieldValue('linkedin')} form={form} xs={3} />



            {/* <F.InputGroup label='Location' form={form} />

            {props.chosenLang.map((lang, index) =>

                    <F.InputText key={index} maxLength={50} label={lt[lang]('Location')} id={`location${lang === 'en' ? '' : `_${lang}`}`} value={getFieldValue('location', lang)} form={form} xs={3} />
            )}
            <F.InputText maxLength={500} label='Map link' id='locationIcon' value={getFieldValue('locationIcon')} form={form} xs={6} />


            <F.InputGroup label='Working hours' form={form} />

            <F.InputText maxLength={50} label='Working hours' id='workingTime' value={getFieldValue('workingTime')} form={form} xs={3} /> */}


        </F.FormGrid >
    )
}

