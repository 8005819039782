import React from 'react';
import {Box, Container, Grid, Typography} from '@mui/material';
import * as FT from './FormTypes';

import {useTranslation} from 'react-i18next';

import i18next from 'i18next';

interface Props extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps {
    label: string;
    type?: 'text' | 'required' | 'optional';
    required?: boolean;
    optional?: boolean;
}

function translateInputLabel(props: Props) {
    let text = i18next.t(props.label);

    if (props.type === 'required' || props.required) {
        text += ' (' + i18next.t('required') + ')';
    } else if (props.type === 'optional' || props.optional) {
        text += ' (' + i18next.t('optional') + ')';
    }

    text += ':';

    return text;
}

export function InputGroup(props: Props) {
    const [label, setLabel] = React.useState(translateInputLabel(props));

    return (
        <Grid item {...FT.getFormGridAtts(props, {xs: 12})} sx={FT.getFormSxParams(props)}>
            <Typography>{label}</Typography>
        </Grid>
    );
}
