import device from "current-device"

import * as UE from './UiElements'

export class ThemeBase {
    static isDark = true;

    static muiPrimaryMain = this.isDark ? '#90caf9' : '#1976d2';
    static muiPrimaryLight = this.isDark ? '#e3f2fd' : '#42a5f5';
    static muiPrimaryDark = this.isDark ? '#42a5f5' : '#1565c0';

    static muiBackground = this.isDark ? '#121212' : '#f0f0f0';

    indicatorColor = ThemeBase.muiPrimaryMain;

    formPadding = 1;


    dialog = new ThemeDialog();
    textField = new ThemeTextField();
    cancelButton = new ThemeButton();
    submitButton = new ThemeButton();


    formGridSpacing = device.mobile() ? 1.2 : 2;

}

class ThemeDialog {
    height?: number | string = '90%';
    background?: UE.UiBackground;
    backgroundImage?: string;
    border?: UE.UiBorder;
    boxShadow?: string;

    sx() {
        return  {
            ...this.border ? this.border.sx() : {},
            ...this.background ? this.background.sx() : {},
            boxShadow: this.boxShadow,
            backgroundImage: this.backgroundImage,
        }
    }
}

class ThemeTextField {
    paddingLeft?: number | string;
    borderRadius?: number | string;
}

class ThemeButton {
    background?: UE.UiBackground;
    border?: UE.UiBorder;
    boxShadow?: string;

    sx() {
        return  {
            ...this.border ? this.border.sx() : {},
            ...this.background ? this.background.sx() : {},
            boxShadow: theme_.dialog.boxShadow,
        }
    }
}
