import React from 'react';

import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import { Box, styled as muiStyled } from '@mui/system';
import { Button, Stack, styled } from '@mui/material';

import { useTranslation } from 'react-i18next';

import Env from 'env';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const IframeElement = styled('iframe')({});


interface HomePageFooterProps {
    fromContactUsPage: boolean;
}


export default function HomePageFooter(props: HomePageFooterProps) {
    const [t] = useTranslation();
    const navigate = useNavigate();

    if (Env.isMobile) {
        return (
            <Stack direction='column' alignItems='center' justifyContent='center' width='100%' sx={{ mt: props.fromContactUsPage ? -10 : 15 }}>
                <Box sx={{ width: '99%' }}>
                    <IframeElement
                        src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.1648474473104!2d44.49571821568435!3d40.20539587638864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd4409c33837%3A0xde22a5eef53f2d96!2zMTUgQXJhbSBLaGFjaGF0cmlhbiBTdCwgWWVyZXZhbiAwMDEyLCDQkNGA0LzQtdC90LjRjw!5e0!3m2!1sru!2s!4v1668174105643!5m2!1sru!2s'
                        loading='lazy'
                        sx={{
                            //filter: 'invert(90%)',
                            width: '100%',
                            height: 200,
                            borderRadius: 3
                        }}
                        frameBorder='0'
                    />
                </Box>

                <Box
                    sx={{
                        width: '100%',
                        height: 300,
                        backgroundColor: theme_.homeBoxBackground,
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Box sx={{ ml: 2, mt: 2, width: '35%', textAlign: 'start', '@media screen and (orientation:landscape)': {
                            justifyContent: 'center',
                            ml: 10
                        }, }}>
                        <Typography sx={{ color: 'white', fontSize: 'x-large', fontWeight: 'bold' }}>
                            {t('Contact Us')}
                        </Typography>
                        <Typography sx={{ color: 'white', fontSize: 'small' }}>{t('home.contact_text')}</Typography>
                        {!props.fromContactUsPage &&
                            <Button
                                variant='contained'
                                onClick={() => navigate('/contact')}
                                sx={{
                                    mt: 4, borderRadius: 10, minWidth: 150, height: 50, ml: -0.5,
                                    fontWeight: '900',
                                    textShadow: `
                                0 -1px 0 #fff, 
                                0 1px 0 #4e4e4e, 
                                // 0 2px 0 #4c4c4c, 
                                // 0 3px 0 #4a4a4a, 
                                0 4px 20px #484848
                                `,
                                    "&:hover": { backgroundColor: theme_.buttonHoverColorGradient }
                                }}
                            >
                                {t('Contact Us')}
                            </Button>
                        }
                    </Box>

                    {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}

                    {/* <AccessTimeIcon style={{ fontSize: 'xxx-large' }} /> */}
                    <Box sx={{ width: '45%', display: 'grid', alignItems: 'center', ml: 5,'@media screen and (orientation:landscape)': {
                            justifyContent: 'center',
                            width: '65%',
                        }, }}>
                        <Box sx={{ display: 'grid', alignItems: 'center', width: '95%' }}>
                            <Typography
                                sx={{ fontSize: 'large', color: 'white', fontWeight: 'bold' }}
                            >
                                {t('contact.opening_hours')}{' '}
                            </Typography>
                            <Typography sx={{ fontSize: 'middle', color: 'white' }}>10:00-20:00</Typography>
                        </Box>
                        {/* </Box> */}

                        {/* <Box sx={{ ml: 3, mt: 2, width: '40%', textAlign: 'center' }}> */}

                        {/* <MailOutlineIcon style={{ fontSize: 'xxx-large' }} /> */}
                        <Box sx={{ display: 'grid', alignItems: 'center', width: '95%' }}>
                            <Typography
                                sx={{ fontSize: 'large', color: 'white', fontWeight: 'bold' }}
                            >
                                {t('Email')}{' '}
                            </Typography>
                            <Typography sx={{ fontSize: 'small', color: 'white' }}>evocard.contact@gmail.com</Typography>
                        </Box>

                        {/* </Box> */}

                        {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}> */}

                        {/* <PhoneIcon style={{ fontSize: 'xxx-large' }} /> */}
                        <Box sx={{ display: 'grid', alignItems: 'center', width: '95%' }}>
                            <Typography
                                sx={{ fontSize: 'large', color: 'white', fontWeight: 'bold' }}
                            >
                                {t('contact.phone')}{' '}
                            </Typography>
                            <Typography sx={{ fontSize: 'middle', color: 'white' }}>+374 99 88 50 44</Typography>
                        </Box>

                        {/* </Box> */}

                        {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}> */}
                        {/* <LocationOnIcon style={{ fontSize: 'xxx-large' }} /> */}
                        <Box sx={{ display: 'grid', alignItems: 'center', width: '95%' }}>
                            <Typography
                                sx={{ fontSize: 'large', color: 'white', fontWeight: 'bold' }}
                            >
                                {t('Location')}{' '}
                            </Typography>
                            <Typography sx={{ fontSize: 'small', color: 'white' }}>
                                {t('contact.address_name')}{' '}
                            </Typography>
                        </Box>

                        {/* </Box> */}
                    </Box>
                </Box>
            </Stack>
        );
    }

    // return <Stack direction='row' alignItems='center' justifyContent='center' width='100%' sx={{ backgroundColor: 'gray', mt: 15 }}>
    return (
        <Stack direction='row' alignItems='center' justifyContent='center' width='100%' sx={{ mt: props.fromContactUsPage ? 0 : 15, '@media screen and (min-width: 100em)': {
            mt: props.fromContactUsPage ? -25 : 15,
        }, }}>
            <Box sx={{ width: '99.5%' }}>
                <IframeElement
                    // src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.1650306881297!2d44.4979069!3d40.2053918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd4409c33837%3A0xde22a5eef53f2d96!2s15%20Aram%20Khachatrian%20St%2C%20Yerevan%200012!5e0!3m2!1sru!2s!4v1669129376957!5m2!1sru!2s"
                    src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24375.63691883694!2d44.4987508!3d40.21007250000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd4409c33837%3A0xde22a5eef53f2d96!2s15%20Aram%20Khachatrian%20St%2C%20Yerevan%200012!5e0!3m2!1sru!2s!4v1669134635756!5m2!1sru!2s'
                    loading='lazy'
                    sx={{
                        filter: 'invert(90%)',
                        width: '100%',
                        height: 400,
                        borderRadius: 4,
                    }}
                    frameBorder='0'
                />
            </Box>

            <Box
                sx={{
                    width: 550,
                    '@media screen and (min-width: 100em)': {
                        width: 650,
                    },
                    height: 300,
                    position: 'absolute',
                    right: 100,
                    // backgroundColor: theme_.themeColor,
                    backgroundColor: theme_.homeBoxBackground,
                    borderRadius: 10,
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ ml: 3, mt: 2, width: '45%', textAlign: 'center' }}>
                    <Typography sx={{ fontSize: 'xx-large', fontWeight: 'bold', color: 'white' }}>
                        {t('Contact Us')}
                    </Typography>
                    <Typography sx={{ color: 'white', fontSize: 'middle' }}>{t('home.contact_text')}</Typography>
                    {/* <Button variant='outlined' onClick={() => navigate('/contact')} sx={{ mt: 6, borderRadius: 10, minWidth: 200, height: 50, color: theme_.themeColor, backgroundColor: 'transparent', border: '1px solid rgba(25, 118, 210, 0.5)', borderColor: '#e8a412', "&:hover": { backgroundColor: "#F8F5F1" }, }}> */}
                    {!props.fromContactUsPage &&

                        <Button
                            variant='contained'
                            onClick={() => navigate('/contact')}
                            sx={{
                                mt: 3, borderRadius: 10, minWidth: 200, height: 50,
                                // fontSize: 'larger', 
                                "&:hover": { backgroundColor: theme_.buttonHoverColorGradient }

                            }}
                        >
                            {t('Contact Us')}
                        </Button>
                    }
                </Box>

                {/* <Box sx={{ display: 'flex', alignItems: 'center' }}> */}

                {/* <AccessTimeIcon style={{ fontSize: 'xxx-large' }} /> */}
                <Box sx={{ width: '45%', display: 'grid', alignItems: 'center', ml: 10 }}>
                    <Box sx={{ display: 'grid', alignItems: 'center' }}>
                        <Typography sx={{ fontSize: 'x-large', color: 'white', fontWeight: 'bold' }}>
                            {t('Opening Hours')}{' '}
                        </Typography>
                        <Typography sx={{ fontSize: 'large', color: 'white' }}>10:00-20:00</Typography>
                    </Box>
                    {/* </Box> */}

                    {/* <Box sx={{ ml: 3, mt: 2, width: '40%', textAlign: 'center' }}> */}

                    {/* <MailOutlineIcon style={{ fontSize: 'xxx-large' }} /> */}
                    <Box sx={{ display: 'grid', alignItems: 'center' }}>
                        <Typography sx={{ color: 'white', fontSize: 'x-large', fontWeight: 'bold' }}>
                            {t('Email')}{' '}
                        </Typography>
                        <Typography sx={{ fontSize: 'large', color: 'white' }}>evocard.contact@gmail.com</Typography>
                    </Box>

                    {/* </Box> */}

                    {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}> */}

                    {/* <PhoneIcon style={{ fontSize: 'xxx-large' }} /> */}
                    <Box sx={{ display: 'grid', alignItems: 'center' }}>
                        <Typography sx={{ color: 'white', fontSize: 'x-large', fontWeight: 'bold' }}>
                            {t('Phone')}{' '}
                        </Typography>
                        <Typography sx={{ fontSize: 'large', color: 'white' }}>+374 99 88 50 44</Typography>
                    </Box>

                    {/* </Box> */}

                    {/* <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}> */}
                    {/* <LocationOnIcon style={{ fontSize: 'xxx-large' }} /> */}
                    <Box sx={{ display: 'grid', alignItems: 'center' }}>
                        <Typography sx={{ color: 'white', fontSize: 'x-large', fontWeight: 'bold' }}>
                            {t('Location')}{' '}
                        </Typography>
                        <Typography sx={{ fontSize: 'large', color: 'white' }}>{t('contact.address_name')} </Typography>
                    </Box>

                    {/* </Box> */}
                </Box>
            </Box>
        </Stack>
    );
}
