import React from 'react';

import { Box, Stack, Typography } from '@mui/material';


import HtmlHeader from '../ui/HtmlHeader';

import { DomSub } from 'tsui/Dom/DomSub';

import PageNav from './PageNavBar';
import PageLogo from './PageLogo';
import AuthHeader from './AuthHeader';
import LangSelector from './LangSelector';

import Env from 'env';
import { ShoppingCart } from './ShoppingCart';
import session_ from '../api/session';

export class PageHeaderState {
    domSub = new DomSub();

    release() {
        this.domSub.release();
    }
}

interface PageHeaderProps {
    current?: string;
    title?: string;
}

export default function PageHeader(props: PageHeaderProps) {
    if (Env.isMobile) {
        return (
            <>
                <HtmlHeader title={props.title ?? 'EvoCard - Smart Business Cards'} />

                <Stack direction='row' width='100%' justifyContent='center' alignItems='center'>
                    <PageLogo />
                    {session_.isLoggedIn === true &&
                        <ShoppingCart />
                    }
                    <LangSelector type='button' />
                    <PageNav current={props.current ?? 'home'} />
                    <AuthHeader />
                </Stack>
            </>
        );
    }

    return (
        <Box sx={{
            position: 'absolute',
            width: '100%',
            minWidth: theme_.pageMinWidth,
            // min-width: 1800px;
            left: 0,
            right: 0,
            top: 0,
            height: theme_.pageHeaderHeight,
            zIndex: 1,
        }}>
            <HtmlHeader title={props.title ?? 'EvoCard - Smart Business Cards'} />

            <Stack
                direction='row'
                width='100%'
                justifyContent='center'
                alignItems='center'
            >
                <PageLogo />
                <PageNav current={props.current ?? 'home'} />

                {session_.isLoggedIn === true &&
                    <ShoppingCart />
                }
                <LangSelector type='button' />
                <AuthHeader />
            </Stack>
        </Box>
    );
}
