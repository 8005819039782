import * as Form from 'tsui/Form';
import {ThemeBase} from 'tsui/ThemeBase';
import {PageHeaderTheme} from './ThemeElements';

import * as UE from 'tsui/UiElements';
import Env from 'env';

export class ThemeInstance extends ThemeBase {
    //isDark = true

    themeColor = '#e8a412';
    //buttonHoverColor = '#fca031'
    // buttonHoverColor = '#dec8ad'
    // buttonHoverColor = '#d09310'
    buttonHoverColor = '#d09310';
    buttonHoverColorGradient = 'linear-gradient(96.31deg, #FCB131 9.36%, #f69d04 57.75%)';
    buttonColor = 'linear-gradient(96.31deg, #FBC04C 9.36%, #FCB131 57.75%)';
    disabledButtonColor = 'linear-gradient(96.31deg, #242a37 9.36%, #242737 57.75%)';
    //evocardFeatureBackgroundColor = '#212121'
    evocardFeatureBackgroundColor = '#4B4B4E';
    // evocardFeatureBackgroundColor = ''
    homeBoxBackground = 'rgba(33, 33, 33, 0.8)';
    // evocardFeatureBackgroundColor = '#37414d'
    bodyColor = '#242c37';

    erorTextColor = '#FF3333';

    betweenTextFieldSpacing = 1.5;

    // cardMaxWidth = 345;
    cardMaxWidth = 450;
    cardBoxShadow = 'none';
    cardBorderRadius = 3;
    cardBackdropFilter = 'blur(5px)';
    cardPadding = 3;
    cardTextTransform = 'initial';

    cardBoxShadowHover = 18;
    cardBackgroundColor = 'white';

    orderModalBoxWidth = 700;
    orderModalBoxHeight = 'auto';
    // orderModalBoxBorderRadius = 8;
    orderModalBoxBorderStyle = 'none';
    orderModalBoxBackgroundColor = 'white';
    orderModalBoxPadding = 5;

    pageToolbarHeight = 20;

    inputTextBackgroundColor = '#ffffff';
    inputReadonlyBackgroundColor = '#eeeeee';

    featureIconSizeMobile = 90;
    featureIconSizeDesktop = 110;

    getFormButtonSize(size?: Form.FormSizeType) {
        switch (size) {
            case 'sm':
                return 80;
            case 'md':
                return 180;
            case 'lg':
                return 300;
            case 'xl':
                return 400;
            default:
                return '100%';
        }
    }

    homePageHeaderTheme = new PageHeaderTheme();

    // Page Layout
    pageMinWidth = Env.isDesktop ? 900 : 320;
    pageMinHeight = Env.isDesktop ? 768 : 480;

    pageHorzMargin = Env.isDesktop ? 1 : 0;
    pageTopMargin = Env.isDesktop ? 1 : 0;
    pageBottomMargin = Env.isDesktop ? 1 : 0;

    pageHeaderHeight = Env.isMobile ? 60 : 80; //120;

    pageLogoWidth = Env.isMobile ? 130 : 300;
    pageLogoHeight = Env.isMobile ? 25 : 35;
    pageLogoLeft = Env.isMobile ? 15 : 25;
    pageLogoTop = Env.isMobile ? 30 : 50;

    pageLangSelectorTop = Env.isMobile ? 10 : 26;
    pageLangSelectorRight = Env.isMobile ? 65 : 15;
    pageLangSelectorInsideWidth = Env.isMobile ? 102 : 106;
    pageLangSelectorInsideTop = Env.isMobile ? 16 : 20;

    pageAuthButtonWidth = Env.isMobile ? 60 : 120;
    pageAuthButtonHeight = Env.isMobile ? 30 : 40;
    pageAuthButtonLeft = Env.isMobile ? 0 : 10;
    pageAuthButtontop = Env.isMobile ? 28 : 43;
    pageAuthButtonPaddingRight = Env.isMobile ? 5 : 10;

    pageUserAuthContainerRight = Env.isMobile ? 10 : 130;

    pageBackgroundColor = '#ffffff';
    pageDarkModeTextColor = '#ffffff';
    pageTextColor = 'black'; // VS Code dark theme color
    // dialogTextColor = 'black'
    dialogTextColor = 'white';
    // tableInsideDialogBackgroundColor = '#e6e6e6'
    tableInsideDialogBackgroundColor = '#242c37';
    tableOddRowColor = '#ff0000';
    tableRowHeight = 52;
    tableActionButtonWidth = this.tableRowHeight;
    tableActionButtonHeight = this.tableRowHeight;

    dialogGreetingBackground =
        'radial-gradient(87.9% 87.9% at 68.96% 12.83%, #030303 0%, rgba(32, 32, 32, 0.951351) 52.4%, rgba(50, 50, 50, 0.89) 100%)';

    constructor() {
        super();

        this.dialog.border = new UE.UiBorder({radius: 4});
        //this.dialog.background = new UE.UiBackground({color: '#4B4B4E'});
        this.dialog.background = new UE.UiBackground({color: '#2C2D31'});
        //this.dialog.background = new UE.UiBackground({color: 'red'});
        this.dialog.boxShadow = '20px 20px 29px rgba(0, 0, 0, 0.45)';
        //this.dialog.boxShadow = '24px';
        this.dialog.backgroundImage = 'none';

        //this.textField.paddingLeft = 4;
        this.textField.borderRadius = 4;

        this.formPadding = 3;
    }

    cssButtonShadow() {
        return {
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        };
    }

    cssButtonHover() {
        return {
            '&:hover': {
                border: '2px solid #25afce66',
            },
        };
    }
}
