import React from 'react';

import { Box, Button, Dialog, DialogTitle, Grid, Stack, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';

import * as F from 'tsui/Form';
import { ApiUser } from 'api/users';
import * as Api from 'api/api';
import session_ from 'api/session';
import ChangePassword from 'dialogs/ChangePassword';
import PageContents from 'components/PageContents';
import ImgElement from '../tsui/DomElements/ImgElement';

import * as GD from 'data/GlobalDispatch';
import ProgressIndicator from '../tsui/ProgressIndicator';

export default function UsersDetailsPage() {
    const [t] = useTranslation();

    const form = F.useForm({
        type: 'update',
        // type: 'update-fields',
    });
    const [data, setData] = React.useState<ApiUser | null>(null);
    const [changePassword, setChangePassword] = React.useState(false);
    const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

    const [currentLang, setCurrentLang] = React.useState(session_.lang);
    const [progIndicator, setProgIndicator] = React.useState(false);


    // React.useEffect(() => {
    //     const langListener = () => {
    //         setCurrentLang(session_.lang);
    //         forceUpdate();
    //     };

    //     GD.pubsub_.addListener(GD.langListenerId, langListener);

    //     return () => GD.pubsub_.removeListener(GD.langListenerId, langListener);
    // }, []);

    React.useEffect(() => {
        form.isDisable = true;
    }, [])

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {

        evt.data.userId = session_.userId;

        let updateData: any = {};
        for (let key of Object.keys(evt.data)) {
            if (key === 'email') continue;
            updateData[key] = evt.data[key];
        }

        if (Object.keys(updateData).length === 1) return;
        setProgIndicator(true);

        Api.requestSession<any>('user', 'update', updateData)
            .then((d) => {
                setProgIndicator(false);
                form.setDisable();
                form.forceUpdate();
                // props.onClose();
                // console.log(`modified count is ${d.modifiedCount}`)
            })
            .catch((err) => alert(err));
    }, []);

    // const onValueChange = React.useCallback((e: TextChangeEvent | null) => {
    //     if (e === null)
    //         return;

    //     let v = e.target.value;
    //     setValue(v);
    //     valueRef.current = v;
    //     props.field.valueChanged = true;
    // }, []);

    //formSx={{border: `1px solid ${theme_.themeColor}`, backgroundColor: theme_.evocardFeatureBackgroundColor,}}

    // if (!session_.isLoggedIn) {
    //     return <PageContents title="User Information" current='userInformation'>
    //         <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/pages_design/background_layout.svg' />
    //     </PageContents>;
    // }

    return (
        <PageContents title='User Information' current='userInformation'>
            <F.FormDataProvider<ApiUser>
                apiGroup='user'
                apiCommand='get'
                apiParams={{ userId: session_.userId }}
                onData={(d) => {
                    setData(d);
                }}
                form={form}
            >
                <ImgElement
                    sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }}
                    src='images/pages_design/background_layout.svg'
                />

                {/* <F.PageForm title={`${t('User')}: ${session_.userId}`} form={form} size='md' onSubmit={onSubmit} formSx={{border: '1px solid #C5C5C5', backgroundColor: theme_.evocardFeatureBackgroundColor,}}> */}
                <F.PageForm
                    title={`${t('User')}: ${session_.userId}`}
                    form={form}
                    size='md'
                    type='confirm'
                    onSubmit={onSubmit}
                    formSx={{ ...theme_.dialog.sx(), px: 3 }}
                >
                    {/* <Box sx={{ display: 'block', width: '100%', justifyContent: 'center' }}>
                    <Box  sx={{ display: 'block', width: '50%', textAlign: 'center' }}> */}

                    <Grid item xs={12} sx={{ mt: theme_.betweenTextFieldSpacing }}>
                        <F.InputText
                            maxLength={100}
                            label='Email'
                            id='email'
                            readonly
                            autocomplete='email'
                            value={data?.email}
                            form={form}
                            xsMax
                        />
                    </Grid>

                    <F.InputText
                        maxLength={100}
                        label='First Name'
                        id='firstName'
                        value={data?.firstName}
                        autocomplete='given-name'
                        form={form}
                        xsMax
                    />
                    <F.InputText
                        maxLength={100}
                        label='Last Name'
                        id='lastName'
                        value={data?.lastName}
                        autocomplete='family-name'
                        form={form}
                        xsMax
                    />

                    {/* <F.InputText label='Area Code' id='phoneAreaCode' value={data?.phoneAreaCode} validate='tel' autocomplete='tel' form={form} xsMax /> */}
                    <F.InputText
                        maxLength={100}
                        label='Phone'
                        id='phoneNumber'
                        value={data?.phoneNumber}
                        validate='tel'
                        autocomplete='tel'
                        form={form}
                        xsMax
                    />
                    {/* </Box>
                </Box> */}

                    <Box sx={{ display: 'grid', width: '100%', ml: 2 }}>
                        <Button
                            variant='outlined'
                            onClick={() => {
                                setChangePassword(true);
                            }}
                            sx={{
                                justifySelf: 'center',
                                minWidth: 200,
                                height: 40,
                                mt: 3,
                                fontSize: 'middle',
                                //"&:hover": { backgroundColor: theme_.themeColor, color: 'white', borderColor: 'transparent' },
                                boxShadow: 'none',
                            }}
                        >
                            {t('Change Password')}
                        </Button>
                    </Box>

                    <F.SubmitButton label='Save Changes' size='lg' form={form} xsMax align='center' buttonSx={{
                        background: form.isDisable ? theme_.disabledButtonColor : theme_.buttonColor,
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                        // color: 'transparent',
                        "&:hover": {
                            background: form.isDisable ? theme_.disabledButtonColor : theme_.buttonHoverColorGradient,
                            borderStyle: form.isDisable ? 'none' : 'style',
                            borderWidth: form.isDisable ? 0 : 1,
                            borderColor: form.isDisable ? 'none' : theme_.pageDarkModeTextColor,
                            backgroundColor: 'transparent',
                            boxShadow: 'none',
                            // color: 'transparent',
                        },
                    }} />
                </F.PageForm>

                {changePassword && (
                    <ChangePassword
                        onClose={() => {
                            setChangePassword(false);
                        }}
                    ></ChangePassword>
                )}

                {progIndicator && (
                    <Dialog open={progIndicator} PaperProps={{ sx: { width: 200, height: 200 } }}>
                        <ProgressIndicator title='Updating...' />
                    </Dialog>
                )}
            </F.FormDataProvider>
        </PageContents>
    );
}
