import React from 'react';
import * as Api from 'api';

import * as F from 'tsui/Form';
import {validateCardId} from 'checking_card_id/checking_card_id';
import {useNavigate} from 'react-router-dom';
import session_ from 'api/session';

import UserCardDetailsModal from '../pages/user/cards/UserCardDetailsModal';
import WarningDialog from './WarningDialog';
import {ErrorAlert} from 'components/ErrorAlert';
import {ApiCardActivation} from 'api';
import { useTranslation } from 'react-i18next';

interface AddUserProps {
    // userId: string;
    onClose: () => void;
}

export function ActivateCardDialog(props: AddUserProps) {
    const [t] = useTranslation();

    const navigate = useNavigate();

    const form = F.useForm({type: 'input'});

    const [error, setError] = React.useState<Error | string | null>(null);
    const [warningMessage, setWarningMessage] = React.useState<string | null>(null);

    const [activatedCardId, setActivatedCardId] = React.useState<string | null>(null);
    const [cardType, setCardType] = React.useState('');

    // const [loggedIn, setLoggedIn] = React.useState(false)
    // const [checkedCardIdTrue, setCheckCardIdTrue] = React.useState<string | null>(null);

    //const cardTypeName = React.useRef('')

    //console.log('cardTypeName', cardTypeName)
    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        // console.log('evt.data', evt.data)

        let cardId = evt.data.cardId;
        if(!cardId ) {
            // setWarningMessage('activation.incorrect_card_id');
            return;
        }
        let newCardId = cardId.replace(/\s/g, '')
        if(newCardId === '') {
            // setWarningMessage('is required');
            setError('Error: is required');
            return;
        }
        let cardValidated = validateCardId(cardId);

        if (!cardValidated) {
            // setWarningMessage('activation.incorrect_card_id');
            // setError('activation.incorrect_card_id')
            setError('Error: You have entered incorrect Card ID')
            return;
        }

        // console.log('isCardIdTrue', isCardIdTrue)
        // if (isCardIdTrue === false) {

        //     warningMessageRef.current = 'YOU HAVE ENTERED AN INCORRECT CARD ID'
        //     setWrongCardIdInput(true)

        // }
        //console.log('session_.isLoggedIn', session_.isLoggedIn)
        //if (cardValidated === true) {
        //setCheckCardIdTrue(id)
        // if (session_.isLoggedIn) {
        //     setLoggedIn(true)
        // }
        // if (!session_.isLoggedIn) {
        //     // console.log
        //     navigate(`/join?cardId=${evt.data.checkCardId}`);
        // }

        // } else {
        //     warningMessageRef.current = 'YOU HAVE ENTERED AN INCORRECT CARD ID'
        //     setWrongCardIdInput(true)
        // }

        Api.requestSessionJson<ApiCardActivation>('card', 'activate', {cardId: cardId})
            .then((d) => {
                // let checkingCard = d as any

                //     let isActivated = !checkingCard.isActivated
                //     if (checkingCard.issued && !isActivated) {
                //         if (cardValidated === true) {
                //             setCheckCardIdTrue(evt.data.checkCardId)
                //             if (session_.isLoggedIn) {
                //                 setLoggedIn(true)
                //             } else {
                //                 navigate(`/join?cardId=${evt.data.checkCardId}`);

                //             }
                //         }

                //     else if (checkingCard.isUserCardAlreadyActivated) {
                //         setWarningMessage('You have already activated this card. Please check it in your cards page.');
                //         //setWrongCardIdInput(true)
                //     }
                //     else {

                //         setWarningMessage('This card is already taken.');
                //         //setWrongCardIdInput(true)

                //     }

                setActivatedCardId(cardId);
                setCardType(d.cardType);

                //cardTypeName.current = checkingCard.cardType
                //}
                // props.onClose();
            })
            .catch(setError);
        // alert('congratulations')
    }, []);

    const onCancelDialog = React.useCallback((key: boolean) => {
        props.onClose();
    }, []);

    // console.log('checkedCardIdTrue', checkedCardIdTrue)
// label='Card ID'
// label='Enter New Card Id'

    return (
        // <F.PageFormDialog title='Card Activation' form={form} size='xs' onSubmit={onSubmit} onClose={props.onClose}>
        <F.PageFormDialog title='Card Activation' form={form} size='xs' onClose={props.onClose} onSubmit={onSubmit} type='confirm' submitLabel='Confirm'>
            <ErrorAlert error={error} />

            <F.InputGroup label='Enter New Card Id' form={form} mb={1} ml={0}/>


            <F.InputText id='cardId'   required form={form} xs={12} />

            {activatedCardId && (
                <UserCardDetailsModal
                    cardType={cardType}
                    cardId={activatedCardId}
                    onSelected={onCancelDialog}
                    onClose={() => setActivatedCardId(null)}
                />
            )}
            <WarningDialog message={warningMessage} onCloseNull={setWarningMessage} />
        </F.PageFormDialog>
    );
}

// function muiStyled(arg0: string) {
//     throw new Error("Function not implemented.");
// }
