import React from 'react';

import {Button} from '@mui/material';

import {useTranslation} from 'react-i18next';
import FormFieldContainer from './FormFieldContainer';
import { FormButtonProps } from '../FormButton';


interface Props {
    item: FormButtonProps;
}

function ButtonElement(itemProps: Props) {
    const props = itemProps.item;
    const form = props.form;

    const [buttonLabel, setButtonLabel] = React.useState('');

    const [t] = useTranslation();

    React.useEffect(() => {
        const onRerender = () => {
            const label = props.form.isBusy ? props.busyLabel ?? 'Working...' : props.label;
            setButtonLabel(label);
        };

        form.pubsub.addListener(form.rerenderListenerId, onRerender);
        onRerender();

        return () => {form.pubsub.removeListener(form.rerenderListenerId, onRerender)};
    }, []);


    const buttonWidth = theme_.getFormButtonSize(props.size ?? 'md');

    const buttonType = props.type === 'submit' ? 'submit' : undefined;
    
    return <Button 
        id={props.id} 
        type={buttonType} //'submit'

        onClick={props.onClick}

        variant='contained'
        disabled={props.form.isBusy === true || props.form.isDisable === true}
        sx={[
            {width: buttonWidth},
            props.buttonSx ?? {},
            
            
            // background: props.form.isDisable ? theme_.disabledButtonColor : theme_.buttonColor,
            // backgroundColor: 'transparent', 
            // boxShadow: 'none', 
            // // color: 'transparent',
            // "&:hover": { 
            //     background: props.form.isDisable ? theme_.disabledButtonColor : theme_.buttonHoverColorGradient,
            //     borderStyle:  props.form.isDisable ? 'none' : 'style',
            //     borderWidth:  props.form.isDisable ? 0 : 1,
            //     borderColor:  props.form.isDisable ? 'none' : theme_.pageDarkModeTextColor, 
            //     backgroundColor: 'transparent', 
            //     boxShadow: 'none', 
            //     // color: 'transparent',
            // },
        ]}
    >
        {t(buttonLabel)}
    </Button>

}

export function FormButtonComponent(props: FormButtonProps) {

    return <FormFieldContainer {...props} needBox={true}>
        <ButtonElement item={props}/>
    </FormFieldContainer>

}
