import { createTheme } from "@mui/material/styles"


const muiTheme = createTheme({
    typography: {
        fontFamily: ["DejaVu Sans", "sans-serif"].join(','),
    },

    palette: {
        mode: 'dark'
    },


    components: {
        MuiButton: {
            styleOverrides: {
                // root: ({ ownerState }) => ({
                //     ...(ownerState.variant === "contained" &&
                //         ownerState.color === "primary" && {
                //         backgroundColor: theme_.themeColor,
                //         //color: "#fff",
                //     }),

                //     ...(ownerState.variant === "outlined" && {
                //         borderColor: theme_.themeColor,
                //         //color: "#fff",
                //     }),

                // }),
                root: ({ ownerState }) => ({
                    ...(ownerState.variant === "contained" &&
                        ownerState.color === "primary" && {
                        // backgroundColor: theme_.themeColor,
                        background: theme_.buttonColor,
                        color: '#f0f0f0',
                        //color: 'black',
                        //fontWeight: 'bold',
                        borderRadius: 30,
                        //fontSize: 'larger',
                        //fontWeight: 'bold',
                        fontWeight: '900',
                        textShadow: `
                        0 -1px 0 #fff, 
                        0 1px 0 #4e4e4e, 
                        0 2px 0 #4c4c4c, 
                        // 0 3px 0 #4a4a4a, 
                        0 4px 20px #484848
                        `,
                        
                        "&:hover": { 
                            background: theme_.buttonHoverColorGradient,
                            borderStyle: 'style',
                            borderWidth: 1,
                            borderColor: theme_.pageDarkModeTextColor, 
                        },
                        //color: "#fff",
                    }),

                    ...(ownerState.variant === "outlined" && {
                        // borderColor: theme_.themeColor,
                        border: `2px solid ${theme_.themeColor}`,
                        borderRadius: 30,
                        color: '#f0f0f0',
                        //fontSize: 'larger',
                        //fontWeight: 'bold',
                        "&:hover": { 
                            backgroundColor: "transparent", 
                            // borderColor: theme_.pageDarkModeTextColor, 
                            border: `2px solid ${theme_.buttonHoverColor}`,
                        },
                    }),

                }),
            },
        },


        MuiTypography: {
            defaultProps: {
                color: '#fff',
            },
        },

        // MuiDialogContent: {
        //     styleOverrides: {
        //         root: () => ({
        //             backgroundColor: theme_.evocardFeatureBackgroundColor,
        //         })
        //     }
        // },
        // MuiDialogActions: {
        //     styleOverrides: {
        //         root: () => ({
        //             backgroundColor: theme_.evocardFeatureBackgroundColor,
        //         })
        //     }
        // },
        // MuiDialogTitle: {
        //     styleOverrides: {
        //         root: () => ({
        //             backgroundColor: theme_.evocardFeatureBackgroundColor,
        //         })
        //     }
        // }
    },
})

//console.debug(muiTheme)

export default muiTheme
