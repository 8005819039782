import React from 'react';

import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';

import { Box, Button, Divider, Link, Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';

import PageContents from "../components/PageContents";

import Env from 'env';
import { ActivateCardDialog } from 'dialogs/ActivateCardDialog';

import CardCategory from 'components/CardCategory';
import { cardCategoryArray_ } from 'data/card';


import session_ from 'api/session';
import CreateAccount from 'dialogs/CreateAccount';

import HomePageFooter from 'home/components/HomePageFooter';
import MobileEvocardFeautresDisplay from './components/evocard_features_display/MobileEvocardFeaturesDisplay';
import DesktopEvocardFeautresDisplay from './components/evocard_features_display/DesktopEvocardFeaturesDisplay';

import { KeyframeCard } from './components/keyframes_animation/KeyframeCard';
import { KeyframesHomePhoneImage2 } from './components/keyframes_animation/KeyframePhoneImg';
import { KeyframeCardMobVersion } from './components/keyframes_animation/KeyframeCardMob';
import { KeyframeMobileImgMobVersion } from './components/keyframes_animation/KeyframePhoneImgMob';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import ImgElement from '../tsui/DomElements/ImgElement';


export default function HomePage() {
    const [t] = useTranslation();
    const navigate = useNavigate();


    const [checkCardId, setCheckCardId] = React.useState(false)
    const [orderWithoutLoggedIn, setOrderWithoutLoggedIn] = React.useState(false)
    const [activatedWithoutLoggedIn, setActivatedWithoutLoggedIn] = React.useState(false)
    // const [orderWithLoggedIn, setOrderWithLoggedIn] = React.useState(false)
    // let [userDetailsId, setUserDetailsId] = React.useState<string | null>(null);
    const [warningMessage, setWarningMessage] = React.useState('')

    const [cardTypeName, setCardTypeName] = React.useState('B')

    const [selectedMenu, setSelectedMenu] = React.useState(false)

    const onClick = React.useCallback(() => {

    }, []);

    const handleMouseEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (cardTypeName === 'B') {
            setCardTypeName('P')
            return
        }
        if (cardTypeName === 'P') {
            setCardTypeName('B')
            return
        }
        // Do something
    };


    return <PageContents>
        <ImgElement src='/images/pages_design/home_lines_original.svg' sx={{position: 'absolute', zIndex: -1, top: 300, width: '100%'}}></ImgElement>
        {/* <ImgElement sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, top: 300, width: '100%', zIndex: -1 }} src='/images/pages_design/home_lines.svg' /> */}

        {Env.isMobile &&
            <Stack direction="column" alignItems='center' justifyContent='center' spacing={4} >
                {/* <Box sx={{ width: '100%', display: 'flex' }}> */}
                <Stack direction="column" alignItems='center' justifyContent='center' >
                    <Stack spacing={4} alignItems='center'>
                        <Typography
                            sx={{
                                fontSize: 'xx-large',
                                fontWeight: 'bold',
                                width: '95%',
                                textAlign: 'center',
                                height: 'auto',
                                marginTop: 5,
                            }}>
                            {t("home.intro_text")}
                        </Typography>
                        <Box sx={{ display: 'flex', position: 'relative', minHeight: 250 }}>
                            {/* <KeyframeMobileImgMobVersion src='/images/phone_1.png' alt='/images/phone_1.png' /> */}
                            <KeyframeCardMobVersion />
                            <KeyframeMobileImgMobVersion />
                        </Box>
                        <Typography
                            sx={{

                                fontSize: 'small',
                                width: '90%',
                                height: 'auto',
                                textAlign: 'center',
                                marginTop: 2,
                                color: '#757575'
                            }}>
                            {t("home.intro_detail")}
                        </Typography>

                        <Stack direction="column" alignItems='center' justifyContent='center' >

                            {/* <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: '100%', mt: 3 }}> */}
                            <Stack direction="row" alignItems='center' justifyContent='space-around' >


                                {session_.isLoggedIn &&

                                    // <Box sx={{ height: 100, width: 110, display: 'grid', justifyItems: 'center' }}>
                                    <Stack direction="column" alignItems='center' justifyContent='space-around' >

                                        <Button
                                            variant='contained'
                                            onClick={() => { setCheckCardId(true) }}
                                            sx={{
                                                backgroundColor: '#FCB131',
                                                borderRadius: 25,

                                                color: 'white',
                                                minWidth: 110,
                                                height: 35,
                                                fontSize: 'small',
                                                fontWeight: '900',
                                                textShadow: `
                                                0 -1px 0 #fff, 
                                                0 1px 0 #4e4e4e, 
                                                // 0 2px 0 #4c4c4c, 
                                                // 0 3px 0 #4a4a4a, 
                                                0 4px 20px #484848
                                                `,
                                                "&:hover": { backgroundColor: theme_.buttonHoverColorGradient }
                                            }}>
                                            {t("Activate")}
                                        </Button>
                                        <Typography
                                            sx={{
                                                color: '#757575',
                                                fontSize: 10,
                                                // width: 140,
                                                textAlign: 'center',
                                                mt: 1,
                                                // ml: 1
                                            }}>
                                            {t("home.activate_it")}
                                        </Typography>

                                    </Stack>
                                }

                                {!session_.isLoggedIn &&
                                    // <Box sx={{ width: 20 }}>
                                    // <Box sx={{ height: 100, width: 110, display: 'grid', justifyItems: 'center' }}>
                                    <Stack direction="column" alignItems='center' justifyContent='space-around' >
                                        <Button
                                            variant='contained'
                                            onClick={() => { setActivatedWithoutLoggedIn(true); setWarningMessage("home.create_or_signin_activate") }}
                                            sx={{
                                                backgroundColor: '#FCB131',
                                                borderRadius: 25,

                                                color: 'white',
                                                minWidth: 110,
                                                height: 35,
                                                fontSize: 'small',
                                                fontWeight: '900',
                                                textShadow: `
                                                0 -1px 0 #fff, 
                                                0 1px 0 #4e4e4e, 
                                                // 0 2px 0 #4c4c4c, 
                                                // 0 3px 0 #4a4a4a, 
                                                0 4px 20px #484848
                                                `,
                                                "&:hover": { backgroundColor: theme_.buttonHoverColorGradient }
                                            }}>
                                            {t("Activate")}
                                        </Button>
                                        <Typography
                                            sx={{
                                                color: '#757575',
                                                fontSize: 'x-small',
                                                // width: 110,
                                                textAlign: 'center',
                                                mt: 1,
                                                // ml: -1
                                            }}>
                                            {t("home.activate_it")}
                                        </Typography>

                                    </Stack>
                                }

                                {/* <Box sx={{ alignSelf: 'center' }}> */}
                                <Stack direction="column" alignItems='center'>
                                    <Typography
                                        sx={{

                                            fontSize: 'medium',
                                            mt: -4,
                                        }}>
                                        {t("OR")}
                                    </Typography>
                                </Stack>

                                {session_.isLoggedIn &&
                                    // <Box sx={{ height: 100, width: 110, display: 'grid', justifyItems: 'center' }}>
                                    <Stack direction="column" alignItems='center' justifyContent='space-around' >
                                        <Button
                                            variant='contained'
                                            onClick={() => navigate('/order')}
                                            sx={{
                                                backgroundColor: '#FCB131',
                                                borderRadius: 25,

                                                color: 'white',
                                                width: 100,
                                                height: 35,
                                                fontSize: 'small',
                                                fontWeight: '900',
                                                textShadow: `
                                                0 -1px 0 #fff, 
                                                0 1px 0 #4e4e4e, 
                                                // 0 2px 0 #4c4c4c, 
                                                // 0 3px 0 #4a4a4a, 
                                                0 4px 20px #484848
                                                `,
                                                "&:hover": { backgroundColor: theme_.buttonHoverColorGradient }
                                            }}>
                                            {t('Order')}
                                        </Button>
                                        <Typography
                                            sx={{
                                                color: '#757575',
                                                fontSize: 10,
                                                // width: 130,
                                                textAlign: 'center',
                                                mt: 1,
                                                // ml: 1
                                            }}>
                                            {t("home.order_new_card")}
                                        </Typography>
                                    </Stack>
                                }
                                {!session_.isLoggedIn &&
                                    // <Box sx={{ width: 20 }}>
                                    // <Box sx={{ height: 100, width: 110, display: 'grid', justifyItems: 'center' }}>
                                    <Stack direction="column" alignItems='center' justifyContent='space-around' >
                                        <Button
                                            variant='contained'
                                            onClick={() => { setOrderWithoutLoggedIn(true); setWarningMessage("home.create_or_signin_order") }}
                                            sx={{
                                                backgroundColor: '#FCB131',
                                                borderRadius: 25,

                                                color: 'white',
                                                width: 100,
                                                height: 35,
                                                fontSize: 'small',
                                                fontWeight: '900',
                                                textShadow: `
                                                0 -1px 0 #fff, 
                                                0 1px 0 #4e4e4e, 
                                                // 0 2px 0 #4c4c4c, 
                                                // 0 3px 0 #4a4a4a, 
                                                0 4px 20px #484848
                                                `,
                                                "&:hover": { backgroundColor: theme_.buttonHoverColorGradient }
                                            }}>
                                            {t('Order')}
                                        </Button>
                                        <Typography
                                            sx={{
                                                color: '#757575',
                                                fontSize: 'x-small',
                                                // width: 100,
                                                textAlign: 'center',
                                                mt: 1,
                                                // ml: -1.8
                                            }}>
                                            {t("home.order_new_card")}
                                        </Typography>
                                    </Stack>

                                }




                            </Stack>
                        </Stack>

                    </Stack>
                </Stack>

                {/* <Box sx={{ width: '100%', height: 'auto', display: 'grid', justifyContent: 'center', mt: 7 }}> */}
                <Stack direction="column" alignItems='center' justifyContent='center' >

                    <Typography sx={{ textAlign: 'center', fontSize: 'x-large', mt: 2 }}>{t("home.about_product")}</Typography>
                    <Divider sx={{ width: '250px', borderBottomWidth: 3, backgroundColor: '#e8a412' }}></Divider>




                </Stack>
                <Typography sx={{ width: '90%', textAlign: 'center', mx: 4, mt: 2 }}>{t("home.about_product_text")}</Typography>

                <Stack direction="column" alignItems='center' justifyContent='center' >
                    <Typography sx={{ textAlign: 'center', fontSize: 'x-large', marginTop: 7 }}>{t("home.card_types")}</Typography>
                    <Divider sx={{ width: '280px', color: 'red', borderBottomWidth: 3, backgroundColor: '#e8a412' }}></Divider>
                </Stack>


                {cardCategoryArray_.map((category, index) => <CardCategory key={index} category={category} />)}

                <Stack direction='column' justifyContent='center' alignItems='center' width='100%' spacing={5}>

                    <Stack direction="column" alignItems='center' justifyContent='center' >

                        <Typography sx={{ textAlign: 'center', fontSize: 'x-large', mt: 2 }}>{t("home.evocard_features")}</Typography>
                        <Divider sx={{ width: '300px', borderBottomWidth: 3, backgroundColor: '#e8a412' }}></Divider>

                    </Stack>

                    <Stack direction='row' spacing={5}>
                        {/* <Link component="button" underline='none' onClick={() => { setSelectedMenu(false) }} sx={{ color: (!selectedMenu ? theme_.themeColor : ''), fontSize: 'large', "&:hover": { color: theme_.themeColor, backgroundColor: 'transparent' } }}>{t("home.one_tap_magic")}</Link>
                        <Link component="button" underline='none' onClick={() => { setSelectedMenu(true) }} sx={{ color: (selectedMenu ? theme_.themeColor : ''), fontSize: 'large', "&:hover": { color: theme_.themeColor, backgroundColor: 'transparent' } }}>{t("home.offline_experience")}</Link> */}
                        <Link component="button" underline='none' onClick={() => { setSelectedMenu(false) }} sx={{ color: theme_.themeColor, fontSize: 'xx-large', "&:hover": { color: theme_.themeColor, backgroundColor: 'transparent' } }}>{selectedMenu ? <PanoramaFishEyeIcon sx={{ fontSize: 25 }} /> : <RadioButtonCheckedIcon sx={{ fontSize: 25 }} />}</Link>
                        <Link component="button" underline='none' onClick={() => { setSelectedMenu(true) }} sx={{ color: theme_.themeColor, fontSize: 'xx-large', "&:hover": { color: theme_.themeColor, backgroundColor: 'transparent' } }}>{!selectedMenu ? <PanoramaFishEyeIcon sx={{ fontSize: 25 }} /> : <RadioButtonCheckedIcon sx={{ fontSize: 25 }} />}</Link>
                    </Stack>
                </Stack>

                <MobileEvocardFeautresDisplay
                    selectedMenu={selectedMenu}
                />

            </Stack>
        }
        {!Env.isMobile &&
            <Stack direction='column' width='100%'>
                {/* <ImgElement src='/images/pages_design/home_lines.svg'></ImgElement> */}
                <Stack direction='row' width='100%' alignContent='center' alignItems='center'>
                    <Stack direction='column' width='50%' sx={{
                        minWidth: 600,
                        ml: 0,
                        '@media screen and (min-width: 90em)': {
                            ml: 25
                        },
                        px: 2,
                    }}>
                        <Typography sx={{ 
                            fontSize: 'xxx-large', 
                            fontWeight: 'bold', 
                            maxWidth: 600, 
                            width: '100%', 
                            textAlign: 'left',
    //                         textShadow: `
    //   1px -1px 0 #767676, 
    //   -1px 2px 1px #737272, 
    //   -2px 4px 1px #767474
    //   `
    // textShadow: `
    // 2px 1px 0px #d5d5d5, 
    // 2px 2px 0px rgba(0, 0, 0, 0.2)
    // `
        textShadow: `
        // 2px -1px 0 #fff, 
        2px 0px 0 #fff, 
        2px 1px 0 #2e2e2e, 
        2px 2px 0 #2c2c2c, 
        2px 3px 0 #2a2a2a, 
        2px 4px 0 #282828, 
        // 2px 5px 0 #262626, 
        // 3px  6px 0 #242424, 
        // 3px  7px 0 #222, 
        // 3px  8px 0 #202020, 
        // 3px  9px 0 #1e1e1e, 
        // 3px  10px 0 #1c1c1c, 
        // 3px  11px 0 #1a1a1a, 
        // 3px  12px 0 #181818, 
        // 3px  12px 0 #161616, 
        // 3px  13px 0 #121212, 
        // 3px  13px 0 #141414, 
        5px 22px 50px rgba(0, 0, 0, 0.9)
        
    `
                            }}>{t("home.intro_text")}</Typography>
                        
                        <Typography sx={{ 
                            fontSize: 14, 
                            maxWidth: 400, 
                            width: '100%', 
                            //color: '#757575', 
                            textAlign: 'left',
                            }}> {t("home.intro_detail")}</Typography>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around', width: 470, padding: 5, ml: -7, }}>


                            {session_.isLoggedIn &&

                                <Box>
                                    <Button variant='contained' onClick={() => { setCheckCardId(true) }} sx={{ 
                                        backgroundColor: theme_.themeColor, 
                                        borderRadius: 25, color: 'white', minWidth: 160, height: 50, 
                                        fontSize: 'larger', 
                                        fontWeight: '900',
                                        textShadow: `
                                        0 -1px 0 #fff, 
                                        0 1px 0 #4e4e4e, 
                                        0 2px 0 #4c4c4c, 
                                        // 0 3px 0 #4a4a4a, 
                                        0 4px 20px #484848
                                        `,
                                        
                                        "&:hover": { backgroundColor: theme_.buttonHoverColorGradient } 
                                        }}>{t("Activate")}</Button>
                                    <Typography sx={{ color: '#757575', fontSize: 'small', width: 180, textAlign: 'center', mt: 1, ml: -0.5 }}>{t("home.activate_it")}</Typography>

                                </Box>
                            }
                            {!session_.isLoggedIn &&
                                <Box>
                                    <Button variant='contained' onClick={() => { setActivatedWithoutLoggedIn(true); setWarningMessage("home.create_or_signin_activate") }} sx={{ backgroundColor: theme_.themeColor, borderRadius: 25, color: 'white', minWidth: 160, height: 50, 
                                      fontSize: 'larger', 
                                      fontWeight: '900',
                                      textShadow: `
                                      0 -1px 0 #fff, 
                                      0 1px 0 #4e4e4e, 
                                      0 2px 0 #4c4c4c, 
                                      // 0 3px 0 #4a4a4a, 
                                      0 4px 20px #484848
                                      `,
                                      
                                      "&:hover": { backgroundColor: theme_.buttonHoverColorGradient } 
                                     }}>{t("Activate")}</Button>
                                    <Typography sx={{ color: '#757575', fontSize: 'small', width: 180, textAlign: 'center', mt: 1, ml: -0.5 }}>{t("home.activate_it")}</Typography>

                                </Box>
                            }
                            <Typography sx={{ fontSize: 'larger', mt: -4 }}>{t('OR')}</Typography>

                            {session_.isLoggedIn &&
                                <Box>
                                    <Button variant='contained' onClick={() => { navigate('/order') }} sx={{ 
                                        backgroundColor: theme_.themeColor, 
                                        borderRadius: 25, 
                                        //color: '#d0d0d0', 
                                        //color: theme_.themeColor, 
                                        width: 150, 
                                        height: 50, 
                                        fontSize: 'larger', 
                                        fontWeight: '900',
                                        textShadow: `
                                        0 -1px 0 #fff, 
                                        0 1px 0 #4e4e4e, 
                                        0 2px 0 #4c4c4c, 
                                        // 0 3px 0 #4a4a4a, 
                                        0 4px 20px #484848
                                        `,
                                        
                                        "&:hover": { backgroundColor: theme_.buttonHoverColorGradient } 
                                        }}>{t('Order')}</Button>
                                    <Typography sx={{ color: '#757575', fontSize: 'small', width: 180, textAlign: 'center', mt: 1, ml: -1.5 }}>{t("home.order_new_card")}</Typography>
                                </Box>
                            }
                            {!session_.isLoggedIn &&
                                <Box>
                                    <Button variant='contained' onClick={() => { setOrderWithoutLoggedIn(true); setWarningMessage("home.create_or_signin_order") }} sx={{ 
                                        backgroundColor: theme_.themeColor, 
                                        borderRadius: 25, color: 'white', width: 150, height: 50, 
                                        fontSize: 'larger', 
                                        fontWeight: '900',
                                        textShadow: `
                                        0 -1px 0 #fff, 
                                        0 1px 0 #4e4e4e, 
                                        0 2px 0 #4c4c4c, 
                                        // 0 3px 0 #4a4a4a, 
                                        0 4px 20px #484848
                                        `,
                                        
                                        "&:hover": { backgroundColor: theme_.buttonHoverColorGradient } 
                                        }}>{t('Order')}</Button>
                                    <Typography sx={{ color: '#757575', fontSize: 'small', width: 180, textAlign: 'center', mt: 1, ml: -1.5 }}>{t("home.order_new_card")}</Typography>
                                </Box>

                            }

                        </Box>
                    </Stack>

                    <Box sx={{
                        display: 'flex',
                        position: 'relative',
                        width: '50%',
                        minHeight: 600,
                        '@media screen and (min-width: 90em)': {
                            width: 550,

                        },
                    }}>
                        <KeyframesHomePhoneImage2 />
                        <KeyframeCard />
                    </Box>

                </Stack>

                <Box sx={{ width: '100%', height: 'auto', display: 'grid', justifyContent: 'center' }}>

                    <Typography sx={{ textAlign: 'center', fontSize: 40 }}>{t("home.about_product")}</Typography>
                    <Divider sx={{ width: '400px', borderBottomWidth: 3, backgroundColor: '#e8a412' }}></Divider>

                </Box>
                {/* <Slider /> */}

                <Typography sx={{ fontSize: 'large', textAlign: 'center', mt: 5, mx: 40 }}>{t("home.about_product_text")}</Typography>

                <Box sx={{ width: '100%', height: 'auto', display: 'grid', justifyContent: 'center' }}>
                    <Typography sx={{ textAlign: 'center', fontSize: 40, marginTop: 15 }}>{t("home.card_types")}</Typography>
                    <Divider sx={{ width: '400px', color: 'red', borderBottomWidth: 3, backgroundColor: '#e8a412' }}></Divider>
                </Box>


                {cardCategoryArray_.map((category, index) => <CardCategory key={index} category={category} />)}



                {/* <Typography sx={{ fontSize: 'xxx-large' }}>EvoCard Features</Typography> */}
                <Stack direction='column' justifyContent='center' alignItems='center' width='100%' spacing={10}>
                    <Stack justifyContent='center' alignItems='center'>
                        <Typography sx={{ textAlign: 'center', fontSize: 'xxx-large', marginTop: 15 }}>{t("home.evocard_features")}</Typography>
                        <Divider sx={{ width: '900px', color: 'red', borderBottomWidth: 3, backgroundColor: '#e8a412' }}></Divider>
                    </Stack>

                    <Stack direction='row' spacing={10}>
                        {/* <Link component="button" underline='none' onClick={() => { setSelectedMenu(false) }} sx={{ color: (!selectedMenu ? theme_.themeColor : ''), fontSize: 'xx-large', "&:hover": { color: theme_.themeColor, backgroundColor: 'transparent' } }}>{t("home.one_tap_magic")}</Link> */}
                        {/* <Link component="button" underline='none' onClick={() => { setSelectedMenu(true) }} sx={{ color: (selectedMenu ? theme_.themeColor : ''), fontSize: 'xx-large', "&:hover": { color: theme_.themeColor, backgroundColor: 'transparent' } }}>{t("home.one_tap_magic")}</Link> */}
                        <Link component="button" underline='none' onClick={() => { setSelectedMenu(false) }} sx={{ color: theme_.themeColor, fontSize: 'xx-large', "&:hover": { color: theme_.themeColor, backgroundColor: 'transparent' } }}>{selectedMenu ? <PanoramaFishEyeIcon sx={{ fontSize: 30 }} /> : <RadioButtonCheckedIcon sx={{ fontSize: 30 }} />}</Link>
                        <Link component="button" underline='none' onClick={() => { setSelectedMenu(true) }} sx={{ color: theme_.themeColor, fontSize: 'xx-large', "&:hover": { color: theme_.themeColor, backgroundColor: 'transparent' } }}>{!selectedMenu ? <PanoramaFishEyeIcon sx={{ fontSize: 30 }} /> : <RadioButtonCheckedIcon sx={{ fontSize: 30 }} />}</Link>
                    </Stack>
                </Stack>

                <DesktopEvocardFeautresDisplay
                    selectedMenu={selectedMenu}
                />


            </Stack>
        }

        <HomePageFooter fromContactUsPage={false}/>

        {/* <Divider sx={{ width: '100%', color: 'red', borderBottomWidth: 50, backgroundColor: '#e8a412' }}></Divider> */}
        <Divider sx={{ width: '100%', color: 'red', borderBottomWidth: 50, backgroundColor: 'transparent' }}></Divider>
        <Typography sx={{ fontSize: Env.isMobile ? 'small' : 'large', marginTop: -4, textAlign: 'center', color: theme_.themeColor }}>{t("home.copyright")}</Typography>


        {checkCardId && <ActivateCardDialog onClose={() => { setCheckCardId(false) }}></ActivateCardDialog>}
        {/* {orderWithLoggedIn && <BuyNewCard userId={userDetailsId as string} onClose={() => { setOrderWithLoggedIn(false) }}></BuyNewCard>} */}
        {/* {orderWithLoggedIn && <BuyNewCard onClose={() => { setOrderWithLoggedIn(false) }}></BuyNewCard>} */}
        {orderWithoutLoggedIn && <CreateAccount warningMessage={warningMessage} onClose={() => { setOrderWithoutLoggedIn(false); setWarningMessage('') }}></CreateAccount>}
        {activatedWithoutLoggedIn && <CreateAccount warningMessage={warningMessage} onClose={() => { setActivatedWithoutLoggedIn(false); setWarningMessage('') }}></CreateAccount>}
    </PageContents >


}


