
import { styled } from "@mui/material";

let width = 160;
let height = 100;
let backgroundSize = '10em'

export const KeyframeCardMobVersion = styled("img")({

    WebkitTransformStyle: 'flat',
    transformStyle: 'flat',

    position: "absolute",
    left: -150,
    top: 0,

    width: width,
    height: height,
    borderRadius: 10,
    zIndex: 2,

    content: `url('/images/card_types/business_card_front.svg')`,

    "@keyframes pulsate": {

        '0%': {

            transform: 'perspective(800px) translateZ(-100px) rotateY(10deg) rotateZ(-4deg) scale(1.1) rotateX(10deg)',
            filter: 'drop-shadow(-10px 10px 10px #555)',

        },
     
        '10%': {
            transform: 'perspective(800px) translateZ(-100px) rotateY(10deg) rotateZ(-4deg) scale(1.1) rotateX(10deg)',
            filter: 'drop-shadow(-10px 10px 10px #555)',
        },

        // '65%': {
        '75%': {
            // left: -70,
            // top: 70,

            transform: 'perspective(800px) translateX(80px) translateY(70px) translateZ(-100px) rotateY(40deg) rotateZ(-15deg) scale(0.9) rotateX(30deg)',
            filter: 'drop-shadow(0px 10px 10px #555)',

        },
        '80%': {
            // left: -70,
            // top: 70,

            transform: 'perspective(800px)  translateX(80px) translateY(70px) translateZ(-100px) rotateY(40deg) rotateZ(-15deg) scale(0.9) rotateX(30deg)',
            filter: 'drop-shadow(0px 10px 10px #555)',

        },
        '100%': {
            // left: -70,
            // top: 70,

            transform: 'perspective(800px)  translateX(80px) translateY(70px) translateZ(-100px) rotateY(40deg) rotateZ(-15deg) scale(0.9) rotateX(30deg)',
            filter: 'drop-shadow(0px 10px 10px #555)',

        },



    },

    animation: "pulsate 5s infinite alternate",
    // animation: "pulsate 2s infinite ease, spin 3s infinite ease",
    // animation: "pulsate 7s infinite linear",
    // animation: "move 3s infinite linear",
    // display: 'inline-block'
});




// import { styled } from "@mui/material";

// let width = 160;
// let height = 100;
// let backgroundSize = '10em'

// export const KeyframeCardMobVersion = styled("img")({

//     WebkitTransformStyle: 'flat',
//     transformStyle: 'flat',

//     width: width,
//     height: height,
//     borderRadius: 10,

//     content: `url('/images/card_types/business_card_front.svg')`,

//     "@keyframes pulsate": {

//         '0%': {
//             left: 10,
//             top: 0,

//             transform: 'perspective(800px) translateZ(-200px) rotateY(10deg) rotateZ(-4deg) scale(1.1) rotateX(20deg)',
//             filter: 'drop-shadow(-10px 10px 10px #555)',

//         },
     
//         '10%': {
//             left: 10,
//             top: 0,
         
//             transform: 'perspective(800px) translateZ(-200px) rotateY(10deg) rotateZ(-4deg) scale(1.1) rotateX(20deg)',
//             filter: 'drop-shadow(-10px 10px 10px #555)',

//         },

//         '65%': {
//             left: 70,
//             top: 50,

//             transform: 'perspective(800px) translateZ(-200px) rotateY(50deg) rotateZ(-15deg) scale(0.9) rotateX(40deg)',
//             filter: 'drop-shadow(0px 10px 10px #555)',

//         },
//         '80%': {
//             left: 70,
//             top: 50,

//             transform: 'perspective(800px) translateZ(-200px) rotateY(50deg) rotateZ(-15deg) scale(0.9) rotateX(40deg)',
//             filter: 'drop-shadow(0px 10px 10px #555)',

//         },
//         '100%': {
//             left: 70,
//             top: 50,

//             transform: 'perspective(800px) translateZ(-200px) rotateY(50deg) rotateZ(-15deg) scale(0.9) rotateX(40deg)',
//             filter: 'drop-shadow(0px 10px 10px #555)',

//         },



//     },

//     animation: "pulsate 5s infinite alternate",
//     position: "absolute",
//     // animation: "pulsate 2s infinite ease, spin 3s infinite ease",
//     // animation: "pulsate 7s infinite linear",
//     // animation: "move 3s infinite linear",
//     // display: 'inline-block'
// });