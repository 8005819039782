import React from 'react'

import {Button} from '@mui/material'
import { ButtonProps } from './PageButton'

export function DialogButton(props: ButtonProps) {
    const [buttonLabel] = React.useState(props.tlabel ?? props.label ?? '* BUTTON *')
    const [layoutSx] = React.useState(props.layout ? props.layout.sx() : {})
    const [variant] = React.useState<'outlined' | 'contained'>(
        props.default === true ? 'contained' : 'outlined'
    )
    //const navigate = useNavigate()

    const handleClick = React.useCallback(() => {
        props.onClick && props.onClick()
        props.onClickTrue && props.onClickTrue(true)
        props.onClickParam && props.onClickParam.cb(props.onClickParam.param)

        // if (props.href) {
        //     navigate(props.href)
        // }
    }, [])

    if (props.href) {
        return (
            <Button variant={variant} target={props.target} href={props.href} sx={layoutSx}>
                {buttonLabel}
            </Button>
            // <Link variant='button' target={props.target} href={props.href}>
            //     {buttonLabel}
            // </Link>
        )
    }

    // Designed component
    //
    return (
        <Button variant={variant} onClick={handleClick} sx={layoutSx}>
            {buttonLabel}
        </Button>
    )
}
