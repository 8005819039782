import React from 'react';

import { useNavigate } from "react-router-dom";


import { Button, Stack, Typography, Box, Grid } from '@mui/material';

import * as Api from "api";

import * as CardsApi from 'api/cards'


import PageContents from "components/PageContents";

import { styled as muiStyled } from '@mui/system';
import session_ from 'api/session';
import UserCardDetailsModal from './user/cards/UserCardDetailsModal';
import { CardDisplayData } from 'data/card_display_data';
import Env from 'env';
import ErrorPage from './ErrorPage';
import ImgElement from '../tsui/DomElements/ImgElement';
import ProgressIndicator from '../tsui/ProgressIndicator';

import QrCode2Icon from '@mui/icons-material/QrCode2';
import CardQrShowDialog from 'dialogs/CardQrDialog';
import { useTranslation } from 'react-i18next';


export default function UserCardsPage() {

    const [t] = useTranslation();

    const navigate = useNavigate();
    const [showQr, setShowQr] = React.useState(false)

    const [cardId, setCardId] = React.useState('')

    const mounted = React.useRef(false);
    const [dataRequested, setDataRequested] = React.useState(false);
    let [error, setError] = React.useState<Error | null>(null);
    const [cards, setCards] = React.useState<CardDisplayData[] | null>(null);

    let [cardDetailsId, setCardDetailsId] = React.useState<string | null>(null);
    let [cardType, setCardType] = React.useState<string>('');

    React.useEffect(() => {
        mounted.current = true;
        if (!dataRequested) {
            Api.requestSession<CardsApi.ApiCard[]>('cards', 'fetch', {}).then(cards => {
                if (mounted.current) {
                    //setCards(cards);
                    let cardsData: CardDisplayData[] = [];

                    for (let c of cards) {
                        cardsData.push(new CardDisplayData(c));
                    }

                    setCards(cardsData)
                }
            }).catch(setError);
            setDataRequested(true);
            return;
        }
        return () => { mounted.current = false }
    }, [dataRequested]);


    if (error) {
        return <ErrorPage error={error} />
    }

    if (!cards) {
        return <PageContents title="EvoCard - My Cards" current='cards'>
            <ProgressIndicator />
        </PageContents>
    }

    return <PageContents title="EvoCard - My Cards" current='cards'>
        {Env.isMobile
            &&
            <Stack direction="column" width='100%' justifyContent="center" alignItems='center'>


                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    {/* <Box sx={{ display: 'grid', minWidth: 200, height: 35, p: 2, border: '1px dashed #e8a412', marginTop: 3, }}> */}
                    <Button onClick={() => navigate('/order')} variant='contained' sx={{ color: theme_.pageDarkModeTextColor, px: 3, py: 1.2, fontSize: 'middle' }}>{t('Order New Card')}</Button>
                    {/* </Box> */}
                </Box>

                <Box sx={{ display: 'block', width: '90%', justifyContent: 'center', mb: 2 }}>

                    {cards.map((type, index) =>
                        // <Stack direction="column" width='100%' justifyContent="center" alignItems='center'>
                        // <Stack>
                        <Box sx={{
                            backgroundColor: theme_.evocardFeatureBackgroundColor, display: 'block', borderRadius: 5, textAlign: 'center', p: 2, marginTop: 5, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                        }}
                            key={index}
                        >
                            <Button onClick={() => { setShowQr(true); setCardId(type.cardId) }} sx={{ mt: 1, ml: '60vw', color: theme_.themeColor, border: `2px dashed ${theme_.themeColor}`, "&:hover": { color: 'white', backgroundColor: theme_.themeColor } }}><QrCode2Icon sx={{ fontSize: 50 }} /> </Button>

                            <Box>

                                {/* <ImgElement src={`/images/card_types/${type.cardType}_account_card.png`} sx={{ width: '94%', height: 'auto', zIndex: -1, marginTop: 2, padding: 1 }}></ImgElement> */}
                                {/* <ImgElement src={Env.getCardTypeName(type.cardType, 'front')} sx={{ width: '94%', height: 'auto', zIndex: -1, marginTop: 2, padding: 1 }}></ImgElement> */}
                                <Box
                                    sx={{
                                        mt: 2,
                                        display: 'block',
                                        width: '100%',

                                    }}>
                                    <ImgElement
                                        src={Env.getCardTypeName(type.cardType, 'back')}
                                        // sx={{ width: 480, ml: -2 }}
                                        sx={{
                                            display: 'block',

                                            width: '60vw',
                                            borderRadius: 5,
                                            mt: -3,
                                            ml: -1,
                                            padding: 1,
                                            transform: 'perspective(1200px) translateZ(-220px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                            transition: '0.6s ease all',
                                            filter: 'drop-shadow(-10px 10px 10px #555)',
                                        }}
                                    />
                                    <ImgElement
                                        src={Env.getCardTypeName(type.cardType, 'front')}
                                        sx={{
                                            display: 'block',
                                            width: '60vw',
                                            borderRadius: 5,
                                            mt: -10,
                                            ml: 9,
                                            padding: 1,
                                            transform: 'perspective(1200px) translateZ(-220px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                            transition: '0.6s ease all',
                                            filter: 'drop-shadow(-10px 10px 10px #555)',
                                        }}
                                    />
                                </Box>

                                <Typography sx={{
                                    color: '#e8a412',
                                    // position: 'absolute',
                                    marginTop: 1,
                                    // marginLeft: 9,
                                    fontSize: 'xx-large',
                                }}>{type.cardId}</Typography>

                                <Button sx={{ marginTop: 1, minWidth: 140, height: 50 }} variant='outlined' onClick={() => { setCardDetailsId(type.cardId); setCardType(type.cardType) }}>{t('Details')}</Button>

                            </Box>
                        </Box>

                    )}

                </Box>
            </Stack>
        }

        {!Env.isMobile
            &&
             <>
                <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/pages_design/background_layout.svg' />

                <Box sx={{ width: '100%', zIndex: 1, display: 'flex', justifyContent: 'center', mt: 3 }}>
                    {/* <Box sx={{ display: 'block', textAlign: 'center', width: 270, height: 50, p: 2, border: '1px dashed #e8a412', marginTop: 0, }}> */}
                    <Button onClick={() => navigate('/order')} variant='contained' sx={{ color: theme_.pageDarkModeTextColor, px: 4, py: 2, fontSize: 'larger' }}>{t('Order New Card')}</Button>
                    {/* <Typography sx={{fontSize: 'smaller'}}>If the card is ordered and ready, you can pick it up from our company (address Aram Khachatryan 15)</Typography> */}
                    {/* </Box> */}
                </Box>

                {/* <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-around' }}> */}
                <Grid container justifyContent='space-around' width='100%'>

                    {/* {cardsType.map((type, index) => */}
                    {cards.map((type, index) =>

                        // <Box sx={{ display: 'inline-block', borderRadius: 5, marginLeft: 10, marginTop: 5, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 2 }} key={index}>
                        <Grid
                            key={index}
                            item
                            xs={10}
                            sm={8}
                            md={5.8}
                            lg={4.5}
                            xl={3.5}
                            sx={{
                                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 2,
                                // ml: 5,
                                // width: '50vw',
                                height: 430,
                                '@media screen and (min-width: 100em)': {
                                    height: 480,
                                },
                                zIndex: 1,
                                textAlign: 'center',
                                borderRadius: 5,
                                marginTop: 5,
                                backgroundColor: theme_.evocardFeatureBackgroundColor,
                                // mt: -5,
                                // display: 'flex',
                                // justifyContent: 'center',
                            }}
                        >
                            {/* <Box sx={{ display: 'block', textAlign: 'center', width: 500, height: 500, borderRadius: 5, marginTop: 5, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 2 }} key={index}> */}

                            <Button
                                onClick={() => { setShowQr(true); setCardId(type.cardId) }}
                                sx={{
                                    mt: 1,
                                    ml: 43.5,
                                    color: theme_.themeColor,
                                    backgroundColor: theme_.evocardFeatureBackgroundColor,
                                    border: `2px dashed ${theme_.themeColor}`,
                                    "&:hover": { color: 'white', backgroundColor: theme_.themeColor },
                                    '@media screen and (min-width: 106em)': {
                                        ml: 50
                                    },
                                }}>
                                <QrCode2Icon
                                    sx={{
                                        fontSize: 45,
                                        '@media screen and (min-width: 80em)': {
                                            width: 50,
                                        },
                                    }}
                                />
                            </Button>
                            <Box
                                sx={{
                                    // display: 'inline-block',
                                    // marginLeft: 10,
                                    // filter: 'drop-shadow(0px 0px 18px rgba(34, 33, 81, 0.25))',
                                    // filter: 'blur(2px)',
                                    // opacity: 0.5,
                                    // filter: 'blur(0)',
                                    width: '70%',
                                    textAlign: 'center',
                                    borderRadius: 5,
                                    marginTop: -5,

                                    transform: 'perspective(800px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                    //transform: 'rotateY(25deg) scale(0.9) rotateX(10deg)',
                                    //WebkitTransform: 'perspective(800px) translateZ(200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                    // transform: 'rotateY(25deg) scale(0.9) rotateX(10deg)',
                                    
                                    // transform: 'perspective(800px) translateZ(-150px) rotateY(25deg) scale(0.6) rotateX(10deg)',
                                    transition: '0.6s ease all',
                                    "&:hover": {
                                        // transform: 'rotateY(25deg) scale(0.9) rotateX(10deg)',
                                        // perspective: 800,
                                        transform: 'perspective(800px) translateZ(150px) rotateY(0deg) translateY(-10px) rotateX(0deg) scale(0.6)',
                                        transformStyle: 'flat',                              
                                        // transform: 'perspective(800px) translateZ(-200px) rotateY(0deg) translateY(-10px) rotateX(0deg) scale(1)',
                                       
                                        // filter: 'drop-shadow(0px 1px 10px #555)',
                                        // opacity: 1,
                                    },
                                    WebkitTransformStyle: 'flat',
                                    transformStyle: 'flat',
                               
                                }}>

                                {/* <ImgElement src={`/images/${type.cardType}_account_card.png`} sx={{ width: 500, height: 'auto', zIndex: -1, marginTop: 2, padding: 1 }}/> */}

                                <ImgElement
                                    src={Env.getCardTypeName(type.cardType, 'back')}
                                    sx={{
                                        width: 280,
                                        '@media screen and (min-width: 100em)': {
                                            width: 320,
                                        },
                                        // height: 'auto',
                                        borderRadius: 5,
                                        //zIndex: -1,
                                        mt: -3,
                                        ml: 3,
                                        padding: 1,
                                        filter: 'drop-shadow(-10px 10px 10px #555)',
                                        perspective: 800,
                                        // WebkitTransformStyle: 'flat',
                                        // transformStyle: 'flat',
    

                                    }} />

                                <ImgElement
                                    src={Env.getCardTypeName(type.cardType, 'front')}
                                    sx={{
                                        width: 280,
                                        '@media screen and (min-width: 100em)': {
                                            width: 310,
                                        },
                                        // height: 'auto',
                                        borderRadius: 5,
                                        zIndex: -1,

                                        mt: -15,
                                        ml: 15,
                                        padding: 1,
                                        filter: 'drop-shadow(-10px 10px 10px #555)',
                                        perspective: 800,
                                        //transform: 'perspective(800px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                        //WebkitTransformStyle: 'flat',
                                        //transformStyle: 'flat',


                                    }} />



                                {/* <Typography sx={{
                                    color: theme_.themeColor,
                                    // color: '#e8a412',
                                    // position: 'absolute',
                                    // top: 170,
                                    // left: 240,
                                    // '@media screen and (min-width: 100em)': {
                                    //     top: 220,
                                    //     left: 260,
                                    // },
                                    fontSize: 35,



                                }}>{type.cardId}</Typography> */}
                            </Box>


                            <Box sx={{ display: 'flex', mt: -2, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                <Typography sx={{ fontSize: 'x-large'}}>{t('Card ID')}:</Typography>

                                <Typography sx={{
                                    color: theme_.themeColor,
                                    // color: '#e8a412',
                                    // position: 'absolute',
                                    // top: 170,
                                    // left: 240,
                                    // '@media screen and (min-width: 100em)': {
                                    //     top: 220,
                                    //     left: 260,
                                    // },
                                    fontSize: 'xx-large',
                                    mt: -0.5,
                                    ml: 1



                                }}>{type.cardId}</Typography>
                            </Box>

                            {/* <Button sx={{ mt: 5, minWidth: 120, height: 50 }} variant='outlined' onClick={() => { setCardDetailsId(type.cardId); setCardType(type.cardType) }}>{t('Details')}</Button> */}
                            <Button sx={{ mt: 3, minWidth: 120, height: 50 }} variant='outlined' onClick={() => { setCardDetailsId(type.cardId); setCardType(type.cardType) }}>{t('Details')}</Button>


                            {/* </Box> */}
                        </Grid>

                    )}
                    {/* </Box> */}
                </Grid>
            </>
        }


        {cardDetailsId && <UserCardDetailsModal cardType={cardType} onSelected={() => { }} cardId={cardDetailsId} onClose={() => { setCardDetailsId(null); setDataRequested(false) }} />}
        {showQr && <CardQrShowDialog cardId={cardId} onClose={() => { setShowQr(false) }} />}

    </PageContents >;

}



