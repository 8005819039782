import React from 'react';

import {Stack, TextField, Typography} from '@mui/material';

//import { TimePicker } from '@mui/x-date-pickers';

import {TextChangeEvent} from '../../React/ReactUtil';
import PageDialog from '../../PageDialog';
import {InputFormField} from './FormFieldContext';

interface DialogProps {
    field: InputFormField;

    onResponse: (value: string | null) => void;
}

export default function FormFieldEditDialog(props: DialogProps) {
    const [prompt] = React.useState('Enter ' + props.field.tlabel + '...');
    const [value, setValue] = React.useState(props.field.value);
    const valueRef = React.useRef(props.field.value);

    const onValueChange = React.useCallback((e: TextChangeEvent | null) => {
        if (e === null)
            return;

        let v = e.target.value;
        setValue(v);
        valueRef.current = v;
        props.field.valueChanged = true;
    }, []);

    // if (props.field.fieldType === 'time') {
    //     return (
    //         <TimePicker value={value} onChange={onValueChange}/>  
    //     );
    // }

    return (
        <PageDialog
            type='cancel-confirm'
            size='sm'
            ttitle={props.field.tlabel}
            onCloseNull={props.onResponse}
            onConfirm={() => props.onResponse(valueRef.current)}
        >
            {/* <DialogContent dividers> */}
            <Stack direction='column' spacing={2}>
                <Typography>{prompt}</Typography>

                <TextField
                    autoFocus={true}
                    value={value}
                    onChange={onValueChange}
                />
            </Stack>
        </PageDialog>
    );
}
