import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Stack } from '@mui/material'

import MenuIcon from '@mui/icons-material/Menu'

import Env from 'env'


import PageMainMenu from './PageMainMenu'



export default function PageNavBarMobile() {
    const navigate = useNavigate()

    const [sessionUpdated, setSessionUpdated] = React.useState(false)

    const [openDrawer, setOpenDrawer] = React.useState(false)




    const onMenuItem = React.useCallback((key: string) => {
        setOpenDrawer(false)
    }, [])



    return (
        <>
            <Stack direction="row" alignItems='center' justifyContent='center'>
                {/* <Button onClick={() => setOpenDrawer(!openDrawer)} sx={{ position: 'absolute', right: 0, top: 15, color: 'black' }}> */}
                <Button onClick={() => setOpenDrawer(!openDrawer)} sx={{  color: 'black' }}>
                    <MenuIcon
                        sx={{
                            color: theme_.pageDarkModeTextColor,
                            width: 40,
                            height: 40,
                        }}
                    />
                </Button>
            </Stack>

            <PageMainMenu
                open={openDrawer}
                onClose={() => setOpenDrawer(false)}
                onSelected={onMenuItem}
            />
        </>
    )
}
