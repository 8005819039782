import React from 'react'
import FormImplPage from './FormElements/FormImplPage'

import * as FT from './FormTypes'


export function PageForm(props: FT.FormProps) {
    // console.log('PageForm',props)
    return (
        <FormImplPage {...props}>
            {props.children}
        </FormImplPage>
    )
}
