import * as CardsApi from 'api/cards'

export interface CardDisplayDataField {
    name: string;
    value: string;

    lang?: string;
    url?: string;
    imageId?: string;
};

export class CardDisplayData {
    cardId!: string;
    userId!: string;
    cardType!: string;
    template!: string;
    languages!: string[];

    fields!: Array<CardDisplayDataField>;

    //order!: string;
    //activation!: string;
    //orderDate!: Date;
    //clientEmail!: string;
    //guiCardDate: Date | undefined; //TODO DATE



    getFieldValue(name: string, lang?: string) {
        if (!this.fields) {
            return '';
        }

        // if (lang === undefined || lang === '' || lang === 'en') {
        if (lang === undefined || lang === '') {
            for (let f of this.fields) {
                // if (f.lang !== undefined && f.lang !== '' && f.lang !== 'en')
                if (f.lang !== undefined && f.lang !== '')
                    continue;
                if (f.name === name)
                    return f.value;
            }
        } else {
            let langSuffix = `_${lang}`;

            let key = name + langSuffix;

            for (let f of this.fields) {
                if (f.lang !== lang)
                    continue;
                if (f.name === key)
                    return f.value;
            }
        }



        // let langSuffix = lang ? `_${lang}` : '';

        // let key = name + langSuffix;

        // //let fieldFound = false;
        // for (let field of this.fields) {
        //     if (field.name === key) {
        //         return field.value;
        //     }
        // }

        return '';
    }


    constructor(c?: CardsApi.ApiCard) {
        if (!c)
            return;

        this.cardId = c.cardId;
        this.template = c.template;
        this.cardType = c.cardType;
        this.languages = c.languages ?? ['en'];

        this.fields = c.fields;

        for (let field of this.fields) {
            // if (field.lang === undefined || field.lang === '' || field.lang === 'en')
            if (field.lang === undefined || field.lang === '')
                continue;

            field.name = `${field.name}_${field.lang}`;
        }
    }

    makeApiFields() {
        //let fields = new Array<CardDisplayDataField>();

        //console.log(this.fields);

        let fields = [...this.fields];

        for (let f of fields) {
            //let fc = {...f};
            if (!f.name)
                continue;

            let comps = f.name.split('_');
            f.name = comps[0];
            if (comps.length > 1)
                f.lang = comps[1];
            //fields.push(fc);
        }

        return fields;
    }
}

