import React from 'react';

import {Stack, Typography} from '@mui/material';

import PageDialog from 'tsui/PageDialog';
import {useTranslation} from 'react-i18next';
import Env from '../env';

interface MessageDetailsProps {
    onClose: () => void;
}

export default function SuccessMessageSend(props: MessageDetailsProps) {
    const [t] = useTranslation();

    return (
        <PageDialog type='panel' size='sm' title='Message' onClose={props.onClose}>
            <Stack alignItems='center' sx={{textAlign: 'center'}}>
                <Typography sx={{fontSize: Env.isMobile ? 'x-large' : 'xx-large', padding: 1, color: '#d69710'}}>
                    {t('Your message has been sent successfully')}
                </Typography>

                <Typography
                    sx={{fontSize: Env.isMobile ? 'medium' : 'x-large', color: theme_.inputTextBackgroundColor}}
                >
                    {t('Our employees will contact you')}
                </Typography>
            </Stack>
        </PageDialog>
    );
}
