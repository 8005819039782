import React from 'react';

import i18next from 'i18next';


import { InputFormContext } from 'tsui/Form/FormContext';
import * as F from 'tsui/Form';


interface MobileCardDetailsPersonalProps {
    form: InputFormContext
    chosenLang: Array<string>
    getFieldValue: (name: string, lang?: string) => string
    getFieldAtt: (lang: string, fieldName: string) => boolean
    // onSubmit: (evt: F.InputFormEvent) => void
    // error: Error | string | null
}

const langs_ = ['en', 'am', 'ru'];


export default function MobileCardDetailsPersonal(props: MobileCardDetailsPersonalProps) {

    const [lt] = React.useState({
        en: i18next.getFixedT('en', 'profile'),
        am: i18next.getFixedT('am', 'profile'),
        ru: i18next.getFixedT('ru', 'profile'),
    })

    const getFieldValue = React.useCallback((name: string, lang?: string) => { return props.getFieldValue(name, lang) }, []);
    const form = props.form


    return (
        <F.FormGrid form={form}>
            <F.InputGroup label='Name' form={form} />
            {langs_.map((lang, index) =>
                <React.Fragment key={index}>

                    <F.InputText maxLength={100} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.firstName')} id={`firstName${`_${lang}`}`} attention={props.getFieldAtt(lang, "firstName")} autocomplete='given-name' value={getFieldValue('firstName', lang)} form={form} xsMax />
                    <F.InputText maxLength={100} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.middleName')} id={`middleName${`_${lang}`}`} autocomplete='additional-name' value={getFieldValue('middleName', lang)} form={form} xsMax />
                    <F.InputText maxLength={100} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.lastName')} id={`lastName${`_${lang}`}`} attention={props.getFieldAtt(lang, "lastName")} autocomplete='family-name' value={getFieldValue('lastName', lang)} form={form} xsMax />
                </React.Fragment>

            )}

            <F.InputGroup label='Company/Position' form={form} />

            {langs_.map((lang, index) =>
                <F.InputText key={index} maxLength={70} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.company_position')} id={`profession${`_${lang}`}`} value={getFieldValue('profession', lang)} form={form} xsMax />
            )}

            <F.InputGroup label='Contact' form={form} />
            <F.InputText maxLength={100} label='Phone' id='phone' validate="tel" autocomplete='tel' value={getFieldValue('phone')} form={form} xsMax />
            <F.Spacer form={form} xs={8} />

            <F.InputText maxLength={100} label='Email' id='email' validate='email' autocomplete='email' value={getFieldValue('email')} form={form} xsMax />
            <F.InputText maxLength={100} label='Telegram' id='telegram' validate='tel' autocomplete='tel' value={getFieldValue('telegram')} form={form} xsMax />
            <F.InputText maxLength={100} label='Viber' id='viber' validate='tel' autocomplete='tel' value={getFieldValue('viber')} form={form} xsMax />
            <F.InputText maxLength={100} label='WhatsApp' id='whatsapp' validate='tel' autocomplete='tel' value={getFieldValue('whatsapp')} form={form} xsMax />


            <F.InputGroup label='Social Networks' form={form} />
            <F.InputText maxLength={200} label='Facebook' id='facebook' value={getFieldValue('facebook')} form={form} xsMax />
            <F.InputText maxLength={200} label='Instagram' id='instagram' value={getFieldValue('instagram')} form={form} xsMax />
            <F.InputText maxLength={200} label='LinkedIn' id='linkedin' value={getFieldValue('linkedin')} form={form} xsMax />


        </F.FormGrid>
    )
}

