import React from 'react';

import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {
    Box,
    Button,
    FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Stack,
    Typography,
} from '@mui/material';

import Env from 'env';

import * as Form from 'tsui/Form';
import * as Api from 'api/api';

import PageDialog from 'tsui/PageDialog';
import {ErrorAlert} from 'components/ErrorAlert';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface Props {
    onClose: () => void;
}

export default function ChangePassword(props: Props) {
    const form = Form.useForm({type: 'input'});

    const navigate = useNavigate();

    const [changePasswordState, setChangePasswordState] = React.useState(false);
    const [error, setError] = React.useState<Error | string | null>(null);

    const [passwordOne, setPasswordOne] = React.useState(false);
    const [passwordSecond, setPasswordSecond] = React.useState(false);

    const search = useLocation().search;
    const urlParams = new URLSearchParams(search);
    const email = urlParams.get('email');

    const onSubmit = React.useCallback((evt: Form.InputFormEvent) => {
        if (form.error) {
            return;
        }

        let current = evt.data.currentPassword;

        let password1 = evt.data.newPassword1;
        let password2 = evt.data.newPassword2;

        let dataKeys = Object.keys(evt.data);
        let inputFieldId: Array<string> = [];

        if (dataKeys.length !== 0) {

            for (let key of dataKeys) {
                if (evt.data[key] === '') {
                    inputFieldId.push(key);
                    // inputFieldId = key;
                    // break;
                } else if(password1 !== password2 && key !== 'currentPassword'){
                    inputFieldId.push(key);
                } 
            }
        }

        if (password1 !== password2) {
            form.setError("Passwords don't match", inputFieldId);
            return;
        }

        // evt.data.newPassword = password1;
        // let dataKeys = Object.keys(evt.data);
        // let emptyFields = 0
        // for (let key of dataKeys) {
        //     if (evt.data[key] === '') {
        //         emptyFields += 1;
        //         break;
        //     }
        // }

        if (current.length === 0 || password1.length === 0) {
            // setError('You must fill all the fields');
            // let dataKeys = Object.keys(evt.data);
            // if (dataKeys.length !== 0) {
            //     let inputFieldId: Array<string> = [];

            //     for (let key of dataKeys) {
            //         if (key === 'phoneNumber') {
            //             continue;
            //         }
            //         if (evt.data[key] === '') {
            //             inputFieldId.push(key);
            //             // inputFieldId = key;
            //             // break;
            //         }
            //     }
            //     form.setError('You must fill all the fields', inputFieldId);
            //     return;
            // }
            form.setError('You must fill all the fields', inputFieldId);
            return;
        }

        form.setBusy();

        Api.requestSession<any>('auth', 'change_password', {current: current, new: password1})
            .then((sess) => {
                if (sess) {
                    setChangePasswordState(true);
                }
            })
            .catch(setError);
    }, []);

    return (
        <Form.PageFormDialog title='Change Password' form={form} type='confirm' size='sm' onSubmit={onSubmit} onClose={props.onClose}>
            <ErrorAlert error={error} />
            <Form.InputText
                label='Current Password'
                id='currentPassword'
                type='password'
                autocomplete='current-password'
                validate='not-empty'
                required
                form={form}
                xsMax
            />

            {/* <Box sx={{ p: 1, width: '100%', ml: Env.isMobile ? 0 : 1, mt: 1}}> */}
                <Form.InputText
                    label='Enter New Password'
                    id='newPassword1'
                    type={passwordOne === false ? 'password' : 'text'}
                    autocomplete='new-password'
                    validate='password'
                    required
                    form={form}
                    xsMax
                />

                {/* {passwordOne && (
                    <Button
                        sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={() => {
                            setPasswordOne(false);
                        }}
                    >
                        <VisibilityIcon />
                    </Button>
                )}
                {!passwordOne && (
                    <Button
                        sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={() => {
                            setPasswordOne(true);
                        }}
                    >
                        <VisibilityOffIcon />
                    </Button>
                )} */}
            {/* </Box> */}

            {/* <Box sx={{ p: 1, width: '100%', ml: Env.isMobile ? 0 : 1}}> */}
                <Form.InputText
                    label='Confirm New Password'
                    id='newPassword2'
                    type={passwordSecond === false ? 'password' : 'text'}
                    autocomplete='new-password'
                    validate='password'
                    required
                    form={form}
                    xsMax
                />

                {/* {passwordSecond && (
                    <Button
                        sx={{
                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                            // ml: -10,
                        }}
                        onClick={() => {
                            setPasswordSecond(false);
                        }}
                    >
                        <VisibilityIcon />
                    </Button>
                )}
                {!passwordSecond && (
                    <Button
                        sx={{
                            // ml: -10,

                            '&:hover': {
                                backgroundColor: 'transparent',
                            },
                        }}
                        onClick={() => {
                            setPasswordSecond(true);
                        }}
                    >
                        <VisibilityOffIcon />
                    </Button>
                )} */}
            {/* </Box> */}

            {changePasswordState && (
                <PageDialog type='panel' size='sm' title='Congratulations' onClose={props.onClose}>
                    <Stack alignItems='center'>
                        <Box sx={{display: 'block'}}>
                            <Typography sx={{color: '#FCB131', fontSize: 'large', mt: 1}}>
                                You have successfully changed your password
                            </Typography>
                        </Box>
                    </Stack>
                </PageDialog>
            )}
        </Form.PageFormDialog>
    );
}
