//import * as cdigit from 'cdigit';
import { damm } from 'tslib/cdigit';


const encode32Chars = 'abcdefghijkmnpqrstuvwxyz23456789';

function decodeBase32(word: string) {


    let wordArry = Array.from(word)

    const numberArray: number[] = [];

    let decoded = 0;
    for (let i = 0; i < wordArry.length; i++) {
        for (let j = 0; j < encode32Chars.length; j++) {
            if (wordArry[i] === encode32Chars[j]) {
                numberArray.push(j)

            }

        }
    }
    for (let k = 1; k < numberArray.length; k++) {

        if (k === 1) {
            decoded = 32 * (numberArray[numberArray.length - 1])
        }

        if (numberArray.length - (k + 1) !== 0) {
            decoded = 32 * (decoded + numberArray[numberArray.length - (k + 1)])
        }

        if (k === numberArray.length - 1) {
            decoded = decoded + numberArray[0]
            break;
        }

    }

    return decoded;

}


export function validateCardId(cardId: string) {

    //console.log('cardIdddddddd', cardId)
    let decodedCardId = decodeBase32(cardId)
    //let decodedCardId = decodeBase32('en')
    //console.log('DECODEEEEEED', decodedCardId)
    // console.log(damm.validate(decodedCardId.toString()));

    let a = false
    try{
        a = damm.validate(decodedCardId.toString());
    } catch(e) {
        
    }

    return a;

    // if (a === true) {
    //     return true
    // } else {
    //     return false
    // }


}
