import React from "react";

import {Box} from "@mui/material";

import * as FT from "../FormTypes";
import {FormComputedAtts} from "./FormImpl";

import {processFormSubmit} from "./FormProcessSubmit";
import {FormGrid} from "../FormGrid";
import {FormEvent} from "../../React/ReactUtil";

interface FormImplProps extends FT.FormProps {
    formAtts: FormComputedAtts;
}

export default function FormImplBody(props: FormImplProps) {
    let form = props.form;

    React.useEffect(() => {
        form.onFieldUpdate = props.onFieldUpdate;

        return () => {
            form.onFieldUpdate = undefined;
        };
    }, []);

    const handleSubmit = React.useCallback((evt: FormEvent) => {
        // console.log("FormImplBody.tsx", props)
        evt.preventDefault();
        processFormSubmit(props);
    }, []);

    if (form.formElement === "none" || form.formType === "update-fields" || form.formType === "display") {
        return <FormBodyContents {...props}>{props.children}</FormBodyContents>;
    }

    //const isLayoutGrid = props.layoutElement !== 'none'

    return (
        <Box
            display='block'
            component='form'
            id={props.form.formId}
            onSubmit={handleSubmit}
        >
            <FormBodyContents {...props}>{props.children}</FormBodyContents>
        </Box>
    );
}

function FormBodyContents(props: FormImplProps) {
    if (props.layoutElement === "none") {
        return <>{props.children}</>;
    }

    return (
        // <FormGrid {...props} width={props.formAtts.gridWidth}>
        <FormGrid {...props}>{props.children}</FormGrid>
    );
}
