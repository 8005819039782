import Env from 'env';
import session_ from './session';

export class EndPoint {
    url: string;
    type?: string;

    constructor(_url: string) {
        this.url = _url;
    }
}

export type ApiParams = Record<string, string>;

export type ApiDataTypes = 'json' | 'text' | 'html' | 'yaml';
export type ApiAccess = 'admin' | 'user' | 'public';

export interface RequestOptions {
    group: string;
    command: string;
    params?: ApiParams;

    access?: ApiAccess;

    method?: string;

    body?: any;
    bodyType?: ApiDataTypes;

    resType?: ApiDataTypes; // response type
}

export interface FetchParams {
    limit?: number; // Maximum number of records to retrieve

    page?: number;

    orderBy?: string;
    orderAscending?: boolean;
}

export class FetchStatus {
    code = 0; // status code: 0 - pending, 1 - success, 2 > error code

    data?: string; // status data: depends on the code
    message?: string; // status message: usually error or progress

    count?: number; // Number of recortds returned
    limit?: number; // limit: 0 - no limit
    pages?: number; // total pages: 0 - not paged
    page?: number; // current page
}

export class FetchResponse<T> {
    status?: FetchStatus;
    data?: Array<T>;

    //getStatusCode(): number { return this.status ? this.status.code : 2; }
    getStatus(): FetchStatus {
        return this.status ?? {code: 2};
    }
    getData<DataType>(): DataType {
        return this.data! as unknown as DataType;
    }
}

export class UpdateStatus {
    code = 0;
    message?: string;
}

export type FetchCallback<Data> = (status: FetchStatus, data?: Data) => void;
export type UpdateCallback = (status: UpdateStatus) => void;

export function makeEndPoint(...comps: string[]) {
    return comps.join('/');
}

export function makeImageEndpoint(ep: EndPoint, name: string) {
    return fmtNameEndpoint(ep.url, name);
}

//
// Various Formatting functions
//
export function fmtNameEndpoint(s: string, name: string) {
    return s.replace(new RegExp('{:name}', 'g'), name);
}

function makeError(res?: Response): Error {
    return new Error(res ? res.statusText : 'Invalid response');
}

export function makeApiUrl(group: string, command: string, params?: any) {
    let url = makeEndPoint(Env.apiRoot, group, command);

    if (params) {
        let query = new URLSearchParams(params).toString();

        if (query.length > 0) {
            url += '?' + query;
        }
    }
    return url;
}

function makeApiUrlToken(group: string, command: string, token: string, params?: ApiParams) {
    let url = makeEndPoint(Env.apiRoot, group, command);

    let first = true;
    if (Env.tokenUrl) {
        url += '?token=' + token;
        first = false;
    }

    if (params) {
        let query = new URLSearchParams(params).toString();

        if (query.length > 0) {
            if (first) {
                url += '?';
                first = false;
            } else {
                url += '&';
            }

            url += query;
        }
    }
    return url;
}

function makeApiHeaders() {
    let headers = new Headers();
    return headers;
}
function processFetch<T>(url: string, headers: Headers, body?: any, contentType?: string) {
    // console.log(url);

    let req = new Request(url, {
        method: body ? 'POST' : 'GET',
        headers: headers,
        //body: body
        body: contentType ? body : JSON.stringify(body),
    });
    
    return new Promise<T>((resolve, reject) => {
        fetch(req)
            .then((res) => {
                if (!res.ok) {
                    return reject(makeError(res));
                }

                let contentType = res.headers.get('content-type');
                if (contentType?.split(';')[0].trim() === 'text/html') {
                    res.text()
                        .then((text) => reject(new Error(text)))
                        .catch(reject);
                    return;
                }

                res.json()
                    .then((json) => {
                        if (json.error) {
                            if (json.error.code && +json.error.code === 501) {
                                session_.authenticate();
                            }
                            return reject(new Error(json.error.message ?? 'Unknown Error'));
                        }
                        resolve(json as T);
                    })
                    .catch(reject);
            })
            .catch(reject);
    });
}

export function requestPublic<T>(
    group: string,
    command: string,
    params?: ApiParams,
    body?: any,
    contentType?: string
): Promise<T> {
    let url = makeApiUrl(group, command, params);
    let headers = makeApiHeaders();
    if (contentType) headers.append('Content-Type', contentType);

    return processFetch(url, headers, body, contentType);
}

export function requestSession<T>(
    group: string,
    command: string,
    params?: ApiParams,
    body?: any,
    contentType?: string
): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        if (!session_.isLoggedIn) return reject(new Error('User not logged in'));

        let url = makeApiUrlToken(group, command, session_.token, params);
        let headers = makeApiHeaders();

        if (contentType) {
            headers.append('Content-Type', contentType);
        }

        if (!Env.tokenUrl) {
            headers.append('Authorization', `Bearer ${session_.token}`);
        }

        processFetch<T>(url, headers, body, contentType).then(resolve).catch(reject);
    });
}

export function requestSessionJson<T>(group: string, command: string, params?: ApiParams, body?: any): Promise<T> {
    // if (body)
    //     console.debug('body:', body)

    return requestSession<T>(
        group,
        command,
        params,
        body ? JSON.stringify(body) : undefined,
        body ? 'application/json' : undefined
    );
}

export function requestSessionImage<T>(group: string, command: string, params?: ApiParams, body?: any): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        if (!body) return reject();

        let url = makeApiUrlToken(group, command, session_.token, params);
        //console.log('url', url);
        let headers = makeApiHeaders();
        //processFetch<T>(url, headers, body).then(resolve).catch(reject);

        if (!Env.tokenUrl) {
            headers.append('Authorization', `Bearer ${session_.token}`);
        }

        let req = new Request(url, {
            method: 'POST',
            headers: headers,
            body: body,
        });

        fetch(req)
            .then((res) => {
                //console.log(res);
                if (!res.ok) {
                    return reject(makeError(res));
                }

                res.json()
                    .then((json) => {
                        //console.log('json', json);
                        if (json.error) {
                            //console.debug(json.error);

                            if (json.error.code && +json.error.code === 501) {
                                session_.authenticate();
                            }
                            return reject(new Error(json.error.message ?? 'Unknown Error'));
                            // return reject(json.error.message ?? 'Unknown Error');
                        }
                        resolve(json as T);
                    })
                    .catch(reject);
            })
            .catch(reject);
    });
}

export function requestAdmin<T>(group: string, command: string, params?: ApiParams): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        //if (!session_.isAdmin)
        //    return reject(new Error("User is not administrator"));

        let url = makeApiUrlToken(group, command, session_.token, params);
        let headers = makeApiHeaders();
        processFetch<T>(url, headers).then(resolve).catch(reject);
    });
}

export function requestBlob(ep: string) {
    let url = makeEndPoint(Env.apiRoot, ep);

    //console.log(url);  // TODO: debug

    let headers = new Headers();

    let req = new Request(url, {
        headers: headers,
    });

    // TODO: remove Blob
    return new Promise<Blob>((resolve, reject) => {
        fetch(req)
            .then((res) => {
                if (!res.ok) return reject(makeError(res));

                res.json()
                    .then((json) => resolve(json))
                    .catch(reject);
            })
            .catch(reject);
    });
}
