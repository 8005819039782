import React from 'react';
import {Stack, IconButton} from '@mui/material';

import {SvgIcon} from '@mui/material';

import {keyframes} from '@emotion/react';

import EditIcon from '@mui/icons-material/EditOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';

import {ReactComponent as BusyIcon} from 'images/iconify_loadingflowcw.svg';

import {InputFormField} from './FormFieldContext';
import FormFieldEditDialog from './FormFieldEditDialog';
import {InputFormContext} from '../FormContext';

interface Props {
    field: InputFormField;
    form: InputFormContext;
    children?: React.ReactNode;
}

export default function FormFieldEditContainer(props: Props) {
    const [editActive, setEditActive] = React.useState(false);

    const handleEdit = React.useCallback(() => {
        setEditActive(true);
    }, []);

    const handleEditResponse = React.useCallback((value: string | null) => {
        if (value) {
            let accepted = props.form.processValueEdit(props.field, value); //props.onValue(value)
            if (accepted) {
                setEditActive(false);
            }
        } else {
            setEditActive(false);
        }
    }, []);

    return (
        <>
            <Stack direction='row' alignItems='center' spacing={1}>
                {props.children}

                {props.field.isBusy ? (
                    <SvgIcon
                        component={BusyIcon}
                        inheritViewBox={true}
                        sx={{
                            // width: captionHeight / 2 - 4,
                            // height: captionHeight / 2 - 4,
                            '& path': {
                                fill: theme_.indicatorColor,
                            },
                            animation: `${busyKeyframes} 2s linear infinite`,
                        }}
                    />
                ) : (
                    <IconButton
                        onClick={handleEdit}
                        color='primary'
                        sx={
                            {
                                //width: cst.height,
                                //height: cst.height,
                            }
                        }
                    >
                        {props.field.fieldType === 'time' ? <ScheduleIcon /> : <EditIcon />}
                    </IconButton>
                )}
            </Stack>

            {editActive && <FormFieldEditDialog field={props.field} onResponse={handleEditResponse} />}
        </>
    );
}

const busyKeyframes = keyframes({
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'},
});
