import Env from 'env'
import {FormButtonComponent} from './FormElements/FormButtonComponent'
import * as FT from './FormTypes'
import { SxObject } from '../Mui/SxPropsUtil'

export interface FormButtonProps extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps {
    label: string
    type?: string
    busyLabel?: string
    id?: string
    disabled?: boolean
    onClick?: () => void
    buttonSx?: SxObject;

}

export function FormButton(props: FormButtonProps) {
    return FormButtonComponent(props)
}

export function SubmitButton(props: FormButtonProps) {
    // console.log('propspropspropspropsprops',props)
    let p = {...props}

    if (p.mt === undefined) p.mt = 1

    p.type = 'submit'

    if (!p.size) {
        p.size = Env.isMobile ? 'xl' : 'lg'
    }
    

    return FormButtonComponent(p)
}
