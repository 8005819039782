import React from 'react'

import {styled } from '@mui/material/styles'

import {DialogTitle} from '@mui/material'
import {Typography, Stack, Box} from '@mui/material'

import {IconButton} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import {useTranslation} from 'react-i18next'
import { PageDialogTitle } from '../PageDialog'
import { handleModalClose, ModalCloseProps } from '../Types/ModalCloseParams'

interface DialogTitleProps {
    title?: string | PageDialogTitle
    ttitle?: string
}

interface Props extends DialogTitleProps, ModalCloseProps {
    //onClose: () => void
}

export default function DialogCaption(props: Props) {
    return (
        <DialogTitle>
            <DialogTitleText {...props}/>

            <IconButton
                onClick={() => handleModalClose(props)}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                }}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    )
}


export function DialogTitleText(props: DialogTitleProps) {
    const [t] = useTranslation()

    if (props.title === undefined && props.ttitle === undefined)
        return <></>

    if (props.ttitle)
        return <>{props.ttitle}</>
    
    if (typeof props.title === 'string') {
        return <>{t(props.title)}</>
    }

    let title = props.title!
    // let haveName = title.name || title.tname
    // let haveValue = title.value || title.tvalue
    return <Stack direction='row' spacing={1} alignItems='center'>
        {title.text && <TitleText>{t(title.text)}</TitleText>}
        {title.ttext && <TitleText>{title.ttext}</TitleText>}
        <DialogTitleField {...props}/>

        {/* {title.name && <Typography>{t(title.name)}</Typography>}
        {title.tname && <Typography>{title.tname}</Typography>}
        {haveValue && <Typography>:</Typography>}

        {title.value && <Typography>{t(title.value)}</Typography>}
        {title.tvalue && <Typography>{title.tvalue}</Typography>} */}
    </Stack>

    // return <Typography>aaa</Typography>
}

function DialogTitleField(props: DialogTitleProps) {

    const [t] = useTranslation()

    let title = props.title! as PageDialogTitle
    let haveName = title.name || title.tname
    let haveValue = title.value || title.tvalue

    const suffix = haveValue ? ':' : ''

    const name = haveName ? (title.name ? t(title.name) : title.tname) + suffix : undefined
    const value = haveValue ? (title.value ? t(title.value) : title.tvalue) : undefined

    return <>
        {name && <TitleNameText>{name}</TitleNameText>}
        {value && <TitleValueText>{value}</TitleValueText>}
    </>
}

const TitleText = styled(Box)(({theme}) => ({
    //color: theme.palette.fieldName.main,
    color: theme.palette.text.primary,
    //color: theme.palette.text.secondary,
}))

const TitleNameText = styled(Box)(({theme}) => ({
    //color: theme.palette.fieldName.primary,
    //color: theme.palette.text.primary,
    color: theme.palette.text.secondary,
}))

// const TitleNameText = styled(Typography)<TypographyProps>(({theme}) => ({
//     color: theme.palette.fieldName.main,

// }))

const TitleValueText = styled(Box)(({theme}) => ({
    //color: theme.palette.fieldValue.primary,
    //color: theme.palette.text.secondary,
    color: theme.palette.text.primary,
    //color: theme.palette.info.main,
}))

