import React from 'react';
import {Box, Container, Grid, Typography, Link} from '@mui/material';
import * as FormT from './FormTypes';

import {useTranslation} from 'react-i18next';


interface Props extends FormT.FormPropsBase, FormT.FormInputGridProps, FormT.InputSxProps {
    href: string;
    prefix?: string;
    label: string;
    postfix?: string;

    id?: string;
    size?: 'sm' | 'md' | 'lg';
};

export function NavigateLink(props: Props) {
    const [t] = useTranslation();
    const [gridAtts] = React.useState(FormT.getFormGridAtts(props));
    const [gridSx] = React.useState(FormT.getFormSxParams(props, {height: 20})); //, {mt: 1}));


    const fontSize = props.size === 'sm' ? 'small' : undefined;
    const float = props.align === 'right' ? 'right' : undefined;


    return <Grid item {...gridAtts} sx={gridSx}>
        <Box sx={{display:'flex', float: float,}}>
            {props.prefix && <Typography sx={{fontSize: fontSize}}>{t(props.prefix)}&nbsp;</Typography>}
            <Link href={props.href} sx={{fontSize: fontSize}}>{t(props.label)}</Link>
            {props.postfix && <Typography sx={{fontSize: fontSize}}>&nbsp;{t(props.postfix)}</Typography>}
        </Box>
    </Grid>;
}
