import React from "react";
import * as Api from "api/api"

import PageContents from "components/PageContents";
import * as F from 'tsui/Form';

import session_ from 'api/session';
import { useNavigate } from "react-router-dom";
import SuccessMessageSend from "./SuccessMessage";
import { Box, Stack, Typography } from "@mui/material";
import Env from "env";
import { ErrorAlert } from "components/ErrorAlert";
import { useTranslation } from 'react-i18next';
import HomePageFooter from "../home/components/HomePageFooter";

import ImgElement from 'tsui/DomElements/ImgElement';



export default function ContactUsPage() {

    const [t] = useTranslation();

    const form = F.useInputForm();

    const navigate = useNavigate();

    const [error, setError] = React.useState<Error | string | null>(null);

    const [messageModal, setMessageModal] = React.useState(false)


    const onSubmitContactUs = React.useCallback((evt: F.InputFormEvent) => {
        // console.log('evt.data', evt.data)
        setError('')

        Api.requestSession<any>('contact', 'contact_us', evt.data).then(d => {
            // console.log(evt.data)
            // console.log(d)
            // console.log(`modified count is ${d.modifiedCount}`)
            setMessageModal(true)

        }).catch(err => setError(err));

    }, []);

    const onSubmitSendMessage = React.useCallback((evt: F.InputFormEvent) => {

        Api.requestPublic<any>('contact', 'send_message', evt.data).then(d => {
            // console.log(evt.data)
            // console.log(d)
            // console.log(`modified count is ${d.modifiedCount}`)
            setMessageModal(true)
        }).catch(err => setError(err));

    }, []);


    const onCancelDialog = React.useCallback(() => {
        navigate('/');
    }, []);


    // console.log(session_.isLoggedIn)

    if (Env.isMobile) {
        return <PageContents title="Contact us">
            <ImgElement
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }}
                src='images/background.svg'
            />
            {session_.isLoggedIn ? <>
                <F.PageForm title="contact.contact_us" onSubmit={onSubmitContactUs} form={form} size='sm' formSx={{
                    ...theme_.dialog.sx(), mt: -15

                }}
                >
                    <Stack
                        direction='column'
                        width='100%'
                        spacing={1}

                        sx={
                            {

                                // backgroundColor: 'blue',
                                // backgroundColor: theme_.evocardFeatureBackgroundColor,

                            }
                        }
                    >
                        {/* <ErrorAlert error={error} /> */}


                        {/* <F.InputGroup label='Subject' form={form} /> */}
                        <Stack sx={{ py: theme_.betweenTextFieldSpacing }}>
                            <F.InputText maxLength={100} label='Subject' id='subject' form={form} xsMax />
                        </Stack>
                        {/* <F.InputGroup label='Message' form={form} /> */}
                        <F.InputText maxLength={500} multiline={{ maxRows: 4, minRows: 2 }} type='text' required label='Message' id='content' form={form} xsMax />

                    </Stack>
                    <F.SubmitButton label='Send' form={form} xsMax size='lg' align="center" />


                </F.PageForm>


            </> : <>
                {/* <F.PageForm title={"contact.contact_us"} onSubmit={onSubmitSendMessage} form={form} size='sm' formSx={theme_.dialog.sx()} */}
                <F.PageForm title={"contact.contact_us"} onSubmit={onSubmitSendMessage} form={form} size='sm' formSx={{
                    ...theme_.dialog.sx(), mt: -10,
                }}
                >
                    <Stack
                        direction='column'
                        width='100%'
                        spacing={2.5}

                        sx={
                            {
                                mt: 2
                                // backgroundColor: 'blue',
                                // backgroundColor: theme_.evocardFeatureBackgroundColor,

                            }
                        }
                    >
                        {/* <ErrorAlert error={error} /> */}


                        {/* <F.InputGroup label='Email' form={form} /> */}
                        <Stack>
                            <F.InputText maxLength={100} label='Email' id='senderEmail' autocomplete='username' validate="email" required form={form} xsMax />
                        </Stack>

                        <Stack>
                            {/* <F.InputGroup label='Subject' form={form} /> */}
                            <F.InputText maxLength={100} label='Subject' id='subject' validate="not-empty" required form={form} xsMax />
                        </Stack>

                        <Stack>
                            {/* <F.InputGroup label='Message' form={form} /> */}
                            <F.InputText maxLength={500} multiline={{ maxRows: 4, minRows: 2 }} label='Message' id='content' validate="not-empty" required form={form} xsMax />
                        </Stack>

                        <F.SubmitButton label='Send' form={form} xsMax />
                    </Stack>
                </F.PageForm>
            </>
            }

            {messageModal && <SuccessMessageSend onClose={() => { setMessageModal(false); onCancelDialog() }} />}
            <HomePageFooter fromContactUsPage={true} />

        </PageContents >
    }

    return <PageContents title="Contact us">
            <ImgElement
                sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }}
                src='images/background.svg'
            />
        {session_.isLoggedIn ? <>
            <F.PageForm
                onSubmit={onSubmitContactUs}
                form={form}
                size='lg'
                layoutElement='none'
                formSx={{
                    ...theme_.dialog.sx(), mt: -40
                    // theme_.dialog.sx()
                }}
            >
                <Stack
                    direction='row'
                    width='100%'
                    spacing={1}
                    alignItems='center'

                    sx={
                        {
                            // backgroundColor: 'blue',
                            // backgroundColor: theme_.evocardFeatureBackgroundColor,

                        }
                    }
                >
                    <Box
                        sx={{
                            //backgroundColor: 'red',
                            width: '50%',
                        }}
                    >
                        <Typography sx={{
                            fontSize: 'xx-large',
                            textAlign: 'center',
                            width: '100%',
                            color: 'white'
                        }}>
                            {t("contact.contact_us")}
                        </Typography>

                        <Typography sx={{
                            fontSize: 'large',
                            textAlign: 'center',
                            width: '100%',
                            color: 'white'
                        }}>
                            {t("contect.let_us_help")}
                        </Typography>

                    </Box>
                    <Stack
                        direction='column'
                        spacing={1}
                        sx={{
                            width: '50%',
                            // backgroundColor: 'white',
                            px: 1.5,
                            // backgroundColor: theme_.evocardFeatureBackgroundColor,
                            // borderRadius: theme_.cardBorderRadius,
                            borderLeft: `2px solid ${theme_.themeColor}`,

                        }}
                    >
                        {/* <Typography>{t('Login')} */}
                        <Typography sx={{
                            fontSize: 'xx-large',
                            textAlign: 'center',
                            width: '100%',
                            mb: 2,
                        }}>{t("Contact Us")}

                        </Typography>

                        {/* <ErrorAlert error={error} /> */}

                        {/* <Form.PageForm title='Contact' onSubmit={onSubmitContactUs} form={form} size='md' > */}


                        {/* <F.InputGroup label="Subject" form={form} /> */}
                        <Stack sx={{ py: theme_.betweenTextFieldSpacing }}>

                            <F.InputText maxLength={100} label="Subject" id='subject' form={form} xsMax />
                        </Stack>

                        <F.InputText maxLength={500} multiline={{ maxRows: 4, minRows: 2 }} type='text' required label="Message" id='content' form={form} xsMax />
                        {/* style={resize:'none'} */}

                        <Stack direction='column' alignItems='center' sx={{ pt: 1 }}>
                            <F.SubmitButton label="Send" form={form} xsMax size='lg' align="center" />
                        </Stack>

                    </Stack>
                </Stack>
            </F.PageForm>


        </> : <>
            {/* <F.PageForm title='Contact Us' onSubmit={onSubmitSendMessage} form={form} size='md' > */}
            {/*             
            <Form.InputGroup label='Email' form={form} />
            <Form.InputText label='Email' id='email' form={form} xsMax/> */}
            <F.PageForm
                onSubmit={onSubmitSendMessage}
                form={form}
                size='lg'
                layoutElement='none'
                formSx={{
                    ...theme_.dialog.sx(), mt: -40
                    // theme_.dialog.sx()
                }}
            >
                <Stack
                    direction='row'
                    width='100%'
                    spacing={1}
                    alignItems='center'

                    sx={
                        {
                            //backgroundColor: 'blue',
                        }
                    }
                >
                    <Box
                        sx={{
                            //backgroundColor: 'red',
                            width: '50%',
                        }}
                    >
                        <Typography sx={{
                            fontSize: 'xx-large',
                            textAlign: 'center',
                            width: '100%',
                            color: 'white'
                        }}>
                            {t("contact.contact_us")}
                        </Typography>

                        <Typography sx={{
                            fontSize: 'large',
                            textAlign: 'center',
                            width: '100%',
                            color: 'white'
                        }}>
                            {t("contect.let_us_help")}
                        </Typography>

                    </Box>

                    <Stack
                        direction='column'
                        spacing={1}
                        sx={{
                            width: '50%',
                            // backgroundColor: theme_.evocardFeatureBackgroundColor,
                            // borderRadius: theme_.cardBorderRadius,
                            // p: 1,
                            px: 2,
                            borderLeft: `2px solid ${theme_.themeColor}`,
                        }}
                    >
                        {/* <Typography>{t('Login')} */}
                        <Typography sx={{
                            fontSize: 'xx-large',
                            textAlign: 'center',
                            width: '100%',
                            mb: 2,
                        }}>{t("Contact")}

                        </Typography>

                        {/* <ErrorAlert error={error} /> */}


                        {/* <F.InputGroup label="Email" form={form} /> */}
                        <Stack spacing={2.5}>

                            <Stack >
                                <F.InputText maxLength={100} label="Email" id='senderEmail' autocomplete='username' validate="email" required form={form} xsMax />
                            </Stack>
                            {/* <F.InputGroup label="Subject" form={form} /> */}
                            <Stack>
                                <F.InputText maxLength={100} label="Subject" id='subject' validate="not-empty" required form={form} xsMax />
                            </Stack>

                            {/* <F.InputGroup label="Message" form={form} /> */}
                            <Stack>
                                <F.InputText maxLength={500} multiline={{ maxRows: 4, minRows: 2 }} label="Message" id='content' validate="not-empty" required form={form} xsMax />

                            </Stack>
                        </Stack>
                        <Stack direction='column' alignItems='center' sx={{ pt: 1 }}>

                            <F.SubmitButton label='Send' form={form} xsMax size='lg' align="center" />
                        </Stack>
                    </Stack>
                </Stack>
            </F.PageForm>
        </>
        }

        {messageModal && <SuccessMessageSend onClose={() => { setMessageModal(false); onCancelDialog() }} />}

        <HomePageFooter fromContactUsPage={true} />

    </PageContents >

}

