import { Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeError } from '../tslib/error';

interface Props {
    error: Error | string | undefined | null
}

export function ErrorAlert(props: Props) {
    const [t] = useTranslation();



    if (!props.error) return <></>;


    if (props.error.toString().search('Card not found:') !== -1) {
        let err = props.error.toString().split(':');
        let errText = err[1].replace(/^\s+|\s+$/g, '');

        return <Alert sx={{ width: '75%', ml: 1, backgroundColor: 'transparent' }} severity='error'>
            {t(errText)}
        </Alert>
    }

    if (props.error.toString().search('Error:') !== -1) {

        let err = props.error.toString().split(':');

        return <Alert sx={{ width: '75%', ml: 1, backgroundColor: 'transparent' }} severity='error'>
            {t(err[1])}
        </Alert>
    }


    return (
        <Alert sx={{ width: '75%', ml: 1, backgroundColor: 'transparent' }} severity='error'>
            {makeError(t(props.error as string)).toString()}
        </Alert>
    );
}

// import * as FT from "../tsui/Form/FormTypes";

// export function ProcessFormSubmit(props: FT.FormProps, err: Error | string) {
//     let form = props.form;

//     form.setError(err);

// }
