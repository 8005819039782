import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Box, Stack } from '@mui/material'

import theme_ from 'theme/Theme'
import { AuthButton } from './AuthButton'
import * as AuthApi from 'api/auth'

import session_ from 'api/session'
import * as GD from "data/GlobalDispatch"

import Env from 'env'
import { authListenerId, pubsub_ } from '../data/GlobalDispatch'

export default function AuthHeader() {
    const navigate = useNavigate()

    const [sessionUpdated, setSessionUpdated] = React.useState(false)

    const [openDrawer, setOpenDrawer] = React.useState(false)

    const [isLoggedIn, setLoggedIn] = React.useState(session_.isLoggedIn);


    const th = theme_.homePageHeaderTheme

    React.useEffect(() => {
        const sessionListener = () => {
            //setSessionUpdated(!sessionUpdated);
            // console.log(session_.isLoggedIn)
            setLoggedIn(session_.isLoggedIn);
        }
        pubsub_.addListener(authListenerId, sessionListener);
        return () => { pubsub_.removeListener(authListenerId, sessionListener) };
    }, [])

    const onMenuItem = React.useCallback((key: string) => {
        setOpenDrawer(false)
    }, [])

    const onLogout = React.useCallback(() => {
        if (session_.isLoggedIn) {
            AuthApi.logout().then(() => {
                navigate('/')
            })
        } else {
            navigate('/login')
        }
    }, [])

    let greetingText = ''
    if (session_.greeting !== undefined && session_.greeting.length > 15) {
        greetingText = `${session_.greeting.substring(0, 15)}...`
    } else {
        greetingText = session_.greeting
    }

    if (Env.isMobile) {
        return (
            <>
                {!session_.isLoggedIn && (
                    <Stack direction='row' alignItems='center' justifyContent='center' spacing={1} sx={{
                        mr: 2,
                        '@media screen and (min-width: 90em)': {
                            mr: 27
                        },
                    }}>
                        <AuthButton label={'Login'} href='/login' />
                    </Stack>
                )}
            </>
        )
    }

    return (
        <>
            <Stack direction='row' alignItems='center' justifyContent='center' spacing={1} sx={{
                mr: 2,
                '@media screen and (min-width: 90em)': {
                    mr: 27
                },
            }}>
                {isLoggedIn ? (
                    <>
                        <AuthButton label={greetingText} href='/user' />
                        <Box sx={{ width: 2, height: 20, backgroundColor: theme_.themeColor}} />
                        <AuthButton label={'Logout'} onClick={onLogout} />
                    </>
                ) : (
                    <>
                        <AuthButton label={'Join'} href='/join' />
                        <Box sx={{ width: 2, height: 20, backgroundColor: theme_.themeColor }} />
                        <AuthButton label={'Login'} href='/login' />
                    </>
                )}
            </Stack>

        </>
    )
}
