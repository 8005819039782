import device from "current-device"
import { makeFilePathRoot, makeFilePathRootArr } from "./tslib/filename"
import { verify } from "./tslib/verify"

export default class Env {
    static readonly isMobile = device.mobile()
    static readonly isDesktop = device.desktop()
    static readonly isTablet = device.tablet()

    static readonly isLandscape = device.landscape()
    static readonly isPortrait = device.portrait()

    //
    // Convenience functions
    //
    static getOption(name: string, def: string): string {
        let val = process.env[name]
        return val ?? def
    }

    static requireOption(optName: string): string {
        let val = process.env[optName]
        verify(val, `.env missing: ${optName}`)
        return val!
    }

    static getAppOption(name: string, def: string): string {
        return Env.getOption("REACT_APP_" + name, def)
    }

    static requireAppOption(optName: string): string {
        return Env.requireOption("REACT_APP_" + optName)
    }

    //
    // Assets Locations
    //
    //static readonly publicUrl = process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '/';
    static readonly publicUrl = Env.getOption("PUBLIC_URL", "/")
    static readonly publicUrlImages = makeFilePathRoot(
        Env.publicUrl,
        "images"
    )
    static makePublicImageUrl(...comps: string[]): string {
        return makeFilePathRootArr(Env.publicUrlImages, comps)
    }

    //
    // Server API locations and setup
    //
    static readonly apiRoot = Env.getAppOption("API_ROOT", "api/v1")

    static readonly tokenUrl = false

    static readonly inactiveCardTemplate = 'activate';
    static readonly defaultCardTemplate = 'bw';
    static readonly profileImagePhonePrefix = "profile_image_phone";
    static readonly profileImageWebPrefix = "profile_image_web";
    static readonly profileBlankImage = "https://evocard.me/p/profile-blank-image.svg"

    static getProfileImageLink(cardId: string, imageId: string) {
        let prefix = Env.isMobile ? Env.profileImagePhonePrefix : Env.profileImageWebPrefix;
        return `https://evocard.me/c/${cardId}/${prefix}_${imageId}.jpeg`;
    }

    static getCardTypeName(cardType: string, side: string) {
        let cardTypeName: string = ''
        if(cardType === 'B') {
            cardTypeName = `business_card_${side}`
        }
        if(cardType === 'P') {
            cardTypeName = `personal_card_${side}`
        }
        return `/images/card_types/${cardTypeName}.svg`;
    }

}
