import { Box, Button, Checkbox, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { InputFormContext } from 'tsui/Form/FormContext';
import * as F from 'tsui/Form';
import { styled as muiStyled } from '@mui/system';
import ProgressIndicator from 'tsui/ProgressIndicator';
import { CardDisplayData } from '../../../../data/card_display_data';
import Env from '../../../../env';

import QrCode2Icon from '@mui/icons-material/QrCode2';
import CardQrShowDialog from 'dialogs/CardQrDialog';
import { useTranslation } from 'react-i18next';


const ImageComponent = muiStyled('img')({});


interface DesktopDisplayPanelProps {

    // errArr: string[]
    cardId: string;
    card: CardDisplayData;

    imageUploadingIndicator: string
    showImage: string
    fileNameRef: string
    inputFileRef: string
    chosenLangEn: boolean
    chosenLangAm: boolean
    chosenLangRu: boolean
    onSelectedFileChange: (e: any) => void
    handleChange: (event: any) => void
    onBrowseClick: () => void
    onRemove: () => void
}

export default function DesktopDisplayPanel(props: DesktopDisplayPanelProps) {

    const [t] = useTranslation();

    const [showQr, setShowQr] = React.useState(false)
    // const getFieldValue = React.useCallback((name: string, lang?: string) => { return props.getFieldValue(name, lang) }, []);
    // const form = props.form
    let imageUploadingIndicator = props.imageUploadingIndicator
    let showImage = props.showImage
    let fileNameRef = props.fileNameRef
    let inputFileRef = props.inputFileRef
    let chosenLangEn = props.chosenLangEn
    let chosenLangAm = props.chosenLangAm
    let chosenLangRu = props.chosenLangRu
    let onSelectedFileChange = props.onSelectedFileChange
    let handleLangChange = props.handleChange
    let onBrowseClick = props.onBrowseClick
    let onRemove = props.onRemove

    return (
        <Stack direction='column'>
            {imageUploadingIndicator !== '' && <ProgressIndicator title={imageUploadingIndicator} />}

            {showImage !== '' && <ImageComponent sx={{ width: 200, height: 200, justifySelf: 'center', borderRadius: '50%' }} src={Env.getProfileImageLink(props.cardId, showImage)} />}
            {showImage === '' && imageUploadingIndicator === '' && <ImageComponent sx={{ width: 200, height: 200, justifySelf: 'center', borderRadius: '50%' }} src={fileNameRef ? Env.getProfileImageLink(props.cardId, fileNameRef) : Env.profileBlankImage} />}
            <input
                style={{ display: 'none' }}
                ref={inputFileRef}
                onChange={onSelectedFileChange}
                type='file'
            />
            {/* <Typography>{fileName}</Typography> */}
            {(showImage === '' && fileNameRef === '') &&
                <Button variant='outlined' onClick={onBrowseClick} sx={{ mt: 1 }}>
                    {t('Choose image')}
                </Button>
            }

            {(showImage !== '' || fileNameRef !== '') &&
                <Button variant='outlined' onClick={onRemove} sx={{ mt: 1 }}>
                    {t('Remove image')}
                </Button>
            }


            <Box sx={{ display: 'grid', mt: 3 }}>

                <Box sx={{ display: 'grid' }}>
                    <Typography sx={{ mb: 2 }}>{t('Choose languages')} </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            id="en"
                            name="eng"
                            onChange={handleLangChange}
                            checked={chosenLangEn}
                        />
                        <Typography>ENG</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                        <Checkbox
                            // type="checkbox"
                            id="am"
                            name="հայ"
                            onChange={handleLangChange}
                            checked={chosenLangAm}
                        />

                        <Typography>ՀԱՅ</Typography>

                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                        <Checkbox
                            // type="checkbox"
                            id="ru"
                            name="рус"
                            onChange={handleLangChange}
                            checked={chosenLangRu}
                        />

                        <Typography>РУС</Typography>

                    </Box>

                </Box>
            </Box>

            <Button onClick={() => { setShowQr(true) }} sx={{mt: 5 ,color: theme_.themeColor, border: `2px dashed ${theme_.themeColor}`, "&:hover": { color: 'white', backgroundColor: theme_.themeColor } }}><QrCode2Icon sx={{ fontSize: 100 }} /> </Button>


            {showQr && <CardQrShowDialog cardId={props.cardId} onClose={() => {setShowQr(false)}}/>}
        </Stack>
    )
}

