import React from 'react';

import * as F from 'tsui/Form';

import PageContents from 'components/PageContents';

import * as AuthApi from 'api/auth';

import * as Page from '../components/PageContainer';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import ImgElement from 'tsui/DomElements/ImgElement';
import Env from 'env';
import {InputFormContext} from 'tsui/Form/FormContext';
import {Box, Button, Stack, Typography} from '@mui/material';
import {ErrorAlert} from 'components/ErrorAlert';
import {useTranslation} from 'react-i18next';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function PasswordRecoverPage() {
    const form = F.useForm({type: 'input'});

    const navigate = useNavigate();

    const [passwordOne, setPasswordOne] = React.useState(false);
    const [passwordSecond, setPasswordSecond] = React.useState(false);

    const [error, setError] = React.useState<Error | string | null>(null);

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    const buttonRef = React.useRef<HTMLButtonElement | null | undefined>();

    const search = useLocation().search;
    const urlParams = new URLSearchParams(search);
    const email = urlParams.get('email');

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        // console.log('form.error', form.error);
        // console.log('form', form);

        if (form.error) return;

        form.setBusy();

        let recoverEmailCode = evt.data['recoverEmailCode'];
        let password1 = evt.data['newPassword1'];
        let password2 = evt.data['newPassword2'];
        // console.log('recoverEmailCode', recoverEmailCode);
        // console.log('password1', password1);
        // console.log('password2', password2);

        let dataKeys = Object.keys(evt.data);
        // console.log('dataKeys', dataKeys);
        // console.log('evt.data', evt);

        let emptyFields = 0;
        let inputFieldId: Array<string> = [];

        if (dataKeys.length !== 0) {
            for (let key of dataKeys) {
                console.log('evt.data[key]', evt.data[key]);

                if (evt.data[key] === '') {
                    emptyFields += 1;
                    inputFieldId.push(key);

                    // break;
                }
            }
        }


        if (password1 !== password2) {
            return form.setError('join.passwords_not_matching', ['newPassword1', 'newPassword2']);
                        // form.clearBusy();

            
        }

        evt.data['password'] = password1;
        evt.data['email'] = email!;

        if (emptyFields > 0 || recoverEmailCode.length === 0 || password1.length === 0) {
            // setError('You must fill all the fields');
            form.setError('You must fill all the fields', inputFieldId);
            // form.clearBusy();
        } else {
            AuthApi.passwordRecoverLastStep(evt.data)
                .then((sess) => {
                    if (sess) {
                        navigate('/login');
                    }

                    form.clearBusy();
                })
                .catch((err) => form.setError(err));
        }
    }, []);

    return (
        <PageContents title='Recover Login'>
            <ImgElement
                sx={{position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1}}
                src='images/background.svg'
            />{' '}
            {/* svg/png */}
            {Env.isMobile ? (
                <RecoverPageBodyMobile form={form} onSubmit={onSubmit} error={error} />
            ) : (
                <RecoverPageBody form={form} onSubmit={onSubmit} error={error} />
            )}
            {/* <F.PageForm title='Recover Login' onSubmit={onSubmit} form={form} size='lg' layoutElement='none'
    >

        <F.InputText label='Email' id='email' autocomplete='username' validate="email" required form={form} xsMax />
        {/* <Form.InputText label='Password' id='password' type='password' autocomplete="current-password" required validate='not-empty' form={form} xsMax /> */}
            {/* <Form.InputCheckbox id='rememberMe' label='Remember Me' checked={false} data={false} form={form} xsMax /> */}
            {/* <F.SubmitButton label='send' form={form} xsMax /> */}
            {/* <F.NavigateLink label='Register new account' href='/join' form={form} xsMax mt={2} /> */}
            {/* </F.PageForm>  */}
        </PageContents>
    );
}

    interface RecoverPageBodyProps {
        form: InputFormContext;
        onSubmit: (evt: F.InputFormEvent) => void;
        error: Error | string | null;
    }

    function RecoverPageBody(props: RecoverPageBodyProps) {
        const [t] = useTranslation();

        return (
            <F.PageForm
                onSubmit={props.onSubmit}
                form={props.form}
                size='lg'
                layoutElement='none'
                formSx={theme_.dialog.sx()}
            >
                <Stack direction='row' width='100%' spacing={1} alignItems='center'>
                    <Box
                        sx={{
                            //backgroundColor: 'red',
                            width: '50%',
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 'xx-large',
                                textAlign: 'center',
                                width: '100%',
                                color: 'white',
                            }}
                        >
                            {t('Recover')}
                        </Typography>
                    </Box>
                    <Stack
                        direction='column'
                        spacing={1}
                        sx={{
                            width: '50%',
                            // backgroundColor: theme_.evocardFeatureBackgroundColor,
                            // borderRadius: theme_.cardBorderRadius,
                            // p: 1,
                            borderLeft: `2px solid ${theme_.themeColor}`,
                            px: 1.5,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize: 'xx-large',
                                textAlign: 'center',
                                width: '100%',
                                mb: 2,
                            }}
                        >
                            {t('Recover Login')}
                        </Typography>

                        <ErrorAlert error={props.error} />

                        <F.InputText maxLength={20} label='Recover Code' id='recoverEmailCode' required form={props.form} xsMax />

                        {/* <Box sx={{display: 'flex', alignItems: 'center', py: 1, width: '100%'}}> */}
                        <Stack sx={{py: theme_.betweenTextFieldSpacing}}>

                        <F.InputText
                            label='Enter New Password'
                            id='newPassword1'
                            type='password'
                            autocomplete='new-password'
                            validate='password'
                            required
                            form={props.form}
                            xsMax
                            maxLength={100}
                        />
</Stack>
                        {/* {passwordOne && <Button sx={{
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }} onClick={() => { setPasswordOne(false) }}><VisibilityIcon /></Button>
                        }
                        {!passwordOne && <Button sx={{
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }} onClick={() => { setPasswordOne(true) }}><VisibilityOffIcon /></Button>
                        } */}
                        {/* </Box> */}

                        {/* <Box sx={{display: 'flex', alignItems: 'center', py: 1, width: '100%'}}> */}
                        <Stack sx={{py: theme_.betweenTextFieldSpacing}}>

                        <F.InputText
                            label='Confirm New Password'
                            id='newPassword2'
                            // type={passwordSecond === false ? 'password' : 'text'}
                            type='password'
                            autocomplete='new-password'
                            validate='password'
                            required
                            form={props.form}
                            xsMax
                            maxLength={100}
                        />
                        </Stack>

                        {/* {passwordSecond && <Button sx={{
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }} onClick={() => { setPasswordSecond(false) }}><VisibilityIcon /></Button>
                        }
                        {!passwordSecond && <Button sx={{
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }} onClick={() => { setPasswordSecond(true) }}><VisibilityOffIcon /></Button>
                        } */}
                        {/* </Box> */}

                        <Stack direction='column' alignItems='center'>
                            <F.SubmitButton label='Send' form={props.form} xsMax />
                        </Stack>

                        {/* <F.NavigateLink label='Register new account' href='/join' form={form} xsMax mt={2} /> */}
                    </Stack>
                </Stack>
            </F.PageForm>
        );
    }

    function RecoverPageBodyMobile(props: RecoverPageBodyProps) {
        const [t] = useTranslation();

        return (
            <F.PageForm
                title={t('Recover Login')!}
                onSubmit={props.onSubmit}
                form={props.form}
                size='sm'
                formSx={theme_.dialog.sx()}
            >
                <ErrorAlert error={props.error} />

                <F.InputText label='Recover Code' id='recoverEmailCode' maxLength={20} required form={props.form} xsMax />

                {/* <Box sx={{display: 'flex', alignItems: 'center', p: 1, mt: 1, width: '100%'}}> */}
                <F.InputText
                    label='Enter New Password'
                    id='newPassword1'
                    // type={passwordOne === false ? 'password' : 'text'}
                    type='password'
                    autocomplete='new-password'
                    validate='password'
                    required
                    form={props.form}
                    xsMax
                    maxLength={100}
                />
                {/* {passwordOne && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPasswordOne(false) }}><VisibilityIcon /></Button>
                }
                {!passwordOne && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPasswordOne(true) }}><VisibilityOffIcon /></Button>
                } */}
                {/* </Box> */}

                {/* <Box sx={{display: 'flex', alignItems: 'center', p: 1, width: '100%'}}> */}
                <F.InputText
                    label='Confirm New Password'
                    id='newPassword2'
                    // type={passwordSecond === false ? 'password' : 'text'}
                    type='password'
                    autocomplete='new-password'
                    validate='password'
                    required
                    form={props.form}
                    xsMax
                    maxLength={100}
                />
                {/* {passwordSecond && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPasswordSecond(false) }}><VisibilityIcon /></Button>
                }
                {!passwordSecond && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPasswordSecond(true) }}><VisibilityOffIcon /></Button>
                } */}
                {/* </Box> */}

                <F.SubmitButton label='Send' form={props.form} xsMax />
            </F.PageForm>
        );
    }

    // return <PageContents title='Forgot Login'>

    //     <F.PageForm title='Recover Login' onSubmit={onSubmit} form={form} size='sm'>

    //         <F.InputText label='Recover Email Code' id='recoverEmailCode' required form={form} xsMax />
    //         <F.InputText label='Enter New Password' id='newPassword1' autocomplete='new-password' validate='password' required form={form} xsMax />
    //         <F.InputText label='Confirm New Password' id='newPassword2' autocomplete='new-password' validate='password' required form={form} xsMax />
    //         {/* <Form.InputText label='Password' id='password' type='password' autocomplete="current-password" required validate='not-empty' form={form} xsMax /> */}

    //         {/* <Form.InputCheckbox id='rememberMe' label='Remember Me' checked={false} data={false} form={form} xsMax /> */}

    //         <F.SubmitButton label='send' form={form} xsMax />

    //         <F.NavigateLink label='Register new account' href='/join' form={form} xsMax mt={2} />
    //     </F.PageForm>

    // </PageContents>;
