import React from "react"

import * as Api from "api/api"

import * as Form from "tsui/Form"
import { Box, Link, Typography } from "@mui/material"
import { styled as muiStyled } from "@mui/system"

import { Area, Point } from "react-easy-crop"
import Cropper from "react-easy-crop"
import PageDialog from "tsui/PageDialog"
import Env from "env"

interface Props {
    cardId: string
    imageUrl: string
    onClose: () => void
    onCropComplete: (croppedAreaPixels: Area) => void
}

export default function ImageCutter(props: Props) {
    const [crop, setCrop] = React.useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = React.useState(1)
    const [cropWindow, setCropWindow] = React.useState(false)
    const [croppedArea, setCroppedArea] = React.useState(null)
    const croppedAreaRef = React.useRef()

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
        setCroppedArea(croppedAreaPixels)
        croppedAreaRef.current = croppedAreaPixels
    }

    // console.log('asdadasdascroppedArea', JSON.stringify(croppedArea))

    
    // console.log("croppedArea", croppedArea)

    const form = Form.useInputForm()
    // const [orderId, setOrderId] = React.useState(1000)

    const onSubmit = React.useCallback(() => {
        // console.log('croppedAreaaaaaaaaa', croppedAreaRef)
        // Api.requestSessionJson('admin', 'cropped_image_file_upload', {cardId: props.cardId}, croppedAreaRef.current).then(d => {

        //     // console.log('JSON', JSON.stringify(cardData))
        //     console.log(`Cropped image was added`)
        //     props.onClose();
        // }).catch(err => console.log(err));
        props.onCropComplete(croppedAreaRef.current!)

        props.onClose()
    }, [])

    return (
        <PageDialog
            type="cancel-confirm"
            title="CROP IMAGE"
            form={form}
            size={Env.isMobile ? 'sm' : 'xl'}
            onConfirm={onSubmit}
            onClose={props.onClose}
        >
            <Cropper
                // image={URL.createObjectURL(filesRef.current[0])}
                image={props.imageUrl}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                cropShape='round'
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                style={{
                    containerStyle: {
                        position: "sticky",
                        width: "100%",
                        // height: "800px",
                        height: Env.isMobile ? '300px': '700px',
                        overflow: "hidden",
                        border: "1px solid green",
                    },
                    // mediaStyle: { height: "50%", width: '100%', display: "block" },
                    // cropAreaStyle: { left: 10 }
                }}
            />
        </PageDialog>
    )
}
