import React from 'react';

import {Box, Button, Checkbox, Dialog, Link, Paper, Stack, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';
import * as Api from 'api';
import PageDialog from 'tsui/PageDialog';
import {ShoppingCartData} from 'data/shopping_cart_data';
import Env from 'env';
import * as F from 'tsui/Form';
import {useLocation, useNavigate} from 'react-router-dom';
import session_ from 'api/session';
import ProgressIndicator from '../tsui/ProgressIndicator';
import {ErrorAlert} from './ErrorAlert';
import {BuyCardsDialog} from './BuyCardsDialog';
import {styled} from '@mui/system';

const IframeElement = styled('iframe')({});

interface Props {
    onClose: () => void;
    onSelected: (key: boolean) => void;
}

export function ShippingInfo(props: Props) {
    const navigate = useNavigate();

    const [t] = useTranslation();
    const mounted = React.useRef(false);
    const [dataRequested, setDataRequested] = React.useState(false);
    const [error, setError] = React.useState<Error | string | null>(null);
    const [open, setOpen] = React.useState(false);
    const [data, setData] = React.useState<ShoppingCartData[] | null>(null);

    const [orderChangeDialog, setOrderChangeDialog] = React.useState(false);

    const [submited, setSubmited] = React.useState(false);
    const [shpAddress, setShpAddress] = React.useState<string | null>(null);
    const [clientFullName, setClientFullName] = React.useState<string | null>(null);
    const [clientPhoneNum, setClientPhoneNum] = React.useState<string | null>(null);

    const [choseShip, setChoseShip] = React.useState(false);
    const choseShipRef = React.useRef(false);

    const [choseUnShip, setChoseUnShip] = React.useState(false);
    const choseUnShipRef = React.useRef(false);

    const [cardsTotalAmount, setCardsTotalAmount] = React.useState<string | null>(null);
    const cardsTotalAmountRef = React.useRef<string | null>(null);

    const [cardsTotalQuantity, setCardsTotalQuantity] = React.useState<string | null>(null);
    const cardsTotalQuantityRef = React.useRef<string | null>(null);

    const [cardsCurrency, setCardsCurrency] = React.useState<string | null>(null);
    const cardsCurrencyRef = React.useRef<string | null>(null);

    const [cardsTypes, setCardsTypes] = React.useState<string | null>(null);
    const cardsTypesRef = React.useRef<string | null>(null);

    const [cardsCurrencyCode, setCardsCurrencyCode] = React.useState<string | null>(null);
    const cardsCurrencyCodeRef = React.useRef<string | null>(null);

    // const [paymentPageUrl, setPaymentPageUrl] = React.useState<string | null>(null);
    // const paymentPageUrlRef = React.useRef<string | null>(null)

    const [storedPaymentPageUrl, setStoredPaymentPageUrl] = React.useState<string | null>(null);
    const storedPaymentPageUrlRef = React.useRef<string | null>(null);

    const [progIndic, setProgIndic] = React.useState(false);

    const form = F.useForm({type: 'input'});

    let pageOrigin = window.location.origin;

    const onClose = React.useCallback((event, reason) => {
        if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
        props.onClose();
    }, []);

    const onCloseDialog = React.useCallback(() => {
        props.onClose();
    }, []);

    const onChangeOrder = React.useCallback(() => {
        Api.requestSessionJson<any>('order', 'change', {})
            .then((d) => {
                // props.onClose()
                props.onSelected(false);
            })
            .catch(setError);
    }, []);

    console.log(data);

    React.useEffect(() => {
        setProgIndic(true);

        mounted.current = true;
        if (!dataRequested) {
            let lang: string | undefined;
            if (session_.userLang === '' || session_.userLang === undefined) {
                lang = undefined;
            } else {
                lang = session_.userLang;
            }
            Api.requestSessionJson<any>('cart', 'fetch', {language: lang ?? 'en'})
                .then((d) => {
                    if (mounted.current) {
                        console.log('dddddddddddd', d);

                        setCardsTotalAmount(d.totalAmount);
                        cardsTotalAmountRef.current = d.totalAmount;

                        setCardsTotalQuantity(d.totalQuantity);
                        cardsTotalQuantityRef.current = d.totalQuantity;

                        setCardsCurrency(d.currency);
                        cardsCurrencyRef.current = d.currency;

                        setCardsTypes(d.cardType);
                        cardsTypesRef.current = d.cardType;
                        // console.log('d.currencyCode', d.currencyCode)

                        setCardsCurrencyCode(d.currencyCode);
                        cardsCurrencyCodeRef.current = d.currencyCode;

                        // console.log('d.paymentFormUrl', d.paymentFormUrl)
                        // console.log(d.ameriaFormUrl)
                        // console.log(d.ameriaFormUrl)
                        if (d.ameriaFormUrl !== undefined) {
                            setStoredPaymentPageUrl(d.ameriaFormUrl);
                            storedPaymentPageUrlRef.current = d.ameriaFormUrl;
                        }

                        let shCartData: ShoppingCartData[] = [];
                        if (d.shoppingCartData) {
                            for (let s of d.shoppingCartData) {
                                shCartData.push(new ShoppingCartData(s));
                            }
                            setData(shCartData);
                        } else {
                            setData(null);
                        }
                    }

                    setProgIndic(false);
                })
                .catch(setError);

            setDataRequested(true);

            // Api.requestSessionJson<any>('payment', 'fetch', {}).then(d => {
            //     if (mounted.current) {

            //     }
            // }).catch(setError);
            return;
        }
        return () => {
            mounted.current = false;
        };
    }, [dataRequested]);

    const handleLangChange = React.useCallback((event) => {
        setError(null);
        if (event.target.id === 'ship') {
            setChoseShip(event.target.checked);
            setChoseUnShip(false);
            choseUnShipRef.current = false;
            choseShipRef.current = event.target.checked;
        }

        if (event.target.id === 'unShip') {
            if (event.target.checked === true) {
                setError(null);
            }
            setChoseUnShip(event.target.checked);
            setChoseShip(false);
            choseShipRef.current = false;
            choseUnShipRef.current = event.target.checked;
        }
    }, []);

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        // let dataKeys = Object.keys(evt.data);
        // console.log('dataKeys', dataKeys.length)
        // if (dataKeys.length === 0) {
        //     props.onClose();
        //     return;
        // }

        if (choseShipRef.current === false && choseUnShipRef.current === false) {
            setError('Choose one of the card getting variants');
            // props.onClose();
            return;
        }

        if (choseShipRef.current === true) {
            let inputFieldId: Array<string> = [];
            let dataKeys = Object.keys(evt.data);
            for (let key of dataKeys) {
                if (evt.data[key] === '') {
                    inputFieldId.push(key);
                    // inputFieldId = key;
                    // break;
                }
            }

            if (evt.data.nameSurname === undefined || evt.data.nameSurname === '') {
                // setError('Input name and surname')
                form.setError('Input name and surname', inputFieldId);
                return;
            }

            if (evt.data.shipAddress === undefined || evt.data.shipAddress === '') {
                // setError('Input shipping address')
                form.setError('Input shipping address', inputFieldId);
                return;
            }
            if (evt.data.phoneNumber === undefined || evt.data.phoneNumber === '') {
                // setError('Input phone number')
                form.setError('Input phone number', inputFieldId);
                return;
            } else {
                setShpAddress(evt.data.shipAddress);
                setClientFullName(evt.data.nameSurname);
                setClientPhoneNum(evt.data.phoneNumber);
            }
        }
        setError(null);
        setSubmited(true);

        // NEW CODE

        //let pageView = Env.isMobile ? "MOBILE" : "DESKTOP";
        let pageView = 'DESKTOP';
        // let returnUrl = `https://evocard.am/paymentCallback`;

        let returnUrl = `${pageOrigin}/paymentCallback?userId=${session_.userId}`;
        // let returnUrl = `http://37.157.218.55:3001/paymentCallback`
        let description = 'EvoCard Payment';
        setProgIndic(true);

        Api.requestSession<any>('payments', 'register', {
            currencyCode: cardsCurrencyCodeRef.current!,
            totalAmount: cardsTotalAmountRef.current!,
            currency: cardsCurrencyRef.current!,
            quantity: cardsTotalQuantityRef.current!,
            cardType: cardsTypesRef.current!,
            language: session_.userLang ?? 'en',
            pageView: pageView,
            returnUrl: returnUrl,
            description: description,
            shippingStatus: choseShip.toString(),
            shippingAddress: shpAddress !== null ? shpAddress : 'empty',
            clientFullName: clientFullName !== null ? clientFullName : 'empty',
            clientPhoneNum: clientPhoneNum !== null ? clientPhoneNum : 'empty',
        })
            .then((d) => {
                if (d.errMsg) {
                    alert(`${d.errMsg.errorMessage} - errorCode: ${d.errMsg.errorCode}`);
                    setProgIndic(false);
                }

                if (d.paymentPageUrl) {
                    // console.log(d.paymentPageUrl)

                    setDataRequested(false);
                    // console.log(d.paymentPageUrl)
                    setStoredPaymentPageUrl(d.paymentPageUrl);
                    storedPaymentPageUrlRef.current = d.paymentPageUrl;
                    // setTimeout(() => {
                    //     window.open(d.paymentPageUrl)
                    // }, 100);
                    setProgIndic(false);
                    // props.onSelected(false)
                }
            })
            .catch((err) => alert(err));

        //
    }, []);

    if (progIndic === true) {
        return (
            <Dialog onClose={onClose} open={progIndic} PaperProps={{sx: {width: 200, height: 200}}}>
                <ProgressIndicator />
            </Dialog>
        );
    }

    if (storedPaymentPageUrl) {
        return (
            <PageDialog type='panel' title='Pay' form={form} size='sm' onClose={props.onClose}>
                <Stack alignItems='center' width='100%' sx={{p: 1}}>
                    <Link
                        href={storedPaymentPageUrlRef.current as string}
                        target='_blank'
                        sx={{
                            fontSize: 'large',
                            textDecoration: 'none',
                            background: theme_.buttonColor,
                            color: '#f0f0f0',
                            //color: 'black',
                            //fontWeight: 'bold',
                            borderRadius: 30,
                            p: 2.5,
                            mt: 0.5,
                            //fontSize: 'larger',
                            //fontWeight: 'bold',
                            fontWeight: '900',
                            textShadow: `
                         0 -1px 0 #fff, 
                         0 1px 0 #4e4e4e, 
                         0 2px 0 #4c4c4c, 
                         // 0 3px 0 #4a4a4a, 
                         0 4px 20px #484848
                         `,

                            '&:hover': {
                                background: theme_.buttonHoverColorGradient,
                                borderStyle: 'style',
                                borderWidth: 1,
                                borderColor: theme_.pageDarkModeTextColor,
                            },
                        }}
                    >
                        {t('Payment Page')}
                    </Link>
                    {/* <Button variant='outlined' onClick={onChangeOrder} sx={{mt: 3}}>Change Order</Button> */}
                    <Button
                        variant='outlined'
                        onClick={() => {
                            setOrderChangeDialog(true);
                        }}
                        sx={{mt: 5}}
                    >
                        {t('Change Order')}
                    </Button>
                </Stack>
                {orderChangeDialog && (
                    <F.PageFormDialog
                        title='Change Order'
                        submitLabel='Submit'
                        form={form}
                        size='sm'
                        onSubmit={onChangeOrder}
                        onClose={props.onClose}
                    >
                        <Typography sx={{p: 2}}>{t('Do you want to change your order?')}</Typography>
                    </F.PageFormDialog>
                )}
            </PageDialog>
        );
    }

    if (!cardsTotalAmount && !cardsTotalQuantity) return <></>;
    if (!data) return <></>;

    if (Env.isMobile) {
        return (
            <F.PageFormDialog
                title='Shopping Details'
                submitLabel='Submit'
                form={form}
                type='confirm'
                size='sm'
                onSubmit={onSubmit}
                onClose={props.onClose}
            >
                <ErrorAlert error={error} />

                <Stack alignItems='center' width='100%'>
                    <Box sx={{display: 'block', width: '100%', justifyContent: 'center', mb: 2}}>
                        {/* <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 3 }}>
                        <Box sx={{ display: 'flex', textAlign: 'center' }}>
                            <Box>
                                <Typography sx={{ fontSize: 'large' }}>{t('Card')}</Typography>
                                <Typography sx={{ fontSize: 'large' }}>{cardsTotalQuantity}{' '}{t('count')}{' '}-{' '}{cardsTotalAmount}{' '}{cardsCurrency}</Typography>
                            </Box>
                            {choseShip && <Box sx={{ ml: 2 }}>
                                <Typography sx={{ fontSize: 'large' }}>{t('Shipping Price')}</Typography>
                                <Typography sx={{ fontSize: 'large' }}>500 AMD</Typography>
                            </Box>
                            }
                        </Box>
                    </Box> */}

                        <Box sx={{width: '100%', justifyContent: 'start', mt: 3, ml: 0}}>
                            <Box sx={{display: 'flex', width: '80%', justifyContent: 'space-between', mt: 3, ml: 1}}>
                                <Typography sx={{display: 'block', fontSize: 'large'}}>{t('Card')}</Typography>
                                <Typography sx={{display: 'block', fontSize: 'large'}}>{t('Price')}</Typography>
                            </Box>
                            <Box sx={{width: '100%', height: 2, backgroundColor: '#F3F3F3'}}></Box>
                            <Box sx={{display: 'flex', width: '90%', justifyContent: 'space-between', mt: 3, ml: 1}}>
                                <Typography sx={{display: 'block', fontSize: 'large'}}>
                                    {cardsTotalQuantity} {t('count')}
                                </Typography>
                                <Typography sx={{display: 'block', fontSize: 'large'}}>
                                    {cardsTotalAmount} {cardsCurrency}
                                </Typography>
                            </Box>
                            {/* {choseShip && <Box sx={{ display: 'flex', width: '90%', justifyContent: 'space-between', mt: 1, ml: 1 }}>
                            <Typography sx={{ fontSize: 'large' }}>{t('Shipping Price')}</Typography>
                            <Typography sx={{ fontSize: 'large' }}>500 AMD</Typography>
                        </Box>
                        } */}
                        </Box>

                        <Box sx={{display: 'block', mt: 5}}>
                            <Box sx={{display: 'block'}}>
                                <Typography sx={{mb: 2}}>{t('CHOOSE CARD(S) SHIPPING METHOD')} </Typography>
                                <Box sx={{display: 'flex', alignItems: 'start'}}>
                                    <Checkbox
                                        id='ship'
                                        name='ship'
                                        onChange={handleLangChange}
                                        checked={choseShip}
                                        color='warning'
                                        sx={{color: 'white', mt: -1.2}}
                                    />
                                    <Typography sx={{fontSize: 'medium', width: 160}}>{t('WITH SHIPPING')}</Typography>
                                    <Box sx={{display: 'flex', alignItems: 'start'}}>
                                        <Checkbox
                                            // type="checkbox"
                                            id='unShip'
                                            name='unShip'
                                            onChange={handleLangChange}
                                            checked={choseUnShip}
                                            sx={{color: 'white', mt: -1.2}}
                                            color='warning'
                                        />

                                        <Typography sx={{fontSize: 'medium'}}>{t('LOCAL PICKUP')}</Typography>
                                    </Box>
                                </Box>
                                {choseShip && (
                                    <Box sx={{mt: 2}}>
                                        <Box sx={{mt: theme_.betweenTextFieldSpacing}}>
                                            <F.InputText
                                                maxLength={100}
                                                label='shipping.fullName'
                                                id='nameSurname'
                                                autocomplete='name'
                                                form={form}
                                                xsMax
                                            />
                                        </Box>
                                        <Box sx={{mt: theme_.betweenTextFieldSpacing}}>
                                            <F.InputText
                                                maxLength={100}
                                                label='Shipping Address'
                                                id='shipAddress'
                                                autocomplete='street-address'
                                                form={form}
                                                xsMax
                                            />
                                        </Box>
                                        <Box sx={{mt: theme_.betweenTextFieldSpacing}}>
                                            <F.InputText
                                                maxLength={100}
                                                label='shipping.phoneNumber'
                                                id='phoneNumber'
                                                autocomplete='tel'
                                                form={form}
                                                xsMax
                                            />
                                        </Box>

                                        <Typography sx={{mt: 2, fontSize: 'medium'}}>{t('IMPORTANT')}</Typography>
                                        <Typography sx={{fontSize: 'small'}}>
                                            {t('The delivery of the card is made by mail within 2-3 working days')}
                                        </Typography>
                                    </Box>
                                )}

                                {/* {choseUnShip && <Box sx={{ mt: 2 }}> */}
                                <Box sx={{mt: 2}}>
                                    {choseUnShip && (
                                        <Box sx={{mt: 1}}>
                                            <Typography sx={{my: 1, fontSize: 'medium'}}>
                                                {t('order.followup')}
                                            </Typography>
                                        </Box>
                                    )}
                                    <Box sx={{width: '100%', display: 'grid', alignItems: 'center', mt: 1}}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Typography sx={{fontSize: 'small', color: 'white', fontWeight: 'bold'}}>
                                                {t('Opening Hours')}
                                                {':'}
                                            </Typography>
                                            <Typography sx={{fontSize: 'small', color: 'white', ml: 1.5}}>
                                                10:00 - 20:00
                                            </Typography>
                                        </Box>

                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Typography sx={{color: 'white', fontSize: 'small', fontWeight: 'bold'}}>
                                                {t('Email')}
                                                {':'}
                                            </Typography>
                                            <Typography sx={{fontSize: 'small', color: 'white', ml: 1.5}}>
                                                evocard.contact@gmail.com
                                            </Typography>
                                        </Box>

                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Typography sx={{color: 'white', fontSize: 'small', fontWeight: 'bold'}}>
                                                {t('Phone')}
                                                {':'}
                                            </Typography>
                                            <Typography sx={{fontSize: 'small', color: 'white', ml: 1.5}}>
                                                +374 99 88 50 44
                                            </Typography>
                                        </Box>

                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                            <Typography sx={{color: 'white', fontSize: 'small', fontWeight: 'bold'}}>
                                                {t('Location')}
                                                {':'}
                                            </Typography>
                                            <Typography sx={{fontSize: 'small', color: 'white', ml: 1.5}}>
                                                {t('contact.address_name')}{' '}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    {/* <IframeElement
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.1648474473104!2d44.49571821568435!3d40.20539587638864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd4409c33837%3A0xde22a5eef53f2d96!2zMTUgQXJhbSBLaGFjaGF0cmlhbiBTdCwgWWVyZXZhbiAwMDEyLCDQkNGA0LzQtdC90LjRjw!5e0!3m2!1sru!2s!4v1668174105643!5m2!1sru!2s"

                                loading="lazy"
                                sx={{
                                    filter: 'invert(90%)',
                                    width: Env.isMobile ? 450 : 550,
                                    height: Env.isMobile ? 200 : 300
                                }}
                                frameBorder="0"
                            /> */}
                                </Box>
                                {/* } */}
                            </Box>
                        </Box>
                    </Box>
                </Stack>

                {/* {submited && <BuyCardsDialog onSelected={onCloseDialog} shippingAddress={shpAddress} clientFullName={clientFullName} clientPhoneNum={clientPhoneNum} shippingStatus={choseShip} onClose={() => { setSubmited(false) }} />} */}
            </F.PageFormDialog>
        );
    }

    return (
        <F.PageFormDialog
            title='Shopping Details'
            submitLabel='Submit'
            form={form}
            type='confirm'
            size='sm'
            onSubmit={onSubmit}
            onClose={props.onClose}
        >
            <ErrorAlert error={error} />
            <Stack alignItems='center' width='100%'>
                <Box sx={{display: 'block', width: '100%', justifyContent: 'center', mb: 2}}>
                    {/* <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center', mt: 3 }}>
                    <Box sx={{ display: 'flex', textAlign: 'center' }}>
                        <Box>
                            <Typography sx={{ fontSize: 'x-large' }}>{t('Card')}</Typography>
                            <Typography sx={{ fontSize: 'x-large' }}>{cardsTotalQuantity}{' '}{t('count')}{' '}-{' '}{cardsTotalAmount}{' '}{cardsCurrency}</Typography>
                        </Box>
                        {choseShip && <Box sx={{ ml: 2 }}>
                            <Typography sx={{ fontSize: 'x-large' }}>{t('Shipping Price')}</Typography>
                            <Typography sx={{ fontSize: 'x-large' }}>500 AMD</Typography>
                        </Box>
                        }
                    </Box>
                </Box> */}

                    <Box sx={{width: '90%', justifyContent: 'start', mt: 3, ml: 2}}>
                        <Box sx={{display: 'flex', width: '80%', justifyContent: 'space-between', mt: 3, ml: 1}}>
                            <Typography sx={{display: 'block', fontSize: 'x-large'}}>{t('Card')}</Typography>
                            <Typography sx={{display: 'block', fontSize: 'x-large'}}>{t('Price')}</Typography>
                        </Box>
                        <Box sx={{width: '100%', height: 2, backgroundColor: '#F3F3F3'}}></Box>
                        <Box sx={{display: 'flex', width: '90%', justifyContent: 'space-between', mt: 3, ml: 1}}>
                            <Typography sx={{display: 'block', fontSize: 'x-large'}}>
                                {cardsTotalQuantity} {t('count')}
                            </Typography>
                            <Typography sx={{display: 'block', fontSize: 'x-large'}}>
                                {cardsTotalAmount} {cardsCurrency}
                            </Typography>
                        </Box>
                        {/* {choseShip && <Box sx={{ display: 'flex', width: '90%', justifyContent: 'space-between', mt: 1, ml: 1 }}>
                        <Typography sx={{ fontSize: 'x-large' }}>{t('Shipping Price')}</Typography>
                        <Typography sx={{ fontSize: 'x-large' }}>500 AMD</Typography>
                    </Box>
                    } */}
                    </Box>

                    <Box sx={{display: 'grid', mt: 10}}>
                        <Box sx={{display: 'grid', ml: 2}}>
                            <Typography sx={{mb: 2}}>{t('CHOOSE CARD(S) SHIPPING METHOD')} </Typography>
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Checkbox
                                    id='ship'
                                    name='ship'
                                    onChange={handleLangChange}
                                    checked={choseShip}
                                    color='warning'
                                />
                                <Typography>{t('WITH SHIPPING')}</Typography>
                                <Box sx={{display: 'flex', alignItems: 'center', ml: 8}}>
                                    <Checkbox
                                        // type="checkbox"
                                        id='unShip'
                                        name='unShip'
                                        onChange={handleLangChange}
                                        checked={choseUnShip}
                                        sx={{color: 'white'}}
                                        color='warning'
                                    />

                                    <Typography>{t('LOCAL PICKUP')}</Typography>
                                </Box>
                            </Box>
                            {choseShip && (
                                <Box sx={{mt: 1}}>
                                    <Box sx={{mt: theme_.betweenTextFieldSpacing}}>
                                        <F.InputText
                                            maxLength={100}
                                            label='shipping.fullName'
                                            id='nameSurname'
                                            autocomplete='name'
                                            form={form}
                                            xsMax
                                        />
                                    </Box>
                                    <Box sx={{mt: theme_.betweenTextFieldSpacing}}>
                                        <F.InputText
                                            maxLength={100}
                                            label='Shipping Address'
                                            id='shipAddress'
                                            autocomplete='street-address'
                                            form={form}
                                            xsMax
                                        />
                                    </Box>
                                    <Box sx={{mt: theme_.betweenTextFieldSpacing}}>
                                        <F.InputText
                                            maxLength={100}
                                            label='shipping.phoneNumber'
                                            id='phoneNumber'
                                            autocomplete='tel'
                                            form={form}
                                            xsMax
                                        />
                                    </Box>

                                    <Typography sx={{mt: 2, fontSize: 'medium'}}>{t('IMPORTANT')}</Typography>
                                    <Typography sx={{fontSize: 'small'}}>
                                        {t('The delivery of the card is made by mail within 2-3 working days')}
                                    </Typography>
                                </Box>
                            )}

                            {/* {choseUnShip && <Box sx={{ mt: 1 }}> */}
                            <Box sx={{mt: 1}}>
                                {choseUnShip && (
                                    <Box sx={{mt: 1}}>
                                        <Typography sx={{my: 1, fontSize: 'large'}}>{t('order.followup')}</Typography>
                                    </Box>
                                )}
                                <Box sx={{width: '100%', display: 'grid', alignItems: 'center', mt: 4}}>
                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography sx={{fontSize: 'medium', color: 'white', fontWeight: 'bold'}}>
                                            {t('Opening Hours')}
                                            {':'}
                                        </Typography>
                                        <Typography sx={{fontSize: 'medium', color: 'white', ml: 1.5}}>
                                            10:00 - 20:00
                                        </Typography>
                                    </Box>

                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography sx={{color: 'white', fontSize: 'medium', fontWeight: 'bold'}}>
                                            {t('Email')}
                                            {':'}
                                        </Typography>
                                        <Typography sx={{fontSize: 'medium', color: 'white', ml: 1.5}}>
                                            evocard.contact@gmail.com
                                        </Typography>
                                    </Box>

                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography sx={{color: 'white', fontSize: 'medium', fontWeight: 'bold'}}>
                                            {t('Phone')}
                                            {':'}
                                        </Typography>
                                        <Typography sx={{fontSize: 'medium', color: 'white', ml: 1.5}}>
                                            +374 99 88 50 44
                                        </Typography>
                                    </Box>

                                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <Typography sx={{color: 'white', fontSize: 'medium', fontWeight: 'bold'}}>
                                            {t('Location')}
                                            {':'}
                                        </Typography>
                                        <Typography sx={{fontSize: 'medium', color: 'white', ml: 1.5}}>
                                            {t('contact.address_name')}{' '}
                                        </Typography>
                                    </Box>
                                </Box>
                                {/* <IframeElement
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.1648474473104!2d44.49571821568435!3d40.20539587638864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd4409c33837%3A0xde22a5eef53f2d96!2zMTUgQXJhbSBLaGFjaGF0cmlhbiBTdCwgWWVyZXZhbiAwMDEyLCDQkNGA0LzQtdC90LjRjw!5e0!3m2!1sru!2s!4v1668174105643!5m2!1sru!2s"

                                loading="lazy"
                                sx={{
                                    filter: 'invert(90%)',
                                    width: Env.isMobile ? 450 : 550,
                                    height: Env.isMobile ? 200 : 300
                                }}
                                frameBorder="0"
                            /> */}
                            </Box>
                            {/* } */}
                        </Box>
                    </Box>
                </Box>
            </Stack>

            {/* {submited && <BuyCardsDialog onSelected={onCloseDialog} shippingAddress={shpAddress} clientFullName={clientFullName} clientPhoneNum={clientPhoneNum} shippingStatus={choseShip} onClose={() => { setSubmited(false) }} />} */}
        </F.PageFormDialog>
    );
}
