import React, {Fragment} from 'react';

import {
    Alert,
    Box,
    Button,
    Dialog,
    Link,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {makeError} from '../tslib/error';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import * as Api from 'api';
import PageDialog from 'tsui/PageDialog';
import ImgElement from 'tsui/DomElements/ImgElement';
import {ApiShoppingCart} from 'api';
import {ShoppingCartData} from 'data/shopping_cart_data';
import Env from 'env';
import AddIcon from '@mui/icons-material/Add';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import * as F from 'tsui/Form';
import {BuyCardsDialog} from './BuyCardsDialog';
import {ErrorAlert} from './ErrorAlert';
import * as GD from 'data/GlobalDispatch';
import session_ from 'api/session';
import ProgressIndicator from '../tsui/ProgressIndicator';
import PageContents from './PageContents';
import {ShippingInfo} from './ShippingInfo';

interface Props {
    onClose: () => void;
    addCardButtonStatus?: boolean;
    cardTypeId?: string;
}

let oneTimeFunc: number = 0;

export function ShoppingCartDialog(props: Props) {
    const [t] = useTranslation();
    const mounted = React.useRef(false);
    const [dataRequested, setDataRequested] = React.useState(false);

    const [userPaymentId, setUserPaymentId] = React.useState<string | null>(null);
    const userPaymentIdRef = React.useRef<string | null>(null);
    const webPageOrderIdRef = React.useRef<string | null>(null);

    const [pseudoCardsCountBusiness, setPseudoCardsCountBusiness] = React.useState(-1);
    const pseudoCardsCountBusinessRef = React.useRef(-1);
    const pseudoCardsCountBusinessFirstRef = React.useRef(-1);

    const [pseudoCardsCountPersonal, setPseudoCardsCountPersonal] = React.useState(-1);
    const pseudoCardsCountPersonalRef = React.useRef(-1);
    const pseudoCardsCountPersonalFirstRef = React.useRef(-1);

    const [pseudoCardsAmountBusiness, setPseudoCardsAmountBusiness] = React.useState(-1);
    const pseudoCardsAmountBusinessRef = React.useRef(-1);

    const [pseudoCardsAmountPersonal, setPseudoCardsAmountPersonal] = React.useState(-1);
    const pseudoCardsAmountPersonalRef = React.useRef(-1);

    let [error, setError] = React.useState<Error | string | null>(null);
    const [open, setOpen] = React.useState(false);
    const [progIndic, setProgIndic] = React.useState(false);
    const [soldOutState, setSoldOutState] = React.useState(false);
    const [data, setData] = React.useState<ShoppingCartData[] | null>(null);
    const [cardsTotalAmount, setCardsTotalAmount] = React.useState<string | null>(null);
    const [cardsTotalQuantity, setCardsTotalQuantity] = React.useState<string | null>(null);
    const [cardsCurrency, setCardsCurrency] = React.useState<string | null>(null);
    const form = F.useForm({type: 'input'});

    const onCloseDialog = React.useCallback((event, reason) => {
        if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
        props.onClose();
    }, []);

    const onChangeOrder = React.useCallback(() => {
        setOpen(false);
        setProgIndic(true);
        setDataRequested(false);
        setError(null);
        setTimeout(() => {
            setProgIndic(false);
        }, 1000);
    }, []);

    const onClose = React.useCallback(() => {
        if (
            pseudoCardsCountBusinessRef.current === pseudoCardsCountBusinessFirstRef.current &&
            pseudoCardsCountPersonalRef.current === pseudoCardsCountPersonalFirstRef.current
        ) {
            props.onClose();
            return;
        }
        setProgIndic(true);

        Api.requestSession<any>('cart', 'update_general', {
            countBusiness: pseudoCardsCountBusinessRef.current.toString(),
            countPersonal: pseudoCardsCountPersonalRef.current.toString(),
            amountBusiness: pseudoCardsAmountBusinessRef.current.toString(),
            amountPersonal: pseudoCardsAmountPersonalRef.current.toString(),
        })
            .then((d) => {
                // alert(d.answer)
                // GD.pubsub_.dispatch(GD.cardListenerId);
                if (d.answer) {
                    setError(d.answer);
                }

                setDataRequested(false);

                setTimeout(() => {
                    props.onClose();
                }, 1000);
            })
            .catch((err) => setError(err));
    }, []);

    const onCardQuantityChange = React.useCallback((e: any, productId: string, price: number) => {
        setError(null);

        // if (userPaymentIdRef.current) {
        if (webPageOrderIdRef.current) {
            // alert('you have to pay')
            setError('To change cards quantity in your cart you have to pay for the previous ones or change order');
            return;
        }
        // let a = parseFloat(e.target.value)
        // if(Number.isNaN(a) === true) {
        //     setError('Input Number');
        //     return;
        // }

        if (e.target.value === '-') {
            alert(e.target.value);
            return;
        }

        let newVal = parseFloat(e.target.value);

        if (e.target.value === '') {
            if (e.target.value.length === 0) {
                newVal = 0;
            }
        }
        if (Number.isNaN(newVal) === true) {
            setError('Input number');
            return;
        }

        if (productId === '1000') {
            setPseudoCardsCountBusiness(newVal);
            pseudoCardsCountBusinessRef.current = newVal;
            let newAmount = newVal * price;
            setPseudoCardsAmountBusiness(newAmount);
            pseudoCardsAmountBusinessRef.current = newAmount;
        }

        if (productId === '1001') {
            setPseudoCardsCountPersonal(newVal);
            pseudoCardsCountPersonalRef.current = newVal;
            let newAmount = newVal * price;
            setPseudoCardsAmountPersonal(newAmount);
            pseudoCardsAmountPersonalRef.current = newAmount;
        }
    }, []);

    React.useEffect(() => {
        mounted.current = true;
        if (!dataRequested) {
            let lang: string | undefined;
            if (session_.userLang === '' || session_.userLang === undefined) {
                lang = undefined;
            } else {
                lang = session_.userLang;
            }
            Api.requestSessionJson<any>('cart', 'fetch', {language: lang ?? 'en'})
                .then((d) => {
                    if (mounted.current) {
                        //setCards(cards);
                        setCardsTotalAmount(d.totalAmount);
                        setCardsTotalQuantity(d.totalQuantity);
                        setCardsCurrency(d.currency);
                        setUserPaymentId(d.paymentId);
                        userPaymentIdRef.current = d.paymentId;
                        webPageOrderIdRef.current = d.webPageOrderId;
                        let shCartData: ShoppingCartData[] = [];
                        if (d.shoppingCartData) {
                            for (let s of d.shoppingCartData) {
                                shCartData.push(new ShoppingCartData(s));

                                if (s.productId === '1000') {
                                    setPseudoCardsCountBusiness(s.quantity);
                                    pseudoCardsCountBusinessRef.current = s.quantity;
                                    pseudoCardsCountBusinessFirstRef.current = s.quantity;
                                    setPseudoCardsAmountBusiness(s.amount);
                                    pseudoCardsAmountBusinessRef.current = s.amount;
                                }
                                if (s.productId === '1001') {
                                    setPseudoCardsCountPersonal(s.quantity);
                                    pseudoCardsCountPersonalRef.current = s.quantity;
                                    pseudoCardsCountPersonalFirstRef.current = s.quantity;
                                    setPseudoCardsAmountPersonal(s.amount);
                                    pseudoCardsAmountPersonalRef.current = s.amount;
                                }
                            }
                            setData(shCartData);
                        } else {
                            setData(null);
                        }
                        GD.pubsub_.dispatch(GD.cardListenerId);
                    }
                })
                .catch(setError);
            setDataRequested(true);
            return;
        }
        return () => {
            mounted.current = false;
        };
    }, [dataRequested]);

    React.useEffect(() => {
        if (props.addCardButtonStatus !== undefined) {
            setProgIndic(true);
            setTimeout(() => {
                setDataRequested(false);
                setProgIndic(false);
            }, 3000);
        }
    }, [props.addCardButtonStatus]);

    // if (props.addCardButtonStatus) {
    //     setProgIndic(true)
    //     setTimeout(() => {
    //         console.log('I am here')
    //         setDataRequested(false)
    //         setProgIndic(false)
    //     }, 1000)
    //     // oneTimeFunc++;
    // }

    const AddToCartFunction = React.useCallback(
        (productId: string, command: string, quantity: number | undefined) => {
            setError(null);
            if (webPageOrderIdRef.current) {
                // alert('you have to pay 2')
                setError('To change cards quantity in your cart you have to pay for the previous ones or change order');
                return;
            }

            if (productId === '1000') {
                if (command === 'add') {
                    if (pseudoCardsCountBusiness >= 100) {
                        setError('Limit 100 cards');
                    } else {
                        let newCountVal = pseudoCardsCountBusiness + 1;
                        setPseudoCardsCountBusiness(newCountVal);
                        pseudoCardsCountBusinessRef.current = newCountVal;
                        let newAmountVal = pseudoCardsAmountBusiness + 15000;

                        setPseudoCardsAmountBusiness(newAmountVal);
                        pseudoCardsAmountBusinessRef.current = newAmountVal;
                    }
                }
                if (command === 'remove') {
                    if (pseudoCardsCountBusiness <= 0 || pseudoCardsAmountBusiness <= 0) {
                        pseudoCardsCountBusinessRef.current = 0;
                        setPseudoCardsCountBusiness(0);
                        setPseudoCardsAmountBusiness(0);
                        pseudoCardsAmountBusinessRef.current = 0;
                    } else {
                        let newCountVal = pseudoCardsCountBusiness - 1;
                        setPseudoCardsCountBusiness(newCountVal);
                        pseudoCardsCountBusinessRef.current = newCountVal;
                        let newAmountVal = pseudoCardsAmountBusiness - 15000;
                        setPseudoCardsAmountBusiness(newAmountVal);
                        pseudoCardsAmountBusinessRef.current = newAmountVal;
                    }
                }
            }

            if (productId === '1001') {
                if (command === 'add') {
                    if (pseudoCardsCountPersonal >= 100) {
                        setError('Limit 100 cards');
                    } else {
                        let newCountVal = pseudoCardsCountPersonal + 1;
                        setPseudoCardsCountPersonal(newCountVal);
                        pseudoCardsCountPersonalRef.current = newCountVal;
                        let newAmountVal = pseudoCardsAmountPersonal + 10000;
                        setPseudoCardsAmountPersonal(newAmountVal);
                        pseudoCardsAmountPersonalRef.current = newAmountVal;
                    }
                }

                if (command === 'remove') {
                    if (pseudoCardsCountPersonal <= 0 || pseudoCardsAmountPersonal <= 0) {
                        pseudoCardsCountPersonalRef.current = 0;
                        setPseudoCardsCountPersonal(0);
                        setPseudoCardsAmountPersonal(0);
                        pseudoCardsAmountPersonalRef.current = 0;
                    } else {
                        let newCountVal = pseudoCardsCountPersonal - 1;
                        setPseudoCardsCountPersonal(newCountVal);
                        pseudoCardsCountPersonalRef.current = newCountVal;
                        let newAmountVal = pseudoCardsAmountPersonal - 10000;
                        setPseudoCardsAmountPersonal(newAmountVal);
                        pseudoCardsAmountPersonalRef.current = newAmountVal;
                    }
                }
            }

            // Api.requestSession<any>('cart', 'update', { productId: productId, command: command }).then(d => {
            //     // alert(d.answer)
            //     // GD.pubsub_.dispatch(GD.cardListenerId);
            //     if (d.answer) {

            //         setError(d.answer)
            //     }

            //     setDataRequested(false)

            // }).catch(err => setError(err));
        },
        [pseudoCardsCountBusiness, pseudoCardsCountPersonal]
    );

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        setProgIndic(true);
        // console.log(pseudoCardsCountBusinessRef.current.toString())
        // console.log(pseudoCardsCountPersonalRef.current.toString())
        // console.log(pseudoCardsAmountBusinessRef.current.toString())
        // console.log(pseudoCardsAmountPersonalRef.current.toString())
        Api.requestSession<any>('cart', 'update_general', {
            countBusiness: pseudoCardsCountBusinessRef.current.toString(),
            countPersonal: pseudoCardsCountPersonalRef.current.toString(),
            amountBusiness: pseudoCardsAmountBusinessRef.current.toString(),
            amountPersonal: pseudoCardsAmountPersonalRef.current.toString(),
        })
            .then((d) => {
                // alert(d.answer)
                // GD.pubsub_.dispatch(GD.cardListenerId);
                if (d.answer) {
                    setError(d.answer);
                }

                setDataRequested(false);
            })
            .catch((err) => setError(err));
        Api.requestSession<any>('products', 'sold_out_state', {})
            .then((d) => {
                if (d.isSoldOut === true) {
                    setSoldOutState(d.isSoldOut);
                } else {
                    setOpen(true);
                }

                setDataRequested(false);
                setProgIndic(false);
            })
            .catch((err) => setError(err));
    }, []);

    // if (progIndic === true) {
    //     return <PageDialog title='Loading' type='confirm'>
    //         <ProgressIndicator />
    //     </PageDialog>
    // }

    if (!cardsTotalAmount && !cardsTotalQuantity) return <></>;
    if (!data) return <></>;

    if (props.cardTypeId) {
        for (let cardInformation of data) {
            cardInformation.isVisible = false;
        }

        for (let cardInfo of data) {
            if (cardInfo.productId === props.cardTypeId) {
                cardInfo.isVisible = true;
            }
        }
    }

    if (Env.isMobile) {
        return (
            <F.PageFormDialog
                title='Shopping Cart'
                submitLabel='Buy'
                form={form}
                size='lg'
                onSubmit={onSubmit}
                onClose={onClose}
            >
                {error && <ErrorAlert error={error} />}
                {/* <PageDialog type='confirm' size='xl' title='Shopping Cart' onClose={props.onClose}> */}
                <Stack alignItems='center' width='100%'>
                    <Box sx={{display: 'block', width: '100%', justifyContent: 'center', mb: 2}}>
                        {/* <Box sx={{ display: 'block', width: 350, justifyContent: 'center', mb: 2 }}> */}

                        {data.map(
                            (type, index) => (
                                <Box
                                    sx={{
                                        // backgroundColor: theme_.evocardFeatureBackgroundColor, display: 'block', borderRadius: 5, textAlign: 'center', p: 2, marginTop: 5, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                        display: 'block',
                                        borderRadius: 5,
                                        textAlign: 'center',
                                        p: 2,
                                        ml: -1.5,
                                        marginTop: 5,
                                        width: '100%',
                                    }}
                                    key={index}
                                >
                                    {/* <Button sx={{ width: '95%' }} > */}
                                    {type.isVisible && (
                                        <React.Fragment key={index}>
                                            <Box
                                                sx={{
                                                    textAlign: 'center',
                                                    borderRadius: 5,
                                                    marginTop: 5,

                                                    transform:
                                                        'perspective(1200px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                                    transition: '0.6s ease all',
                                                    // "&:hover": {
                                                    // transform: 'perspective(800px) translateZ(-200px) rotateY(0deg) translateY(-10px) rotateX(0deg) scale(1)',
                                                    // }
                                                }}
                                            >
                                                <ImgElement
                                                    src={Env.getCardTypeName(type.cardType as string, 'back')}
                                                    sx={{
                                                        display: 'block',

                                                        // width: 300,
                                                        width: 280,
                                                        borderRadius: 5,
                                                        mt: -12,
                                                        ml: -5,
                                                        padding: 1,
                                                        // transform: 'perspective(1200px) translateZ(-220px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                                        transform:
                                                            'translateZ(-220px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                                        transition: '0.6s ease all',
                                                        filter: 'drop-shadow(-10px 10px 10px #555)',

                                                        // display: 'block',
                                                        // width: 150,
                                                        // borderRadius: 5,
                                                        // mt: -3,
                                                        // ml: 1,
                                                        // padding: 1,
                                                        // filter: 'drop-shadow(-10px 10px 10px #555)',
                                                    }}
                                                />
                                                <ImgElement
                                                    src={Env.getCardTypeName(type.cardType as string, 'front')}
                                                    sx={{
                                                        display: 'block',
                                                        // width: 300,
                                                        width: 280,
                                                        borderRadius: 5,
                                                        // mt: -18,
                                                        // ml: 6,
                                                        mt: -16,
                                                        ml: 10,
                                                        padding: 1,
                                                        // transform: 'perspective(1200px) translateZ(-220px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                                        transform:
                                                            'translateZ(-220px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                                        transition: '0.6s ease all',
                                                        filter: 'drop-shadow(-10px 10px 10px #555)',

                                                        // display: 'block',
                                                        // width: 150,
                                                        // borderRadius: 5,
                                                        // mt: -20,
                                                        // ml: 10,
                                                        // padding: 1,
                                                        // filter: 'drop-shadow(-10px 10px 10px #555)',
                                                    }}
                                                />
                                            </Box>
                                            {/* </Button> */}
                                            <Box sx={{display: 'grid', width: '100%', justifyItems: 'center', ml: 0}}>
                                                <Typography sx={{fontSize: 'x-large'}}>
                                                    {type.productId === '1001'
                                                        ? t('Personal Card')
                                                        : t('Business Card')}
                                                </Typography>
                                                <TableContainer
                                                    component={Paper}
                                                    sx={{
                                                        height: 135,
                                                        backgroundColor: theme_.evocardFeatureBackgroundColor,
                                                        borderRadius: theme_.cardBorderRadius,
                                                    }}
                                                >
                                                    <Table sx={{width: '100%'}} aria-label='simple table'>
                                                        <TableHead>
                                                            <TableRow
                                                                sx={{
                                                                    backgroundColor:
                                                                        theme_.tableInsideDialogBackgroundColor,
                                                                }}
                                                            >
                                                                {/* <TableCell style={{width: 10}} sx={{ textAlign: 'center' }}>{t('order.card_type')}</TableCell> */}
                                                                <TableCell sx={{textAlign: 'center'}} align='right'>
                                                                    {t('order.card_quantity')}
                                                                </TableCell>
                                                                <TableCell sx={{textAlign: 'center'}} align='right'>
                                                                    {t('order.card_price')}
                                                                </TableCell>
                                                                <TableCell sx={{textAlign: 'center'}} align='right'>
                                                                    {t('order.card_amount')}
                                                                </TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {/* <TableRow key={type.productId} sx={{ '&:last-child td, &:last-child th': { border: 0, backgroundColor: theme_.evocardFeatureBackgroundColor } }}> */}
                                                            <TableRow
                                                                key={type.productId}
                                                                sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                            >
                                                                {/* <TableCell sx={{ textAlign: 'center' }} component='th' scope='row'>
                                                        {type.productId === 'B' ? `${t('Business')}` : `${t('Personal')}`}
                                                    </TableCell> */}
                                                                <TableCell
                                                                    sx={{textAlign: 'center'}}
                                                                    component='th'
                                                                    scope='row'
                                                                >
                                                                    {type.productId === '1000' && (
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                width: 145,
                                                                                ml: -3.5,
                                                                                alignItems: 'center',
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                onClick={() => {
                                                                                    AddToCartFunction(
                                                                                        type.productId as string,
                                                                                        'remove',
                                                                                        type.quantity
                                                                                    );
                                                                                }}
                                                                                sx={{
                                                                                    ml: 1,
                                                                                    '&:hover': {
                                                                                        backgroundColor: 'transparent',
                                                                                        borderColor: 'transparent',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <HorizontalRuleIcon
                                                                                    sx={{
                                                                                        fontSize: 'x-large',
                                                                                        color: theme_.themeColor,
                                                                                        backgroundColor:
                                                                                            theme_.bodyColor,
                                                                                        borderRadius: '50%',
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                            {/* <Typography sx={{ ml: -1 }}>{type.quantity}</Typography> */}
                                                                            {pseudoCardsCountBusiness === -1 && (
                                                                                <TextField
                                                                                    sx={{fontSize: 'small', ml: -1.3}}
                                                                                    id='standard-basic'
                                                                                    hiddenLabel
                                                                                    variant='standard'
                                                                                    inputProps={{
                                                                                        maxLength: 2,
                                                                                        style: {textAlign: 'center'},
                                                                                    }}
                                                                                    value={type.quantity?.toString()}
                                                                                    onChange={(e) => {
                                                                                        onCardQuantityChange(
                                                                                            e,
                                                                                            type.productId!,
                                                                                            type.price!
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {pseudoCardsCountBusiness !== -1 && (
                                                                                <TextField
                                                                                    sx={{fontSize: 'small', ml: -1.3}}
                                                                                    id='standard-basic'
                                                                                    hiddenLabel
                                                                                    variant='standard'
                                                                                    inputProps={{
                                                                                        maxLength: 2,
                                                                                        style: {textAlign: 'center'},
                                                                                    }}
                                                                                    value={pseudoCardsCountBusiness.toString()}
                                                                                    onChange={(e) => {
                                                                                        onCardQuantityChange(
                                                                                            e,
                                                                                            type.productId!,
                                                                                            type.price!
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            <Button
                                                                                onClick={() => {
                                                                                    AddToCartFunction(
                                                                                        type.productId as string,
                                                                                        'add',
                                                                                        type.quantity
                                                                                    );
                                                                                }}
                                                                                sx={{
                                                                                    ml: -1.2,
                                                                                    '&:hover': {
                                                                                        backgroundColor: 'transparent',
                                                                                        borderColor: 'transparent',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <AddIcon
                                                                                    sx={{
                                                                                        fontSize: 'x-large',
                                                                                        color: theme_.themeColor,
                                                                                        backgroundColor:
                                                                                            theme_.bodyColor,
                                                                                        borderRadius: '50%',
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                        </Box>
                                                                    )}
                                                                    {type.productId === '1001' && (
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                ml: -3.5,
                                                                                width: 145,
                                                                            }}
                                                                        >
                                                                            <Button
                                                                                onClick={() => {
                                                                                    AddToCartFunction(
                                                                                        type.productId as string,
                                                                                        'remove',
                                                                                        type.quantity
                                                                                    );
                                                                                }}
                                                                                sx={{
                                                                                    ml: 1,
                                                                                    '&:hover': {
                                                                                        backgroundColor: 'transparent',
                                                                                        borderColor: 'transparent',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <HorizontalRuleIcon
                                                                                    sx={{
                                                                                        fontSize: 'x-large',
                                                                                        color: theme_.themeColor,
                                                                                        backgroundColor:
                                                                                            theme_.bodyColor,
                                                                                        borderRadius: '50%',
                                                                                    }}
                                                                                />
                                                                            </Button>

                                                                            {pseudoCardsCountPersonal === -1 && (
                                                                                <TextField
                                                                                    sx={{fontSize: 'small', ml: -1.3}}
                                                                                    id='standard-basic'
                                                                                    hiddenLabel
                                                                                    variant='standard'
                                                                                    inputProps={{
                                                                                        maxLength: 2,
                                                                                        style: {textAlign: 'center'},
                                                                                    }}
                                                                                    value={type.quantity?.toString()}
                                                                                    onChange={(e) => {
                                                                                        onCardQuantityChange(
                                                                                            e,
                                                                                            type.productId!,
                                                                                            type.price!
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            {pseudoCardsCountPersonal !== -1 && (
                                                                                <TextField
                                                                                    sx={{fontSize: 'small', ml: -1.3}}
                                                                                    id='standard-basic'
                                                                                    hiddenLabel
                                                                                    variant='standard'
                                                                                    inputProps={{
                                                                                        maxLength: 2,
                                                                                        style: {textAlign: 'center'},
                                                                                    }}
                                                                                    value={pseudoCardsCountPersonal.toString()}
                                                                                    onChange={(e) => {
                                                                                        onCardQuantityChange(
                                                                                            e,
                                                                                            type.productId!,
                                                                                            type.price!
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            )}
                                                                            <Button
                                                                                onClick={() => {
                                                                                    AddToCartFunction(
                                                                                        type.productId as string,
                                                                                        'add',
                                                                                        type.quantity
                                                                                    );
                                                                                }}
                                                                                sx={{
                                                                                    ml: -1.2,
                                                                                    '&:hover': {
                                                                                        backgroundColor: 'transparent',
                                                                                        borderColor: 'transparent',
                                                                                    },
                                                                                }}
                                                                            >
                                                                                <AddIcon
                                                                                    sx={{
                                                                                        fontSize: 'x-large',
                                                                                        color: theme_.themeColor,
                                                                                        backgroundColor:
                                                                                            theme_.bodyColor,
                                                                                        borderRadius: '50%',
                                                                                    }}
                                                                                />
                                                                            </Button>
                                                                        </Box>
                                                                    )}
                                                                </TableCell>
                                                                <TableCell sx={{textAlign: 'center'}} align='right'>
                                                                    {type.price} {type.currency}
                                                                </TableCell>
                                                                {type.productId === '1000' && (
                                                                    <TableCell
                                                                        sx={{textAlign: 'center', fontSize: 'small'}}
                                                                        align='right'
                                                                    >
                                                                        {/* {type.amount}{' '}{type.currency} */}
                                                                        {pseudoCardsAmountBusiness !== -1 && (
                                                                            <Typography sx={{fontSize: 'small'}}>
                                                                                {pseudoCardsAmountBusiness}{' '}
                                                                                {type.currency}
                                                                            </Typography>
                                                                        )}
                                                                        {pseudoCardsAmountBusiness === -1 && (
                                                                            <Typography sx={{fontSize: 'small'}}>
                                                                                {type.amount} {type.currency}
                                                                            </Typography>
                                                                        )}
                                                                    </TableCell>
                                                                )}
                                                                {type.productId === '1001' && (
                                                                    <TableCell
                                                                        sx={{textAlign: 'center', fontSize: 'small'}}
                                                                        align='right'
                                                                    >
                                                                        {/* {pseudoCardsAmountPersonal}{' '}{type.currency} */}
                                                                        {pseudoCardsAmountPersonal !== -1 && (
                                                                            <Typography sx={{fontSize: 'small'}}>
                                                                                {pseudoCardsAmountPersonal}{' '}
                                                                                {type.currency}
                                                                            </Typography>
                                                                        )}
                                                                        {pseudoCardsAmountPersonal === -1 && (
                                                                            <Typography sx={{fontSize: 'small'}}>
                                                                                {type.amount} {type.currency}
                                                                            </Typography>
                                                                        )}
                                                                    </TableCell>
                                                                )}
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                                {/* <Button variant='outlined' sx={{ minWidth: 120, height: 40, mt: 3, fontSize: 'medium', "&:hover": { backgroundColor: theme_.themeColor, color: 'white', borderColor: 'transparent' }, boxShadow: 'none', color: theme_.themeColor }}>{t('Buy')}</Button> */}
                                            </Box>
                                        </React.Fragment>
                                    )}
                                </Box>
                            )
                            // </Box>
                        )}
                        {/* <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                        <Button sx={{ position: 'absolute', bottom: 0.5, left: 10, width: 200, justifySelf: 'center', backgroundColor: theme_.themeColor, color: theme_.inputTextBackgroundColor }}>
                            <Box sx={{ display: 'grid' }}>
                                <Typography fontSize='small'>{t('Total Amount')}</Typography>
                                <Typography fontSize='small'>{cardsTotalQuantity}{' '}{t('count')}{' '}-{' '}{cardsTotalAmount}{' '}{cardsCurrency}</Typography>
                            </Box>
                        </Button>
                    </Box> */}
                    </Box>

                    {/* <Button sx={{ mt: 3, "&:hover": { backgroundColor: '#d09310', color: theme_.themeColor }, backgroundColor: theme_.themeColor, boxShadow: 'none', color: theme_.themeColor }}>
                        <Link sx={{ textDecoration: 'none', color: 'white' }} href={`https://evocard.me/c/${props.cardId}/card_qr_phone.png`} download target="_blank">
                            {t('Save to gallery')}
                        </Link>
                    </Button> */}
                </Stack>
                {/* </PageDialog> */}
                {/* ); */}
                {/* {open && <BuyCardsDialog onClose={() => { setOpen(false) }} />} */}
                {/* {open && <ShippingInfo onClose={() => { setOpen(false) }} />} */}
                {progIndic && (
                    // <Dialog onClose={props.onClose} onBackdropClick={() => {props.onClose}} open={progIndicator} PaperProps={{ sx: { width: 200, height: 200 } }}>
                    <Dialog onClose={onCloseDialog} open={progIndic} PaperProps={{sx: {width: 200, height: 200}}}>
                        <ProgressIndicator title='Updating' />
                    </Dialog>
                )}

                {/* {open && <BuyCardsDialog onClose={() => { setOpen(false) }} />} */}
                {open && (
                    <ShippingInfo
                        onSelected={onChangeOrder}
                        onClose={() => {
                            setOpen(false);
                            setDataRequested(false);
                            setError(null);
                        }}
                        // onSelected={onChangeOrder}
                        // onClose={() => {
                        //     setOpen(false);
                        //     setDataRequested(false);
                        //     setError(null);
                        // }}
                    />
                )}
                {soldOutState === true && (
                    <PageDialog
                        type='panel'
                        title='Sold Out'
                        form={form}
                        size='sm'
                        onClose={() => {
                            setSoldOutState(false);
                        }}
                    >
                        <Typography>{t('buy.sold_out_text')}</Typography>
                    </PageDialog>
                )}
            </F.PageFormDialog>
        );
    }

    return (
        <F.PageFormDialog
            title='Shopping Cart'
            submitLabel='Buy'
            form={form}
            size='xl'
            onSubmit={onSubmit}
            onClose={onClose}
            type='confirm'
        >
            {error && <ErrorAlert error={error} />}
            {/* <PageDialog type='confirm' size='xl' title='Shopping Cart' onClose={props.onClose}> */}
            <Stack alignItems='center' width='100%'>
                {/* <ImgElement sx={{ width: '50%', height: '50%' }} src={`https://evocard.me/c/${props.cardId}/card_qr_phone.png`} /> */}
                {/* <Typography>ssss</Typography> */}
                <Box sx={{display: 'block', width: '100%', justifyContent: 'center', mb: 2}}>
                    {data.map(
                        (type, index) => (
                            <Box
                                sx={{
                                    // backgroundColor: theme_.evocardFeatureBackgroundColor, display: 'flex', borderRadius: 5, textAlign: 'center', p: 2, marginTop: 5, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)'
                                    display: 'flex',
                                    borderRadius: 5,
                                    textAlign: 'center',
                                    p: 2,
                                    marginTop: 5,
                                }}
                                key={index}
                            >
                                {/* <Typography>{type.amount}</Typography> */}
                                {/* <Box sx={{ display: 'flex', alignItems: 'center', width: 850, mt: 7, p: 3, marginBottom: 3, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.evocardFeatureBackgroundColor, }}> */}
                                {/* <Button > */}
                                {type.isVisible && (
                                    <React.Fragment key={index}>
                                        <Box
                                            sx={{
                                                textAlign: 'center',
                                                borderRadius: 5,
                                                marginTop: 5,

                                                transform:
                                                    'perspective(1200px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                                transition: '0.6s ease all',
                                                '&:hover': {
                                                    transform:
                                                        'perspective(800px) translateZ(-200px) rotateY(0deg) translateY(-10px) rotateX(0deg) scale(1)',
                                                },
                                            }}
                                        >
                                            <ImgElement
                                                src={Env.getCardTypeName(type.cardType as string, 'back')}
                                                sx={{
                                                    display: 'block',
                                                    width: 350,
                                                    borderRadius: 5,
                                                    mt: -3,
                                                    ml: 1,
                                                    padding: 1,
                                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                                }}
                                            />
                                            <ImgElement
                                                src={Env.getCardTypeName(type.cardType as string, 'front')}
                                                sx={{
                                                    display: 'block',
                                                    width: 350,
                                                    borderRadius: 5,
                                                    mt: -20,
                                                    ml: 10,
                                                    padding: 1,
                                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                                }}
                                            />
                                        </Box>
                                        {/* </Button> */}
                                        <Box sx={{display: 'grid', justifyItems: 'center', ml: 5}}>
                                            <Typography sx={{fontSize: 'xx-large'}}>
                                                {type.productId === '1001' ? t('Personal Card') : t('Business Card')}
                                            </Typography>
                                            <TableContainer
                                                component={Paper}
                                                sx={{
                                                    height: session_.userLang !== 'am' ? 135 : 165,
                                                    backgroundColor: theme_.evocardFeatureBackgroundColor,
                                                    borderRadius: theme_.cardBorderRadius,
                                                }}
                                            >
                                                {/* <Table sx={{ width: 630 }} aria-label='simple table'> */}
                                                <Table aria-label='simple table'>
                                                    <TableHead>
                                                        <TableRow
                                                            sx={{
                                                                backgroundColor:
                                                                    theme_.tableInsideDialogBackgroundColor,
                                                            }}
                                                        >
                                                            <TableCell sx={{textAlign: 'center', fontSize: 'medium'}}>
                                                                {t('order.card_type')}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    width: 170,
                                                                    fontSize: 'medium',
                                                                }}
                                                                align='right'
                                                            >
                                                                {t('order.card_quantity')}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{textAlign: 'center', fontSize: 'medium'}}
                                                                align='right'
                                                            >
                                                                {t('order.card_price')}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{
                                                                    textAlign: 'center',
                                                                    width: 100,
                                                                    fontSize: 'medium',
                                                                }}
                                                                align='right'
                                                            >
                                                                {t('order.card_amount')}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {/* <TableRow key={type.productId} sx={{ '&:last-child td, &:last-child th': { border: 0, backgroundColor: theme_.evocardFeatureBackgroundColor } }}> */}
                                                        <TableRow
                                                            key={type.productId}
                                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                                        >
                                                            <TableCell
                                                                sx={{textAlign: 'center', fontSize: 'medium'}}
                                                                component='th'
                                                                scope='row'
                                                            >
                                                                {type.productId === '1001'
                                                                    ? `${t('Personal')}`
                                                                    : `${t('Business')}`}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{textAlign: 'center', fontSize: 'medium'}}
                                                                component='th'
                                                                scope='row'
                                                            >
                                                                {type.productId === '1000' && (
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            ml: 2,
                                                                            width: 185,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            onClick={() => {
                                                                                AddToCartFunction(
                                                                                    type.productId as string,
                                                                                    'remove',
                                                                                    type.quantity
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                '&:hover': {
                                                                                    backgroundColor: 'transparent',
                                                                                    borderColor: 'transparent',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <HorizontalRuleIcon
                                                                                sx={{
                                                                                    fontSize: 'x-large',
                                                                                    color: theme_.themeColor,
                                                                                    borderRadius: '50%',
                                                                                    backgroundColor: theme_.bodyColor,
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                        {/* {pseudoCardsCountBusiness !== -1 && <Typography>{pseudoCardsCountBusiness}</Typography>} */}
                                                                        {/* {pseudoCardsCountBusiness === -1 && <Typography>{type.quantity}</Typography>} */}
                                                                        {/* {pseudoCardsCountBusiness === -1 && <F.InputText maxLength={2} value={type.quantity?.toString()} id='cardQuantity' form={form} xs={5} />} */}
                                                                        {pseudoCardsCountBusiness === -1 && (
                                                                            <TextField
                                                                                id='standard-basic'
                                                                                hiddenLabel
                                                                                variant='standard'
                                                                                inputProps={{
                                                                                    maxLength: 2,
                                                                                    style: {textAlign: 'center'},
                                                                                }}
                                                                                value={type.quantity?.toString()}
                                                                                onChange={(e) => {
                                                                                    onCardQuantityChange(
                                                                                        e,
                                                                                        type.productId!,
                                                                                        type.price!
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                        {/* {pseudoCardsCountBusiness !== -1 && <F.InputText maxLength={2} value={pseudoCardsCountBusiness.toString()} id='cardQuantity' form={form} xs={5} />} */}
                                                                        {pseudoCardsCountBusiness !== -1 && (
                                                                            <TextField
                                                                                id='standard-basic'
                                                                                hiddenLabel
                                                                                variant='standard'
                                                                                inputProps={{
                                                                                    maxLength: 2,
                                                                                    style: {textAlign: 'center'},
                                                                                }}
                                                                                value={pseudoCardsCountBusiness.toString()}
                                                                                onChange={(e) => {
                                                                                    onCardQuantityChange(
                                                                                        e,
                                                                                        type.productId!,
                                                                                        type.price!
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                        {/* <TextField id="standard-basic" hiddenLabel variant="standard" value={pseudoCardsCountBusiness} onChange={(e) => {onCardQuantityChange(e)}} /> */}

                                                                        <Button
                                                                            onClick={() => {
                                                                                AddToCartFunction(
                                                                                    type.productId as string,
                                                                                    'add',
                                                                                    type.quantity
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                '&:hover': {
                                                                                    backgroundColor: 'transparent',
                                                                                    borderColor: 'transparent',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <AddIcon
                                                                                sx={{
                                                                                    fontSize: 'x-large',
                                                                                    color: theme_.themeColor,
                                                                                    backgroundColor: theme_.bodyColor,
                                                                                    borderRadius: '50%',
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    </Box>
                                                                )}
                                                                {type.productId === '1001' && (
                                                                    <Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            ml: 2,
                                                                            width: 185,
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            onClick={() => {
                                                                                AddToCartFunction(
                                                                                    type.productId as string,
                                                                                    'remove',
                                                                                    type.quantity
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                '&:hover': {
                                                                                    backgroundColor: 'transparent',
                                                                                    borderColor: 'transparent',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <HorizontalRuleIcon
                                                                                sx={{
                                                                                    fontSize: 'x-large',
                                                                                    color: theme_.themeColor,
                                                                                    backgroundColor: theme_.bodyColor,
                                                                                    borderRadius: '50%',
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                        {/* {pseudoCardsCountPersonal !== -1 && <Typography>{pseudoCardsCountPersonal}</Typography>}
                                                        {pseudoCardsCountPersonal === -1 && <Typography>{type.quantity}</Typography>} */}

                                                                        {/* {pseudoCardsCountPersonal === -1 && <F.InputText maxLength={2} value={type.quantity?.toString()} id='cardQuantity' form={form} xs={5} />} */}
                                                                        {/* {pseudoCardsCountPersonal !== -1 && <F.InputText maxLength={2} value={pseudoCardsCountPersonal.toString()} id='cardQuantity' form={form} xs={5} />} */}
                                                                        {pseudoCardsCountPersonal === -1 && (
                                                                            <TextField
                                                                                id='standard-basic'
                                                                                hiddenLabel
                                                                                variant='standard'
                                                                                inputProps={{
                                                                                    maxLength: 2,
                                                                                    style: {textAlign: 'center'},
                                                                                }}
                                                                                value={type.quantity?.toString()}
                                                                                onChange={(e) => {
                                                                                    onCardQuantityChange(
                                                                                        e,
                                                                                        type.productId!,
                                                                                        type.price!
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                        {pseudoCardsCountPersonal !== -1 && (
                                                                            <TextField
                                                                                id='standard-basic'
                                                                                hiddenLabel
                                                                                variant='standard'
                                                                                inputProps={{
                                                                                    maxLength: 2,
                                                                                    style: {textAlign: 'center'},
                                                                                }}
                                                                                value={pseudoCardsCountPersonal.toString()}
                                                                                onChange={(e) => {
                                                                                    onCardQuantityChange(
                                                                                        e,
                                                                                        type.productId!,
                                                                                        type.price!
                                                                                    );
                                                                                }}
                                                                            />
                                                                        )}
                                                                        <Button
                                                                            onClick={() => {
                                                                                AddToCartFunction(
                                                                                    type.productId as string,
                                                                                    'add',
                                                                                    type.quantity
                                                                                );
                                                                            }}
                                                                            sx={{
                                                                                '&:hover': {
                                                                                    backgroundColor: 'transparent',
                                                                                    borderColor: 'transparent',
                                                                                },
                                                                            }}
                                                                        >
                                                                            <AddIcon
                                                                                sx={{
                                                                                    fontSize: 'x-large',
                                                                                    color: theme_.themeColor,
                                                                                    backgroundColor: theme_.bodyColor,
                                                                                    borderRadius: '50%',
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                    </Box>
                                                                )}
                                                            </TableCell>
                                                            <TableCell
                                                                sx={{textAlign: 'center', fontSize: 'medium'}}
                                                                align='right'
                                                            >
                                                                {type.price} {type.currency}
                                                            </TableCell>
                                                            {type.productId === '1000' && (
                                                                <TableCell
                                                                    sx={{textAlign: 'center', fontSize: 'medium'}}
                                                                    align='right'
                                                                >
                                                                    {pseudoCardsAmountBusiness !== -1 && (
                                                                        <Typography>
                                                                            {pseudoCardsAmountBusiness}
                                                                        </Typography>
                                                                    )}
                                                                    {pseudoCardsAmountBusiness === -1 && (
                                                                        <Typography>{type.amount}</Typography>
                                                                    )}

                                                                    {/* {pseudoCardsAmountBusiness}{' '}{type.currency} */}
                                                                </TableCell>
                                                            )}
                                                            {type.productId === '1001' && (
                                                                <TableCell
                                                                    sx={{textAlign: 'center', fontSize: 'medium'}}
                                                                    align='right'
                                                                >
                                                                    {/* {pseudoCardsAmountPersonal}{' '}{type.currency} */}
                                                                    {pseudoCardsAmountPersonal !== -1 && (
                                                                        <Typography>
                                                                            {pseudoCardsAmountPersonal}
                                                                        </Typography>
                                                                    )}
                                                                    {pseudoCardsAmountPersonal === -1 && (
                                                                        <Typography>{type.amount}</Typography>
                                                                    )}
                                                                </TableCell>
                                                            )}
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            {/* <Button variant='outlined' sx={{ minWidth: 130, height: 50, mt: 1, fontSize: 'x-large', "&:hover": { backgroundColor: theme_.themeColor, color: 'white', borderColor: 'transparent' }, boxShadow: 'none', color: theme_.themeColor }}>{t('Buy')}</Button> */}
                                        </Box>
                                    </React.Fragment>
                                )}
                            </Box>
                        )
                        // </Box>
                    )}
                </Box>

                {/* <Button sx={{ mt: 3, "&:hover": { backgroundColor: '#d09310', color: theme_.themeColor }, backgroundColor: theme_.themeColor, boxShadow: 'none', color: theme_.themeColor }}>
                    <Link sx={{ textDecoration: 'none', color: 'white' }} href={`https://evocard.me/c/${props.cardId}/card_qr_phone.png`} download target="_blank">
                        {t('Save to gallery')}
                    </Link>
                </Button> */}
                {/* <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                <Button sx={{ position: 'absolute', bottom: 0.5, left: 880, width: 200, justifySelf: 'center', backgroundColor: theme_.themeColor, color: theme_.inputTextBackgroundColor, "&:hover": { backgroundColor: theme_.buttonHoverColorGradient }, }}>
                    <Box sx={{ display: 'grid' }}>
                        <Typography fontSize='small'>{t('Total Amount')}</Typography>
                        <Typography fontSize='small'>{cardsTotalQuantity}{' '}{t('count')}{' '}-{' '}{cardsTotalAmount}{' '}{cardsCurrency}</Typography>
                    </Box>
                </Button>
            </Box> */}
            </Stack>

            {progIndic && (
                // <Dialog onClose={props.onClose} onBackdropClick={() => {props.onClose}} open={progIndicator} PaperProps={{ sx: { width: 200, height: 200 } }}>
                <Dialog onClose={onCloseDialog} open={progIndic} PaperProps={{sx: {width: 200, height: 200}}}>
                    <ProgressIndicator title='Updating' />
                </Dialog>
            )}

            {/* {open && <BuyCardsDialog onClose={() => { setOpen(false) }} />} */}
            {open && (
                <ShippingInfo
                    onSelected={onChangeOrder}
                    onClose={() => {
                        setOpen(false);
                        // setProgIndic(true)
                        setDataRequested(false);
                        setError(null);

                        // setTimeout(() => {
                        //     setProgIndic(false)

                        // }, 1000)
                    }}
                />
            )}
            {soldOutState === true && (
                <PageDialog
                    type='panel'
                    title='Sold Out'
                    form={form}
                    size='sm'
                    onClose={() => {
                        setSoldOutState(false);
                    }}
                >
                    <Typography>{t('buy.sold_out_text')}</Typography>
                </PageDialog>
            )}
        </F.PageFormDialog>
    );
}
