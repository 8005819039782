import React from 'react';

import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import * as F from 'tsui/Form';

import PageContents from 'components/PageContents';
import ImgElement from 'tsui/DomElements/ImgElement';
import { Box } from '@mui/material';
import Env from 'env';
import { useTranslation } from 'react-i18next';


export default function SuccessJoiningPage() {
    const [t] = useTranslation();


    if (Env.isMobile) {
        return <PageContents title='Join Reponse'>
            <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' />  {/* svg/png */}

            <Box sx={{ width: '100%', textAlign: 'center', justifyContent: 'center', mt: 10 }}>
                <Box sx={{
                    display: 'inline-block',
                    width: '65%',
                    border: `1px solid ${theme_.themeColor}`,
                    backgroundColor: theme_.evocardFeatureBackgroundColor,
                    p: 5,
                    borderRadius: 4,
                    borderWidth: 1,
                    borderStyle: 'solid',
                }}>

                    <Typography
                        sx={{
                            fontSize: 'xx-large',
                            // marginTop: 20,
                            color: 'green',
                        }}
                    >
                        {t('join.success_message_title')}
                    </Typography>
                    <Typography>{t('join.success_message_description')}</Typography>
                    <Link style={{ fontSize: 'x-large', color: theme_.themeColor, marginTop: 20, }} to='/login'>
                        {t('Sign in')}
                    </Link>
                </Box>
            </Box>
        </PageContents>
    }

    return <PageContents title='Join Reponse'>
        <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' />  {/* svg/png */}

        <Box sx={{ width: '100%', textAlign: 'center', justifyContent: 'center', mt: 10 }}>
            <Box sx={{
                display: 'inline-block',
                width: '50%',
                border: `1px solid ${theme_.themeColor}`,
                backgroundColor: theme_.evocardFeatureBackgroundColor,
                p: 5,
                borderRadius: 4,
                borderWidth: 1,
                borderStyle: 'solid',
            }}>

                <Typography
                    sx={{
                        fontSize: 'xxx-large',
                        // marginTop: 20,
                        color: 'green',
                    }}
                >
                    {t('join.success_message_title')}
                </Typography>
                <Typography sx={{
                    fontSize: 'xxx-large',
                    // marginTop: 20,
                    color: 'green',
                }}
                >
                    {t('join.success_message_description')}
                </Typography>
                <Link style={{ fontSize: 'xx-large', color: theme_.themeColor, marginTop: 20 }} to='/login'>
                    {t('Sign in')}
                </Link>
            </Box>
        </Box>
    </PageContents>
}
