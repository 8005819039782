import { Accordion, AccordionDetails, AccordionSummary, Box, Grid, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import React from 'react';
import { InputFormContext } from 'tsui/Form/FormContext';
import * as F from 'tsui/Form';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';


interface MobileCardDetailsBusinessProps {
    form: InputFormContext
    chosenLang: Array<string>
    getFieldValue: (name: string, lang?: string) => string
    getFieldAtt: (lang: string, fieldName: string) => boolean

    // onSubmit: (evt: F.InputFormEvent) => void
    // error: Error | string | null
}

export default function MobileCardDetailsBusiness(props: MobileCardDetailsBusinessProps) {
    const [t] = useTranslation();

    const [lt] = React.useState({
        en: i18next.getFixedT('en', 'profile'),
        am: i18next.getFixedT('am', 'profile'),
        ru: i18next.getFixedT('ru', 'profile'),
    })

    const getFieldValue = React.useCallback((name: string, lang?: string) => { return props.getFieldValue(name, lang) }, []);
    const form = props.form





    return (
        <>

            <Accordion sx={{ mt: 5 }}>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="businessPersonal"
                    id="businessPersonal"
                    sx={{ mt: 5 }}
                >
                    <Typography>{t('Personal')}</Typography>
                </AccordionSummary>
                <AccordionDetails >

                    <F.FormGrid form={form}>

                        <F.InputGroup label='Name' form={form} />
                        {props.chosenLang.map((lang, index) =>
                            <React.Fragment key={index}>


                                <F.InputText maxLength={30} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.firstName')} id={`firstName${`_${lang}`}`} attention={props.getFieldAtt(lang, "firstName")} autocomplete='given-name' value={getFieldValue('firstName', lang)} form={form} xsMax />
                                <F.InputText maxLength={30} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.middleName')} id={`middleName${`_${lang}`}`} autocomplete='additional-name' value={getFieldValue('middleName', lang)} form={form} xsMax />
                                <F.InputText maxLength={30} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.lastName')} id={`lastName${`_${lang}`}`} attention={props.getFieldAtt(lang, "lastName")} autocomplete='family-name' value={getFieldValue('lastName', lang)} form={form} xsMax />
                            </React.Fragment>
                        )}


                        {/* <F.InputGroup label='Company / Position' form={form} /> */}
                        <F.InputGroup label='card.profession' form={form} />
                        {props.chosenLang.map((lang, index) =>

                            // <F.InputText key={index} maxLength={70} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.company_position')} id={`profession${`_${lang}`}`} value={getFieldValue('profession', lang)} form={form} xsMax />
                            <F.InputText key={index} maxLength={70} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.profession')} id={`profession${`_${lang}`}`} value={getFieldValue('profession', lang)} form={form} xsMax />
                        )}


                        <F.InputGroup label='Contact' form={form} />
                        <F.InputText maxLength={20} label='Phone' id='phone' validate="tel" autocomplete='tel' value={getFieldValue('phone')} form={form} xsMax />
                        <F.InputText maxLength={50} label='Email' id='email' validate='email' autocomplete='email' value={getFieldValue('email')} form={form} xsMax />
                        <F.InputText maxLength={20} label='Telegram' id='telegram' validate='tel' autocomplete='tel' value={getFieldValue('telegram')} form={form} xsMax />
                        <F.InputText maxLength={20} label='Viber' id='viber' validate='tel' autocomplete='tel' value={getFieldValue('viber')} form={form} xsMax />
                        <F.InputText maxLength={20} label='WhatsApp' id='whatsapp' validate='tel' autocomplete='tel' value={getFieldValue('whatsapp')} form={form} xsMax />


                        <F.InputGroup label='Social Networks' form={form} />
                        <F.InputText maxLength={200} label='Facebook' id='facebook' value={getFieldValue('facebook')} form={form} xsMax />
                        <F.InputText maxLength={200} label='Instagram' id='instagram' value={getFieldValue('instagram')} form={form} xsMax />
                        <F.InputText maxLength={200} label='LinkedIn' id='linkedin' value={getFieldValue('linkedin')} form={form} xsMax />

                    </F.FormGrid>
                </AccordionDetails>

            </Accordion>


            <Accordion>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="businessPersonal"
                    id="businessPersonal"
                    sx={{ mt: 3 }}
                >
                    <Typography>{t('Company')}</Typography>
                </AccordionSummary>
                <AccordionDetails >

                    <F.FormGrid form={form}>

                        <F.InputGroup label='Company/Position' form={form} />
                        {props.chosenLang.map((lang, index) => <React.Fragment key={index}>
                            {/* <F.InputText key={index} maxLength={70} tlabel={lt[lang]('Company/Position')} id={`profession${`_${lang}`}`} value={getFieldValue('profession', lang)} form={form} xsMax /> */}
                            <F.InputText maxLength={70} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.companyName')} id={`companyName${`_${lang}`}`} value={getFieldValue('companyName', lang)} form={form} xsMax />
                            <F.InputText maxLength={70} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.companyPosition')} id={`companyPosition${`_${lang}`}`} value={getFieldValue('companyPosition', lang)} form={form} xsMax />
                        </React.Fragment>

                        )}

                        <F.InputGroup label='Business Description' form={form} />
                        {props.chosenLang.map((lang, index) =>

                            // <F.InputText multiline key={index} maxLength={500} label='Business Description' id={`businessDescription${`_${lang}`}`} value={getFieldValue('businessDescription', lang)} form={form} xsMax />
                            <F.InputText multiline key={index} show={props.chosenLang.includes(lang)} maxLength={500} id={`businessDescription${`_${lang}`}`} tlabel={lt[lang]('card.businessDescription')} value={getFieldValue('businessDescription', lang)} form={form} xsMax />

                        )}

                        <F.InputGroup label='Business Phone' form={form} />
                        <F.InputText maxLength={200} label='Company Phone' id='companyPhone' value={getFieldValue('companyPhone')} form={form} xsMax />



                        <F.InputGroup label='Social Networks' form={form} />
                        <F.InputText maxLength={200} label='Company Website' id='companyWebsite' value={getFieldValue('companyWebsite')} form={form} xsMax />
                        <F.InputText maxLength={200} label='Facebook Business Page' id='facebookBusinessPage' value={getFieldValue('facebookBusinessPage')} form={form} xsMax />
                        <F.InputText maxLength={200} label='Telegram Channel' id='telegramChannel' value={getFieldValue('telegramChannel')} form={form} xsMax />


                        <F.InputGroup label='Address' form={form} />

                        {props.chosenLang.map((lang, index) =>

                            <React.Fragment key={index}>
                                {/* <F.InputText maxLength={50} label={lt[lang]('Country')} id={`country${`_${lang}`}`} value={getFieldValue('country', lang)} form={form} xsMax />
                                <F.InputText maxLength={50} label={lt[lang]('City')} id={`city${`_${lang}`}`} value={getFieldValue('city', lang)} form={form} xsMax />
                                <F.InputText maxLength={50} label={lt[lang]('Street')} id={`street${`_${lang}`}`} value={getFieldValue('street', lang)} form={form} xsMax />
                                <F.InputText maxLength={50} label={lt[lang]('Zip Code')} id={`zipCode${`_${lang}`}`} value={getFieldValue('zipCode', lang)} form={form} xsMax /> */}
                                <F.InputText maxLength={50} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.businessStreet')} id={`businessStreet${`_${lang}`}`} value={getFieldValue('businessStreet', lang)} form={form} xsMax />
                                <F.InputText maxLength={50} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.businessCity')} id={`businessCity${`_${lang}`}`} value={getFieldValue('businessCity', lang)} form={form} xsMax />
                                <F.InputText maxLength={50} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.businessCountry')} id={`businessCountry${`_${lang}`}`} value={getFieldValue('businessCountry', lang)} form={form} xsMax />
                                <F.InputText maxLength={50} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.businessZip')} id={`businessZip${`_${lang}`}`} value={getFieldValue('businessZip', lang)} form={form} xsMax />
                            </React.Fragment>
                        )}

                        {/* <F.InputText maxLength={500} label='Map link' id='locationIcon' value={getFieldValue('locationIcon')} form={form} xsMax /> */}
                        <F.InputText maxLength={500} label='card.businessLocationLink' id='businessLocationLink' value={getFieldValue('businessLocationLink')} form={form} xsMax />

                        <F.InputGroup label='Working schedule' form={form} />
                        {/* <F.InputText multiline maxLength={150} label='Working hours' id='workingTime' autocomplete='given-name' value={getFieldValue('workingTime')} form={form} xsMax /> */}
                        {props.chosenLang.map((lang, index) =>

                            <F.InputText key={index} multiline maxLength={150} id={`workingHours${`_${lang}`}`} tlabel={lt[lang]('card.workingHours')} value={getFieldValue('workingHours', lang)} form={form} xsMax />
                        )}

                        {/* <F.InputGroup label='Working Hours' form={form} />
                        <F.Label label='Mon:' form={form} xsMax />
                        <F.InputText type='time' label='From' id='hoursMonFrom' value={getFieldValue('hoursMonFrom')} tplaceholder='00:00' form={form} xsMax size='sm' maxLength={50} />
                        <F.InputText type='time' label='To' id='hoursMonTo' value={getFieldValue('hoursMonTo')} tplaceholder='23:59' form={form} xsMax size='sm' maxLength={50} />

                        <F.Label label='Tues:' form={form} xsMax />
                        <F.InputText type='time' label='From' id='hoursTuesFrom' value={getFieldValue('hoursTuesFrom')} tplaceholder='00:00' form={form} xsMax size='sm' maxLength={50} />
                        <F.InputText type='time' label='To' id='hoursTuesTo' value={getFieldValue('hoursTuesTo')} tplaceholder='23:59' form={form} xsMax size='sm' maxLength={50} />

                        <F.Label label='Wed:' form={form} xsMax />
                        <F.InputText type='time' label='From' id='hoursWedFrom' value={getFieldValue('hoursWedFrom')} tplaceholder='00:00' form={form} xsMax size='sm' maxLength={50} />
                        <F.InputText type='time' label='To' id='hoursWedTo' value={getFieldValue('hoursWedTo')} tplaceholder='23:59' form={form} xsMax size='sm' maxLength={50} />

                        <F.Label label='Thurs:' form={form} xsMax />
                        <F.InputText type='time' label='From' id='hoursThursFrom' value={getFieldValue('hoursThursFrom')} tplaceholder='00:00' form={form} xsMax size='sm' maxLength={50} />
                        <F.InputText type='time' label='To' id='hoursThursTo' value={getFieldValue('hoursThursTo')} tplaceholder='23:59' form={form} xsMax size='sm' maxLength={50} />

                        <F.Label label='Fri:' form={form} xsMax />
                        <F.InputText type='time' label='From' id='hoursFriFrom' value={getFieldValue('hoursFriFrom')} tplaceholder='00:00' form={form} xsMax size='sm' maxLength={50} />
                        <F.InputText type='time' label='To' id='hoursFriTo' value={getFieldValue('hoursFriTo')} tplaceholder='23:59' form={form} xsMax size='sm' maxLength={50} />

                        <F.Label label='Sat:' form={form} xsMax />
                        <F.InputText type='time' label='From' id='hoursSatFrom' value={getFieldValue('hoursSatFrom')} tplaceholder='00:00' form={form} xsMax size='sm' maxLength={50} />
                        <F.InputText type='time' label='To' id='hoursSatTo' value={getFieldValue('hoursSatTo')} tplaceholder='23:59' form={form} xsMax size='sm' maxLength={50} />

                        <F.Label label='Sun:' form={form} xsMax />
                        <F.InputText type='time' label='From' id='hoursSunFrom' value={getFieldValue('hoursSunFrom')} tplaceholder='00:00' form={form} xsMax size='sm' maxLength={50} />
                        <F.InputText type='time' label='To' id='hoursSunTo' value={getFieldValue('hoursSunTo')} tplaceholder='23:59' form={form} xsMax size='sm' maxLength={50} /> */}

                    </F.FormGrid>

                </AccordionDetails>
            </Accordion>


        </>
    )
}

