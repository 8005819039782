import {createRoot} from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import {GlobalStyles} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';

import globalStyles from 'theme/GlobalStyle';
import muiTheme from 'theme/MuiTheme';

import './i18n';

import HomePage from './home/HomePage';
import LoginPage from './pages/LoginPage';
import JoinPage from './pages/JoinPage';
import ContactUsPage from 'pages/ContactUsPage';
import UserCardsPage from 'pages/UserCardsPage';
import LoginForgotPage from 'auth/LoginForgotPage';
import JoinConfirmUserEmailPage from 'auth/JoinConfirmUserEmail';
import AwsConfirmUserPage from 'pages/AwsConfirmUserPage';
import {CardActivationPage} from 'pages/CardActivationPage';
import PasswordRecoverPage from 'auth/PasswordRecoverPage';

import UsersDetailsPage from 'pages/UsersDetailsPage';
import OrderCardPage from 'pages/OrderCardPage';
import OrdersListPage from 'pages/UserOrdersPage';
import TocPage from 'pages/TocPage';
import SuccessPayment from 'pages/SuccessPayment';
import SuccessJoiningPage from 'pages/SuccessJoining';

// Disable right click menu
document.oncontextmenu = function () {
    return false;
}


const inputGlobalStyles = <GlobalStyles styles={globalStyles} />;

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
    <ThemeProvider theme={muiTheme}>
        {inputGlobalStyles}

        <BrowserRouter>
            <Routes>
                <Route path='/' element={<HomePage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/user' element={<UsersDetailsPage />} />

                <Route path='/join' element={<JoinPage />} />
                <Route path='/join_confirm_user_email' element={<JoinConfirmUserEmailPage />} />

                <Route path='/confirmUser' element={<AwsConfirmUserPage />} />

                <Route path='/forgot' element={<LoginForgotPage />} />
                <Route path='/recover' element={<PasswordRecoverPage />} />

                <Route path='/order' element={<OrderCardPage />} />
                <Route path='/toc' element={<TocPage />} />

                <Route path='/cards' element={<UserCardsPage />} />
                <Route path='/orders' element={<OrdersListPage />} />
                <Route path='/contact' element={<ContactUsPage />} />

                <Route path='/successJoining' element={<SuccessJoiningPage />} />
                <Route path='/paymentCallback' element={<SuccessPayment />} />

                <Route path='/activate' element={<CardActivationPage />} />
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
);

