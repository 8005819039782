import React from 'react';

import {Stack} from '@mui/material';

import {PageNavItem} from './PageNavItem';
import Env from 'env';
import session_ from 'api/session';
import PageNavBarMobile from './PageNavBarMobile';
import {useTranslation} from 'react-i18next';

interface PageNavProps {
    current: string;
}
export default function PageNav(props: PageNavProps) {
    const [t] = useTranslation();

    if (Env.isMobile) {
        return (
            <>
                {session_.isLoggedIn && (
                    <Stack direction='row' alignItems='center' justifyContent='center'>
                        <PageNavBarMobile />
                    </Stack>
                )}
            </>
        );
    }

    return (
        <Stack direction='row' width='100%' alignItems='center' justifyContent='center' spacing={2}>
            {session_.isLoggedIn && (
                <>
                    <PageNavItem tag='home' title='Home' href='/' current={props.current} show={true} />
                    <PageNavItem
                        tag='cards'
                        title={t('My Cards')}
                        href='/cards'
                        current={props.current}
                        show={true}
                    />
                    <PageNavItem
                        tag='myOrders'
                        title={t('My Orders')}
                        href='/orders'
                        current={props.current}
                        show={true}
                    />
                </>
            )}
        </Stack>
    );
}
