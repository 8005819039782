import * as FT from '../FormTypes';

import FormFieldContainer from '../FormElements/FormFieldContainer';
import { Typography } from '@mui/material';


interface Props extends FT.FieldProps {
}


export function Spacer(props: Props) {

    if (props.show !== undefined && props.show === false) {
        return <></>;
    }

    if (props.ngHidden) {
        return (<></>);
    }

    return (
        <FormFieldContainer {...props}>
            &nbsp;
        </FormFieldContainer>
    );
}