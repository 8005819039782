import { styled } from "@mui/material";

// let mobWidth = 400
let mobWidth = 320
let mobHeight = 200
// let mobHeight = 250

export const KeyframeCard = styled("img")({

    position: "absolute",
    right: 320,
    top: 250,


    width: mobWidth,
    height: mobHeight,

    borderRadius: 25,
    // borderRadius: 20,
    content: `url('/images/card_types/business_card_front.svg')`,

    WebkitTransformStyle: 'flat',
    transformStyle: 'flat',

    "@keyframes pulsate": {

        '0%': {
            // transform: 'perspective(600px) translateZ(-100px) rotateY(10deg) rotateZ(-4deg) scale(1.1) rotateX(10deg)',
            transform: 'perspective(600px) translateZ(-160px) rotateY(10deg) rotateZ(-4deg) scale(1.1) rotateX(10deg)',
            filter: 'drop-shadow(-10px 10px 10px #555)',
        },

        '10%': {
            transform: 'perspective(600px) translateZ(-160px) rotateY(10deg) rotateZ(-4deg) scale(1.1) rotateX(10deg)',
            filter: 'drop-shadow(-10px 10px 10px #555)',    
        },
        

        // '65%': {
        '75%': {
            // right: 100,
            // top: 150,

            transform: 'perspective(600px) translateX(110px) translateY(-100px) translateZ(-160px) rotateY(30deg) rotateZ(-15deg) scale(0.9) rotateX(30deg)',
            filter: 'drop-shadow(0px 10px 10px #555)',
        },
        '80%': {
            // right: 100,
            // top: 150,

            transform: 'perspective(600px) translateX(110px) translateY(-100px) translateZ(-160px) rotateY(30deg) rotateZ(-15deg) scale(0.9) rotateX(30deg)',
            filter: 'drop-shadow(0px 10px 10px #555)',
        },
        '100%': {
            // right: 100,
            // top: 150,

            transform: 'perspective(600px) translateX(110px) translateY(-100px) translateZ(-160px) rotateY(30deg) rotateZ(-15deg) scale(0.9) rotateX(30deg)',
            filter: 'drop-shadow(0px 10px 10px #555)',
        },



    },

    animation: "pulsate 5s infinite alternate",
    // animation: "pulsate 2s infinite ease, spin 3s infinite ease",
    // animation: "pulsate 7s infinite linear",
    // animation: "move 3s infinite linear",
    // display: 'inline-block'
});