import React from 'react'
import {Alert, Box, Container, Grid, Typography} from '@mui/material'

import * as FT from '../FormTypes'

import {DialogTitleText} from '../DialogCaption'

interface Props extends FT.FormProps {}

export default function FormPageTitleImpl(props: Props) {

    if (!props.title && !props.ttitle) return <></>

    return (
        <Typography
            align='center'
            width='100%'
            fontSize='xx-large'
            //color={theme_.dialogTextColor}
            sx={{
                mb: 2,
            }}
        >
            <DialogTitleText title={props.title} ttitle={props.ttitle} />
        </Typography>
    )
}
