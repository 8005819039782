import React from 'react'

import * as Api from 'api/api'
// import * as UsersApi from 'api/users';
import {ApiUser} from 'api/users'
import * as AuthApi from 'api/auth'

import * as Form from 'tsui/Form'

import {
    Box,
    Button,
    Dialog,
    DialogTitle,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Stack,
    Typography,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import PageDialog from 'tsui/PageDialog'

// export interface SimpleDialogProps {
//     open: boolean;
//     selectedValue: string;
//     onClose: (value: string) => void;
//   }

interface UserDetailsTabProps {
    // userId: string;
    onClose: () => void
    // userCards: Array<string>;
    open: boolean
    email: string

    // selectedValue: string;
}

export default function SuccessfullyJoined(props: UserDetailsTabProps) {
    const form = Form.useForm({type: 'input'})

    const onResend = React.useCallback(() => {
        AuthApi.resendVerificationEmail({email: props.email!})
            .then((sess) => {
                // setStatus('Your verification code has been sent')
                // setDisabled(true);
            })
            .catch((err) => form.setError(err))
    }, [])

    return (
        <PageDialog type='panel' size='sm' title='EvoCard Join' onClose={props.onClose}>
            <Stack alignItems='center'>
                <Typography sx={{fontSize: 'large', padding: 1, color: '#d69710'}}>
                    Your verification link has been sent. Please check your email!
                </Typography>

                <Typography sx={{fontSize: 'small', color: '#616161'}}>
                    Haven't got one? click the button below:
                </Typography>

                <Button
                    variant='outlined'
                    onClick={onResend}
                    size='small'
                    sx={{color: '#e8a412', '&:hover': {backgroundColor: '#fdf7e8'}}}
                >
                    Resend
                </Button>
            </Stack>
        </PageDialog>
    )

    // return (
    //     <Dialog onClose={props.onClose} open={props.open} PaperProps={{ sx: { width: 500, height: 220, borderRadius: 5, border: 1.5, borderColor: '#e8a412' } }}>

    //     <Box sx={{width: '100%'}}>
    //         <Box sx={{float: 'right', mr: 1}}>
    //             <IconButton onClick={props.onClose} sx={{width: 40}}>
    //                 <CloseIcon />
    //             </IconButton>
    //         </Box>

    //         <Box sx={{mt: 7}}>
    //             <Typography sx={{textAlign: 'center', fontSize: 20, padding: 1, color: '#d69710'}}>Your verification link has been sent. Please check your email!</Typography>
    //         </Box>

    //         <Box sx={{display: 'grid', float: 'right', mt: 3, mr: 1, width: 300, justifyContent: 'end'}}>
    //             <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '0.8rem', color: '#616161' }}>Haven't got one? click the button below</Typography>

    //             <Box sx={{justifySelf: 'end'}}>
    //                 <Button onClick={onResend} sx={{color: '#e8a412', "&:hover": { backgroundColor: "#fdf7e8" } }} >Resend</Button>
    //             </Box>
    //         </Box>
    //     </Box>
    //     </Dialog>
    //   );
}
