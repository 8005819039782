import React from 'react';

import {FormImplProps} from './FormImpl';

import FormAlert from './FormAlert';
import {useTranslation} from 'react-i18next';

export default function FormAlertSection(props: FormImplProps) {
    // console.log('props', props);

    // if (props.form.inputFieldId) {
    //     for (let i of props.form.inputFieldId) {
            
    //     }
    // }

    const [t] = useTranslation();

    const [message, setMessage] = React.useState(props.form.message);

    React.useEffect(() => {
        //console.debug("on form message")

        if (props.form.error) {
            setMessage({
                text: t(props.form.error.message),
                severity: 'error',
            });
        } else {
            setMessage(props.form.message);
        }
    }, [props.form.error, props.form.message]);

    if (!message) {
        return <></>;
    }

    return <FormAlert message={message} />;
}
