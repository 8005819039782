import React from 'react';

import { Button, Stack, Typography, Box } from '@mui/material';

import PageContents from "components/PageContents";
import Env from 'env';
import ImgElement from 'tsui/DomElements/ImgElement';
import OrderConfirmationDialog from 'dialogs/OrderConfirmationDialog';
import { useTranslation } from 'react-i18next';
import * as Api from "api/api"
import { ErrorAlert } from 'components/ErrorAlert';
import ErrorAlertDialog from 'components/ErrorAlertDialog';
import * as GD from "data/GlobalDispatch"
import session_ from '../api/session';
import CreateAccount from '../dialogs/CreateAccount';
import { ShoppingCartDialog } from '../components/ShoppingCartDialog';



export default function OrderCardPage() {

    const [t] = useTranslation();

    const [orderModal, setOrderModal] = React.useState(false)
    const [productIdState, setProductIdState] = React.useState('');
    const [cardPrice, setCardPrice] = React.useState('');

    const [orderWithoutLoggedIn, setOrderWithoutLoggedIn] = React.useState(false)


    const [error, setError] = React.useState<Error | string | null>(null);
    const [warningMessage, setWarningMessage] = React.useState<string | null>(null);
    const [wngMessage, setWngMessage] = React.useState('')

    const [showCartDialog, setShowCartDialog] = React.useState(false);


    // const onAddToCart = React.useCallback(() => {
    //     Api.requestSession<any>('user', 'add', {}).then(d => {


    //     }).catch(err => setError(err));
    // }, [])



    const onOrderNewCard = React.useCallback((productId: string) => {
        if (!session_.isLoggedIn) {
            setWngMessage("home.create_or_signin_order")
            return;
        }


        setShowCartDialog(true)
        Api.requestSession<any>('cart', 'add', { productId: productId }).then(d => {

            GD.pubsub_.dispatch(GD.cardListenerId);

            if (d.answer) {

                setWarningMessage(d.answer)
            }
        }).catch(err => setError(err));
    }, []);


    if (Env.isMobile) {
        return <PageContents title="Contact Us" current='contact'>
            {/* {error && <ErrorAlert error={error} />} */}

            <Stack direction='column' width='100%' justifyContent='center' alignItems='center'>
                {/* <Box sx={{ display: 'flex', flexDirection: 'column', }}> */}
                {/* <ImgElement sx={{ position: 'absolute', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' />  svg/png */}

                <Box sx={{ display: 'grid', justifyItems: 'center', width: '80%', mt: 3, p: 3, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', backgroundColor: theme_.evocardFeatureBackgroundColor, borderRadius: theme_.cardBorderRadius }}>

                    <Button onClick={() => { setOrderModal(true); setProductIdState('1000'); setCardPrice(`${t('order.business_card_price')}`) }} sx={{ "&:hover": { backgroundColor: "transparent" }, backgroundColor: 'transparent', boxShadow: 'none', color: 'transparent' }}>
                        {/* <ImgElement src='images/B_account_card.png' sx={{ width: 300, ml: -2 }} /> */}
                        <Box
                            sx={{
                                mt: 2,
                            }}>
                            <ImgElement
                                src={Env.getCardTypeName('B', 'back')}
                                // sx={{ width: 480, ml: -2 }}
                                sx={{
                                    display: 'block',

                                    width: 230,
                                    borderRadius: 5,
                                    mt: -3,
                                    ml: 1,
                                    padding: 1,
                                    transform: 'perspective(1200px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                    transition: '0.6s ease all',
                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                }}
                            />
                            <ImgElement
                                src={Env.getCardTypeName('B', 'front')}
                                sx={{
                                    display: 'block',
                                    width: 230,
                                    borderRadius: 5,
                                    mt: -13,
                                    ml: 12,
                                    padding: 1,
                                    transform: 'perspective(1200px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                    transition: '0.6s ease all',
                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                }}
                            />
                        </Box>
                    </Button>
                    <Box sx={{ display: 'grid', justifyItems: 'center', mt: 2 }}>
                        <Typography sx={{ fontSize: 'x-large' }}>{t('Business Card')}</Typography>
                        {/* <Button variant='outlined' onClick={() => { setOrderModal(true); setCardType('1000'); setCardPrice(`${t('order.business_card_price')}`) }} sx={{ minWidth: 120, height: 40, mt: 1, fontSize: 'large', "&:hover": { backgroundColor: theme_.themeColor, color: 'white', borderColor: 'transparent' }, boxShadow: 'none', color: theme_.themeColor }}>{t('Order')}</Button> */}
                        <Button variant='outlined' onClick={() => { onOrderNewCard('1000') }} sx={{ minWidth: 120, height: 40, mt: 1, fontSize: 'middle', "&:hover": { background: theme_.buttonHoverColorGradient, borderColor: 'transparent' }, boxShadow: 'none' }}>{t('Add to Cart')}</Button>

                    </Box>
                </Box>

                <Box sx={{ display: 'grid', justifyItems: 'center', width: '80%', mt: 7, mb: 4, p: 3, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', backgroundColor: theme_.evocardFeatureBackgroundColor, borderRadius: theme_.cardBorderRadius }}>
                    <Button onClick={() => { setOrderModal(true); setProductIdState('1001'); setCardPrice(`${t('order.personal_card_price')}`) }} sx={{ "&:hover": { backgroundColor: "transparent" }, backgroundColor: 'transparent', boxShadow: 'none', color: 'transparent' }}>
                        <Box
                            sx={{
                                mt: 2,
                            }}>
                            <ImgElement
                                src={Env.getCardTypeName('P', 'back')}
                                // sx={{ width: 480, ml: -2 }}
                                sx={{
                                    display: 'block',

                                    width: 230,
                                    borderRadius: 5,
                                    mt: -3,
                                    ml: 1,
                                    padding: 1,
                                    // transform: 'perspective(1200px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                    transform: 'perspective(1200px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                    transition: '0.6s ease all',
                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                }}
                            />
                            <ImgElement
                                src={Env.getCardTypeName('P', 'front')}
                                sx={{
                                    display: 'block',
                                    width: 230,
                                    borderRadius: 5,
                                    mt: -13,
                                    ml: 12,
                                    padding: 1,
                                    transform: 'perspective(1200px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                                    transition: '0.6s ease all',
                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                }}
                            />
                        </Box>
                    </Button>
                    <Box sx={{ display: 'grid', justifyItems: 'center', mt: 2 }}>

                        <Typography sx={{ fontSize: 'x-large' }}>{t('Personal Card')}</Typography>
                        {/* <Button variant='outlined' onClick={() => { setOrderModal(true); setCardType('1001'); setCardPrice(`${t('order.personal_card_price')}`) }} sx={{  minWidth: 120, height: 40, mt: 1, fontSize: 'large', "&:hover": { backgroundColor: theme_.themeColor, color: 'white', borderColor: 'transparent' }, boxShadow: 'none', color: theme_.themeColor }}>{t('Add to Cart')}</Button> */}
                        <Button variant='outlined' onClick={() => { onOrderNewCard('1001') }} sx={{ minWidth: 120, height: 40, mt: 1, fontSize: 'middle', "&:hover": { background: theme_.buttonHoverColorGradient, borderColor: 'transparent' }, boxShadow: 'none' }}>{t('Add to Cart')}</Button>

                    </Box>

                </Box>
                {/* </Box> */}
            </Stack>

            <OrderConfirmationDialog show={orderModal} cardPrice={cardPrice} productId={productIdState} onSelected={() => { }} onClose={() => { setOrderModal(false) }} />
            {warningMessage && <ErrorAlertDialog message={warningMessage} onCloseNull={setWarningMessage} />}
            {wngMessage !== '' && <CreateAccount warningMessage={wngMessage} onClose={() => { setWngMessage('') }}></CreateAccount>}
            {showCartDialog && <ShoppingCartDialog addCardButtonStatus={showCartDialog} onClose={() => { setShowCartDialog(false) }} />}

        </PageContents >;

    }


    return <PageContents title="Order Card" current='order'>
        {/* {error && <ErrorAlert error={error} />} */}

        <Stack direction='column' width='100%' justifyContent='center' alignItems='center' sx={{ mb: 20 }}>
            {/* <Box sx={{ display: 'flex', flexDirection: 'column', }}> */}
            {/* <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' /> */}
            <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/pages_design/background_layout.svg' />

            <Box sx={{ display: 'flex', alignItems: 'center', zIndex: 1, width: 850, mt: 5, p: 2, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.evocardFeatureBackgroundColor, }}>

                <Button onClick={() => { setOrderModal(true); setProductIdState('1000'); setCardPrice(`${t('order.business_card_price')}`) }} sx={{ "&:hover": { backgroundColor: "transparent" }, backgroundColor: 'transparent', boxShadow: 'none', color: 'transparent' }}>
                    {/* <ImgElement src='images/B_account_card.png' sx={{ width: 480, ml: -2 }} /> */}
                    <Box
                        sx={{
                            // display: 'inline-block',
                            // marginLeft: 10,
                            // filter: 'drop-shadow(0px 0px 18px rgba(34, 33, 81, 0.25))',
                            // filter: 'blur(2px)',
                            // opacity: 0.5,
                            // filter: 'blur(0)',
                            textAlign: 'center',
                            borderRadius: 5,
                            marginTop: 5,

                            transform: 'perspective(1200px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                            transition: '0.6s ease all',
                            "&:hover": {
                                transform: 'perspective(800px) translateZ(-200px) rotateY(0deg) translateY(-10px) rotateX(0deg) scale(1)',
                                // filter: 'drop-shadow(0px 1px 10px #555)',
                                // opacity: 1,
                            }
                        }}>
                        <ImgElement
                            src={Env.getCardTypeName('B', 'back')}
                            // sx={{ width: 480, ml: -2 }}
                            sx={{
                                display: 'block',

                                width: 350,
                                borderRadius: 5,
                                mt: -3,
                                ml: 1,
                                padding: 1,
                                filter: 'drop-shadow(-10px 10px 10px #555)',
                            }}
                        />
                        <ImgElement
                            src={Env.getCardTypeName('B', 'front')}
                            sx={{
                                display: 'block',
                                width: 350,
                                borderRadius: 5,
                                mt: -20,
                                ml: 10,
                                padding: 1,
                                filter: 'drop-shadow(-10px 10px 10px #555)',
                            }}
                        />
                    </Box>
                </Button>
                <Box sx={{ display: 'grid', justifyItems: 'center', ml: 0 }}>
                    <Typography sx={{ fontSize: 'xx-large', width: 350, textAlign: 'center' }}>{t('Business Card')}</Typography>
                    {/* <Button variant='outlined' onClick={() => { setOrderModal(true); setCardType('B'); setCardPrice(`${t('order.business_card_price')}`) }} sx={{ minWidth: 130, height: 50, mt: 1, fontSize: 'x-large', "&:hover": { backgroundColor: theme_.themeColor, color: 'white', borderColor: 'transparent' }, boxShadow: 'none', color: theme_.themeColor }}>{t('Order')}</Button> */}
                    <Button variant='outlined' onClick={() => { onOrderNewCard('1000') }} sx={{ minWidth: 130, height: 50, mt: 1, fontSize: 'large', "&:hover": { background: theme_.buttonHoverColorGradient, color: 'white', borderColor: 'transparent' }, boxShadow: 'none' }}>{t('Add to Cart')}</Button>

                </Box>
            </Box>

            <Box sx={{ display: 'flex', zIndex: 1, alignItems: 'center', width: 850, mt: 7, p: 3, marginBottom: 3, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.evocardFeatureBackgroundColor, }}>
                <Button onClick={() => { setOrderModal(true); setProductIdState('1001'); setCardPrice(`${t('order.personal_card_price')}`) }} sx={{ "&:hover": { backgroundColor: "transparent" }, backgroundColor: 'transparent', boxShadow: 'none', color: 'transparent' }}>
                    {/* <ImgElement src='images/P_account_card.png' sx={{ width: 480, ml: -2 }} /> */}
                    <Box
                        sx={{
                            // display: 'inline-block',
                            // marginLeft: 10,
                            // filter: 'drop-shadow(0px 0px 18px rgba(34, 33, 81, 0.25))',
                            // filter: 'blur(2px)',
                            // opacity: 0.5,
                            // filter: 'blur(0)',
                            textAlign: 'center',
                            borderRadius: 5,
                            marginTop: 5,

                            transform: 'perspective(1200px) translateZ(-200px) rotateY(25deg) scale(0.9) rotateX(10deg)',
                            transition: '0.6s ease all',
                            "&:hover": {
                                transform: 'perspective(800px) translateZ(-200px) rotateY(0deg) translateY(-10px) rotateX(0deg) scale(1)',
                                // filter: 'drop-shadow(0px 1px 10px #555)',
                                // opacity: 1,
                            }
                        }}>
                        <ImgElement
                            src={Env.getCardTypeName('P', 'back')}
                            sx={{
                                display: 'block',
                                width: 350,
                                borderRadius: 5,
                                mt: -3,
                                ml: 1,
                                padding: 1,
                                filter: 'drop-shadow(-10px 10px 10px #555)',
                            }}
                        />
                        <ImgElement
                            src={Env.getCardTypeName('P', 'front')}
                            sx={{
                                display: 'block',
                                width: 350,
                                borderRadius: 5,
                                mt: -20,
                                ml: 10,
                                padding: 1,
                                filter: 'drop-shadow(-10px 10px 10px #555)',
                            }}
                        />
                    </Box>
                </Button>
                <Box sx={{ display: 'grid', justifyItems: 'center', ml: 0 }}>
                    <Typography sx={{ fontSize: 'xx-large', width: 350, textAlign: 'center' }}>{t('Personal Card')}</Typography>
                    {/* <Button variant='outlined' onClick={() => { setOrderModal(true); setCardType('1001'); setCardPrice(`${t('order.personal_card_price')}`) }} sx={{ minWidth: 130, height: 50, mt: 1, fontSize: 'x-large', "&:hover": { backgroundColor: theme_.themeColor, color: 'white', borderColor: 'transparent' }, boxShadow: 'none', color: theme_.themeColor }}>{t('Order')}</Button> */}
                    <Button variant='outlined' onClick={() => { onOrderNewCard('1001') }} sx={{ minWidth: 130, height: 50, mt: 1, fontSize: 'large', "&:hover": { background: theme_.buttonHoverColorGradient, borderColor: 'transparent' }, boxShadow: 'none' }}>{t('Add to Cart')}</Button>
                </Box>
            </Box>
            {/* </Box> */}
        </Stack>
        <OrderConfirmationDialog show={orderModal} cardPrice={cardPrice} productId={productIdState} onSelected={() => { }} onClose={() => { setOrderModal(false) }} />
        {warningMessage && <ErrorAlertDialog message={warningMessage} onCloseNull={setWarningMessage} />}
        {wngMessage !== '' && <CreateAccount warningMessage={wngMessage} onClose={() => { setWngMessage('') }}></CreateAccount>}
        {showCartDialog && <ShoppingCartDialog addCardButtonStatus={showCartDialog} onClose={() => { setShowCartDialog(false) }} />}

    </PageContents >;

}



