import React from 'react';

import {useNavigate} from 'react-router-dom';

import {Button, Stack, Typography, Box, Paper} from '@mui/material';

import { useTranslation } from 'react-i18next';

import PageContents from 'components/PageContents';
import Env from 'env';
import ImgElement from 'tsui/DomElements/ImgElement';

export default function TocPage() {
    const navigate = useNavigate();
    const [t] = useTranslation();

    return (
        <PageContents title='TOC' current='toc'>
            <Stack direction='column' width='100%' justifyContent='center' alignItems='center' sx={{mt: 2}}>
                <ImgElement
                    sx={{position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1}}
                    src='/images/background.svg'
                />{' '}
                {/* svg/png */}
                <Box
                    component={Paper}
                    sx={{
                        position: 'absolute',
                        bottom: Env.isMobile ? 50 : 50,
                        top: Env.isMobile ? 10 : 10,
                        left: Env.isMobile ? 10 : 250,
                        right: Env.isMobile ? 10 : 250,
                        p: 2,
                        textAlign: 'center',
                        boxShadow: '0 4px 8px 5px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    <Typography sx={{fontSize: 'xxx-large', fontWeight: 'bold'}}>TOC</Typography>
                    <iframe
                        src='/toc/terms-and-conditions.html'
                        height='80%'
                        width='100%'
                        frameBorder='0'
                        title='Iframe Example'
                    ></iframe>
                    <Button
                        onClick={() => navigate(`/join?agree=yes`)}
                        sx={{
                            position: 'aboslute',
                            bottom: 20,
                            mt: 5,
                            backgroundColor: '#FCB131',
                            borderRadius: 25,
                            
                            color: 'white',
                            width: 150,
                            height: 45,
                            fontSize: 'large',
                            '&:hover': {backgroundColor: '#fca031'},
                        }}
                    >
                        {t('toc.agree')}
                    </Button>
                </Box>
            </Stack>
        </PageContents>
    );
}
