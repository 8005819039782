//import './theme'

import "@fontsource/dejavu-serif"

const globalFonts = [
    //'Roboto', 
    'DejaVu Sans',
    '-apple-system',
    'BlinkMacSystemFont',
    'Segoe UI',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'sans-serif',
];

const codeFonts = ['source-code-pro', 'Menlo', 'Monaco', 'Consolas', 'Courier New', 'monospace'];

const globalStyles = {
     "*": {
         draggable: false,
        //WebkitTransformStyle: 'flat',
        //transformStyle: 'flat',
        // "-webkit-transform-style": 'flat',
        // "transform-style": 'flat',
    },

    body: {
        fontFamily: globalFonts.join(','),

        fontSmooth: 'auto',
        WebkitFontSmoothing: 'antialiased',
        MozOsxFontSmoothing: 'grayscale',

        //backgroundColor: '#000000',
        backgroundColor: '#242c37',

        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundAttachment: 'fixed',
        // backgroundImage: 'url("/images/page-background.png")',
        // background: `linear-gradient(to right, #3D3E42 , #2C2D31 , #1B1C21)`
        background: `linear-gradient(to right, #1B1C21 , #2C2D31 , #3D3E42)`,

        //WebkitTransformStyle: 'flat',
        //transformStyle: 'flat',


        // backgroundColor: theme_.pageBackgroundColor,

        // // minWidth: theme_.pageMinWidth,
        // // minHeight: theme_.pageMinHeight,
        // width: '100%',
        // height: '100%',
        // overflow: 'auto',
    },

    code: {
        fontFamily: codeFonts.join(','),
    },

    // "& input:autofill": {
    //     color: 'red',
    //     border: '1px solid darkorange',
    // },
    // "& input:-webkit-autofill": {
    //     backgroundColor: 'red',
    //     border: '1px solid darkorange',
    // },
};

export default globalStyles;
