import React from 'react';
import {useNavigate} from 'react-router-dom';

import {Box, Button, Grid, Stack, Typography} from '@mui/material';

import {useTranslation} from 'react-i18next';

import * as F from 'tsui/Form';

import * as AuthApi from 'api/auth';

import PageContents from 'components/PageContents';
import {InputFormContext} from 'tsui/Form/FormContext';
import Env from 'env';
import {ErrorAlert} from 'components/ErrorAlert';
import ImgElement from 'tsui/DomElements/ImgElement';
import {log} from 'console';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface LoginPageProps {
    return?: string;
}

export default function LoginPage(props: LoginPageProps) {
    const form = F.useForm({type: 'input'});
    const navigate = useNavigate();
    const [error, setError] = React.useState<Error | string | null>(null);

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        if (form.error) return;

        form.setBusy();
        let dataKeys = Object.keys(evt.data);
        let emptyFields = 0;
        // let inputFieldId: string | undefined = undefined;
        let inputFieldId: Array<string> = [];

        for (let key of dataKeys) {
            if (evt.data[key] === '') {
                emptyFields += 1;
                // inputFieldId = key;
                inputFieldId.push(key);

                // break;
            }

            if (key === 'login') {
                evt.data[key] = evt.data[key].replace(/\s/g, '').toLowerCase();
            }
            evt.data[key] = evt.data[key].replace(/\s/g, '');
        }

        setError('');

        if (emptyFields > 0 && inputFieldId) {
            // setError('You must fill all the fields');
            // form.setError('You must fill all the fields', inputFieldId);
            // form.setError('join.fill_the_filds', inputFieldId);
            form.setError('join.fill_the_filds', inputFieldId);
            form.clearBusy();
        } else {
            AuthApi.login(evt.data)
                .then((sess) => {
                    // console.log(evt.data)

                    form.clearBusy();
                    navigate(props.return ?? '/');
                })
                .catch((err) => {
                    console.log(err);

                    // form.setError(err);
                    inputFieldId.push('login');
                    inputFieldId.push('password');
                    console.log(err, inputFieldId);

                    form.setError(err, inputFieldId);
                });
        }
    }, []);

    return (
        <PageContents title='Login'>
            <ImgElement
                sx={{position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1}}
                src='images/background.svg'
            />{' '}
            {/* svg/png */}
            {Env.isMobile ? (
                <LoginFormBodyMobile form={form} onSubmit={onSubmit} error={error} />
            ) : (
                <LoginFormBody form={form} onSubmit={onSubmit} error={error} />
            )}
        </PageContents>
    );
}

interface LoginFormBodyProps {
    form: InputFormContext;
    onSubmit: (evt: F.InputFormEvent) => void;
    error: Error | string | null;
}

function LoginFormBody(props: LoginFormBodyProps) {
    const [t] = useTranslation();
    const [password, setPassword] = React.useState(false);

    //console.log({...(theme_.dialog.border ? theme_.dialog.border.css() : {})});

    return (
        <F.PageForm
            onSubmit={props.onSubmit}
            form={props.form}
            size='lg'
            layoutElement='none'
            formSx={theme_.dialog.sx()}
        >
            <Stack
                direction='row'
                width='100%'
                //height = '100%'
                spacing={1}
                alignItems='center'
            >
                <Stack
                    direction='column'
                    sx={{
                        display: 'flex',
                        // borderRadius: theme_.cardBorderRadius,
                        // background: theme_.dialogGreetingBackground,
                        width: '50%',
                        //height: '100%',
                        // height: '40vh',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 'xx-large',
                            textAlign: 'center',
                            width: '100%',
                        }}
                    >
                        {t('login.welcome_back')}
                    </Typography>

                    {/* <Typography
                        sx={{
                            fontSize: 'large',
                            textAlign: 'center',
                            width: '100%',
                            color: 'white',
                        }}
                    >
                        {t('Login')}
                    </Typography> */}
                </Stack>
                <Stack
                    direction='column'
                    spacing={1}
                    sx={{
                        width: '50%',
                        // p: 1,
                        // borderRadius: theme_.cardBorderRadius,
                        // backgroundColor: theme_.evocardFeatureBackgroundColor,
                        borderLeft: `2px solid ${theme_.themeColor}`,
                        px: 2,
                        py: 1,
                    }}
                >
                    {/* <Typography>{t('Login')} */}
                    <Typography
                        sx={{
                            fontSize: 'xx-large',
                            textAlign: 'center',
                            width: '100%',
                            mb: 2,
                        }}
                    >
                        {t('Login')}
                    </Typography>

                    <ErrorAlert error={props.error} />

                    <Stack sx={{py: theme_.betweenTextFieldSpacing}}>
                        <F.InputText
                            maxLength={100}
                            label='Email'
                            id='login'
                            autocomplete='username'
                            validate='email'
                            required
                            form={props.form}
                            xsMax
                        />
                    </Stack>

                    {/* <Box sx={{alignItems: 'center', width: '100%'}}> */}
                        <F.InputText
                            maxLength={100}
                            label='Password'
                            id='password'
                            type={password === false ? 'password' : 'text'}
                            autocomplete='current-password'
                            required
                            validate='not-empty'
                            form={props.form}
                            xsMax
                        />
                        {/* 
                        {password && <Button sx={{
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }} onClick={() => { setPassword(false) }}><VisibilityIcon /></Button>
                        }
                        {!password && <Button sx={{
                            "&:hover": {
                                backgroundColor: "transparent",
                            },
                        }} onClick={() => { setPassword(true) }}><VisibilityOffIcon /></Button>
                        } */}
                    {/* </Box> */}

                    {/* <F.InputCheckbox
                        id='rememberMe'
                        label='Remember Me'
                        value={false}
                        data={false}
                        form={props.form}
                        xsMax
                    /> */}

                    <Stack direction='column' alignItems='center' sx={{pt: 2}}>
                        <F.SubmitButton label='Login' form={props.form} xsMax />
                    </Stack>

                    <Stack direction='row' alignItems='center' width='100%' justifyContent='space-between' sx={{pt: 2}}>
                        <F.NavigateLink label='Register new account' href='/join' form={props.form} xsMax size='sm' />
                        <F.NavigateLink
                            prefix='forgot'
                            label='Password_forget'
                            href='/forgot'
                            form={props.form}
                            xsMax
                            size='sm'
                            align='right'
                        />
                    </Stack>
                </Stack>
            </Stack>
        </F.PageForm>
    );
}

function LoginFormBodyMobile(props: LoginFormBodyProps) {
    const [password, setPassword] = React.useState(false);

    return (
        <F.PageForm
            title='Login'
            onSubmit={props.onSubmit}
            form={props.form}
            size='sm'
            // formSx={theme_.dialog.sx()}
            formSx={{
                ...theme_.dialog.sx(),
                '@media screen and (orientation:landscape)': {
                    mt: 25,
                    width: '60%',
                    // height: 350,
                },
            }}
        >
            {/* <ErrorAlert error={props.error} /> */}

            <F.InputText
                maxLength={100}
                label='Email'
                id='login'
                autocomplete='username'
                validate='email'
                required
                form={props.form}
                xsMax
            />

            {/* <Box sx={{display: 'flex', alignItems: 'center', mt: 1, ml: 1.1, width: '100%', pt: 1}}> */}
                <F.InputText
                    maxLength={100}
                    label='Password'
                    id='password'
                    type={password === false ? 'password' : 'text'}
                    autocomplete='current-password'
                    required
                    validate='not-empty'
                    form={props.form}
                    xsMax
                />

                {/* {password && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPassword(false) }}><VisibilityIcon /></Button>
                }
                {!password && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPassword(true) }}><VisibilityOffIcon /></Button>
                } */}
            {/* </Box> */}

            <F.SubmitButton label='Login' form={props.form} xsMax />

            <Stack direction='row' alignItems='center' width='100%' justifyContent='space-between' sx={{pt: 2}}>
                <F.NavigateLink label='Register new account' href='/join' size='sm' form={props.form} />
                <F.NavigateLink
                    prefix='forgot'
                    label='Password_forget'
                    href='/forgot'
                    form={props.form}
                    // xsMax
                    size='sm'
                    align='right'
                    // mb={1}
                />
            </Stack>
        </F.PageForm>
    );
}
