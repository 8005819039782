import React from "react";

import { useTranslation } from "react-i18next"


import * as Form from 'tsui/Form';
import { Box, Typography } from "@mui/material";
import PageDialog from "tsui/PageDialog";


interface Props {

    message: string | null;
    message2?: string;
    onCloseNull: (_: null) => void;
}


export default function WarningDialog(props: Props) {

    const [t] = useTranslation()

    //const form = Form.useInputForm();

    // const [orderId, setOrderId] = React.useState(1000)

    // const onSubmit = React.useCallback((evt: Form.InputFormEvent) => {

    //     props.onClose();


    // }, []);

    if (!props.message)
        return <></>;

    // let dialogTitle = ''

    // if(props.message === 'You have already activated this card. Please check it in your cards page') {
    //     dialogTitle = 'Already Activated Card ID'
    // }  else if(props.message !== '') {
    //     dialogTitle = props.message
    // }
    //  else{
    //     dialogTitle = 'INCORECT CARD ID'
    // }


    return <PageDialog type='panel' title={"Warning!"} size='sm' onCloseNull={props.onCloseNull}>

        <Box sx={{ display: 'grid' }}>
            <Typography sx={{ color: 'error.main', fontSize: 'larger', m: 2 }}>{t(props.message)}</Typography>
            {props.message2 && <Typography sx={{ fontSize: 'larger' }}>{t(props.message2)}</Typography>}
        </Box>

    </PageDialog>


}

// function muiStyled(arg0: string) {
//     throw new Error("Function not implemented.");
// }
