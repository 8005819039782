import React from 'react';

import {useLocation, useNavigate, useParams} from 'react-router-dom';

import * as AuthApi from 'api/auth';

import * as F from 'tsui/Form';
import PageContents from 'components/PageContents';
import SuccessfullyJoined from 'dialogs/SuccessfullyJoined';
import {Box, Button, Checkbox, Grid, Link, Stack, Typography} from '@mui/material';
import Env from 'env';
import {InputFormContext} from 'tsui/Form/FormContext';
import {ErrorAlert} from 'components/ErrorAlert';
import {useTranslation} from 'react-i18next';
import ImgElement from 'tsui/DomElements/ImgElement';
import TocPageDialog from 'dialogs/TocDialog';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export default function JoinPage() {
    const [t] = useTranslation();

    const form = F.useForm({type: 'input'});
    const navigate = useNavigate();

    const [passwordOne, setPasswordOne] = React.useState(false);

    const [openToc, setOpenToc] = React.useState(false);

    const [tocState, setTocState] = React.useState(false);
    const tocStateRef = React.useRef(false);

    const [error, setError] = React.useState<Error | string | null>(null);

    const [isJoinTrue, setIsJoinTrue] = React.useState(false);
    const [enteredEmail, setEnteredEmail] = React.useState('');

    const params = useParams();
    const checkedInputId = params.cardId!;

    const search = useLocation().search;
    const urlParams = new URLSearchParams(search);
    const agree = urlParams.get('agree');

    const handleChange = React.useCallback((event) => {
        if (event.target.id === 'toc') {
            setTocState(event.target.checked);
            tocStateRef.current = event.target.checked;
        }
    }, []);

    React.useEffect(() => {
        if (agree === 'yes') {
            setTocState(true);
            tocStateRef.current = true;
        }
    }, [agree]);

    // if (agree === 'yes') {
    //     // setTocState(true);
    //     tocStateRef.current = true
    // }

    // if (agree === 'yes') {
    //     setTocState(true);
    //     tocStateRef.current = true
    //     return
    // }

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        setError('');

        if (!tocStateRef.current) {
            setError('check TOC');
            return;
        }

        if (form.error) return;

        let dataKey = Object.keys(evt.data);
        if (dataKey.length !== 0) {
            for (let ef of dataKey) {
                if (ef === 'email') {
                    evt.data[ef] = evt.data[ef].replace(/\s/g, '').toLowerCase();
                }
                evt.data[ef] = evt.data[ef].replace(/\s/g, '');
            }
        }

        let password1 = evt.notData['password1'];
        let password2 = evt.notData['password2'];

        const email = evt.data['email'];

        evt.data['password1'] = password1;
        evt.data['password2'] = password2;

        setEnteredEmail(email);
        const encodeEmail = encodeURIComponent(email);
        // const verificationCode = 1234
        // const url = `/join_confirm_user_email?email=${email}`

        // if(!password1 || password1 === '' || !password2 || password2 === '') {

        //     let dataKeys = Object.keys(evt.data);
        //     let emptyFields = 0;
        //     let inputFieldId: Array<string> = [];
        //     inputFieldId.push('password1')
        //     inputFieldId.push('password2')

        //     for (let key of dataKeys) {
        //         if (key === 'phoneNumber') {
        //             continue;
        //         }
        //         if (evt.data[key] === '') {
        //             console.log('evt.data[key]', key)
        //             emptyFields += 1;
        //             inputFieldId.push(key);
        //             // inputFieldId = key;
        //             // break;
        //         }
        //     }

        //     return form.setError('join.passwords_not_matching', inputFieldId);

        // }

        if (password1 !== password2 || !password1 || password1 === '' || !password2 || password2 === '') {
            form.setBusy();

            let dataKeys = Object.keys(evt.data);
            let emptyFields = 0;
            let inputFieldId: Array<string> = [];
            inputFieldId.push('password1');
            inputFieldId.push('password2');

            for (let key of dataKeys) {
                if (key === 'phoneNumber') {
                    continue;
                }
                if (evt.data[key] === '') {
                    console.log('evt.data[key]', key);
                    emptyFields += 1;
                    inputFieldId.push(key);
                    // inputFieldId = key;
                    // break;
                }
            }

            if (password1 !== password2) {
                return form.setError('join.passwords_not_matching', ['password1', 'password2']);
            } else {
                return form.setError('join.passwords_not_matching', inputFieldId);
            }
        }
        evt.data['password'] = password1;

        //form.clearError(); //setError(null);
        form.setBusy();

        let dataKeys = Object.keys(evt.data);
        let emptyFields = 0;
        let inputFieldId: Array<string> = [];
        // let inputFieldId: string | undefined = undefined;

        for (let key of dataKeys) {
            if (key === 'phoneNumber') {
                continue;
            }
            if (evt.data[key] === '') {
                // console.log('evt.data[key]',evt.data[key])

                emptyFields += 1;
                inputFieldId.push(key);
                // inputFieldId = key;
                // break;
            }
        }

        if (emptyFields > 0 && inputFieldId) {
            // setError('join.fill_the_filds');
            // form.clearBusy();
            // form.setError('join.fill_the_filds', inputFieldId);

            // for (let fieldId of inputFieldId) {
            // setError('You must fill all the fields');
            form.setError('join.fill_the_filds', inputFieldId);
            // }
            console.log(form);
        } else {
            AuthApi.join(evt.data)
                .then((sess) => {
                    form.clearBusy();
                    //navigate(props.return ?? '/');
                    // navigate(url);
                    // navigate('/login');
                    setIsJoinTrue(true);
                    // navigate('/sucsessJoining');
                })
                .catch((err) => form.setError(err));
        }
    }, []);

    const onCancelDialog = React.useCallback(() => {
        navigate('/login');
    }, []);

    return (
        <PageContents title='Join'>
            <ImgElement
                sx={{position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1}}
                src='images/background.svg'
            />{' '}
            {/* svg/png */}
            {Env.isMobile ? (
                <JoinFormBodyMobile
                    tocState={tocState}
                    onClose={() => {
                        setOpenToc(false);
                    }}
                    onOpen={() => {
                        setOpenToc(true);
                    }}
                    openToc={openToc}
                    handleChange={handleChange}
                    form={form}
                    onSubmit={onSubmit}
                    error={error}
                />
            ) : (
                <JoinFormBody
                    tocState={tocState}
                    onClose={() => {
                        setOpenToc(false);
                    }}
                    onOpen={() => {
                        setOpenToc(true);
                    }}
                    openToc={openToc}
                    handleChange={handleChange}
                    form={form}
                    onSubmit={onSubmit}
                    error={error}
                />
            )}
            {isJoinTrue && (
                <SuccessfullyJoined
                    email={enteredEmail}
                    open={isJoinTrue}
                    onClose={() => {
                        setIsJoinTrue(false);
                        onCancelDialog();
                    }}
                />
            )}
            {/* {isJoinTrue && <JoinConfirmUserEmailPage email={email} />} */}
            {openToc && (
                <TocPageDialog
                    onClose={() => {
                        setOpenToc(false);
                    }}
                />
            )}
        </PageContents>
    );
}

interface JoinFormBodyProps {
    form: InputFormContext;
    onSubmit: (evt: F.InputFormEvent) => void;
    handleChange: (evt) => void;
    onClose: () => void;
    onOpen: () => void;
    tocState: boolean;
    error: Error | string | null;
    openToc: boolean;
}

function JoinFormBody(props: JoinFormBodyProps) {
    const [t] = useTranslation();
    const [passwordOne, setPasswordOne] = React.useState(false);
    const [passwordSecond, setPasswordSecond] = React.useState(false);

    return (
        <F.PageForm
            onSubmit={props.onSubmit}
            form={props.form}
            size='lg'
            layoutElement='none'
            // formSx={theme_.dialog.sx()}
            formSx={{
                ...theme_.dialog.sx(),
                '@media screen and (max-width: 1600px)': {
                    mt: 10,
                    '@media screen and (max-width: 1200px)': {
                        mt: 25,
                    },
                },
            }}
        >
            <Stack direction='row' width='100%' spacing={1} alignItems='center'>
                <Box
                    sx={{
                        //backgroundColor: 'red',
                        width: '50%',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: 'xx-large',
                            textAlign: 'center',
                            width: '100%',
                            color: 'white',
                        }}
                    >
                        {t('join.welcome_text')}
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: 'large',
                            textAlign: 'center',
                            width: '100%',
                            color: 'white',
                        }}
                    >
                        {t('join.create_account')}
                    </Typography>
                </Box>

                {/* <Box
                    sx={{
                        backgroundColor: theme_.themeColor,
                        height: 600,
                        width: 2,
                    }}
                ></Box> */}
                <Grid
                    container
                    spacing={1}
                    sx={{
                        width: '50%',
                        borderLeft: `2px solid ${theme_.themeColor}`,
                        px: 1,
                    }}
                >
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                fontSize: 'xx-large',
                                textAlign: 'center',
                                width: '100%',
                                mb: 1,
                            }}
                        >
                            {t('Join')}
                        </Typography>

                        {/* <ErrorAlert error={props.error} /> */}
                    </Grid>

                    {/* <Form.PageForm title='Join' onSubmit={onSubmit} form={form} size='md' > */}

                    <Grid item xs={12} sx={{mt: theme_.betweenTextFieldSpacing}}>
                        <F.InputText
                            maxLength={100}
                            form={props.form}
                            id='email'
                            label='Email'
                            autocomplete='email'
                            required
                            validate='email'
                        />
                    </Grid>

                    {/* <Form.InputText form={form} xs={8} id='login' label='Email' autocomplete='email' required validate="email"/> //id='ligin' not working */}

                    <Grid item xs={12} sx={{mt: theme_.betweenTextFieldSpacing}}>
                        {/* <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}> */}
                        <F.InputText
                            maxLength={100}
                            form={props.form}
                            xs={8}
                            id='password1'
                            label='Password'
                            type={passwordOne === false ? 'password' : 'text'}
                            autocomplete='new-password'
                            required
                            validate='password'
                            data={false}
                        />
                        {/* {passwordOne && <Button sx={{
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                            }} onClick={() => { setPasswordOne(false) }}><VisibilityIcon /></Button>
                            }
                            {!passwordOne && <Button sx={{
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                            }} onClick={() => { setPasswordOne(true) }}><VisibilityOffIcon /></Button>
                            } */}
                        {/* </Box> */}
                    </Grid>

                    <Grid item xs={12} sx={{mt: theme_.betweenTextFieldSpacing}}>
                        {/* <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}> */}
                        <F.InputText
                            maxLength={100}
                            form={props.form}
                            xs={8}
                            id='password2'
                            label='Confirm Password'
                            type={passwordSecond === false ? 'password' : 'text'}
                            autocomplete='new-password'
                            required
                            validate='password'
                            data={false}
                        />
                        {/* {passwordSecond && <Button sx={{
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                            }} onClick={() => { setPasswordSecond(false) }}><VisibilityIcon /></Button>
                            }
                            {!passwordSecond && <Button sx={{
                                "&:hover": {
                                    backgroundColor: "transparent",
                                },
                            }} onClick={() => { setPasswordSecond(true) }}><VisibilityOffIcon /></Button>
                            } */}
                        {/* </Box> */}
                    </Grid>

                    {/* <F.InputGroup form={props.form} label={t('Full Name')} /> */}

                    <Grid item xs={6} sx={{mt: theme_.betweenTextFieldSpacing}}>
                        <F.InputText
                            maxLength={100}
                            form={props.form}
                            id='firstName'
                            label='First Name'
                            autocomplete='given-name'
                        />
                    </Grid>

                    <Grid item xs={6} sx={{mt: theme_.betweenTextFieldSpacing}}>
                        <F.InputText
                            maxLength={100}
                            form={props.form}
                            id='lastName'
                            label='Last Name'
                            autocomplete='family-name'
                        />
                    </Grid>

                    {/* <F.InputGroup form={props.form} label='Contact' optional /> */}
                    {/* <F.InputText form={props.form} xs={4} id='phoneAreaCode' label="Area Code" placeholder="Area Code" autocomplete='tel' validate='tel' /> */}

                    <Grid item xs={12} sx={{mt: theme_.betweenTextFieldSpacing}}>
                        <F.InputText
                            form={props.form}
                            xs={8}
                            id='phoneNumber'
                            label='Phone'
                            autocomplete='tel'
                            validate='tel'
                        />
                    </Grid>

                    <Grid item xs={12} sx={{mt: 2}}>
                        {/* <Typography>
                            {t('Before using our service you have to agree with our terms of conditions')}
                        </Typography> */}

                        <Box sx={{display: 'flex', alignItems: 'center', ml: -0.8}}>
                            <Checkbox id='toc' name='toc' onChange={props.handleChange} checked={props.tocState} />
                            {/* <Link href='/toc'>TOC</Link> */}
                            <Button onClick={props.onOpen} sx={{ml: -1}}>
                                {t('Terms and Conditions')}
                            </Button>

                        </Box>
                        <ErrorAlert error={props.error} />

                    </Grid>

                    <Grid item xs={12} justifyContent='center' display='flex'>
                        <F.SubmitButton label='Submit' size='lg' form={props.form} xsMax align='center' />
                    </Grid>

                    <Grid item xs={12} justifyContent='right' display='flex' sx={{mt: 2}}>
                        <F.NavigateLink label='Already have an account? Login' href='/login' form={props.form} />
                    </Grid>
                </Grid>
            </Stack>
        </F.PageForm>
    );
}

function JoinFormBodyMobile(props: JoinFormBodyProps) {
    const [t] = useTranslation();
    const [passwordOne, setPasswordOne] = React.useState(false);
    const [passwordSecond, setPasswordSecond] = React.useState(false);

    return (
        <F.PageForm
            title='Join'
            onSubmit={props.onSubmit}
            form={props.form}
            size='sm'
            formSx={{
                ...theme_.dialog.sx(),
                // '@media screen and (max-width: 1200px)': {
                mt: props.error ? 20 : 5,
                // }
                '@media screen and (orientation:landscape)': {
                    mt: props.error ? 65 : 50,
                    width: '60%',
                },
            }}
        >
            {/* <ErrorAlert error={props.error} /> */}

            <F.InputText
                maxLength={100}
                form={props.form}
                xs={12}
                id='email'
                label='Email'
                autocomplete='email'
                required
                validate='email'
            />

            {/* <Box sx={{display: 'flex', alignItems: 'center', mt: 1, ml: 1, width: '100%'}}> */}
            <F.InputText
                maxLength={100}
                form={props.form}
                xsMax
                id='password1'
                label='Enter Password'
                type={passwordOne === false ? 'password' : 'text'}
                autocomplete='new-password'
                required
                validate='password'
                data={false}
            />

            {/* {passwordOne && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPasswordOne(false) }}><VisibilityIcon /></Button>
                }
                {!passwordOne && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPasswordOne(true) }}><VisibilityOffIcon /></Button>
                } */}
            {/* </Box> */}

            {/* <Box sx={{display: 'flex', alignItems: 'center', mt: 1, ml: 1, width: '100%'}}> */}
            <F.InputText
                maxLength={100}
                form={props.form}
                xsMax
                id='password2'
                label='Confirm Password'
                type={passwordSecond === false ? 'password' : 'text'}
                autocomplete='new-password'
                required
                validate='password'
                data={false}
            />

            {/* {passwordSecond && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPasswordSecond(false) }}><VisibilityIcon /></Button>
                }
                {!passwordSecond && <Button sx={{
                    "&:hover": {
                        backgroundColor: "transparent",
                    },
                }} onClick={() => { setPasswordSecond(true) }}><VisibilityOffIcon /></Button>
                } */}
            {/* </Box> */}

            {/* <F.InputGroup form={props.form} label='Name' /> */}

            <F.InputText
                maxLength={100}
                form={props.form}
                xs={6}
                id='firstName'
                label='First Name'
                autocomplete='given-name'
            />

            <F.InputText
                maxLength={100}
                form={props.form}
                xs={6}
                id='lastName'
                label='Last Name'
                autocomplete='family-name'
            />

            <F.InputText form={props.form} xs={12} id='phoneNumber' label='Phone' autocomplete='tel' validate='tel' />

            {/* <Stack width='100%' sx={{ mt: 2 }}> */}
            {/* <Stack width='100%' sx={{ml:1, mt: 1}}> */}

            {/* <Typography sx={{ p: 1, ml: 1 }}>
                {t('Before using our service you have to agree with our terms of conditions')}
            </Typography> */}

            <Box sx={{width: '100%', ml: 0.5, display: 'flex', alignItems: 'center'}}>
                <Checkbox id='toc' name='toc' onChange={props.handleChange} checked={props.tocState} />
                {/* <Link href='/toc'>TOC</Link> */}
                <Button onClick={props.onOpen} sx={{ml: -1}}>
                    {t('Terms and Conditions')}
                </Button>
            </Box>
            <ErrorAlert error={props.error} />

            {/* </Stack> */}
            {/* </Stack> */}

            <F.SubmitButton label='Submit' form={props.form} xsMax />

            <F.NavigateLink
                label='Already have an account? Login'
                href='/login'
                form={props.form}
                xsMax
                mt={2}
                mb={1}
                align='center'
            />
        </F.PageForm>
    );
}
