// import React from 'react';

// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import Paper from '@mui/material/Paper';

// import { styled as muiStyled } from '@mui/system';

// import { useTranslation } from 'react-i18next';



// import * as Api from 'api/api';
// import * as F from 'tsui/Form';
// import { ApiCard } from 'api';
// import { Typography } from '@mui/material';

// import session_ from 'api/session';

// import OrderResultPopup from 'dialogs/OrderResultPopup';
// import { ErrorAlert } from 'components/ErrorAlert';
// import { ApiCardOrderResult } from 'api/orders';

// const ImageComponent = muiStyled('img')({});

// interface UserOrdersDetailsDialogProps {
//     show: boolean;
//     onCloseFalse: (_: boolean) => void;
//     cardType: string;
//     cardPrice: string;
//     onSelected: (key: boolean) => void;
// }

// export default function UserOrdersDetailsDialog(props: UserOrdersDetailsDialogProps) {
//     const form = F.useInputForm();
//     const [error, setError] = React.useState<Error | string | null>(null);

//     const [t] = useTranslation();

//     const [orderId, setOrderId] = React.useState('');

//     const [confirmModal, setConfirmModal] = React.useState(false);


//     const onSubmit = React.useCallback(() => {

//         //console.log(

//         if (props.cardType !== '') {
//             Api.requestSessionJson<ApiCardOrderResult>('order', 'place', { cardType: props.cardType })
//                 .then((d) => {
//                     setOrderId(d.orderId);
//                     setConfirmModal(true);
//                 })
//                 .catch(setError);

//             //alert('congratulations')
//         }

//         // <h1>asdasdas</h1>

//         // props.onClose();
//     }, [props.show]);

//     const onCancelDialog = React.useCallback((key: boolean) => {
//         props.onSelected(false);
//         props.onCloseFalse(false);
//     }, []);

//     if (!props.show)
//         return <></>;

//     return (
//         <F.PageFormDialog title='Order' form={form} size='md' onSubmit={onSubmit} onCloseFalse={props.onCloseFalse}>
//             <ErrorAlert error={error} />

//             {/* <Button onClick={() => { setOrderModal(true); onSubmit() }}>click</Button> */}

//             <iframe
//                 src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.1648474473104!2d44.49571821568435!3d40.20539587638864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd4409c33837%3A0xde22a5eef53f2d96!2zMTUgQXJhbSBLaGFjaGF0cmlhbiBTdCwgWWVyZXZhbiAwMDEyLCDQkNGA0LzQtdC90LjRjw!5e0!3m2!1sru!2s!4v1668174105643!5m2!1sru!2s"
//                 width="600"
//                 height="450"
//                 loading="lazy" >
//             </iframe>
//             <Typography sx={{ fontSize: 'large', marginTop: 5 }}>
//                 You can order card and when the card will ready, you can pick it up from our company (address Aram
//                 Khachatryan 15)
//             </Typography>

//             {confirmModal && (
//                 <OrderResultPopup
//                     open={confirmModal}
//                     orderId={orderId}
//                     onSelected={onCancelDialog}
//                     onClose={() => {
//                         setConfirmModal(false);
//                     }}
//                 />
//             )}
//         </F.PageFormDialog>
//     );
// }

// // function muiStyled(arg0: string) {
// //     throw new Error("Function not implemented.");
// // }



import React from "react";

import { Box, Button, Dialog, DialogTitle, Stack, Typography } from "@mui/material";
import { styled } from '@mui/system'
import { useTranslation } from "react-i18next"


import * as Form from 'tsui/Form'
import PageDialog from "tsui/PageDialog";
import Env from "env";


interface Props {

    onClose: () => void;
    orderId: string;
    orderPaid: boolean;
    cardType: string | null;

}

const IframeElement = styled("iframe")({})

export default function UserOrdersDetailsDialog(props: Props) {

    const form = Form.useForm({ type: 'input' })

    const [t] = useTranslation();

    if (props.orderPaid === false) {
        return (
            <PageDialog type='panel' size='sm' title='Order Details' onClose={props.onClose}>
                <Stack alignItems='center'>
                    
                    {props.cardType !== null && <Typography sx={{ fontSize: 'larger', my: 1 }}>{t(`${props.cardType} ${t(`Card`)}`)}</Typography>}

                    <Typography sx={{ fontSize: 'larger', my: 1 }}>{t('order.is_not_paid_pending')}</Typography>

                </Stack>
            </PageDialog>
        )
    }

    return (
        <PageDialog type='panel' size='sm' title='Order Details' onClose={props.onClose}>
            <Stack alignItems='center'>

                <Typography sx={{ fontSize: 'larger', my: 1 }}>{t('order.placed')}</Typography>
                <Typography sx={{ fontSize: 'larger', my: 1 }}>{t('order.order_id')}</Typography>

                <Typography sx={{ color: theme_.themeColor, fontSize: 'larger', my: 1 }}>{props.orderId}</Typography>


                <Typography sx={{ my: 1 }}>{t('order.followup')}</Typography>

                <IframeElement
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3047.1648474473104!2d44.49571821568435!3d40.20539587638864!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x406abd4409c33837%3A0xde22a5eef53f2d96!2zMTUgQXJhbSBLaGFjaGF0cmlhbiBTdCwgWWVyZXZhbiAwMDEyLCDQkNGA0LzQtdC90LjRjw!5e0!3m2!1sru!2s!4v1668174105643!5m2!1sru!2s"

                    loading="lazy"
                    sx={{
                        filter: 'invert(90%)',
                        width: Env.isMobile ? 300 : 400,
                        height: Env.isMobile ? 200 : 300
                    }}
                    frameBorder="0"
                />

            </Stack>
        </PageDialog>
    )
}
