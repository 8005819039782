import React from 'react';

import * as FT from '../FormTypes';

import FormFieldContainer from '../FormElements/FormFieldContainer';
import InputTextElement from '../FormElements/FormTextInputComponent';
import {useFormField} from '../FormElements/FormFieldHook';
import InputElement from '../../DomElements/InputElement';

//
// Linear input fields
//
//export type InputTextType = 'text' | 'checkbox' | 'password' | 'integer' | 'float' | 'date' | 'datetime' | 'time';


export interface InputTextMultilineProps {
    minRows?: number;
    maxRows?: number;
}

export interface InputTextProps extends FT.FieldProps {
    type?: FT.InputFieldType;

    placeholder?: string | boolean;
    tplaceholder?: string;
    autocomplete?: FT.AutoCompleteType;
    maxLength?: number;

    onEditButton?: (id: string) => string;

    multiline?: InputTextMultilineProps | number | boolean;
}

export function InputText(props: InputTextProps) {
    // console.log("I am here");
    const field = useFormField(props, {
        fieldType: props.type ?? 'text',
    });

    
    if (props.show !== undefined && props.show === false) {
        return <></>;
    }

    if (props.ngHidden) {
        return (
            <InputElement
                type='text'
                autoComplete='off'
                sx={{
                    visibility: 'hidden',
                    m: 0,
                    p: 0,
                    height: 0,
                    borderWidth: 0,
                    border: 'none',
                }}
            />
        );
    }

    return (
        <FormFieldContainer {...props}>
            <InputTextElement field={field} {...props} />
        </FormFieldContainer>
    );
}
