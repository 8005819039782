import * as FT from "../FormTypes";
import {validateForm} from "./FormValidate";

export function processFormSubmit(props: FT.FormProps) {
    let form = props.form;

    form.clearError();

    let ev = new FT.InputFormEvent();
    let dataCount = validateForm(form, ev);

    // if (form.formType === "update" && dataCount === 0) {
    //     form.setError("No changes made.");
    // }

    props.onSubmit && props.onSubmit(ev);

    // Reset original values
    for (let [_, field] of form.registeredFields) {
        field.origValue = field.value;
    }
}
