import React from 'react';

import {Box, Dialog, Link, Stack} from '@mui/material';

import {Area} from 'react-easy-crop/types';

import * as F from 'tsui/Form';
import * as Api from 'api';

import {ApiCard} from 'api';

import ImageCropper from './ImageCropper';

import * as CardsApi from 'api/cards';
import WarningDialog from 'dialogs/WarningDialog';
import Env from 'env';

import ProgressIndicator from 'tsui/ProgressIndicator';
import {ErrorAlert} from 'components/ErrorAlert';
import CardDetailsDialog from './UserCardDetailsModalB';
import DesktopCardDetailsPersonal from './personal/DesktopCardDetailsPersonal';
import DesktopDisplayPanel from './displayPanel/DesktopDisplayPanel';
import MobileDisplayPanel from './displayPanel/MobileDisplayPanel';
import MobileCardDetailsPersonal from './personal_mobile/MobileCardDetailsPersonal';
import MobileCardDetailsBusiness from './business_mobile/MobileCardDetailsBusiness';
import {CardDisplayData} from '../../../data/card_display_data';
import {useTranslation} from 'react-i18next';

interface CardsDetailsProps {
    cardId: string;
    cardType: string;
    onClose: () => void;
    onSelected: (key: boolean) => void;
}

function isIMage(fileName) {
    var fileExt = getFileExtension(fileName);
    var imagesExtension = ['png', 'jpg', 'jpeg'];
    if (imagesExtension.indexOf(fileExt) !== -1) {
        return fileExt;
    } else {
        return false;
    }
}

function getFileExtension(fileName) {
    let fileExtension = fileName.replace(/^.*\./, '');
    return fileExtension;
}

export default function UserCardDetailsModal(props: CardsDetailsProps) {
    const [t] = useTranslation();

    const [error, setError] = React.useState<Error | string | null>(null);

    // const [_, forceUpdate] = React.useReducer((x) => x + 1, 0)

    // const [selectedMenu, setSelectedMenu] = React.useState<string>('name');

    const [cropWindow, setCropWindow] = React.useState(false);
    const [croppedImageSize, setCroppedImageSize] = React.useState({});
    const croppedImageSizeRef = React.useRef<Area>();

    const imageExtRef = React.useRef('');
    // const [croppedArea, setCroppedArea] = React.useState(null)

    const form = F.useForm({type: 'update'});

    const [showImage, setShowImage] = React.useState('');

    const ownerDataRef = React.useRef(new CardDisplayData());
    const [dataReady, setDataReady] = React.useState(false);

    const langChangedRef = React.useRef(false);
    const [chosenLangEn, setChosenLangEn] = React.useState(true);
    const chosenLangEnRef = React.useRef(true);

    const [chosenLangAm, setChosenLangAm] = React.useState(false);
    const chosenLangAmRef = React.useRef(false);

    const [chosenLangRu, setChosenLangRu] = React.useState(false);
    const chosenLangRuRef = React.useRef(false);

    const [chosenLang, setChosenLang] = React.useState(['en']);

    const [fileName, setFileName] = React.useState<string>('Image file is not selected.');
    const filesRef = React.useRef<File[]>([]);
    const fileNameRef = React.useRef('');
    const inputFileRef: any = React.useRef(null);

    const [wrongCardIdInput, setWrongCardIdInput] = React.useState(false);
    const [warningMessage, setWarningMessage] = React.useState<string | null>(null);
    //const warningMessageRef = React.useRef('')

    const [progIndicator, setProgIndicator] = React.useState(false);
    const [imageUploadingIndicator, setImageUploadingIndicator] = React.useState('');

    const errorFieldNames2 = React.useRef(new Map<string, any>());

    // let errorFieldNames2 = new Map<string, any>();

    const getFieldAtt = React.useCallback(
        (lang: string, fieldName: string) => {
            let langItems = errorFieldNames2.current.get(lang);
            if (!langItems) return false;

            // let fieldLangName = fieldName + (lang === 'en' ? '' : '_' + lang);
            let fieldLangName = fieldName + lang;
            let field = langItems.get(fieldLangName);
            if (!field) return false;

            //console.log(langItems, field)
            return !field.checked || field.error;
        },
        [errorFieldNames2.current]
    );

    const handleError = React.useCallback((err: string | Error | null, errFieldName?: Array<string> | undefined) => {
        setProgIndicator(false);
        if (err !== null && errFieldName) form.setError(err, errFieldName);
        if (err !== null && !errFieldName) setError(err);

        // setError(err);
    }, []);

    const onClose = React.useCallback((event, reason) => {
        if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
        props.onClose();
    }, []);

    const onData = React.useCallback((apiData: Api.ApiCard) => {
        // console.log('apiData', apiData);

        //setOwnerData(new CardDisplayData(apiData));
        ownerDataRef.current = new CardDisplayData(apiData);

        let card = ownerDataRef.current;

        if (card.languages !== undefined) {
            if (card.languages.includes('ru')) {
                setChosenLangRu(true);
                chosenLangRuRef.current = true;
            }

            if (card.languages.includes('am')) {
                setChosenLangAm(true);
                chosenLangAmRef.current = true;
            }

            if (card.languages.includes('en')) {
                setChosenLangEn(true);
                chosenLangEnRef.current = true;
            }

            if (!chosenLangAmRef.current && !chosenLangRuRef.current) {
                setChosenLangEn(true);
                chosenLangEnRef.current = true;
            }
        } else {
            setChosenLangEn(true);
            chosenLangEnRef.current = true;
        }

        fileNameRef.current = getFieldValue('profileImageId');

        setDataReady(true);
        setChosenLang(ownerDataRef.current.languages);
    }, []);

    const getFieldValue = React.useCallback(
        (name: string, lang?: string) => {
            if (!ownerDataRef.current) return '';

            return ownerDataRef.current.getFieldValue(name, lang);
        },
        [dataReady]
    );

    const onSelectedFileChange = React.useCallback((e) => {
        const files = e.target.files;
        //console.debug(e.target.files)

        if (files && files.length) {
            const fileType = files.type;
            // console.log('fileType', fileType) //ex: zip, rar, jpg, svg etc.
            let file = files[0];

            let imageExt = isIMage(file.name);
            // console.log('imageExt', imageExt)
            if (imageExt !== false) {
                imageExtRef.current = imageExt;
                setCropWindow(true);
                // console.log('file', file)
                const reader = new FileReader();

                reader.readAsDataURL(file);
                reader.addEventListener('load', () => {
                    // setImage(reader.result);
                });

                filesRef.current = files;
                setFileName(file.name);
                // console.log(file.name)
                // console.log('typeof(file.name', typeof (file.name))
                // fileNameRef.current = file.name
            } else {
                setWarningMessage('Wrong image file extension!');
                setWrongCardIdInput(true);
            }
        }
    }, []);

    const onBrowseClick = () => {
        inputFileRef.current.click();
    };

    const onRemove = () => {
        let imageName: string;
        if (showImage !== '') {
            imageName = showImage;
        } else {
            imageName = fileNameRef.current;
        }
        setImageUploadingIndicator(`${t('Removing')}`);

        Api.requestSessionJson<any>('user', 'remove_profile_image', {cardId: props.cardId, userImageId: imageName}, {})
            .then((d) => {
                setImageUploadingIndicator('');
                fileNameRef.current = '';
                setShowImage('');
                setFileName('Image file is not selected.');
            })
            .catch(handleError);
    };

    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {
        // console.debug('evt', evt);

        let dataKeys = Object.keys(evt.data);

        if (dataKeys.length === 0 && !langChangedRef.current) {
            props.onClose();
            return;
        }

        let checkFacebook: string | undefined = undefined;

        if (dataKeys.length !== 0) {
            for (let ef of dataKeys) {
                if (ef === 'email') {
                    evt.data[ef] = evt.data[ef].replace(/\s/g, '').toLowerCase();
                }

                if (ef === 'facebook' && (evt.data[ef] !== undefined || evt.data[ef] !== '')) {
                    checkFacebook = evt.data[ef];
                }
                if (ef === 'facebook' && evt.data[ef] === '') {
                    checkFacebook = undefined;
                }
                evt.data[ef] = evt.data[ef].trim();
            }
        }

        let ownerData = ownerDataRef.current!;

        if (ownerData.fields === undefined) {
            ownerData.fields = [];
        }

        for (let formFieldName of dataKeys) {
            let fieldComps = formFieldName.split('_');
            let fieldName = fieldComps[0];
            let fieldLang = fieldComps.length > 1 ? fieldComps[1] : undefined;

            let fieldValue = evt.data[formFieldName];

            let fieldFound = false;

            for (let field of ownerData.fields) {
                // if (field.name !== fieldName && field.lang === fieldLang) {
                // }

                if (field.name === formFieldName && field.lang === fieldLang) {
                    field.value = fieldValue;
                    fieldFound = true;
                }
            }

            if (!fieldFound) {
                ownerData.fields.push({
                    name: formFieldName,
                    value: fieldValue,
                    lang: fieldLang,
                });
            }
        }

        let errorFieldNames: any[] = [];

        errorFieldNames2.current.clear();

        for (let language of ownerData.languages) {
            // let langSuffix = language === 'en' ? '' : `_${language}`;
            let langSuffix = `_${language}`;

            errorFieldNames.push({
                lang: language,
                field: `firstName${langSuffix}`,
                message: 'First Name',
                checked: false,
                error: false,
            });

            errorFieldNames.push({
                lang: language,
                field: `lastName${langSuffix}`,
                message: 'Last Name',
                checked: false,
                error: false,
            });

            let fieldsMap = new Map<string, any>();

            fieldsMap.set(`firstName${langSuffix}`, {
                message: 'First Name',
                checked: false,
                error: false,
            });

            fieldsMap.set(`lastName${langSuffix}`, {
                message: 'Last Name',
                checked: false,
                error: false,
            });

            errorFieldNames2.current.set(language, fieldsMap);
        }

        for (let field of ownerData.fields) {
            for (let errFieldName of errorFieldNames) {
                if (field.name === errFieldName.field) {
                    errFieldName.checked = true;

                    if (field.value === '' || field.value === undefined) {
                        errFieldName.error = true;
                    }
                }
            }

            for (let [lang, fieldNames] of errorFieldNames2.current) {
                for (let [fieldName, fieldData] of fieldNames) {
                    if (field.name === fieldName) {
                        fieldData.checked = true;

                        if (field.value === '' || field.value === undefined) {
                            fieldData.error = true;
                        }
                    }
                }
            }
        }

        let errFieldName: Array<string> = [];

        for (let [_, fieldNames] of errorFieldNames2.current) {
            for (let [fName, fieldData] of fieldNames) {
                if (!fieldData.checked || fieldData.error) {
                    errFieldName.push(fName);

                    // handleError(`You have to fill ''${fieldData.message}'' field`, errFieldName);
                    handleError(`Fill red marked fields`, errFieldName);

                    // return;
                }
            }
        }
        if (errFieldName.length !== 0) return;

        setProgIndicator(true);

        let card = ownerDataRef.current;

        if (checkFacebook !== undefined) {
            Api.requestSession<any>('card', 'social_link', {name: 'facebook', text: checkFacebook}, {})
                .then((data) => {
                    if (data.profileUrl) {
                        for (let field of ownerData.fields) {
                            if (field.name === data.socialName) {
                                field.url = data.profileUrl;
                            }
                            // console.log('field', field)
                        }
                    } else {
                        handleError('Error: ERR_INVALID_URL', ['facebook']);
                    }

                    // if(!data.profileUrl) {
                    //     handleError('Error: ERR_INVALID_URL')
                    // } else {

                    //     requestUpdate(card)
                    // }

                    requestUpdate(card);
                })
                .catch((err) => handleError(err));
        } else {
            requestUpdate(card);
        }
    }, []);

    const requestUpdate = React.useCallback((card: CardDisplayData) => {
        Api.requestSessionJson<any>(
            'card',
            'update',
            {
                cardId: props.cardId,
            },
            {
                template: Env.defaultCardTemplate,
                fields: card.makeApiFields(),
                languages: card.languages,
            }
        )
            .then((d) => {
                langChangedRef.current = false;

                setProgIndicator(false);
                props.onSelected(false);

                props.onClose();
            })
            .catch(handleError);
    }, []);

    const onCropComplete = React.useCallback((croppedAreaPixels) => {
        setCroppedImageSize(croppedAreaPixels);
        croppedImageSizeRef.current = croppedAreaPixels;

        let files = filesRef.current;
        //console.log('files[0]', files[0])

        const formData = new FormData();
        formData.append('File', files[0], files[0].name);

        // TODO: implement through Env

        setImageUploadingIndicator('Loading');

        Api.requestSessionImage<any>(
            'user',
            'upload_profile_image',
            {
                cardId: props.cardId,
                width: croppedImageSizeRef.current!.width.toString(),
                height: croppedImageSizeRef.current!.height.toString(),
                y: croppedImageSizeRef.current!.y.toString(),
                x: croppedImageSizeRef.current!.x.toString(),
                imageExt: imageExtRef.current,
            },
            files[0]
        )
            .then((d) => {
                // console.log('d.userImageId', d.userImageId)
                fileNameRef.current = d.userImageId;
                setShowImage(d.userImageId);
                setImageUploadingIndicator('');
            })
            .catch(handleError);
    }, []);

    const handleLangChange = React.useCallback((event) => {
        let chosenLangArray: Array<string> = [];

        langChangedRef.current = true;

        if (event.target.id === 'en') {
            setChosenLangEn(event.target.checked);
            chosenLangEnRef.current = event.target.checked;
            //chosenLangArray.push('en')
        }

        if (event.target.id === 'am') {
            setChosenLangAm(event.target.checked);
            chosenLangAmRef.current = event.target.checked;
            //chosenLangArray.push('am')
        }

        if (event.target.id === 'ru') {
            setChosenLangRu(event.target.checked);
            chosenLangRuRef.current = event.target.checked;
            //chosenLangArray.push('ru')
        }

        if (!chosenLangEnRef.current && !chosenLangAmRef.current && !chosenLangRuRef.current) {
            setChosenLangEn(!event.target.checked);
            chosenLangEnRef.current = true; // !event.target.checked
            //chosenLangArray.push('en')

            setWarningMessage('Choose at least one language');
            setWrongCardIdInput(true);
        }

        if (chosenLangEnRef.current) {
            chosenLangArray.push('en');
        }

        if (chosenLangAmRef.current) {
            chosenLangArray.push('am');
        }

        if (chosenLangRuRef.current) {
            chosenLangArray.push('ru');
        }

        setChosenLang(chosenLangArray);

        ownerDataRef.current.languages = chosenLangArray;

        // ownerDataRef.current.languages = chosenLangArray;
        // Api.requestSession('card', 'update', {cardId: ownerDataRef.current.cardId}, {
        //     languages: ownerDataRef.current.languages,
        // });

        //console.log('chosenLangArray', chosenLangArray)
        // chosenLangArrayRef.current = chosenLangArray
    }, []);

    // fileNameRef.current = getFieldValue('profileImageId');

    // console.log('showImage', showImage)
    // console.log('fileName', fileNameRef.current)
    // console.log('props.cardType', props.cardType)
    // console.log('selectedMenu', selectedMenu)
    // console.log('ownerDataRef.current.languages', ownerDataRef.current.languages)

    return (
        <F.FormDataProvider<ApiCard>
            apiGroup='card'
            apiCommand='get'
            apiParams={{cardId: props.cardId}}
            onData={(d) => onData(d)}
            form={form}
        >
            <F.PageFormDialog
                title={`${t('Card')}: ${props.cardId}`}
                form={form}
                fromComp={true}
                size='lg'
                layoutElement='none'
                onSubmit={onSubmit}
                onClose={props.onClose}
                type='confirm'
                // height='80%'
            >
                <ErrorAlert error={error} />

                {Env.isMobile && (
                    <Box sx={{width: '100%'}}>
                        <Box sx={{textAlign: 'center', display: 'grid'}}>
                            <MobileDisplayPanel
                                cardId={props.cardId}
                                imageUploadingIndicator={imageUploadingIndicator}
                                showImage={showImage}
                                fileNameRef={fileNameRef.current}
                                inputFileRef={inputFileRef}
                                chosenLangEn={chosenLangEn}
                                chosenLangAm={chosenLangAm}
                                chosenLangRu={chosenLangRu}
                                onSelectedFileChange={onSelectedFileChange}
                                handleChange={handleLangChange}
                                onBrowseClick={onBrowseClick}
                                onRemove={onRemove}
                            />
                        </Box>

                        <Box>
                            {props.cardType === 'B' && (
                                <MobileCardDetailsBusiness
                                    form={form}
                                    chosenLang={chosenLang}
                                    getFieldValue={getFieldValue}
                                    getFieldAtt={getFieldAtt}
                                />
                            )}

                            {props.cardType === 'P' && (
                                <MobileCardDetailsPersonal
                                    form={form}
                                    chosenLang={chosenLang}
                                    getFieldValue={getFieldValue}
                                    getFieldAtt={getFieldAtt}
                                />
                            )}
                        </Box>
                    </Box>
                )}

                {!Env.isMobile && (
                    <Stack width='100%' direction='row' height='100%' spacing={10}>
                        {/* <Stack width='100%' justifyContent='center'> */}
                        {props.cardType === 'B' && (
                            <CardDetailsDialog
                                form={form}
                                chosenLang={chosenLang}
                                getFieldValue={getFieldValue}
                                getFieldAtt={getFieldAtt}
                            />
                        )}

                        {props.cardType === 'P' && (
                            <DesktopCardDetailsPersonal
                                form={form}
                                chosenLang={chosenLang}
                                getFieldValue={getFieldValue}
                                getFieldAtt={getFieldAtt}
                            />
                        )}
                        {/* </Stack> */}

                        {/* <Stack direction='column' width='28%' alignItems='center'> */}
                        <DesktopDisplayPanel
                            card={ownerDataRef.current}
                            cardId={props.cardId}
                            imageUploadingIndicator={imageUploadingIndicator}
                            showImage={showImage}
                            fileNameRef={fileNameRef.current}
                            inputFileRef={inputFileRef}
                            chosenLangEn={chosenLangEn}
                            chosenLangAm={chosenLangAm}
                            chosenLangRu={chosenLangRu}
                            onSelectedFileChange={onSelectedFileChange}
                            handleChange={handleLangChange}
                            onBrowseClick={onBrowseClick}
                            onRemove={onRemove}
                        />
                        {/* </Stack> */}
                    </Stack>
                )}
            </F.PageFormDialog>

            {/* {showUserPageLink && <UserPageLink cardId={props.cardId} onClose={() => { setShowUserPageLink(false) }}></UserPageLink>} */}
            {cropWindow && (
                <ImageCropper
                    onCropComplete={onCropComplete}
                    onClose={() => {
                        setCropWindow(false);
                    }}
                    imageUrl={URL.createObjectURL(filesRef.current[0])}
                    cardId={props.cardId}
                />
            )}

            <WarningDialog message={warningMessage} onCloseNull={setWarningMessage} />

            {progIndicator && (
                <Dialog onClose={onClose} open={progIndicator} PaperProps={{sx: {width: 200, height: 200}}}>
                    <ProgressIndicator title='Updating...' />
                </Dialog>
            )}
        </F.FormDataProvider>
    );
}
