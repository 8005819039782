import React from 'react'

import * as FT from '../FormTypes'


function initFormField(props: FT.FieldProps, params: FT.FieldParams) {
    return props.form.registerField(props, params)
}

export function useFormField(props: FT.FieldProps, params: FT.FieldParams) {
    const fieldRef = React.useRef(initFormField(props, params))

    return fieldRef.current
}
