import React from 'react';

import { useNavigate } from 'react-router-dom';

import { Button, Stack, Typography, Box, Paper } from '@mui/material';
import { styled } from '@mui/material';


import * as F from 'tsui/Form';


import { useTranslation } from 'react-i18next';

import PageContents from 'components/PageContents';
import Env from 'env';
import ImgElement from 'tsui/DomElements/ImgElement';

interface Props {

    onClose: () => void;
    // onSelected: (key: boolean) => void;

}

let IframeElement = styled('iframe')({});


export default function TocPageDialog(props: Props) {
    const navigate = useNavigate();
    const [t] = useTranslation();

    const form = F.useForm({ type: 'input' });
    console.log('ss')
    const onSubmit = React.useCallback(() => {
        navigate(`/join?agree=yes`)
        props.onClose()
    }, []);


    return (
        <F.PageFormDialog title='Terms and Conditions' submitLabel={t('I agree') as string} form={form} fromComp={true} size='xl' onSubmit={onSubmit} onClose={props.onClose}>
            {/* <Stack direction='column' width='100%' height='700px' justifyContent='center' alignItems='center' sx={{ mt: 2 }}>
                <Typography sx={{ fontSize: 'xxx-large', fontWeight: 'bold' }}>TOC</Typography> */}

                <IframeElement
                    src='/toc/terms-and-conditions.html'
                    // height='100%'
                    // width='100%'
                    frameBorder='0'
                    sx={{
                        width: '100%',
                        height: '600px',
                        color: 'white'
                    }}
                />
            {/* </Stack> */}
        </F.PageFormDialog>
    );
}
