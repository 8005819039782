import React from "react";

import { Button, Link, Stack } from "@mui/material";

import PageDialog from "tsui/PageDialog";
import ImgElement from "tsui/DomElements/ImgElement";
import { useTranslation } from "react-i18next";


interface Props {

    onClose: () => void;
    cardId: string

}


export default function CardQrShowDialog(props: Props) {
    const [t] = useTranslation();

    return (

        <PageDialog type='panel' size='sm' title='Your Card QR' onClose={props.onClose}>
            <Stack alignItems='center'>
                <ImgElement sx={{ width: '50%', height: '50%' }} src={`https://evocard.me/c/${props.cardId}/card_qr_phone.png`} />

                <Button sx={{ mt: 3, "&:hover": { backgroundColor: '#d09310', color: theme_.themeColor }, backgroundColor: theme_.themeColor, boxShadow: 'none', color: theme_.themeColor }}>
                    <Link sx={{ textDecoration: 'none', color: 'white' }} href={`https://evocard.me/c/${props.cardId}/card_qr_phone.png`} download={`${props.cardId}.png`} target="_blank">
                        {t('Save to gallery')}
                    </Link>
                </Button>

            </Stack>
        </PageDialog>
    )

}
