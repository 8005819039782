import React from "react"

import { Box, Container } from "@mui/material"
import { Popper } from "@mui/material"
import {
    Select,
    InputLabel,
    InputBase,
    FormControl,
    MenuList,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material"

import * as i18n from "i18n"
import * as GD from "data/GlobalDispatch"

import session_ from "api/session"
import theme_ from "theme/Theme"

import { styled as muiStyled } from "@mui/system"

const ImageComponent = muiStyled("img")({})

interface Props {
    type: "button" | "popper"

    show?: boolean
    anchorRef?: any
    anchorId?: string
}

export default function LangSelector(props: Props) {
    const [currentLang, setCurrentLang] = React.useState(session_.lang)
    const th = theme_.homePageHeaderTheme
    const thLang = th.langSelector

    // console.log(currentLang)

    React.useEffect(() => {
        const langListener = () => {
            setCurrentLang(session_.lang)
        }

        GD.pubsub_.addListener(GD.langListenerId, langListener)

        return () => GD.pubsub_.removeListener(GD.langListenerId, langListener)
    }, [])

    const handleChange = React.useCallback((event: SelectChangeEvent) => {
        let lang = event.target.value as string
        session_.setLang(lang)
    }, [])

    if (props.type === "popper") {
        let open = props.show === false ? false : true
        let anchorEl = open ? document.getElementById(props.anchorId!) : null

        return (
            <Popper open={open} anchorEl={anchorEl} transition>
                <MenuList>
                    {i18n.supportedLangs_.map((lang) => (
                        <MenuItem key={lang.lang} value={lang.lang}>
                            {lang.label}
                        </MenuItem>
                    ))}
                </MenuList>
            </Popper>
        )
    }
    // console.log(thLang)

    if (!thLang.show) return <></>

    // return <Box sx={thLang.sxProps()}>
    // return <Box sx={{position: 'absolute', right: theme_.pageLangSelectorRight, top: theme_.pageLangSelectorTop}}>
    // return <Box>
    return (
        <FormControl
            sx={{
                minWidth: 100,
            //     position: thLang.position,
            //     left: 0,
            //     top: 0,
            //     right: 0,
            //     bottom: 0,

            //     //borderColor: theme_.pageTextColor,
            }}
        >
            <Select
                value={currentLang}
                onChange={handleChange}
                input={<SelectorInput />}
                sx={{
                    minWidth: 90,
                    ml: 3
                    // position: thLang.position,
                    // // width: 106,
                    // width: theme_.pageLangSelectorInsideWidth,
                    // left: 0,
                    // // top: 20,
                    // top: theme_.pageLangSelectorInsideTop,
                    // right: 0,
                    // bottom: 0,
                    // borderRadius: 0,
                    // zIndex: 1,
                }}
            >
                {i18n.supportedLangs_.map((lang) => (
                    <MenuItem key={lang.lang} value={lang.lang} sx={{}}>
                        <ImageComponent
                            sx={{ marginRight: 1, mt: -0.3 }}
                            src={lang.flag}
                        ></ImageComponent>
                        {lang.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
    // </Box>
}

const SelectorInput = muiStyled(InputBase)(({ theme }) => ({
    color: theme_.pageDarkModeTextColor,
    "& .MuiInputBase-input": {
        // border: '1px solid #ced4da',
        // paddingLeft: 8,
        display: 'flex',
        fontSize: 'small',
        paddingLeft: 10,
    },

    //'& .MuiSelectIcon': {
    "& svg": {
        color: theme_.pageDarkModeTextColor,
    },
}))
