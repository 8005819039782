import React from 'react';
import * as GD from "data/GlobalDispatch"

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { styled as muiStyled } from '@mui/system';

import { useTranslation } from 'react-i18next';



import * as Api from 'api/api';
import * as F from 'tsui/Form';
import { ApiCard } from 'api';
import { Typography } from '@mui/material';

import session_ from 'api/session';

import OrderResultPopup from 'dialogs/OrderResultPopup';
import { ErrorAlert } from 'components/ErrorAlert';
import { ApiCardOrderResult } from 'api/orders';
import Env from '../env';
import { ShoppingCartDialog } from '../components/ShoppingCartDialog';

const ImageComponent = muiStyled('img')({});

interface OrderProps {
    show: boolean;
    onClose: () => void;
    // onCloseFalse: (_: boolean) => void;
    productId: string;
    cardPrice: string;
    onSelected: (key: boolean) => void;
}

export default function OrderConfirmationDialog(props: OrderProps) {
    const form = F.useInputForm();
    const [error, setError] = React.useState<Error | string | null>(null);

    const [t] = useTranslation();

    const [orderId, setOrderId] = React.useState('');
    const [showCartDialog, setShowCartDialog] = React.useState(false);

    // const [confirmModal, setConfirmModal] = React.useState(false);

    const onSubmit = React.useCallback(() => {

        //console.log(

        if (props.productId) {

            // Api.requestSessionJson<ApiCardOrderResult>('order', 'place', {cardType: props.cardType})
            //     .then((d) => {
            //         setOrderId(d.orderId);
            //         setConfirmModal(true);
            //     })
            // .catch(setError);

            setShowCartDialog(true)
            Api.requestSession<any>('cart', 'add', { productId: props.productId }).then(d => {
                GD.pubsub_.dispatch(GD.cardListenerId);
                if (d.answer) {
                    setError('To add new cards in your cart you have to pay for the previous ones or change order')
                }
            }).catch(err => setError(err));
            //alert('congratulations')
        }

        // <h1>asdasdas</h1>

        // props.onClose();
    }, [props.show]);

    // const onCancelDialog = React.useCallback((key: boolean) => {
    //     props.onSelected(false);
    //     props.onCloseFalse(false);
    // }, []);

    const onClose = React.useCallback(() => {
        setError(null)
        props.onClose()
    }, [])

    if (!props.show)
        return <></>;

    return (
        <F.PageFormDialog submitLabel='Add to Cart' title='Order' form={form} size='md' onSubmit={onSubmit} onClose={onClose}>
            <ErrorAlert error={error} />

            {/* <Button onClick={() => { setOrderModal(true); onSubmit() }}>click</Button> */}
            <F.InputGroup label={t('order.your_order_details')} form={form} />
            <TableContainer component={Paper} sx={{
                mt: 1,
                ml: Env.isMobile ? 0 : 1.5,
                backgroundColor: theme_.evocardFeatureBackgroundColor,
                borderRadius: theme_.cardBorderRadius,
            }}>
                <Table sx={{ width: '100%', }} aria-label='simple table'>
                    <TableHead>
                        <TableRow sx={{ backgroundColor: theme_.tableInsideDialogBackgroundColor }}>
                            <TableCell sx={{ textAlign: 'center' }}>{t('order.card_type')}</TableCell>
                            <TableCell sx={{ textAlign: 'center' }} align='right'>
                                {t('order.card_quantity')}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }} align='right'>
                                {t('order.card_price')}
                            </TableCell>
                            {/* <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow key={props.productId} sx={{ '&:last-child td, &:last-child th': { border: 0, backgroundColor: theme_.evocardFeatureBackgroundColor } }}>
                            <TableCell sx={{ textAlign: 'center' }} component='th' scope='row'>
                                {props.productId === '1001' ? `${t('Personal')}` : `${t('Business')}`}
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }} component='th' scope='row'>
                                1
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }} align='right'>
                                {props.cardPrice}
                            </TableCell>
                            {/* <TableCell align="right">{row.fat}</TableCell> */}
                            {/* <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell> */}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            {/* <Typography sx={{ fontSize: 15, marginTop: 5 }}>
                {t('order.card_description_below')}
            </Typography> */}

            {/* {confirmModal && (
                <OrderResultPopup
                    open={confirmModal}
                    orderId={orderId}
                    onSelected={onCancelDialog}
                    onClose={() => {
                        setConfirmModal(false);
                    }}
                />
            )} */}
            {showCartDialog && <ShoppingCartDialog addCardButtonStatus={showCartDialog} onClose={() => { setShowCartDialog(false) }} />}
        </F.PageFormDialog>
    );


}

// function muiStyled(arg0: string) {
//     throw new Error("Function not implemented.");
// }
