import React from "react";

import * as F from 'tsui/Form';


import PageContents from "components/PageContents";

import * as AuthApi from 'api/auth'

import * as Page from "../components/PageContainer";
import { useNavigate } from "react-router-dom";
import { InputFormContext } from "tsui/Form/FormContext";
import { Box, Stack, Typography } from "@mui/material";
import Env from "env";
import { ErrorAlert } from "components/ErrorAlert";
import ImgElement from "tsui/DomElements/ImgElement";
import { useTranslation } from "react-i18next";

function validateEmail(email: string) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default function LoginForgotPage() {

    const form = F.useForm({ type: 'input' });

    const navigate = useNavigate();

    const [error, setError] = React.useState<Error | string | null>(null);

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);

    const buttonRef = React.useRef<HTMLButtonElement | null | undefined>();


    const onSubmit = React.useCallback((evt: F.InputFormEvent) => {

        console.log('evt', evt)

        if (form.error)
            return;

        form.setBusy();

        let dataKeys = Object.keys(evt.data);
        let emptyFields = 0
        for (let key of dataKeys) {
            if (evt.data[key] === '') {
                emptyFields += 1
                break;
            }
        }

        if (emptyFields > 0) {
            setError('You must fill all the fields')
            form.clearBusy();

        } else {
            AuthApi.passwordRecoverFirstStep(evt.data).then((sess) => {
                console.log('sess', sess)
                if (sess) {
                    navigate(`/recover?email=${evt.data.email}`)
                }

                form.clearBusy();

            }).catch(err => form.setError(err));
        }
        // let login = document.getElementById("login") as HTMLInputElement
        // let loginValue = login.value.trim();
        // if (loginValue === '') {
        //     //console.log("There is no email")
        //     return alert('Login/Email is empty!');
        // }


        // let password = document.getElementById("password") as HTMLInputElement
        // let passwordValue = password.value.trim()
        // if (passwordValue === '') {
        //     return alert('Password is empty!');
        // }
        // return;
    }, []);


    return <PageContents title='Forgot Login'>
        <ImgElement sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, width: '100%', zIndex: -1 }} src='images/background.svg' />  {/* svg/png */}


        {Env.isMobile ? (
            <LoginForgotPageBodyMobile form={form} onSubmit={onSubmit} error={error} />
        ) : (
            <LoginForgotPageBody form={form} onSubmit={onSubmit} error={error} />
        )}

        {/* <F.PageForm title='Recover Login' onSubmit={onSubmit} form={form} size='lg' layoutElement='none'
        >

            <F.InputText label='Email' id='email' autocomplete='username' validate="email" required form={form} xsMax />
            {/* <Form.InputText label='Password' id='password' type='password' autocomplete="current-password" required validate='not-empty' form={form} xsMax /> */}

        {/* <Form.InputCheckbox id='rememberMe' label='Remember Me' checked={false} data={false} form={form} xsMax /> */}

        {/* <F.SubmitButton label='send' form={form} xsMax /> */}

        {/* <F.NavigateLink label='Register new account' href='/join' form={form} xsMax mt={2} /> */}
        {/* </F.PageForm>  */}


    </PageContents >;
}

interface LoginForgotPageBodyProps {
    form: InputFormContext
    onSubmit: (evt: F.InputFormEvent) => void
    error: Error | string | null
}

function LoginForgotPageBody(props: LoginForgotPageBodyProps) {
    const [t] = useTranslation();

    return <F.PageForm
        onSubmit={props.onSubmit}
        form={props.form}
        size='lg'
        layoutElement='none'
        formSx={theme_.dialog.sx()}
    >
        <Stack
            direction='row'
            width='100%'
            spacing={1}
            alignItems='center'

        >

            <Box
                sx={{
                    //backgroundColor: 'red',
                    width: '50%',
                }}
            >
                <Typography sx={{
                    fontSize: 'xx-large',
                    textAlign: 'center',
                    width: '100%',
                    color: 'white'
                }}>
                    {t('recover_login_text')}
                </Typography>

            </Box>
            <Stack
                direction='column' spacing={1.5}
                sx={{
                    width: '50%',
                    // backgroundColor: theme_.evocardFeatureBackgroundColor,
                    // borderRadius: theme_.cardBorderRadius,
                    borderLeft: `2px solid ${theme_.themeColor}`,
                    px: 1.5,
                }}
            >
                <Typography sx={{
                    fontSize: 'xx-large',
                    textAlign: 'center',
                    width: '100%',
                    mb: 2,
                }}>
                    {t('Recover Login')}
                </Typography>

                <ErrorAlert error={props.error} />

                <F.InputText maxLength={100} label='Email' id='email' autocomplete='username' validate="email" required form={props.form} xsMax />
                {/* <F.InputText label='Password' id='password' type='password' autocomplete="current-password" required validate='not-empty' form={props.form} xsMax /> */}

                {/* <F.InputCheckbox id='rememberMe' label='Remember Me' checked={false} data={false} form={form} xsMax /> */}
                <Stack direction='column' alignItems='center'>

                    <F.SubmitButton label='Send' form={props.form} xsHalf />
                </Stack>

                <F.NavigateLink label='Register new account' href='/join' form={props.form} xsMax mt={2} />
            </Stack>
        </Stack>
    </F.PageForm >

}

function LoginForgotPageBodyMobile(props: LoginForgotPageBodyProps) {
    const [t] = useTranslation();

    return <F.PageForm title={t('Recover Login')!}
        onSubmit={props.onSubmit}
        form={props.form}
        size='sm'
        // formSx={theme_.dialog.sx()}
        formSx={{
            ...theme_.dialog.sx(),
                '@media screen and (orientation:landscape)': {
                    mt: 13,
                    width: "60%",
                    
                }
        }}
    >
        <ErrorAlert error={props.error} />

        <F.InputText maxLength={100} label='Email' id='email' autocomplete='username' validate="email" required form={props.form} xsMax />
        {/* <F.InputText label='Password' id='password' type='password' autocomplete="current-password" required validate='not-empty' form={props.form} xsMax /> */}

        {/* <F.InputCheckbox id='rememberMe' label='Remember Me' checked={false} data={false} form={form} xsMax /> */}

        <F.SubmitButton label='Send' form={props.form} xsMax />

        <F.NavigateLink label='Register new account' href='/join' form={props.form} xsMax mt={2} />

    </F.PageForm >

}