import React from 'react';
import {Box, Grid, Skeleton} from '@mui/material';

import * as FT from '../FormTypes';

//interface GridItemProps extends FT.FormPropsBase, FT.FormInputGridProps, FT.InputSxProps {}

interface Props extends FT.FieldProps {
    needLoading?: boolean;
    needBox?: boolean;
    children?: React.ReactNode;
}

export default function FormFieldContainer(props: Props) {
    return <FormFieldLayout {...props}>{props.children}</FormFieldLayout>;
}

function FormFieldLayout(props: Props) {
    if (props.form.layoutElement === 'none') {
        return <>{props.children}</>;
    }

    const gridAtts = FT.getFormGridAtts(props);

    // TODO: figure out dialog grid spacing
    //const gridSx = FT.getFormSxParams(props, {mt: theme_.formGridSpacing, ml: theme_.formGridSpacing});
    const gridSx = FT.getFormSxParams(props);

    return (
        <Grid item {...gridAtts} sx={gridSx}>
            <FormFieldState {...props}>{props.children}</FormFieldState>
        </Grid>
    );
}

function FormFieldState(props: Props) {
    const isSkeleton = (props.needLoading === undefined || props.needLoading === true) && props.form.isLoading;

    if (isSkeleton) {
        return (
            <Skeleton animation='wave'>
                <FormFieldBox {...props}>{props.children}</FormFieldBox>
            </Skeleton>
        );
    }

    return <FormFieldBox {...props}>{props.children}</FormFieldBox>;
}

function FormFieldBox(props: Props) {
    if (props.needBox) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: props.align,
                    height: '100%',
                    alignItems: 'center',
                }}
            >
                {props.children}
            </Box>
        );
    }

    return <>{props.children}</>;
}
