import React from 'react'

import {Grid} from '@mui/material'

import * as FT from './FormTypes'

interface GridProps
    extends FT.FormPropsBase,
        FT.FormInputGridProps,
        FT.InputSxProps,
        FT.DataFieldsProps {
    hidden?: boolean;
    children?: React.ReactNode
}

export function FormGrid(props: GridProps) {

    //console.debug(theme_.formGridSpacing)
    
    return (
        <Grid
            container
            spacing={theme_.formGridSpacing}
            //alignItems='center'
            sx={{
                //display: 'flex',
                flexGrow: 1,
                display: props.hidden === true ? 'none' : undefined,
            }}
        >
            {props.children}
        </Grid>
    )
}
