import React from 'react';
import * as Api from 'api';
import Env from 'env';
import { useNavigate, useParams, useLocation } from "react-router-dom";

import * as Form from 'tsui/Form';
import {
    Box,
    Button,
    FormControl,
    InputLabel,
    Link,
    MenuItem,
    Radio,
    Select,
    SelectChangeEvent,
    Typography,
} from '@mui/material';
import {ApiUser} from 'api/users';
import * as UsersApi from 'api/users';
import PageDialog from 'tsui/PageDialog';

import {useTranslation} from 'react-i18next';

interface AddUserProps {
    // userId: string;
    onClose: () => void;
    warningMessage: string;
}

export default function CreateAccount(props: AddUserProps) {
    const [t] = useTranslation();
    const navigate = useNavigate();

    // return <Form.NoActionDialogInputForm title='CREATE AN ACCOUNT OR SIGN IN' form={form} size='md' onClose={props.onClose}>
    return (
        // <PageDialog type='confirm' title='CREATE AN ACCOUNT OR SIGN IN' size='sm' onClose={props.onClose}>
        <PageDialog type='panel' title='SIGN IN' size='sm' onClose={props.onClose}>
            <Box sx={{display: 'grid', width: '100%',}}>
                {/* <Typography sx={{fontSize: 'larger', marginTop: 3}}>{t(props.warningMessage)}</Typography> */}
                <Typography sx={{fontSize: 'larger', marginTop: 3, textAlign: 'center'}}>{t(props.warningMessage)}</Typography>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textAlign: 'center',
                        width: '100%',
                        mt: 2,
                    }}
                >
                    {/* <Link href='/join' sx={{ minWidth: 140, textDecorationColor: '#FCB131', color: '#FCB131' }}>{t('Create Account')}</Link> */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            textAlign: 'center',
                            width: Env.isMobile ? '100%' : '70%',
                            mt: Env.isMobile ? 0 : 1
                            // backgroundColor: 'red'
                            
                        }}
                    >
                        {/* <Button variant='outlined' sx={{fontSize: 'small'}}>{t('Create Account')}</Button> */}
                        <Button variant='outlined' onClick={() => navigate('/join')} sx={{fontSize: Env.isMobile ?  'small' : 'middle'}}>{t('Create Account')}</Button>

                        <Typography sx={{fontSize: Env.isMobile ?  'small' : 'middle'}}>{t('OR')}</Typography>
                        {/* <Link href='/login' sx={{ minWidth: 130, ml: 2, textDecorationColor: '#FCB131', color: '#FCB131' }}>{t('Sign In')}</Link> */}
                        {/* <Button variant='contained' sx={{fontSize: 'small',}}> */}
                        <Button variant='contained'  onClick={() => navigate('/login')} sx={{fontSize: Env.isMobile ?  'small' : 'middle'}}>

                            {t('Sign In')}
                        </Button>
                    </Box>
                </Box>
            </Box>
        </PageDialog>
    );
}
