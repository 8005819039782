import React from 'react';

import PageContents from "components/PageContents";
import ErrorMessage from 'tsui/ErrorMessage';

interface Props {
    error: Error
}

export default function ErrorPage(props: Props) {

    return <PageContents>
        <ErrorMessage error={props.error}/>
    </PageContents>

}

