import React from 'react';

import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';

import { Box } from '@mui/system';
import { Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';


import ImgElement from 'tsui/DomElements/ImgElement';

interface DesktopEvocardFeautresDisplayProps {
    selectedMenu: boolean
}

export default function DesktopEvocardFeautresDisplay(props: DesktopEvocardFeautresDisplayProps) {

    const [t] = useTranslation();
    const navigate = useNavigate();



    return (
        <>
            {!props.selectedMenu &&
                <Stack direction='column' spacing={10} sx={{ mt: 5 }}>
                    <Stack direction='row' width='100%' justifyContent='center' alignItems='center' spacing={20}>
                        <Stack >
                            <Box sx={{borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, width: 500, height: 200, display: 'grid' }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeDesktop }} src="/images/home_page_icons/nfc_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.nfc_chip")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.share_details")}</Typography>
                                </Box>
                            </Box>
                        </Stack>

                        <Stack >
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, width: 500, height: 200, display: 'grid' }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeDesktop }} src="/images/home_page_icons/mobile_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.plug_and_play")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.share_and_receive")}</Typography>
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>

                    <Stack direction='row' width='100%' justifyContent='center' alignItems='center' spacing={20}>

                        <Stack>
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, width: 500, height: 200, display: 'grid' }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeDesktop }} src="/images/home_page_icons/qr_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.qr_codes")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.scan_qr")}</Typography>
                                </Box>
                            </Box>
                        </Stack>
                        <Stack>
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, width: 500, height: 200, display: 'grid' }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeDesktop }} src="/images/home_page_icons/device_support_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.device_support")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.android_and_ios")}</Typography>
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>

                </Stack>
            }
            {props.selectedMenu &&
                <Stack direction='column' spacing={10} sx={{ mt: 5 }}>
                    <Stack direction='row' width='100%' justifyContent='center' alignItems='center' spacing={20}>
                        <Stack >
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, width: 500, height: 200, display: 'grid' }}>
                                <Box sx={{ alignSelf: 'center' }}>
                                    <ImgElement sx={{ height: theme_.featureIconSizeDesktop }} src="/images/home_page_icons/reduce_waste_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.reduce_waste")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.limit_carbon")}</Typography>
                                </Box>
                            </Box>
                        </Stack>
                        <Stack>
                            <Box sx={{borderRadius: theme_.cardBorderRadius,  backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, width: 500, height: 200, display: 'grid' }}>

                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeDesktop }} src="/images/home_page_icons/gain_an_edge_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.gain_edge")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.impress")}</Typography>
                                </Box>
                            </Box>
                        </Stack>

                    </Stack>

                    <Stack direction='row' width='100%' justifyContent='center' alignItems='center' spacing={20}>

                        <Stack >

                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, width: 500, height: 200, display: 'grid' }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeDesktop }} src="/images/home_page_icons/save_on_costs_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.save_on_costs")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.remove_line_item")}</Typography>
                                </Box>
                            </Box>
                        </Stack>
                        <Stack>
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, width: 500, height: 200, display: 'grid' }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeDesktop }} src="/images/home_page_icons/language_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.choose_different_language")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.several_languages")}</Typography>
                                </Box>
                            </Box>
                        </Stack>
                    </Stack>

                </Stack>
            }
        </>
    )
}