import React from 'react';

import {Box, Button, Card, CardContent, Typography} from '@mui/material';

import theme_ from 'theme/Theme';
import {CardBoxInit} from 'data/card';
import ImgElement from '../tsui/DomElements/ImgElement';
import {useTranslation} from 'react-i18next';
import Env from 'env';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import session_ from '../api/session';
import {useNavigate} from 'react-router-dom';
import CreateAccount from '../dialogs/CreateAccount';
import {ShoppingCartDialog} from './ShoppingCartDialog';

interface CardBoxProps {
    card: CardBoxInit;
}

export default function CardBox(props: CardBoxProps) {
    const [t] = useTranslation();
    const navigate = useNavigate();
    const [warningMessage, setWarningMessage] = React.useState('');
    const [orderWithoutLoggedIn, setOrderWithoutLoggedIn] = React.useState(false);

    const [chosenCardTypeId, setChosenCardTypeId] = React.useState('');

    const [value, setValue] = React.useState(0);
    const [phoneNumber, setPhoneNumber] = React.useState<string>('');

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // function handleChangeQuantity(event: React.ChangeEvent<HTMLInputElement>) {
    //     setPhoneNumber(event.target.value);
    // }

    const onNavigateLink = React.useCallback(() => {
        if (session_.isLoggedIn) {
            // navigate('/order');
            setChosenCardTypeId(props.card.cardId);

        } else {
            setOrderWithoutLoggedIn(true);
            setWarningMessage('home.create_or_signin_order');
        }
    }, []);

    return (
        <>
            <Card
                sx={[
                    // {
                    //     '&:hover': {
                    //         // height: 'auto',
                    //         boxShadow: theme_.cardBoxShadowHover,
                    //         backgroundColor: theme_.cardBackgroundColor,
                    //     },
                    // },

                    {
                        backgroundColor: theme_.homeBoxBackground,
                        // opacity: 0.5,
                        maxWidth: theme_.cardMaxWidth,
                        boxShadow: theme_.cardBoxShadow,
                        borderRadius: theme_.cardBorderRadius,
                        backdropFilter: theme_.cardBackdropFilter,
                        padding: theme_.cardPadding,
                        textTransform: theme_.cardTextTransform,
                        // mt: -15,
                        mt: 2,
                        '@media screen and (orientation:landscape)': {
                            mx: 0.5,
                        },
                    },
                ]}
            >
                {/* <Container className='container'> */}
                {/* <Card sx={{ p: 1, backgroundColor: theme_.homeBoxBackground, boxShadow: theme_.cardBoxShadow,}}> */}
                {Env.isMobile && (
                    <Box
                        sx={{
                            display: 'grid',
                            justifyItems: 'center',
                            width: '100%',
                            height: 400,
                            '@media screen and (orientation:landscape)': {
                                height: 450,
                            },
                            borderRadius: 3,
                            background: 'linear-gradient(to right bottom, #dedede, #ececec)',
                        }}
                    >
                        <ImgElement src='/images/company-logo.svg' sx={{width: '60%', mt: 2}} />
                        <Typography
                            sx={{
                                color: theme_.themeColor,
                                fontSize: '1.8em',
                                fontWeight: 'bold',
                                mt: -10,
                                '@media screen and (orientation:landscape)': {
                                    mt: -1,
                                    zIndex: 1,
                                },
                            }}
                        >
                            {t(props.card.cardTypeFullName)}
                        </Typography>
                        <Box
                            sx={{
                                marginTop: -6,
                                '@media screen and (orientation:landscape)': {
                                    mt: 3,
                                },
                                textAlign: 'center',
                                transform: 'perspective(800px) rotateY(0deg) translateY(-10px) rotateX(0deg) scale(1)',
                            }}
                        >
                            <ImgElement
                                src={Env.getCardTypeName(props.card.cardType, props.card.cardBackSide)}
                                sx={{
                                    display: 'block',
                                    mt: -3,
                                    ml: 3,
                                    width: '60%',
                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                    borderRadius: 2.5,
                                }}
                            />
                            <ImgElement
                                src={Env.getCardTypeName(props.card.cardType, props.card.cardFrontSide)}
                                sx={{
                                    display: 'block',
                                    mt: -10,
                                    ml: 13,
                                    width: '60%',
                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                    borderRadius: 2.5,
                                }}
                            />
                        </Box>
                    </Box>
                )}

                {!Env.isMobile && (
                    <Box
                        sx={{
                            display: 'grid',
                            justifyItems: 'center',
                            width: 380,
                            height: 500,
                            borderRadius: 3,
                            background: 'linear-gradient(to right bottom, #dedede, #ececec)',
                        }}
                    >
                        <ImgElement src='/images/company-logo.svg' sx={{width: '60%', mt: 2}} />
                        <Typography sx={{color: theme_.themeColor, fontSize: '1.8em', fontWeight: 'bold', mt: -10}}>
                            {t(props.card.cardTypeFullName)}
                        </Typography>
                        <Box
                            sx={{
                                marginTop: -6,
                                textAlign: 'center',
                                transform: 'perspective(1200px) rotateY(25deg) scale(0.9) rotateX(10deg)',

                                transition: '0.6s ease all',
                                '&:hover': {
                                    transform:
                                        'perspective(800px) rotateY(0deg) translateY(-10px) rotateX(0deg) scale(1)',
                                    // filter: 'drop-shadow(0px 1px 10px #555)',
                                    // opacity: 1,
                                },
                            }}
                        >
                            <ImgElement
                                src={Env.getCardTypeName(props.card.cardType, props.card.cardBackSide)}
                                sx={{
                                    display: 'block',
                                    mt: -3,
                                    ml: 4,
                                    width: '60%',
                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                    borderRadius: 2.5,
                                }}
                            />
                            <ImgElement
                                src={Env.getCardTypeName(props.card.cardType, props.card.cardFrontSide)}
                                sx={{
                                    display: 'block',
                                    mt: -10,
                                    ml: 15,
                                    width: '60%',
                                    filter: 'drop-shadow(-10px 10px 10px #555)',
                                    borderRadius: 2.5,
                                }}
                            />
                        </Box>
                    </Box>
                )}

                <CardContent>
                    <Typography align='center' gutterBottom variant='h5' component='div'>
                        {t(props.card.cardName)}
                    </Typography>
                    <Typography align='center' sx={{mb: 1.5}} color='text.secondary'>
                        {t(props.card.cardPrice)}
                    </Typography>
                    <Box sx={{display: 'flex'}}>
                        <Box sx={{ml: -3.5, width: Env.isMobile ? 150 : 200}}>
                            <Box sx={{display: 'flex'}}>
                                <ChevronRightIcon sx={{color: theme_.themeColor, fontSize: 35, mt: -1}} />
                                <Typography variant='body2' color='text.secondary'>
                                    {t(props.card.name)}
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex'}}>
                                <ChevronRightIcon sx={{color: theme_.themeColor, fontSize: 35, mt: -1}} />
                                <Typography variant='body2' color='text.secondary'>
                                    {t(props.card.compPos)}
                                </Typography>
                            </Box>

                            <Box sx={{display: 'flex'}}>
                                <ChevronRightIcon sx={{color: theme_.themeColor, fontSize: 35, mt: -1}} />
                                <Typography variant='body2' color='text.secondary'>
                                    {t(props.card.contact)}
                                </Typography>
                            </Box>
                            <Box sx={{display: 'flex'}}>
                                <ChevronRightIcon sx={{color: theme_.themeColor, fontSize: 35, mt: -1}} />
                                <Typography variant='body2' color='text.secondary'>
                                    {t(props.card.socialNetworks)}
                                </Typography>
                            </Box>
                        </Box>

                        <Box sx={{ml: Env.isMobile ? 0.5 : 2}}>
                            {props.card.compDescription && (
                                <Box sx={{display: 'flex', width: 100}}>
                                    <ChevronRightIcon sx={{color: theme_.themeColor, fontSize: 35, mt: -1}} />
                                    <Typography variant='body2' color='text.secondary'>
                                        {t(props.card.compDescription!)}
                                    </Typography>
                                </Box>
                            )}

                            {props.card.workingSchedule && (
                                <Box sx={{display: 'flex', width: 120}}>
                                    <ChevronRightIcon sx={{color: theme_.themeColor, fontSize: 35, mt: -1}} />
                                    <Typography variant='body2' color='text.secondary'>
                                        {t(props.card.workingSchedule!)}
                                    </Typography>
                                </Box>
                            )}

                            {props.card.address && (
                                <Box sx={{display: 'flex'}}>
                                    <ChevronRightIcon sx={{color: theme_.themeColor, fontSize: 35, mt: -1}} />
                                    <Typography variant='body2' color='text.secondary'>
                                        {t(props.card.address!)}
                                    </Typography>
                                </Box>
                            )}
                            {props.card.mapLink && (
                                <Box sx={{display: 'flex'}}>
                                    <ChevronRightIcon sx={{color: theme_.themeColor, fontSize: 35, mt: -1}} />
                                    <Typography variant='body2' color='text.secondary'>
                                        {t(props.card.mapLink!)}
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    {Env.isMobile && (
                        <Box
                            sx={{
                                width: '100%',
                                justifyContent: 'center',
                                display: 'grid',
                                mt: 3,
                                '@media screen and (orientation:landscape)': {
                                    // display: 'flex',
                                    mt: props.card.id === '2' ? 6.5 : 3,
                                },
                            }}
                        >
                            <Button
                                sx={{fontSize: Env.isMobile ? 'medium' : 'large'}}
                                variant='outlined'
                                onClick={onNavigateLink}
                            >
                                {t('Order')}
                            </Button>
                        </Box>
                    )}
                    {!Env.isMobile && (
                        <Box sx={{width: '100%', justifyContent: 'center', display: 'grid', mt: 3}}>
                            <Button
                                sx={{fontSize: Env.isMobile ? 'medium' : 'large'}}
                                variant='outlined'
                                onClick={onNavigateLink}
                            >
                                {t('Order')}
                            </Button>
                        </Box>
                    )}
                </CardContent>
            </Card>

            {/* </Container> */}
            {/* </Card> */}
            {orderWithoutLoggedIn && (
                <CreateAccount
                    warningMessage={warningMessage}
                    onClose={() => {
                        setOrderWithoutLoggedIn(false);
                        setWarningMessage('');
                    }}
                ></CreateAccount>
            )}

            {chosenCardTypeId !== '' && (
                <ShoppingCartDialog
                    onClose={() => {
                        setChosenCardTypeId('');
                    }}
                    cardTypeId={chosenCardTypeId}
                />
            )}
        </>
    );
}
