import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { InputFormContext } from 'tsui/Form/FormContext';
import * as F from 'tsui/Form';
import i18next from 'i18next';
import { supportedLangs_ } from '../../personal/DesktopCardDetailsPersonal';


interface BusinessManProps {
    form: InputFormContext
    chosenLang: Array<string>
    getFieldValue: (name: string, lang?: string) => string
    tab: string
    getFieldAtt: (lang: string, fieldName: string) => boolean

    // onSubmit: (evt: F.InputFormEvent) => void
    // error: Error | string | null
}

export default function BusinessCompany(props: BusinessManProps) {

    const [lt] = React.useState({
        en: i18next.getFixedT('en', 'profile'),
        am: i18next.getFixedT('am', 'profile'),
        ru: i18next.getFixedT('ru', 'profile'),
    })

    const getFieldValue = React.useCallback((name: string, lang?: string) => { return props.getFieldValue(name, lang) }, []);
    const form = props.form





    return (
        <F.FormGrid form={form} hidden={props.tab !== 'businessCompany'}>

            <F.InputGroup label='Company' form={form} />
            {supportedLangs_.map((lang, index) => <React.Fragment key={index}>
                    <F.InputText maxLength={70} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.companyName')} id={`companyName${`_${lang}`}`} value={getFieldValue('companyName', lang)} form={form} xs={6} />
                    <F.InputText maxLength={70} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.companyPosition')} id={`companyPosition${`_${lang}`}`} value={getFieldValue('companyPosition', lang)} form={form} xs={6} />
                </React.Fragment>
            )}

            {/* <F.InputGroup label='Business Description' form={form} /> */}
            {supportedLangs_.map((lang, index) =>
                <F.InputText multiline={2} key={index} show={props.chosenLang.includes(lang)} maxLength={500} id={`businessDescription${`_${lang}`}`} tlabel={lt[lang]('card.businessDescription')} value={getFieldValue('businessDescription', lang)} form={form} xs={12} />
            )}

            <F.InputText maxLength={200} label='Company Website' id='companyWebsite' value={getFieldValue('companyWebsite')} form={form} xs={6} />
            <F.InputText maxLength={200} label='Company Phone' id='companyPhone' value={getFieldValue('companyPhone')} form={form} xs={6} />


            <F.InputGroup label='Social Networks' form={form} />
            {/* <F.Spacer form={form} xs={6} /> */}
            <F.InputText maxLength={200} label='Facebook Business Page' id='facebookBusinessPage' value={getFieldValue('facebookBusinessPage')} form={form} xs={6} />
            {/* <F.Spacer form={form} xs={6} /> */}
            <F.InputText maxLength={200} label='Telegram Channel' id='telegramChannel' value={getFieldValue('telegramChannel')} form={form} xs={6} />


            <F.InputGroup label='Working schedule' form={form} />
            {supportedLangs_.map((lang, index) =>
                <F.InputText key={index} multiline={2} maxLength={150} show={props.chosenLang.includes(lang)} id={`workingHours${`_${lang}`}`} tlabel={lt[lang]('card.workingHours')} value={getFieldValue('workingHours', lang)} form={form} xs={4} />
            )}
            <F.Spacer form={form} xs={8}/>


            <F.InputGroup label='Address' form={form} />
            {supportedLangs_.map((lang, index) =>
                <React.Fragment key={index}>
                    <F.InputText maxLength={50} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.businessStreet')} id={`businessStreet${`_${lang}`}`} value={getFieldValue('businessStreet', lang)} form={form} xs={6} />
                    <F.InputText maxLength={50} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.businessCity')} id={`businessCity${`_${lang}`}`} value={getFieldValue('businessCity', lang)} form={form} xs={2.5} />
                    <F.InputText maxLength={50} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.businessCountry')} id={`businessCountry${`_${lang}`}`} value={getFieldValue('businessCountry', lang)} form={form} xs={2} />
                    <F.InputText maxLength={50} show={props.chosenLang.includes(lang)} tlabel={lt[lang]('card.businessZip')} id={`businessZip${`_${lang}`}`} value={getFieldValue('businessZip', lang)} form={form} xs={1.5} />
                </React.Fragment>
            )}
            <F.InputText maxLength={500} label='card.businessLocationLink' id='businessLocationLink' value={getFieldValue('businessLocationLink')} form={form} xs={12} />

        </F.FormGrid>
    )
}

