import session_, {ApiSession} from 'api/session';
import * as Api from './api';

export function login(data: Record<string, string>): Promise<ApiSession> {
    return new Promise((resolve, reject) => {
        Api.requestPublic<ApiSession>('auth', 'login', data)
            .then((sess) => {
                session_.authenticate(sess);
                resolve(sess);
            })
            .catch(reject);
    });
}

export function authenticate(): Promise<ApiSession> {
    return new Promise((resolve, reject) => {
        Api.requestSession<ApiSession>('auth', 'authenticate')
            .then((sess) => {
                session_.authenticate(sess);
                resolve(sess);
            })
            .catch(reject);
    });
}

export function logout(): Promise<void> {
    return new Promise((resolve, reject) => {
        Api.requestSession('auth', 'logout');
        session_.logout();
        resolve();
    });
}

// export function changePassword(data: Record<string, string>): Promise<ApiSession> {

//     return new Promise((resolve, reject) => {

//         console.log(data)

//         Api.requestSession<ApiSession>('user', 'change_password', data).then(sess => {

//             // session_.authenticate(sess);
//             resolve(sess);
//         }).catch(reject);
//     });
// }

export function passwordRecoverFirstStep(data: Record<string, string>): Promise<ApiSession> {
    return new Promise((resolve, reject) => {
        Api.requestPublic<ApiSession>('auth', 'recover_first_step', data)
            .then((sess) => {
                resolve(sess);
            })
            .catch(reject);
    });
}

export function passwordRecoverLastStep(data: Record<string, string>): Promise<ApiSession> {
    return new Promise((resolve, reject) => {
        Api.requestPublic<ApiSession>('auth', 'recover_last_step', data)
            .then((sess) => {
                resolve(sess);
            })
            .catch(reject);
    });
}

export function join(data: Record<string, string>): Promise<ApiSession> {
    return new Promise((resolve, reject) => {
        Api.requestPublic<ApiSession>('auth', 'join', data)
            .then((sess) => {
                session_.authenticate(sess);
                resolve(sess);
            })
            .catch(reject);
    });
}

// export function confirmRegistration(data: Record<string, string>): Promise<ApiSession> {

//     return new Promise((resolve, reject) => {

//         console.log(data)

//         Api.requestShared<ApiSession>('auth', 'confirm_registration_code', data).then(sess => {

//             console.log('stage', sess)

//             // session_.authenticate(sess);
//             resolve(sess);
//         }).catch(reject);
//     });
// }

export function resendVerificationEmail(data: Record<string, string>): Promise<ApiSession> {
    return new Promise((resolve, reject) => {
        Api.requestPublic<ApiSession>('auth', 'resend_verification_email', data)
            .then((sess) => {
                resolve(sess);
            })
            .catch(reject);
    });
}

export function confirmUserEmail(data: Record<string, string>): Promise<ApiSession> {
    return new Promise((resolve, reject) => {
        Api.requestPublic<ApiSession>('auth', 'join_confirm_user_email', data)
            .then((sess) => {
                resolve(sess);
            })
            .catch(reject);
    });
}
