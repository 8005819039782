export type FormValueFunc = (p: any) => string;
export type FormValue = string | boolean | Date | FormValueFunc;

export function setFormValue(val: FormValue | undefined) {
    if (val === undefined) return '';

    if (typeof val === 'string') return val;

    if (val instanceof Date) return val.toLocaleString();

    return val ? '1' : '0';
}

export function getFormBoolValue(val: FormValue | undefined): boolean {
    if (val === undefined) return false;

    if (typeof val === 'string') return val === '1' ? true : false;

    if (typeof val === 'boolean') return val;

    return false;
}
