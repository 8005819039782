import React from 'react';

import { Box, Button, Checkbox, Grid, Stack, Typography } from '@mui/material';
import QrCode2Icon from '@mui/icons-material/QrCode2';


import { styled as muiStyled } from '@mui/system';

import ProgressIndicator from 'tsui/ProgressIndicator';
import Env from '../../../../env';

import CardQrShowDialog from 'dialogs/CardQrDialog';
import { useTranslation } from 'react-i18next';


const ImageComponent = muiStyled('img')({});


interface MobileDisplayPanelProps {

    cardId: string

    imageUploadingIndicator: string
    showImage: string
    fileNameRef: string
    inputFileRef: string
    chosenLangEn: boolean
    chosenLangAm: boolean
    chosenLangRu: boolean
    onSelectedFileChange: (e: any) => void
    handleChange: (event: any) => void
    onBrowseClick: () => void
    onRemove: () => void
}

export default function MobileDisplayPanel(props: MobileDisplayPanelProps) {
    const [t] = useTranslation();

    const [showQr, setShowQr] = React.useState(false)

    // const getFieldValue = React.useCallback((name: string, lang?: string) => { return props.getFieldValue(name, lang) }, []);
    // const form = props.form
    let imageUploadingIndicator = props.imageUploadingIndicator
    let showImage = props.showImage
    let fileNameRef = props.fileNameRef
    let inputFileRef = props.inputFileRef
    let chosenLangEn = props.chosenLangEn
    let chosenLangAm = props.chosenLangAm
    let chosenLangRu = props.chosenLangRu
    let onSelectedFileChange = props.onSelectedFileChange
    let handleChange = props.handleChange
    let onBrowseClick = props.onBrowseClick
    let onRemove = props.onRemove




    return (
        <>
            <Button onClick={() => { setShowQr(true) }} sx={{ width: 50 ,color: theme_.themeColor, border: `2px dashed ${theme_.themeColor}`, "&:hover": { color: 'white', backgroundColor: theme_.themeColor } }}><QrCode2Icon sx={{ fontSize: 40 }} /> </Button>
            {imageUploadingIndicator !== '' && <ProgressIndicator title={imageUploadingIndicator} />}
            {showImage !== '' && <ImageComponent sx={{ width: 150, height: 150, justifySelf: 'center', borderRadius: '50%' }} src={Env.getProfileImageLink(props.cardId, showImage)} />}
            {showImage === '' && imageUploadingIndicator === '' && <ImageComponent sx={{ width: 150, height: 150, justifySelf: 'center', borderRadius: '50%' }} src={fileNameRef ? Env.getProfileImageLink(props.cardId, fileNameRef) : Env.profileBlankImage} />}

            <input
                style={{ display: 'none' }}
                ref={inputFileRef}
                onChange={onSelectedFileChange}
                type='file'
            />
            {/* <Typography>{fileName}</Typography> */}
            {(showImage === '' && fileNameRef === '') &&
                <Button variant='outlined' onClick={onBrowseClick} sx={{ mt: 1 }}>
                    {t('Choose image')}
                </Button>
            }

            {(showImage !== '' || fileNameRef !== '') &&
                <Button variant='outlined' onClick={onRemove} sx={{ mt: 1 }}>
                    {t('Remove image')}
                </Button>
            }



            <Box sx={{ display: 'grid', mt: 2 }}>

                <Typography sx={{ mb: 2 }}>{t('Choose languages')} </Typography>
                <Box sx={{ display: 'flex', mt: -1, justifyContent: 'center' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                            id="en"
                            name="eng"
                            onChange={handleChange}
                            checked={chosenLangEn}
                        />
                        <Typography>ENG</Typography>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                        <Checkbox
                            // type="checkbox"
                            id="am"
                            name="հայ"
                            onChange={handleChange}
                            checked={chosenLangAm}
                        />

                        <Typography>ՀԱՅ</Typography>

                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center' }}>

                        <Checkbox
                            // type="checkbox"
                            id="ru"
                            name="рус"
                            onChange={handleChange}
                            checked={chosenLangRu}
                        />

                        <Typography>РУС</Typography>

                    </Box>

                    {/* <button onClick={handleClick}>Click</button> */}
                </Box>
            </Box>

            {showQr && <CardQrShowDialog cardId={props.cardId} onClose={() => { setShowQr(false) }} />}

        </>
    )
}




