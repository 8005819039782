import { ThemeInstance } from "./ThemeInstance";

export class LiteTheme extends ThemeInstance {

    //
    // Layout
    //
    // pageMinWidth = 800;
    // pageMinHeight = 800;

    // pageHorzMargin = '1%';
    // pageTopMargin = 0;
    // pageBottomMargin = 0;

    // pageHeaderHeight = 165;



    // pageBackgroundColor = '#F5F5F5';  // White Smoke
    // //pageTextColor = '#2d2d30';  // VS Code dark theme color
    // pageTextColor = '#1F3F3f';  // VS Code dark theme color


};