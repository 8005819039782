import React from 'react';

import theme_ from 'theme/Theme';
import {PageContainer} from 'components/PageContainer';

import PageHeader from './PageHeader';
import {Box} from '@mui/material';

interface PageContentsProps {
    type?: 'data'; // Contents type

    title?: string;
    current?: string;

    children: React.ReactNode;
}

export const PageContentsId = 'PageContentsId';

export default function PageContents(props: PageContentsProps) {
    if (props.type === 'data') {
        return (
            <PageContainer>
                <PageHeader title={props.title} current={props.current} />
                <Box
                    id='ContentsContainer'
                    sx={{
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: theme_.pageHeaderHeight,
                        bottom: 0,
                        paddingLeft: theme_.pageHorzMargin,
                        paddingRight: theme_.pageHorzMargin,
                        paddingTop: theme_.pageTopMargin,
                        paddingBottom: theme_.pageBottomMargin,
                    }}
                >
                    <Box
                        id='ContentsData'
                        sx={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: theme_.pageBackgroundColor,
                        }}
                    >
                        {props.children}
                    </Box>
                </Box>
            </PageContainer>
        );
    }

    return (
        <PageContainer>
            <PageHeader title={props.title} current={props.current} />
            <Box
                id={PageContentsId}
                sx={{
                    position: 'absolute',

                    width: '100%',
                    minWidth: theme_.pageMinWidth,

                    top: theme_.pageHeaderHeight,
                    bottom: 0,
                    left: 0,
                }}
            >
                {props.children}
            </Box>
        </PageContainer>
    );
}
