import React from 'react'

import FormImplBody from './FormElements/FormImplBody'
import {computeFormAtts, FormImplProps} from './FormElements/FormImpl'
import FormAlertSection from './FormElements/FormAlertSection'
import PageDialog from '../PageDialog'


import * as FT from './FormTypes'


export function PageFormDialog(props: FT.FormProps) {
    if (props.show === false) return <></>

    return (
        <FormImplDialog {...props}>
            {props.children}
        </FormImplDialog>
    )
}


function FormImplDialog(props: FormImplProps) {
    const atts = computeFormAtts(props)

    return (
        <PageDialog
            // type='cancel-confirm'
            type={props.type ? props.type : 'cancel-confirm'}
            {...props}
            form={undefined}
            contentsForm={props.form}
            contentsFormProps={props}
        >
            {/* <FormAlertSection {...props} /> */}
            <FormImplBody formAtts={atts} {...props}>
                {props.children}
            </FormImplBody>

        </PageDialog>
    )

}
