import React from 'react';

import { Link } from 'react-router-dom';

import Env from 'env';

import theme_ from 'theme/Theme';
import PageImage from 'tsui/PageImage';

export default function PageLogo() {
    return (
        <>
            <Link to='/'>
                <PageImage
                    src='/images/company-logo-dark.svg'
                    alt='Evotek'
                    width={theme_.pageLogoWidth}
                    sx={{
                        m: Env.isMobile ? 1 : 2,
                        mt: Env.isMobile ? 1.5 : 4.5,
                        ml: Env.isMobile ? 1.5 : 0, 
                        '@media screen and (min-width: 90em)': {
                            ml: 27,
                        },
                    }}

                />
            </Link>
        </>
    );
}
