import React from 'react';

import { Alert, Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { makeError } from '../tslib/error';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import * as Api from "api";
import { ShoppingCartDialog } from './ShoppingCartDialog';
import { ShoppingCartData } from 'data/shopping_cart_data';
import * as GD from "data/GlobalDispatch"
import session_ from 'api/session';

interface Props {
    error: Error | string | undefined | null
}

export function ShoppingCart() {
    const [t] = useTranslation();
    const mounted = React.useRef(false);
    const [dataRequested, setDataRequested] = React.useState(false);
    let [error, setError] = React.useState<Error | null>(null);
    const [open, setOpen] = React.useState(false)
    const [data, setData] = React.useState<ShoppingCartData[] | null>(null);
    const [totalQuantityCards, setTotalQuantityCards] = React.useState(0);


    React.useEffect(() => {
        mounted.current = true;

        const cardListener = () => {
            setDataRequested(false)
        }
        GD.pubsub_.addListener(GD.cardListenerId, cardListener)

        return () => {
            mounted.current = false;
            GD.pubsub_.removeListener(GD.cardListenerId, cardListener);
        }
    }, [])


    React.useEffect(() => {

        if (!dataRequested) {
            setDataRequested(true);

            let lang: string | undefined
            if (session_.userLang === '' || session_.userLang === undefined) {
                lang = undefined
            } else {
                lang = session_.userLang
            }
            Api.requestSessionJson<any>('cart', 'fetch', { language: lang ?? 'en' }).then(d => {
                if (!mounted.current) return;

                let shCartData: ShoppingCartData[] = [];

                if (d.shoppingCartData) {
                    for (let s of d.shoppingCartData) {
                        shCartData.push(new ShoppingCartData(s));
                    }
                    setData(shCartData)
                } else {
                    setData(null)
                }

                setTotalQuantityCards(d.totalQuantity)
            }).catch(setError);

            return;
        }
    }, [dataRequested]);

    // data!.map((item) => {
    //     console.log(item)
    // })
    // if(change === true) {
    //     console.log('asdasdasdasdasd')
    // }

    if (!data) return <></>

    return (
        <>
            <Button onClick={() => { setOpen(true) }} sx={{ width: 50, height: 50, pl: 6, "&:hover": { backgroundColor: 'transparent', borderColor: 'transparent' } }}><AddShoppingCartIcon sx={{ color: theme_.themeColor, fontSize: 'x-large' }} />

                {/* <Box sx={{ borderRadius: 10, mt: -3, backgroundColor: theme_.themeColor, p: 0.5 }}> */}
                <Typography sx={{ color: theme_.inputTextBackgroundColor, mt: -3 }}>{totalQuantityCards > 0 ? `+${totalQuantityCards}` : totalQuantityCards}</Typography>
                {/* </Box> */}

            </Button>

            {open && <ShoppingCartDialog onClose={() => { setOpen(false) }} />}
        </>
    );
}

// import * as FT from "../tsui/Form/FormTypes";

// export function ProcessFormSubmit(props: FT.FormProps, err: Error | string) {
//     let form = props.form;

//     form.setError(err);

// }
