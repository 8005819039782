import { useNavigate } from 'react-router-dom';

import { Box, Button, ListItem, Typography } from '@mui/material';

import { useTranslation } from 'react-i18next';


import Env from 'env';

interface NavButtonProps {
    tag: string;
    current: string;
    show: boolean;
    title: string;
    href: string;
}
export function PageNavItem(props: NavButtonProps) {

    const [t] = useTranslation();

    const navigate = useNavigate();

    let onClick = () => {
        if (props.href) {
            navigate(props.href);
        }
    };

    if (!props.show) return <></>;

    return (
        <Box sx={{ display: 'grid', textAlign: 'center', alignItems: 'center', minWidth: 160 }}>
            <Button
                variant='outlined'
                onClick={onClick}
                sx={{
                    display: 'block',
                    minWidth: 160,
                    border: 'none',
                    borderColor: 'transparent',
                    "&:hover": { backgroundColor: "transparent", borderColor: 'transparent', color: theme_.themeColor }
                }}
            >
                <Typography>{t(props.title)}</Typography>
            </Button>
            {props.current === props.tag && <Box sx={{ display: 'block', maxWidth: 80, minWidth: 120, height: 3, backgroundColor: theme_.themeColor, mr: 'auto', ml: 'auto' }}></Box>}
        </Box>
    );
}
