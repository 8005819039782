import React from 'react';

import { useNavigate } from "react-router-dom";

import Typography from '@mui/material/Typography';

import { Box, styled as muiStyled } from '@mui/system';
import { Button, Divider, Link, Stack } from '@mui/material';

import { useTranslation } from 'react-i18next';

import Env from 'env';

import CardCategory from 'components/CardCategory';
import { cardCategoryArray_ } from 'data/card';


import session_ from 'api/session';

import ImgElement from 'tsui/DomElements/ImgElement';

interface MobileEvocardFeautresDisplayProps {
    selectedMenu: boolean
}

export default function MobileEvocardFeautresDisplay(props: MobileEvocardFeautresDisplayProps) {

    const [t] = useTranslation();
    const navigate = useNavigate();

    let portrait = window.matchMedia("(orientation: landscape)");

    // window.addEventListener("orientationchange", (event) => {
    //     window.location.reload();
    // });

    return (
        <>
            {!props.selectedMenu &&
                <Stack direction='column' sx={{ mt: 5 }}>
                    {/* <Stack direction='column' width='100%' justifyContent='center' alignItems='center' spacing={5}> */}
                    {/* <Stack direction={portrait.matches ? 'row' : 'column'} width='100%' justifyContent='center' alignItems='center' spacing={5}> */}
                    <Box sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',

                        '@media screen and (orientation:landscape)': {
                            display: 'flex'
                        },
                    }}>
                        <Box sx={{
                            py: 2,
                            '@media screen and (orientation:landscape)': {
                                py: 0,
                                px: 1,
                            },
                        }}>
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, height: 230, width: 250 }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeMobile }} src="/images/home_page_icons/nfc_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.nfc_chip")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.share_details")}</Typography>
                                </Box>
                            </Box>
                        </Box>

                        <Box sx={{
                            py: 2,
                            '@media screen and (orientation:landscape)': {
                                py: 0,
                                px: 1,
                            },
                        }}>
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, height: 230, width: 250 }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeMobile }} src="/images/home_page_icons/mobile_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.plug_and_play")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.share_and_receive")}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        {/* </Stack> */}
                    </Box >

                    {/* <Stack direction='column' width='100%' justifyContent='center' alignItems='center' spacing={5}> */}
                    {/* < Stack direction={portrait.matches ? 'row' : 'column'} width='100%' justifyContent='center' alignItems='center' spacing={5} > */}
                    <Box sx={{
                        mt: 2,
                        '@media screen and (orientation:landscape)': {
                            display: 'flex'
                        },
                    }}>
                        {/* <Stack> */}
                        <Box sx={{
                            py: 2,
                            '@media screen and (orientation:landscape)': {
                                py: 0,
                                px: 1,
                            },
                        }}>
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, height: 230, width: 250 }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeMobile }} src="/images/home_page_icons/qr_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.qr_codes")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.scan_qr")}</Typography>
                                </Box>
                            </Box>
                            {/* </Stack> */}
                        </Box>
                        {/* <Stack> */}
                        <Box sx={{
                            py: 2,
                            '@media screen and (orientation:landscape)': {
                                py: 0,
                                px: 1,
                            },

                        }}>
                            <Box sx={{
                                borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, height: 230, width: 250
                            }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeMobile }} src="/images/home_page_icons/device_support_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.device_support")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.android_and_ios")}</Typography>
                                </Box>
                            </Box>
                            {/* </Stack> */}
                        </Box>
                        {/* </Stack > */}
                    </Box >
                </Stack >
            }
            {
                props.selectedMenu &&
                // <Stack direction='column' spacing={5} sx={{ mt: 5 }}>
                <Stack direction='column' sx={{ mt: 5 }}>
                    {/* <Stack direction='column' width='100%' justifyContent='center' alignItems='center' spacing={5}> */}
                    {/* <Stack direction={portrait.matches ? 'row' : 'column'} width='100%' justifyContent='center' alignItems='center' spacing={5}> */}

                    <Box sx={{
                        width: '100%',
                        justifyContent: 'space-between',
                        alignItems: 'center',

                        '@media screen and (orientation:landscape)': {
                            display: 'flex'
                        },
                    }}>
                        <Box sx={{
                            py: 2,
                            '@media screen and (orientation:landscape)': {
                                py: 0,
                                px: 1,
                            },
                        }}>
                            {/* <Stack > */}
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, height: 230, width: 250 }}>
                                <Box sx={{ alignSelf: 'center' }}>
                                    <ImgElement sx={{ height: theme_.featureIconSizeMobile }} src="/images/home_page_icons/reduce_waste_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.reduce_waste")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.limit_carbon")}</Typography>
                                </Box>
                            </Box>
                            {/* </Stack> */}
                        </Box>
                        {/* <Stack> */}
                        <Box sx={{
                            py: 2,
                            '@media screen and (orientation:landscape)': {
                                py: 0,
                                px: 1,
                            },
                        }}>
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, height: 230, width: 250 }}>

                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeMobile }} src="/images/home_page_icons/gain_an_edge_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.gain_edge")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.impress")}</Typography>
                                </Box>
                            </Box>
                            {/* </Stack> */}
                        </Box>

                        {/* </Stack> */}
                    </Box>

                    {/* <Stack direction='column' width='100%' justifyContent='center' alignItems='center' spacing={5}> */}
                    {/* <Stack direction={portrait.matches ? 'row' : 'column'} width='100%' justifyContent='center' alignItems='center' spacing={5}> */}
                    <Box sx={{
                        mt: 2,
                        '@media screen and (orientation:landscape)': {
                            display: 'flex'
                        },
                    }}>
                        <Box sx={{
                            py: 2,
                            '@media screen and (orientation:landscape)': {
                                py: 0,
                                px: 1,
                            },
                        }}>
                            {/* <Stack > */}

                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, height: 230, width: 250 }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeMobile }} src="/images/home_page_icons/save_on_costs_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.save_on_costs")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.remove_line_item")}</Typography>
                                </Box>
                            </Box>
                            {/* </Stack> */}
                        </Box>
                        {/* <Stack> */}
                        <Box sx={{
                            py: 2,
                            '@media screen and (orientation:landscape)': {
                                py: 0,
                                px: 1,
                            },
                        }}>
                            <Box sx={{ borderRadius: theme_.cardBorderRadius, backgroundColor: theme_.homeBoxBackground, boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', p: 5, height: 230, width: 250 }}>
                                <Box sx={{ alignSelf: 'center' }}>

                                    <ImgElement sx={{ height: theme_.featureIconSizeMobile }} src="/images/home_page_icons/language_icon.svg" />
                                    <Typography sx={{ fontSize: 'x-large' }}>{t("home.choose_different_language")}</Typography>
                                    <Typography sx={{ fontFamily: 'inherit', fontSize: 'large' }}>{t("home.several_languages")}</Typography>
                                </Box>
                            </Box>
                            {/* </Stack> */}
                        </Box>
                        {/* </Stack> */}
                    </Box>
                </Stack>
            }
        </>
    )
}