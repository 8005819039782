import React from 'react';

import {Box, Stack, CircularProgress, Typography} from '@mui/material';

import {useTranslateLabel} from './I18n/TranslateLabel';

export interface ProgressIndicatorProps {
    show?: boolean;
    title?: string;
    tlabel?: string;
    background?: 'data' | 'dialog';
}

export default function ProgressIndicator(props: ProgressIndicatorProps) {
    const [label, t] = useTranslateLabel(props, 'Loading...');

    let color = theme_.dialogTextColor;

    return (
        <Box
            display='flex'
            justifyContent='center'
            sx={{
                mt: 2,
            }}
        >
            <Stack direction='column' spacing={2} justifyContent='center' alignItems='center'>
                <Typography
                    align='center'
                    sx={{
                        fontSize: 'x-large',
                        color: color,
                    }}
                >
                    {label as string}
                </Typography>

                <CircularProgress
                    sx={{
                        '& svg': {
                            color: color,
                        },
                    }}
                />
            </Stack>
        </Box>
    );
}
