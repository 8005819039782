import React from 'react';

import {useNavigate} from 'react-router-dom';

import {Button} from '@mui/material';

import {useTranslation} from 'react-i18next';

import {SxPropsParam} from 'tsui/Mui/SxPropsUtil';

interface AuthButtonProps {
    label: string;
    href?: string;
    onClick?: () => void;
    sx?: SxPropsParam;
}

export function AuthButton(props: AuthButtonProps) {
    const navigate = useNavigate();
    const {t, i18n} = useTranslation();

    const onClick = React.useCallback(() => {
        if (props.href) {
            navigate(props.href);
        } else if (props.onClick) {
            props.onClick();
        }
    }, [props.href, props.onClick]);

    // return <AuthButtonStyle onClick={onClick}>{t(props.label as string)}</AuthButtonStyle>

    return (
        <Button
            variant='outlined'
            onClick={onClick}
            sx={{
                minWidth: 80,
                border: 'none',
                borderColor: 'transparent',
                "&:hover": { backgroundColor: "transparent", borderColor: 'transparent', color: theme_.themeColor }
            }}
        >
            {t(props.label!)}
        </Button>
    );
}
