import React from "react";
import { Grid, Tabs, Tab, Box } from "@mui/material";

import { useSearchParams } from "react-router-dom";
import UserCardDetailsModalAccountBusinessName from "./business/name/CardDetailsBusinessName";
import UserCardDetailsModalAccountBusinessContact from "./business/contact/CardDetailsBusinessContact";
import UserCardDetailsModalAccountBusinessSocialNetworks from "./business/socialNetworks/CardDetailsBusinessSocialNetworks";
import UserCardDetailsModalAccountBusinessLocation from "./business/location/CardDetailsBusinessLocation";
import UserCardDetailsModalAccountBusinessWorkingHours from "./business/workingHours/CardDetailsBusinessWorkingHours";
import { InputFormContext } from "tsui/Form/FormContext";
import Env from "env";
import BusinessMan from "./business/business_man/BusinessMan";
import BusinessCompany from "./business/business_company/BusinessCompany";


interface CardDetailsDialogProps {
    form: InputFormContext
    chosenLang: Array<string>
    getFieldValue: (name: string, lang?: string) => string
    getFieldAtt: (lang: string, fieldName: string) => boolean

}

export default function CardDetailsDialog(props: CardDetailsDialogProps) {

    const form = props.form;

    const [params] = useSearchParams();

    let startTab = params.get("tab") ?? "businessMan";

    const [selectedTab, setSelectedTab] = React.useState(startTab);
    const onTabChange = React.useCallback((evt, value) => {
        setSelectedTab(value);
    }, []);

    // return <Grid container spacing={2} height='100%' sx={{ display: 'grid' }}>
    //     <Grid item xs={8}>

    return (<Box sx={{ display: 'block' }}>
        <Box sx={{
            borderBottom: 1,
            borderColor: 'divider',
        }}>
            <Tabs
                value={selectedTab}
                onChange={onTabChange}
                orientation="horizontal"
                sx={{
                    borderBottom: 1,
                    borderColor: 'divider',
                }}>

                {/* <Tab value='name' label="Name" />
                <Tab value='contact' label="Contact" />
                <Tab value='socialNetworks' label="Social Networks" />
                <Tab value='location' label="Location" />
                <Tab value='workingHours' label="Working Hours" /> */}
                <Tab value='businessMan' label="Person" />
                <Tab value='businessCompany' label="Business" />

            </Tabs>
        </Box>

        {/* <Grid item xs marginBottom={10}> */}
        {/*
        {Env.isMobile &&
                {selectedTab === 'name' && <UserCardDetailsModalAccountBusinessName form={props.form} chosenLang={props.chosenLang} getFieldValue={props.getFieldValue} />}
                {selectedTab === 'contact' && <UserCardDetailsModalAccountBusinessContact form={props.form} getFieldValue={props.getFieldValue} />}
                {selectedTab === 'socialNetworks' && <UserCardDetailsModalAccountBusinessSocialNetworks form={props.form} getFieldValue={props.getFieldValue} />}
                {selectedTab === 'location' && <UserCardDetailsModalAccountBusinessLocation form={props.form} chosenLang={props.chosenLang} getFieldValue={props.getFieldValue} />}
                {selectedTab === 'workingHours' && <UserCardDetailsModalAccountBusinessWorkingHours form={props.form} getFieldValue={props.getFieldValue} />}
        }
    */}

        {!Env.isMobile && <>
            <BusinessMan tab={selectedTab} form={props.form}  chosenLang={props.chosenLang} getFieldValue={props.getFieldValue} getFieldAtt={props.getFieldAtt}/>
            <BusinessCompany tab={selectedTab} form={props.form} chosenLang={props.chosenLang} getFieldValue={props.getFieldValue} getFieldAtt={props.getFieldAtt}/>
        </>
        }
    </Box>);
}
