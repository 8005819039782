import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

//import HttpApi from 'i18next-http-backend';

import {langDefault} from './i18n_lib';
import {supportedLangs_} from './i18n_status';

//export * from 'i18next';

import * as tProfileEn from './profile-en.json';
import * as tProfileRu from './profile-ru.json';
import * as tProfileAm from './profile-am.json';

import en_translate from './translations/en/translation.json';
import am_translate from './translations/am/translation.json';
import ru_translate from './translations/ru/translation.json';



//console.debug(supportedLangs_.map(lng => lng.lang));

// learn more: https://github.com/i18next/i18next-http-backend
//i18next.use(HttpApi);

// detect user language
// learn more: https://github.com/i18next/i18next-browser-languageDetector
//.use(LanguageDetector)

// connect with React
i18next.use(initReactI18next);

// for all options read: https://www.i18next.com/overview/configuration-options
i18next.init({
    //debug: true,

    lng: langDefault,
    fallbackLng: langDefault,
    supportedLngs: supportedLangs_.map((lng) => lng.lang),

    defaultNS: 'translation',
    ns: 'translation',

    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
        useSuspense: false,
    },

    resources: {
        en: {
            translation: en_translate,
        },
        am: {
            translation: am_translate,
        },
        ru: {
            translation: ru_translate,
        },
    },

    // backend: {
    //     loadPath: '/translations/{{lng}}/{{ns}}.json',
    // },
});

i18next.addResourceBundle('en', 'profile', tProfileEn);
i18next.addResourceBundle('ru', 'profile', tProfileRu);
i18next.addResourceBundle('am', 'profile', tProfileAm);
