import React from "react";

import { useTranslation } from "react-i18next"


import * as Form from 'tsui/Form';
import { Box, Typography } from "@mui/material";
import PageDialog from "tsui/PageDialog";


interface Props {

    message: string | null;
    message2?: string;
    onCloseNull: (_: null) => void;
}


export default function ErrorAlertDialog(props: Props) {

    const [t] = useTranslation()


    if (!props.message)
        return <></>;


    return <PageDialog type='panel' title="Warning" size='sm' onCloseNull={props.onCloseNull}>

        <Box sx={{ display: 'grid' }}>
            <Typography sx={{ color: 'white', fontSize: 'larger', m: 2 }}>{t(props.message)}</Typography>
        </Box>

    </PageDialog>


}

// function muiStyled(arg0: string) {
//     throw new Error("Function not implemented.");
// }
