import * as ShoppingCartApi from 'api/shopping_cart'


export class ShoppingCartData {
    productId?: string;
    quantity?: number;
    price?: number;
    amount?: number;
    currency?: string;  
    cardType?: string;  
    isVisible?: boolean;


    getFieldValue() {
        
    }

    constructor(s?: ShoppingCartApi.ApiShoppingCart) {
        if (!s)
            return;

        this.productId = s.productId;
        this.quantity = s.quantity;
        this.price = s.price;
        this.amount = s.amount;
        this.currency = s.currency;
        this.cardType = s.cardType
        this.isVisible = true;
    }

}